import { STATUSES } from '@helpers/javascript/config/CONSTANTS'
import { travelTimeFor } from "./start"
// Dispatch
export const deliverWorkorderAction = id => dispatch =>
  dispatch({
    type:    WORKORDER_DELIVER.ACTION,
    payload: { id }
  })

// Reducer response
export const WORKORDER_DELIVER = {
  ACTION: 'WORKORDER_DELIVER',

  call: ({ state, id }) => ({
    ...state,
    items: state.items.map(item => item.id != id ? item : {
      ...item,
      manual_travel_time:  travelTimeFor(item),
      date_started:        new Date(),
      date_done:           new Date(),
      paused:              false,
      status:              STATUSES.IN_PROGRESS,
      synced:              false
    })
  })
}
