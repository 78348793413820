import React, { useRef, useEffect } from 'react';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js';
import { Dutch }      from 'flatpickr/dist/l10n/nl.js'
import { French }     from 'flatpickr/dist/l10n/fr.js'
import { German }     from 'flatpickr/dist/l10n/de.js'
import { Italian }    from 'flatpickr/dist/l10n/it.js'
import { Polish }     from 'flatpickr/dist/l10n/pl.js'
import { Spanish }    from 'flatpickr/dist/l10n/es.js'

const MonthPicker = ({ onSelect, defaultDate }) => {
    const monthInputRef = useRef(null);

    useEffect(() => {
        if (monthInputRef.current) {
          flatpickr(monthInputRef.current, {
            locale: I18n.locale,
            plugins: [new monthSelectPlugin({ shorthand: true, dateFormat: "F Y", altFormat: "F Y" })],
            dateFormat: "Y-m", 
            altInput: true,
            altFormat: "F Y",
            defaultDate: new Date(defaultDate),
            onChange: (selectedDates) => { onSelect(selectedDates[0]) }
          });
        }
    }, [defaultDate]);

    return (
        <input ref={monthInputRef} type="text" placeholder={I18n.t("calendar.select_month")} />
    );
}

export default MonthPicker;
