import React        from 'react'
import { AddFiles } from '@shared/addFiles';

export const UploadFiles = ({ workorderState, setWorkorderState }) => {
  const addedFiles = [...workorderState.files].concat([...workorderState.photos])
  const addFiles = filesAndImages => {
    const photos = filesAndImages.filter(file => file.filetype.match('image/*'))
    const files  = filesAndImages.filter(file => !file.filetype.match('image/*'))
    setWorkorderState({ ...workorderState, files: workorderState.files.concat(files), photos: workorderState.photos.concat(photos) })
  }

  const removePhoto = index => {
    const copiedPhotos = [...workorderState.photos]
    const photoToRemove = workorderState.photos.findIndex(photo => photo.url == addedFiles[index].url)
    copiedPhotos.splice(photoToRemove, 1)
    setWorkorderState({ ...workorderState, photos: copiedPhotos })
  }

  const removeFile = index => {
    const copiedFiles = [...workorderState.files]
    const fileToRemove = workorderState.files.findIndex(file => file.url == addedFiles[index].url)
    copiedFiles.splice(fileToRemove, 1)
    setWorkorderState({ ...workorderState, files: copiedFiles })
  }

  return (
    <AddFiles 
      prompt        = { "workorders.add_document_or_photo" }
      addedFiles    = { addedFiles } 
      onAddedFiles  = { addFiles } 
      onRemoveFile  = { removeFile }
      onRemoveImage = { removePhoto }
      multiple
    />
  );
};
