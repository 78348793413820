import React       from "react"
import PriceInput  from "./price_input.js"
import AnnualTable from "./annual_table.js"

import { roundNumber } from "@react/quotes/form/helpers"

export default class AnnualPercentages extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      beginning_price : this.props.beginning_price,
      percentages     : this.props.percentages,
      checkboxes      : this.props.checkboxes,
      subscription    : this.props.subscription,
      editable        : false
    }
    this.handleSubscriptionChange = this.handleSubscriptionChange.bind(this)
    this.handlePriceChange        = this.handlePriceChange.bind(this)
    this.handleMonthChange        = this.handleMonthChange.bind(this)
    this.handleCheckbox           = this.handleCheckbox.bind(this)
    this.checkAll                 = this.checkAll.bind(this)
  }

  handlePriceChange(e) {
    const multiplier = this.state.subscription == this.props.yearly ? 1 : 12
    this.setState({beginning_price : e.target.value * multiplier })
  }

  handleSubscriptionChange(e) {
    const subscription    = e.target.value
    const multiplier      = subscription == this.props.monthly ? 12 : 1 / 12
    const beginning_price = roundNumber(this.state.beginning_price * multiplier)
    this.setState({ subscription, beginning_price })
  }

  handleMonthChange(index, value, event) {
    const percentages = this.state.percentages
    percentages[index] = value
    this.setState({ percentages })
  }

  handleCheckbox(index) {
    const checkboxes = this.state.checkboxes
    checkboxes[index] = !this.state.checkboxes[index]
    const percentages = this._recalculatePercentages(checkboxes)
    this.setState({ checkboxes, percentages })
  }

  checkAll(event) {
    const checked    = event.target.checked
    const checkboxes = this.state.checkboxes.map(checkbox => checked)
    const percentages = this._recalculatePercentages(checkboxes)
    this.setState({ percentages, checkboxes })
  }

  _recalculatePercentages(checkboxes) {
    let months = 0
    const newPercentages = []
    checkboxes.forEach(checkbox => checkbox ? months += 1 : null)
    const monthlyPartial = this._round(100 / months)
    return checkboxes.map(checkbox => checkbox ? monthlyPartial : 0)
  }

  _round(amount) {
    return Math.round(amount * 10000000) / 10000000
  }

  render () {
    return (
      <div>
        <div className="form-group">
          <label htmlFor="contract_invoice_monthly">
            {I18n.t("maintenance.contracts.subscription")}
          </label>

          <select
            className = "form-control"
            name      = "contract[subscription]"
            value     = {this.state.subscription}
            onChange  = {this.handleSubscriptionChange}
          >
            <option value={this.props.yearly}>{I18n.t("workorders.yearly")}</option>
            <option value={this.props.monthly}>{I18n.t("workorders.monthly")}</option>
          </select>
        </div>

        <PriceInput
          value    = {this.state.beginning_price}
          onChange = {this.handlePriceChange}
        />

        <AnnualTable
          labels            = {this.props.labels}
          handleMonthChange = {this.handleMonthChange}
          price             = {this.state.beginning_price}
          percentages       = {this.state.percentages}
          checkboxes        = {this.state.checkboxes}
          editable          = {this.state.editable}
          handleCheckbox    = {this.handleCheckbox}
          checkAll          = {this.checkAll}
          currency_symbol   = {this.props.currency_symbol}
        />
      </div>
    )
  }
}
