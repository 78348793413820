import React             from "react"
import { ResponsivePie } from '@nivo/pie'
import { EmptyCard }     from "./EmptyCard"
import { parseMoney }    from "../../../application/helpers/helpers"

export const TopTypes = ({ data, type, currency }) => {
  const STATUS_HEIGHT     = 600
  if (!data?.props_top_types) return

  return (
    <div className="dashboard__card mr-3 w-92vw">
      <h3>{I18n.t(`dashboard.percentage_${type}`)}</h3>
        {
          !data.props_top_types.length ? <EmptyCard />
          :
          <div style={{ height: `${STATUS_HEIGHT}px`}}>
            <ResponsivePie
              data                             = {data.props_top_types}
              margin                           = {{ top: 20, right: 300, bottom: 20, left: 40 }}
              innerRadius                      = {0.5}
              padAngle                         = {0.7}
              cornerRadius                     = {3}
              borderWidth                      = {1}
              sliceLabel                       = {({ value }) => `${parseMoney(value, currency)}`}
              radialLabel                      = {d => d.label}
              radialLabelsSkipAngle            = {10}
              radialLabelsTextXOffset          = {6}
              radialLabelsTextColor            =  "#333333"
              radialLabelsLinkDiagonalLength   = {16}
              radialLabelsLinkHorizontalLength = {24}
              radialLabelsLinkStrokeWidth      = {1}
              radialLabelsLinkColor            = {{ from: 'color' }}
              slicesLabel                      = {d => d.label}
              slicesLabelsSkipAngle            = {10}
              slicesLabelsTextColor            = "#333333"
              startAngle                       = {0}
              animate
              motionStiffness                  = {90}
              motionDamping                    = {15}
              tooltip = { ({ value, id }) => (
                <div style={{background: 'white'}}>
                  {id} : {parseMoney(value, currency)}
                </div>
              )}
              legends = {[
                {
                  anchor: 'right',
                  direction: 'column',
                  justify: false,
                  translateY: 0,
                  itemsSpacing: 20,
                  itemWidth: 180,
                  itemHeight: 10,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape:   'circle',
                  effects: [{
                      on: 'hover',
                      style: { itemTextColor: '#000' }
                  }]
                }
              ]}
            />
          </div>
        }
    </div>
  )
}
