import React from "react"

// Helpers
import {lineNameFor} from '../../helpers'

export default class Option extends React.Component {
  onChange(e) {
    const { index, replaceLine, qline } = this.props
    replaceLine({...qline, option: e.target.checked}, index)
  }

  render() {
    const { qline, index } = this.props

    return(
      <div className="checkbox checkbox-primary">
        <input
          type="hidden"
          defaultValue="0"
          name={lineNameFor("is_option", index)}
        />

        <input
          type="checkbox"
          id={`option-${index}`}
          checked={qline.option}
          defaultValue="1"
          onChange={this.onChange.bind(this)}
          name={lineNameFor("is_option", index)}
        />

        <label
          className="control-label"
          htmlFor={`option-${index}`}
        >
          {I18n.t("quotes.new.option")}
        </label>
      </div>
    )
  }
}
