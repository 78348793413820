import React, { useState } from 'react'

import ComboboxMulti from '@shared/combobox_multi'

const TechnicianSelect = props => {

  const [state, setState] = useState({
    team:  null,
    value: []
  })

  const teams   = props.teams.accountTeams
  const options = props.teams.allTechs.values
  const value   = state.value

  const onSelect = (team, index) => {
    if (props.onChange) props.onChange(team.values)

    setState({ team: index, value: team.values })
  }

  const onChange = value => {
    if (props.onChange) props.onChange(value)

    setState({ team: null, value })
  }

  const onClear = () => {
    if (props.onChange) props.onChange([])

    setState({ team: null, value: []})
  }

  return(
    <>
      <ComboboxMulti {...{ ...props, options, value, onChange, onClear }}/>
      <div className="timesheet__filters__title">
        {I18n.t("users.timesheets.your_teams")}
      </div>

      {teams.map((team, index) => (
        <span
          key       = {index}
          className = {`autocomplete__shortcut ${state.team === index ? 'autocomplete__shortcut--selected' : ''}`}
          onClick   = {() => onSelect(team, index)}
        >
          {team.name}
        </span>
      ))}
    </>
  )
}

export default TechnicianSelect
