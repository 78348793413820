import React, { useState, useEffect } from "react"
import Autocomplete  from "./autocomplete"

const PATHNAME = 'https://api.mapbox.com/geocoding/v5/mapbox.places/{query}.json'
const HEADERS  = {
  'Content-Type':     'application/json',
  'Accept':           'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'Cache-Control':    'no-cache',
}

const THROTTLE = 200
const formatAddress = addressObj => {
  if(!addressObj) return({})

  const lat     = addressObj.center[1]
  const lng     = addressObj.center[0]

  const address = addressObj[`place_name_${I18n.locale}`]

  return ({ address, lat, lng })
}

const Address = props => {
  const [suggestions,    setSuggestions]    = useState([])
  const [currentTimeout, setCurrentTimeout] = useState(null)
  const [address, setAddress] = useState('')

  const buildURL = query => {
    const path = PATHNAME.replace('{query}', query)
    const url  = new URL(path)
    url.searchParams.set('autocomplete', true)
    url.searchParams.set('fuzzyMatch',   true)
    url.searchParams.set('access_token', props.accessToken)
    url.searchParams.set('language',     I18n.locale)
    url.searchParams.set('types',        ['place', 'address'].join(","))
    if (props.coordinates) {
      url.searchParams.set('proximity', props.coordinates.join(","))
    }
    return url
  }

  const onChange = query => {
    if (currentTimeout) clearTimeout(currentTimeout)

    setCurrentTimeout(
      setTimeout(() => {
        const url = buildURL(query)
        fetch(url, { headers: HEADERS })
        .then(response => response.json())
        .then(data => {
          const parsedData = data.features.map(formatAddress)
          setSuggestions(parsedData)
        })
      }, THROTTLE)
    )
  }

  useEffect(() => {
    let addressEvent = new Event('address');
    let addressField = document.getElementById("address_field")
    if (address && addressField) {
      addressField.dispatchEvent(addressEvent);
    }
  }, [address])

  return(
    <Autocomplete
      defaultValue    = {props.defaultValue}
      identifier      = {props.shownValue}
      shownValue      = {props.shownValue}
      fieldId         = {props.fieldId}
      fieldName       = {props.fieldName}
      required        = {props.required}
      placeholder     = {props.placeholder}
      readonly        = {props.readonly}
      datasets        = {props.data}
      preSuggestions  = {suggestions}
      onChange        = {onChange}
      onSelectElement = {setAddress}
      isText
    />
  )
}

export default Address
