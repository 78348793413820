import React, { useEffect, useState } from "react"
import { FontAwesomeIcon }            from '@fortawesome/react-fontawesome'
import { ResponsiveCalendar }         from '@nivo/calendar'
import { bodyRequest }                from '@helpers/javascript/javascript'
import Shortcuts                      from '../../shared/shortcuts'

export const YearWorkorders = ({ url, users, updateDate, currentTeam, accountTeams, onChangeTeam }) => {
  const YEAR_HEIGHT = 200

  const [teamIndex,      setTeamIndex]      = useState(0)
  const [year,           setYear]           = useState(new Date().getFullYear())
  const [yearWorkorders, setYearWorkorders] = useState([])

  const updateDailyDate = (data, _event) => updateDate(data.date)

  const selectTeam = (team, index) => {
    setTeamIndex(index)
    onChangeTeam(team.values)
  }

  useEffect(() => {
    const userIds = [...new Set(users.map(u => u.id))]
    bodyRequest({
      method: 'POST', url: url,
      body: { year, users: userIds }
    }).then(setYearWorkorders)
  }, [year, users])

  return (
    <div className="dashboard__year">
      <div className="dashboard__card mr-3">
        <div className="dashboard__year__selectors">
          <div className="d-flex j-c-center a-i-center mb-3">
            <span onClick={() => setYear(year - 1)}>
              <FontAwesomeIcon icon="arrow-left" />
            </span>
            <h2>{year}</h2>
            <span onClick={() => setYear(year + 1)}>
              <FontAwesomeIcon icon="arrow-right" />
            </span>
          </div>
          <div>
            <div className="timesheet__filters__title">{I18n.t('dashboard.teams')}</div>
            <div className="dashboard__today__team">
              <Shortcuts
                identifier            = 'id'
                shownValue            = 'name'
                shortcuts             = {accountTeams}
                shortcutSelectedIndex = {teamIndex}
                onClickShortcut       = {selectTeam}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard__card">
        <div className="dashboard__year__calendar" style={{ height: `${YEAR_HEIGHT}px` }}>
          <ResponsiveCalendar
            data        = {yearWorkorders}
            from        = {new Date(year, 0, 1).toISOString()}
            to          = {new Date(year, 11, 31, 23, 59, 59).toISOString()}
            height      = {YEAR_HEIGHT}
            align       = "left"
            emptyColor  = "#eeeeee"
            colors      = {['#9EDCF5', '#65C7EF', '#1587F3', '#006FAE', '#00364D']}
            margin      = {{ top: 40, right: 40, bottom: 40, left: 40 }}
            yearSpacing = {40}
            monthBorderColor = "#ffffff"
            dayBorderWidth   = {2}
            dayBorderColor   = "#ffffff"
            onClick          = {updateDailyDate}
            monthLegend      = {(_year, _month, date) => date.toLocaleDateString(I18n.locale, { month: 'short' })}
            tooltip          = {({ day, value }) => (
              <>{new Date(day).toLocaleDateString(I18n.locale)}: <strong>{value}</strong></>
            )}
            legends={[{
                anchor:        'bottom-left',
                direction:     'row',
                translateY:    36,
                itemCount:     4,
                itemWidth:     42,
                itemHeight:    36,
                itemsSpacing:  14,
                itemDirection: 'right-to-left'
            }]}
          />
        </div>
      </div>
    </div>
  )
}
