import React        from "react"
import LineBase     from "./line_base"

// Components
import Buttons       from "./shared/buttons"
import HiddenInputs  from "./shared/hidden_inputs"

// Helpers
import { formatNumber } from "../helpers"

export default class LineInactive extends LineBase {
  render() {
    const { index, qline, showCode, buttonProps } = this.props

    const unit_price = parseFloat(qline.unit_price) || 0
    const quantity   = parseFloat(qline.quantity)   || 0
    const total      = unit_price * quantity

    const discount       = parseFloat(qline.discount)
    const discountAmount = total * (discount || 0) / 100

    const description = qline.option
    ? `<i>${I18n.t('quotes.pdf.optional_qline')}<i><p>&nbsp;</p>` +
      qline.description
    : qline.description

    return(
      <React.Fragment>
        <div className="quotes--form-pointer quotes--form-table-row" onClick={this.activate}>
          {
            showCode
            ? <div className="code-column">
                {qline.ref_product_distrib}
              </div>
            : ""
          }

          <div className="description-column"
            dangerouslySetInnerHTML={{__html: description}}
          ></div>

          <div className="price-column">
            {formatNumber(unit_price)}
          </div>

          <div className="amount-column">
            {formatNumber(quantity)}
          </div>

          <div className="unit-column">
            {qline.unit}
          </div>

          <div className="vat-column">
            {formatNumber(qline.vat_rate.percentage)}
          </div>

          <div className="total-column">
            {formatNumber(total)}
            {qline.option ? " *" : ""}
          </div>

          <Buttons
            index={index}
            qline={qline}
            buttonProps={buttonProps}
          />
        </div>

        { discount && discountAmount > 0
          ? <div className="quotes--form-table-row">
              { showCode ? <div className="code-column"></div> : "" }
              <div className="description-column">
                {I18n.t("quotes.pdf.discount", {discount: formatNumber(discount)})}
              </div>
              <div className="price-column"></div>
              <div className="amount-column"></div>
              <div className="unit-column"></div>
              <div className="vat-column"></div>
              <div className="total-column">
                -
                {formatNumber(discountAmount)}
                {qline.option ? " *" : ""}
              </div>
            </div>
          : ""
        }

        <HiddenInputs qline={qline} index={index}/>
      </React.Fragment>
    )
  }
}
