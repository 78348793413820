import React          from 'react'
import Flatpickr      from "react-flatpickr"
import { Dutch }      from 'flatpickr/dist/l10n/nl.js'
import { French }     from 'flatpickr/dist/l10n/fr.js'
import { German }     from 'flatpickr/dist/l10n/de.js'
import { Italian }    from 'flatpickr/dist/l10n/it.js'
import { Polish }     from 'flatpickr/dist/l10n/pl.js'
import { Spanish }    from 'flatpickr/dist/l10n/es.js'

const SharedFlatpickr = ({
  options, className, placeholder, onChange, value, name, dataAction, dataTarget, required, onClose
}) => {

  return (
    <Flatpickr
      options     = { options }
      className   = { className }
      placeholder = { placeholder }
      onChange    = { onChange }
      value       = { value }
      name        = { name }
      data-action = { dataAction }
      data-target = { dataTarget }
      onClose     = { onClose }
      required    = { required }
    />
  )
}

export default SharedFlatpickr
