import React, { useState, useCallback, useEffect, useRef } from "react"
import Combobox             from "./combobox"
import { library }          from '@fortawesome/fontawesome-svg-core'
import { fas }              from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome'

library.add(fas)

const ComboboxMulti = props => {
  const {
    identifier = "id",
    display    = "name",
    fieldName  = "",
    allowBlank = false,
    options,
    disabled
  } = props

  const didMountRef = useRef(null)
  const [activated, setActivated] = useState(false)
  const [values,    setValues]    = useState(props.value || [])

  const onSelectElement = useCallback(item => {
    if (item?.[identifier]) {
      const selected = values.find(value => value[identifier] == item[identifier])
      if (!selected) {
        if (props.onChange) {
          props.onChange([...values, item])
        } else {
          setValues([...values, item])
        }
      }
    }
  })

  const removeItem = useCallback(index => {
    const newValues = [...values]
    newValues.splice(index, 1)
    if (props.onChange) {
      props.onChange(newValues)
    } else {
      setValues(newValues)
    }
  })

  const onClick = e => {
    if (disabled) return
    if (e.target.closest(".combobox__multiple__pill")) return
    if (e.target.closest(".combobox__multiple__reset")) return

    setActivated(!activated)
  }

  const onClear = () => {
    if (values.length > 0) removeItem(values.length - 1)
  }

  const clear = () => {
    if (props.onChange) {
      props.onChange([])
    } else {
      setValues([])
    }
  }

  const unselectedOptions = options.filter(option =>
    !values.find(value => value[identifier] == option[identifier])
  )

  const comboboxProps = {
    ...props,
    value:       undefined,
    multi:       true,
    options:     unselectedOptions,
    prompt:      null,
    required:    props.required && values.length == 0,
    placeholder: props.prompt,
    onClear,
    onSelectElement,
    display,
    identifier,
    activated,
    disabled
  }

  useEffect(() => {
    if (didMountRef.current) {
      setValues(props.value)
    } else {
      didMountRef.current = true
    }
  }, [props.value])

  return(
    <div className={`combobox__multiple ${disabled && 'disabled'}`} onClick={onClick}>
      {values.map((value, index) => {
        return(
          <span
            key       = {index}
            onClick   = {() => removeItem(index)}
            className = "combobox__multiple__pill"
          >
            {value[display]}
            <FontAwesomeIcon icon="times"/>
          </span>
        )}
      )}

      <Combobox {...comboboxProps}/>

      { values.length > 0 &&
        <div onClick={clear} className="combobox__multiple__reset">
          <FontAwesomeIcon icon="times"/>
        </div>
      }

      { values.map((value, index) =>
        <input
          key          = {index}
          type         = "hidden"
          defaultValue = {value[identifier]}
          name         = {fieldName + "[]"}
        />
      )}

      { allowBlank && values.length == 0  &&
        <input
          type         = "hidden"
          name         = {fieldName + "[]"}
          defaultValue = ""
        />
      }
    </div>
  )
}

export default ComboboxMulti
