import React from "react"

export default class CheckboxField extends React.Component {

  render() {
    const { name, label, index } = this.props

    return(
      <div className="option checkbox checkbox-primary">
        <input
          type="hidden"
          defaultValue="0"
          name={name}
        />

        <input
          type="checkbox"
          id={`checkbox-${index}`}
          checked={this.props.checked}
          onChange={this.props.onChange}
          defaultValue="1"
          name={name}
        />

        <label
          className="control-label"
          htmlFor={`checkbox-${index}`}
        >
          {label}
        </label>
      </div>
    )
  }
}
