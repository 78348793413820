// Modules
import React from "react"

// Components
import TimesheetUser       from './timesheetUser'
import TimesheetFilters    from './timesheetFilters'
import TimesheetStatistics from './timesheetStatistics'

// Helpers
import { bodyRequest, pathname } from '@react/helpers/fetch'

export default class Timesheets extends React.Component {

  // Default state for the fetch is :
  // Fetching the closed wo, from the 1st of the current month to today
  // For all the technicians of the current manager
  state = {
    loading:     false,
    showFilters: true,
    users:       [... new Set(this.props.all_techs.values.map(u => u.id))],
    range:       [new Date(new Date().setDate(1)), new Date()],
    timesheets:  null
  }

  // Fetch with initial state on mounting
  componentDidMount() {
    if (this.state.timesheets === null) this.fetchTimesheets()
  }

  fetchTimesheets = () => {
    if (this.state.range.length < 2) return

    this.setState({ loading: true })
    const users = this.state.users
    const range = this.state.range.map(date => date.toISOString())
    const url   = pathname({ path: this.props.timesheet_url, format: 'json' })
    const body   = { users, range }
    bodyRequest({ url, body })
    .then(timesheets => this.setState({ timesheets, loading: false }))
  }

  render() {
    const { autocomplete_url, all_techs, account_teams, csv_url, overtime_url } = this.props
    const { loading, timesheets, range, showFilters, users } = this.state

    const toggleFilterWindow = ()    => this.setState({ showFilters: !this.state.showFilters})
    const renderDate         = date  => date !== undefined ? date.toLocaleDateString(I18n.locale) : ''
    const onSelectUser       = users => this.setState({ users }, this.fetchTimesheets)
    const onSelectDate       = range => this.setState({ range }, this.fetchTimesheets)

    const autompletePath = pathname({ path: autocomplete_url, format: 'json' })
    const params         = { users, range }
    const csvPath        = pathname({ path: csv_url, format: 'csv', params })
    const overtimePath   = overtime_url ? pathname({ path: overtime_url, format: 'csv', params }) : ''

    return (
      <div>
        <TimesheetFilters
          {...{
            showFilters,
            toggleFilterWindow,
            all_techs,
            account_teams,
            autompletePath,
            csvPath,
            overtimePath,
            onSelectUser,
            onSelectDate,
            range
          }}
        />

        <TimesheetStatistics {...{timesheets, renderDate, range }}/>

        {/* Rendering timesheets */}
        { loading
          ? <div className="loading-spinner-container-sm">
              <div id="loading-spinner"></div>
            </div>
          : timesheets !== null &&
            <>
              {timesheets.sheets.map((sheet, key) => (
                <TimesheetUser {...{ key, sheet }} />
              ))}
            </>
        }
      </div>
    )
  }
}
