import React    from "react"
import LineBase from "./line_base"

// Components
import Buttons from "./shared/buttons"

// Helpers
import { lineCalculations, formatNumber, lineNameFor } from "../helpers"

const resetAttributes = [
  "ref_product_distrib",
  "discount",
  "purchase_price",
  "vat_rate_id",
  "product_id",
  "is_option"
]

export default class SubTotal extends LineBase {

  onChange(e) {
    e.preventDefault()
    const { index, qline } = this.props
    const { replaceLine  } = this.props.buttonProps

    replaceLine({...qline, description: e.target.value}, index)
  }

  render() {
    const { index, qline, showCode, buttonProps } = this.props

    const calculations = lineCalculations(qline)
    return(
      <React.Fragment>
        {
          qline.active
          ? <div className="quotes--form-qline-container">
              <div className="text-input">
                <label>Description</label>
                <input
                  className="form-control"
                  value={qline.description || ""}
                  onChange={this.onChange.bind(this)}
                  name={lineNameFor("description", index)}
                />
              </div>

              <div className="quotes--form-calculations sub-total">
                {formatNumber(calculations.total)}
              </div>

              <Buttons
                index={index}
                qline={qline}
                buttonProps={buttonProps}
              />
            </div>
          : <div
              className="quotes--form-pointer quotes--form-table-row sub-total"
              onClick={this.activate}
            >
              { showCode ? <div className="code-column"></div> : "" }

              <div className="description-column">
                {qline.description}
              </div>

              { qline.quantity > 1 &&
                <>
                  <div className="price-column">
                    {formatNumber(qline.unit_price)}
                  </div>

                  <div className="amount-column">
                    {formatNumber(qline.quantity)}
                  </div>

                  <div className="unit-column"/>

                  <div className="vat-column"/>
                </>
              }

              <div className="total-column">
                {formatNumber(calculations.total)}
              </div>

              <Buttons
                index={index}
                qline={qline}
                buttonProps={buttonProps}
              />

              <input
                type="hidden"
                name={lineNameFor("description", index)}
                defaultValue={qline.description}
              />
            </div>
        }

        <input
          type="hidden"
          name={lineNameFor("id", index)}
          defaultValue={qline.id}
        />

        <input
          type="hidden"
          name={lineNameFor("order", index)}
          defaultValue={index + 1}
        />

        <input
          type="hidden"
          name={lineNameFor("quantity", index)}
          defaultValue={qline.quantity || "1"}
        />

        <input
          type="hidden"
          name={lineNameFor("unit_price", index)}
          defaultValue={qline.unit_price}
        />

        <input
          type="hidden"
          name={lineNameFor("is_subtotal", index)}
          defaultValue="1"
        />

        {
          resetAttributes.map((attribute, resetIndex) =>
            <input
              key={resetIndex}
              type="hidden"
              name={lineNameFor(attribute, index)}
              defaultValue=""
            />
          )
        }
      </React.Fragment>
    )
  }
}
