import { FETCH } from './fetch'
import { CHANGE_LOCALE } from './change_locale'

const initialState = {
  permissions: {}
}

export default function userReducer(state = initialState, action) {

  switch (action.type) {
  case FETCH.USER:
    return FETCH
      .fetchUserResponse(action.payload.user)

  case FETCH.SESSION:
    return FETCH
      .fetchSessionResponse(state, action.payload.unique_session_id)

  case CHANGE_LOCALE.BEGIN:
    return CHANGE_LOCALE
      .beginResponse(state)

  case CHANGE_LOCALE.SUCCESS:
    return CHANGE_LOCALE
      .successResponse(action.payload.user)


  case CHANGE_LOCALE.FAILURE:
    return CHANGE_LOCALE
      .failureResponse(state)

  default:
    return state
  }
}
