// Returns an integer of the difference between two dates. -2 or 2
export const diffIndays = (a, b) => {
  const MS_PER_DAY = 1000 * 60 * 60 * 24
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
  return Math.floor((utc2 - utc1) / MS_PER_DAY)
}

// Returns an object with hours, minutes, seconds. Ex: { hours: 03, minutes: 48, seconds: 02 }
export const msToTime = milliseconds => {

  let seconds = parseInt((milliseconds / 1000) % 60)
  let minutes = parseInt((milliseconds / (1000 * 60)) % 60)
  let hours = parseInt((milliseconds / (1000 * 60 * 60)) % 24)

  hours = (hours < 10) ? '0' + hours : hours
  minutes = (minutes < 10) ? '0' + minutes : minutes
  seconds = (seconds < 10) ? '0' + seconds : seconds

  return { hours, minutes, seconds }
}

export const hourToTime = value => {
  let hours   = parseInt(value, 10)
  let minutes = parseInt((value % 1) * 60)

  return { hours, minutes }
}

export const hourToString = value => {
  const timeObj = hourToTime(value)

  return `${timeObj.hours}:${timeObj.minutes}`
}

export const minToString = value => {
  const timeObj = minToTime(value)

  return `${timeObj.hours}:${timeObj.minutes}`
}

export const minToTime = min => {

  let minutes = parseInt(min % 60)
  let hours = parseInt(min / 60)

  hours = (hours < 10) ? '0' + hours : hours
  minutes = (minutes < 10) ? '0' + minutes : minutes

  return { hours, minutes }
}

export const secToTime = sec => minToTime(sec / 60)

export const timeToMin = time => {
  let minutes = time.minutes
  let hours = time.hours

  return (hours * 60) + minutes
}

export const showDate = date => {
  return date.toISOString().split("T")[0].split("-").reverse().join("/")
}

export const datetimeLocalInput = str => {
  const date     = new Date(str)
  const zone     = date.getTimezoneOffset() / -60
  const day      = date.getDate()
  const month    = date.getMonth()
  const year     = date.getFullYear()
  const hour     = date.getHours() + zone
  const minute   = date.getMinutes()
  const zoneDate = new Date(year, month, day, hour, minute)

  return zoneDate.toISOString().substr(0, 16)
}

export const beginningOfNextHour = () => {
  var date = new Date();
  return datetimeLocalInput(roundMinutes(date))

  function roundMinutes(date) {
    date.setHours(date.getHours() + 1);
    date.setMinutes(0, 0, 0);

    return date;
  }
}

export const showMoney = ({ amount, currency }) => {
  const style = 'currency'
  return amount.toLocaleString(I18n.locale, {style, currency})
}

export const roundMoney = amount => {
  let money
  if (typeof(amount) == "string") {
    money = parseFloat(amount.replace(/,/, '.'), 10)
  } else {
    money = parseFloat(amount, 10)
  }

  if (isNaN(money)) return 0

  if (money < 0) {
    money = Math.floor(money * 100) / 100
  } else {
    money = Math.ceil(money * 100) / 100
  }
  return money
}

// Build a string of time. Ex: "03h 48min 02sec"
// Depreceted, should use an I18 sentence with variables
export const buildDurationStringFromMs = (ms) => {
  const time = msToTime(ms)
  let result = ''
  if (time.hours > 0)   result += `${time.hours} h `
  if (time.minutes > 0) result += `${time.minutes} min `
  if (time.seconds)     result += `${time.seconds} sec `
  return result
}

export const buildDurationStringFromMin = (minutes) => {
  const time = minToTime(minutes)
  let result = ''
  if (time.hours > 0) result += `${time.hours} h `
  result += `${time.minutes} min `
  return result
}

// datestring -> Ex: 11:06
export const buildTimeString = date => {
  const options = { hour: "2-digit", minute: "2-digit"}
  return new Date(date).toLocaleTimeString(I18n.locale, options)
}

// datestring -> Ex: 26/01/2022
export const buildDateString = date => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit"}
  return new Date(date).toLocaleDateString(I18n.locale, options)
}

// datestring -> Ex: 26/01/2022 11:06
export const buildDateTimeString = date => {
  return [buildDateString(date) , buildTimeString(date)].join(" ")
}

export const inputDate = dateStr => {
  if (!dateStr) return
  if (!dateStr.includes("/")) return ""

  const [day, month, year] = dateStr.split("/")
  return [year, month, day].join("-")
}

export const inputDateTime = dateStr => {
  if (!dateStr) return
  if (!dateStr.includes("-")) return ""
  let [date, time] = dateStr.split('T')
  const [year, month, day] = date.split("-")
  date = [year, day, month].join("-")
  return [date, time].join("T")
}

export const localeDate = date => new Date(date).toLocaleDateString(I18n.locale)

export const rand = base => Math.floor(Math.random(base) * base)
