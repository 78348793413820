import React, { useState, useEffect } from 'react'
import Combobox from "@shared/combobox"

import { getRequest } from '@react/helpers/fetch'
import { pathname } from '@react/helpers/fetch'

const PurchaseOrderForSupplier = ({ suppliers, supplier, purchaseOrder, name }) => {
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const fetchPurchaseOrders = supplier => {
    if(purchaseOrder) return

    setPurchaseOrders([])
    const path = "/purchase_orders"
    const params = { supplier: supplier.id } 
    const url = pathname({ path, params }) 
    getRequest(url).then(setPurchaseOrders)
  }

  useEffect(() => {
    if (supplier) { fetchPurchaseOrders(supplier) }
  }, [])

  return (
    <>
      <div className="form-group">
        <label required>{I18n.t("purchase_invoices.supplier")}</label>
        <Combobox
          options         = {suppliers}
          required        = {true}
          value           = {supplier?.id}
          onSelectElement = {fetchPurchaseOrders}
          fieldName       = {`${name}[supplier_id]`}
          prompt          = {I18n.t("purchase_invoices.select_supplier")}
        />
      </div>

      { purchaseOrder
        ? <input name={`${name}[purchase_order_id]`} defaultValue={purchaseOrder} hidden />
        : purchaseOrders.length > 0 &&
            <div className="form-group">
              <label>{I18n.t("purchase_invoices.purchase_order")}</label>
              <Combobox
                options   = {purchaseOrders}
                fieldName = {`${name}[purchase_order_id]`}
                prompt    = {I18n.t("purchase_invoices.select_po")}
              />
            </div>
      }
    </>
  )
}

export default PurchaseOrderForSupplier
