import React    from "react"
import Combobox from '@shared/combobox'

export const CategorySelect = ({ categories, onSelect, workorderCategory }) => {
  return (
    <div className='form-group'>
      <label 
        className="control-label" 
      >
        {I18n.t("categories.category")}
      </label>

      <Combobox 
        prompt          = { I18n.t('categories.select') }
        options         = { categories }
        onSelectElement = { onSelect }
        value           = { workorderCategory }
      />
    </div>
  )
}