import React from "react"

export const EmptyCard = () => {
  const CARD_HEIGHT = 350
  
  return(
    <div
      className="dashboard__card--empty"
      style={{ height: `${CARD_HEIGHT}px` }}
    >
      {I18n.t('dashboard.no_data')}
    </div>
  )
}