import React, { useState }       from 'react'
import { bodyRequest, pathname } from "@react/helpers/fetch"
import { FontAwesomeIcon }       from '@fortawesome/react-fontawesome'
import Checkbox                  from '../shared/checkbox/checkbox'


const ResourceList = ({ resources, setResources, setShowPopup }) => {
  const [filterResponse, setFilterResponse] = useState('')

  const uncheckedResources = resources.filter(resource => {
    return resource.unchecked === true
  }).map(resource => resource.id)

  const handleResourceChange = (add, resource) => {
    setFilterResponse('')
    let newResources = [...resources]
    const showResource = resourceId => {
      let currentResource = newResources.find(res => res.id === resourceId)
      if (currentResource) {
        currentResource.unchecked = false
      }
    }

    const hideResource = resourceId => {
      let currentResource = newResources.find(res => res.id === resourceId)
      if (currentResource) {
        currentResource.unchecked = true
      }
    }

    if (!!resource.manager) {
      const children = resources.filter(child => child.parentId == resource.id)
      children.map(child => {
        add ? showResource(child.id) : hideResource(child.id)
      })
    }

    if (add && !resource.manager) {
      showResource(resource.parentId)   
    }

    add ? showResource(resource.id) : hideResource(resource.id)

    setResources(newResources)
  }

  const saveFilter = () => {
    const path   = '/calendar/save_filter'
    const body   = {
      unchecked: uncheckedResources.join(',')
    }

    const url = pathname({ path })
    bodyRequest({ url, body }).then(res => {setFilterResponse(res.success)})
  }

  const managers = resources.filter(resource => resource.manager == true)

  const userInfo = info => {
    return <span className="mt-1"
              onMouseEnter={e => setShowPopup({x: e.pageX, y: e.pageY, content: info})}
              onMouseLeave={() => setShowPopup(false)}>
              <FontAwesomeIcon icon='info-circle' />
            </span>
  }

  const Manager = ({ manager }) => {
    const technicians = resources.filter(resource => resource.parentId == manager.id)

    return(
      <>
        <div className="d-flex" key={manager.id}>
          <Checkbox
            checked    = {!manager.unchecked}
            id         = {manager.id}
            onChange   = {checked => handleResourceChange(checked, manager)}
            label      = {manager.title}
            color      = {manager.color}
            className  = "form-group mr-2"
          />
          {manager.information && userInfo(manager.information)}
        </div>

        { technicians.map(technician =>
            <div className="d-flex" key={technician.id}>
              <Checkbox
                className = 'form-group ml-2 mr-2'
                checked   = {!technician.unchecked}
                id        = {technician.id}
                onChange  = {checked => handleResourceChange(checked, technician)}
                label     = {technician.title}
                color     = {technician.color}
              />
              {technician.information && userInfo(technician.information)}
            </div>
        )}
      </>
    )
  }

  return (
    <div className={`resource-list`}>
      <div>
        {managers.map(manager => <Manager key={manager.id} manager={manager}/>)}
      </div>

      <button className="btn btn-primary" onClick={saveFilter} disabled={!!filterResponse}>
        {!!filterResponse ? filterResponse : I18n.t("calendar.save_filter")}
      </button>
      
    </div>
  )
}

export default ResourceList
