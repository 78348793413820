import React        from "react"
import Base         from "./base"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Components
import Autocomplete from '../../shared/autocomplete'

// Helpers
import { getRequest } from '@helpers/javascript/javascript'

export default class Client extends Base {

  onChange(clients) {
    const { quote, replaceQuote } = this.props
    this.deactivate()

    getRequest(`/quotes/form/${clients}/client.json`)
      .then(client => {
        const site = client.sites.length == 1 ? {...client.sites[0]} : {}
        const contact = {}
        const discount = client.discount || 0
        const vat_rate = site.vat_rate
        const reminders_enabled = client.quote_reminders_enabled_by_default
        quote.qlines.forEach(qline => {
          if (vat_rate)     qline.vat_rate = vat_rate
          if (discount > 0) qline.discount = discount
        })
        replaceQuote({...quote, client, site, contact, reminders_enabled})
      })
  }

  render() {
    const quote = this.props.quote
    const client = quote.client

    return(
      this.state.active || client.id === null
      ? <div className="form-group">
          <label>{I18n.t("workorders.client")}</label>

          <Autocomplete
            fieldName        = "quote[client_id]"
            placeholder      = {I18n.t("workorders.minimum_2_characters")}
            preselected      = {client.id ? [client] : []}
            className        = "quotes--form-client-input"
            autocomplete_url = {client.autocomplete_path}
            onSelectElement  = {this.onChange}
            multiselect      = {false}
            required         = {true}
          />

          { client.id
            ? <div onClick={this.deactivate} className = "quotes--form-client-reset">
                &times;
              </div>
            : ""
          }
        </div>
      : <>
          <div className="quotes--form-pointer no-margins" onClick={this.activate}>
            <strong>{client.name}</strong>
            <p>{client.address_1}</p>
            <p>{client.address_2}</p>
            <input type="hidden" name="quote[client_id]" defaultValue={quote.client.id} />
          </div>

          { client.unpaid_invoices &&
            <div className="client-details">
              <a  href={client.unpaid_invoices.path}
                  target="_blank"
                  className="color-open"
              >
                {
                  I18n.t("client.unpaid_invoices", {
                    number: client.unpaid_invoices.size
                  })
                }
                &nbsp;
                <FontAwesomeIcon icon="external-link-alt" />
              </a>
            </div>
          }
        </>
    )
  }
}
