import React, { useState } from 'react'


const Avatar = ({avatar}) => {
  const [bigImage, showBigImage] = useState(false)
  return (
    <>
      <img src={avatar} onClick={()=>showBigImage(true)} className="avatar-thumbnail"/>
      {
        bigImage && 
          <div className='avatar-container' onClick={()=>showBigImage(false)}>
            <img src={avatar} />
          </div>
      }
    </>
  )
}

export default Avatar
