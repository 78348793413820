import React                from "react"
import { CategorySelect }   from './categorySelect'
import { ExtraFieldAnswer } from "./extraFieldAnswer"

export const ExtraFieldsAndCategory = (
  { categories, 
    workorderState,
    setWorkorderState,
    categoryFields, 
    workorderCategory,
  }) => {
  const handleInputChange = (value, fieldAnswer) => {
    setWorkorderState({ 
      ...workorderState, 
      extraFieldAnswers: workorderState.extraFieldAnswers.map(answer => 
        answer.extraFieldId != fieldAnswer.extraFieldId ? answer : { ...answer, [`${fieldAnswer.answerType}Answer`]: value } 
      ) 
    })
  }

  const handleCategoryChange = category => {
    const newCategoryFields  = categoryFields.filter(element => element.categoryId === category.id)
    const newExtraFieldState = workorderState.extraFieldAnswers.filter(element => !element.categoryId)

    setWorkorderState({ 
      ...workorderState, 
      categoryId: category.id,
      extraFieldAnswers: newCategoryFields.concat(newExtraFieldState)
    })
  }

  return(
    <div>
      { !!categories.length && 
        <CategorySelect
          workorderCategory = { workorderCategory }
          categories        = { categories } 
          onSelect          = { handleCategoryChange }
        />
      }
      { workorderState.extraFieldAnswers.map(answer => {
          return <ExtraFieldAnswer 
            key      = { answer.extraFieldId } 
            answer   = { answer } 
            onChange = { handleInputChange } 
          />
        })
      }
    </div>
  )
}