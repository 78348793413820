import React, { useState }      from "react"
import Combobox                 from "@shared/combobox"
import Input                    from '@shared/input'
import { FontAwesomeIcon }      from "@fortawesome/react-fontawesome"
import { getRequest, pathname } from '@react/helpers/fetch'

export const Product = ({ product, technician, onChange, updateProduct, removeProduct }) => {
  const productObject = { id: product.productId, name: product.name }
  const productOptions = product.productId ? [productObject] : []

  const [stock, setStock] = useState(null)

  const onProductChange = newProduct => {
    if (newProduct) {
      updateProduct(product.productId, newProduct.id, newProduct.name)
    }

    const technicianId = technician?.id || ""
    const params       = { product: newProduct.id, technician: technicianId }
    const path         = '/workorders/products/fetch_stocks'
    const format       = 'json'
    const url          = pathname({ path, params, format })
    getRequest(url).then(res => {
      setStock(res.html)
    })
  }

  const onQuantityChange = quantity => {
    onChange(product.productId, quantity)
  }

  return (
    <>
      <div className="cocoon-grid">
        <div className="form-group mr-2">
          <Combobox 
            feed            = { '/products/autocomplete.json' }
            options         = { productOptions }
            value           = { product.productId || '' }
            prompt          = { I18n.t("products.new") }
            onSelectElement = { product => onProductChange(product) }
            className       = 'select-view'
            required
          />
        </div>
        <Input 
          name         = "quantity"
          type         = "number" 
          step         = "0.01"
          defaultValue = { product.quantity }
          className    = "form-control text-center"
          onChange     = { value => onQuantityChange(value) }
          required
        />
        <div className="mt-2 ml-2 text-center">
          <a className="mb-2 text-pointer"> 
            <FontAwesomeIcon icon="minus-circle" onClick={() => removeProduct(product.productId)}/>
          </a>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{__html: stock}}></div>
    </>
  )
}