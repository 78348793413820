import React     from "react"
import { TYPES } from "../app"

export const Type = ({
  materials,
  workorderState,
  setWorkorderState
}) => {
  const type = workorderState.workorderType

  const types = () => {
    const availableTypes = Object.values(TYPES).filter(type => {
      if (!workorderState.siteId || !materials.length || !workorderState.maintenanceModule) {
        return type !== TYPES.preventive
      }
      return Object.values(TYPES)
    })
    return availableTypes
  }

  const updateWorkorderDuration = (tasks, type) => {
    const totalDuration = tasks.reduce((sum, task) => sum + task.predictedDuration, 0)
    setWorkorderState({ ...workorderState, predictedDuration: totalDuration, maintenanceTasks: tasks, workorderType: type })
  }

  const handleTypeChange = type => {
    if (type === TYPES.preventive) {
      const maintenanceTasks = [...workorderState.maintenanceTasks]
      workorderState.materials.map(mat => {
        const newTask = { materialId: mat, taskBlueprintId: null, predictedDuration: 60 }
        if (maintenanceTasks.filter(task => task.materialId == mat).length == 0) {
          maintenanceTasks.push(newTask)
        }
      })
      updateWorkorderDuration(maintenanceTasks, type)
    } else {
      setWorkorderState({ ...workorderState, workorderType: type, maintenanceTasks: [] })
    }
  }

  return (
    <div className="form-group">
      <label>{ I18n.t("workorders.type") }</label>
      <select
        className = "form-control"
        value     = { type }
        onChange  = { e => handleTypeChange(e.target.value) }
        name      = "workorder_type"
        required
      >
        {
         types().map(type =>
            <option key = { type } value = { type }>
              { I18n.t(`workorders.workorder_types.${type}`) }
            </option>
          )
        }
      </select>
    </div>
  )
}