// Dispatch
export const updateSignatureAction = (id, newSignature) => dispatch =>
  dispatch({
    type:    WORKORDER_UPDATE_SIGNATURE.ACTION,
    payload: { id, newSignature }
  })

// Reducer response
export const WORKORDER_UPDATE_SIGNATURE = {
  ACTION: 'WORKORDER_UPDATE_SIGNATURE',

  call: ({ state, id, newSignature }) => {
    const workorder = state.items.find(wo => wo.id === id)
    const editedWorkorder = {
      ...workorder,
      signature: newSignature,
      synced: false
    }

    const workorders = state.items.map(wo => wo.id === id ? editedWorkorder : wo)
    return { ...state, items: workorders }
  }
}
