import React from "react"

// Helpers
import {lineNameFor} from '../../helpers'

export default class HiddenInputs extends React.Component {

  render() {
    const {qline, index} = this.props

    const HiddenInput = (props) =>
      <input
        type="hidden"
        name={lineNameFor(props.attribute, index)}
        defaultValue={props.value || qline[props.attribute]}
      />

    return(
      <React.Fragment>
        <HiddenInput attribute="id" />
        <HiddenInput attribute="unit"/>
        <HiddenInput attribute="description"/>
        <HiddenInput attribute="discount"/>
        <HiddenInput attribute="quantity"/>
        <HiddenInput attribute="purchase_price"/>
        <HiddenInput attribute="unit_price"/>
        <HiddenInput attribute="ref_product_distrib" />
        <HiddenInput attribute="vat_rate_id"   value={qline.vat_rate.id}/>
        <HiddenInput attribute="product_id"    value={qline.type == "product" ? qline.product.id : ""}/>
        <HiddenInput attribute="is_page_break" value={qline.is_page_break ? "1" : "0"}/>
        <HiddenInput attribute="is_option"     value={qline.option ? "1" : "0"}/>
        <HiddenInput attribute="hide_in_pdf"   value={qline.hide_in_pdf ? "1" : "0"}/>
        <HiddenInput attribute="order"         value={`${index + 1}`}/>
      </React.Fragment>
    )
  }
}

