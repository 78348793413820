import React      from "react"
import LineBase   from "./line_base"

// Components
import Destroyed  from "./line_destroyed"
import Buttons    from "./shared/buttons"
import Combobox   from "@shared/combobox"

// Helpers
import { getRequest } from '@helpers/javascript/javascript'

export default class Group extends LineBase {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.state    = {...this.state, groupId: "", amount: 1}
  }

  onSubmit() {
    const { index, qline }    = this.props
    const { groupId, amount } = this.state
    const { addGroup }        = this.props.groupProps
    getRequest(`/quotes/form/${groupId}/group.json?currency=${qline.currency}`)
    .then(group => addGroup(group, parseFloat(amount), index))
  }

  render() {
    const { index, qline, buttonProps} = this.props
    const { groupId, amount } = this.state
    const { groups }          = this.props.groupProps

    return(
      <div className="quotes--form-qline-container">
        <div className="text-input">
          <Combobox
            options         = {groups}
            value           = {groupId}
            display         = "title"
            onSelectElement = {group => this.setState({ groupId: group.id })}
            prompt          = {I18n.t("quotes.new.select_group")}
            required        = {true}
          />
        </div>

        <div className="calculations">
          <input
            className="form-control"
            type="number"
            step="1"
            min="0"
            onChange={e => this.setState({ amount: e.target.value })}
            value={amount}
          />
        </div>

        <Buttons
          index={index}
          qline={qline}
          buttonProps={buttonProps}
          submit={groupId && amount ? this.onSubmit : null}
        />

        <Destroyed
          index={index}
          qline={qline}
        />
      </div>
    )
  }
}
