// To remove a photo, we only need the workorder index and the photo index inside that workorderrkorder

// Dispatch
export const removePhotoAction = (id, version) => (
  dispatch => dispatch({
    type:    REMOVE_PHOTO.ACTION,
    payload: { id, version }
  })
)

// Reducer response
export const REMOVE_PHOTO = {
  ACTION: 'REMOVE_PHOTO',

  call: ({ state, id, version }) => {

    const workorder = state.items.find(item => item.id == id)
    const photo     = workorder.photos.find(photo => photo.version == version)
    if(!photo || photo.id) return {...state}

    const photoIndex = workorder.photos.findIndex(photo => photo.version == version)
    workorder.photos.splice(photoIndex, 1)

    return {
      ...state,
      items: state.items.map(item => item.id != id ? item : workorder)
    }
  }
}
