import React, { useEffect, useState } from "react"

// Helper
import { bodyRequest } from '@helpers/javascript/javascript'

// Components
import { Toggler }  from '@shared/Toggler'
import { Spinner }  from '@components/Spinner/Spinner'
import { ChartTab } from '../Demands/ChartTab'


import { AdvancedFilters }            from './AdvancedFilters'
import { FilteredTimeRepartition }    from './FilteredTimeRepartition'
import { FilteredAmountRepartition }  from './FilteredAmountRepartition'
import { FilteredRatios }             from './FilteredRatios'

const Advanced = ({
  teams,
  url,
  trackTime,
  displayClient,
  categories,
  statuses
}) => {
  const from = new Date(new Date().setDate(1))
  const to   = new Date()

  const [loading,     setLoading]       = useState(true)
  const [date,        setDate]          = useState([from, to])
  const [technicians, setTechnicians]   = useState([])
  const [assets,      setAssets]        = useState({})
  const [data,        setData]          = useState({})

  const fetchData = () => {
    if (date.length < 2) return

    const body = { date: date.toString() }
    if (technicians.length > 0) body.users  = technicians.map(t => t.id)
    if (assets.client?.id)      body.client = assets.client.id
    if (assets.site?.id)        body.site   = assets.site.id
    setLoading(true)
    bodyRequest({ url, body }).then(response => {
      setData(response)
      setLoading(false)
    })
  }

  useEffect(() => { fetchData() }, [date, assets, technicians])

  return (
    <>
      <AdvancedFilters
        date           = {date}
        setDate        = {setDate}
        setTechnicians = {setTechnicians}
        teams          = {teams}
        setAssets      = {setAssets}
        displayClient  = {displayClient}
      />


      {
        loading
        ? <Spinner />
        : <>
            <Toggler title={I18n.t('dashboard.repartition')} opened>
              <FilteredRatios ratios={data.ratios} />
            </Toggler>

            { data.categories &&
              <Toggler title={I18n.t('dashboard.repartition_workorders')} opened>
                <ChartTab
                  ratios      = { data.categories }
                  categories  = { categories }
                  statuses    = { statuses }
                  range       = { 1 }
                />
              </Toggler>
            }

            { Object.keys(data.extra_ratios || {}).length > 0 &&
              <Toggler title={I18n.t('dashboard.ratios')} opened>
                <FilteredRatios ratios={data.extra_ratios} />
              </Toggler>
            }

            { trackTime &&
              <>
                <FilteredTimeRepartition data={data.time_repartition} />

                <Toggler title={I18n.t('dashboard.work_overview')} opened>
                  <FilteredAmountRepartition data={data.amount_repartition} />
                </Toggler>
              </>
            }
          </>
      }
    </>
  )
}

export default Advanced
