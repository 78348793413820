// components/blueprints/field_positions.js
import React, { useState, useEffect, useRef } from 'react';
import { bodyRequest, deleteRequest } from '@react/helpers/fetch';
import FieldCard from './FieldCard';
import SectionCard from './SectionCard';

const FieldPositions = ({
  blueprintId,
  fields,
  fieldPositions,
  pagePreviewsUrls,
  backButtonLink,
  previewButtonLink
}) => {

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [selectedField, setSelectedField] = useState(null);
  const [positions, setPositions] = useState({});
  const [markerPosition, setMarkerPosition] = useState(null);
  const hasManyPages = pagePreviewsUrls.length > 1;
  const fieldCardRefs = useRef({});

  useEffect(() => {
    setMarkerPosition(null);
  }, [currentPageIndex, selectedField]);

  useEffect(() => {
    const initialPositions = fieldPositions.reduce((acc, position) => {
      acc[position.field_id] = position;
      return acc;
    }, {});
    setPositions(initialPositions);
  }, [fieldPositions]);

  useEffect(() => {
    // Create refs for all fields
    fields.forEach(field => {
      fieldCardRefs.current[field.id] = React.createRef();
    });
  }, [fields]);

  // Scroll to the corresponding FieldCard when a rectangle is clicked
  useEffect(() => {
    if (selectedField && fieldCardRefs.current[selectedField]?.current) {
      fieldCardRefs.current[selectedField].current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedField]);

  const handleFieldSelect = (fieldId) => {
    setSelectedField(parseInt(fieldId));
    const position = positions[fieldId];
    if (position && position.page_number) {
      setCurrentPageIndex(position.page_number - 1);
    }
  };

  const handleImageClick = (e) => {
    if (!selectedField) return;

    const rect = e.target.getBoundingClientRect();
    const x = Math.ceil(e.clientX - rect.left); // x position within the element, rounded up to the nearest integer.
    const y = Math.ceil(e.clientY - rect.top);  // y position within the element, rounded up to the nearest integer.
    const currentPosition = positions[selectedField] || {};

    // Check if it's the start of a new position (x1, y1) or completing it (x2, y2)
    if (!currentPosition.x1 || (currentPosition.x1 && currentPosition.x2)) {
      // Start of a new position or a new rectangle
      const updatedPosition = {
        ...currentPosition,
        x1: x,
        y1: y,
        // Reset x2 and y2 if starting a new position to avoid confusion
        x2: undefined,
        y2: undefined,
      };
      setPositions({
        ...positions,
        [selectedField]: updatedPosition
      });
    } else {
      // Completing the rectangle with x2, y2
      const updatedPosition = {
        ...currentPosition,
        x2: x,
        y2: y,
        page_number: currentPageIndex + 1,
      };
      setPositions({
        ...positions,
        [selectedField]: updatedPosition
      });
      saveFieldPosition(selectedField, updatedPosition);
    }
    setMarkerPosition({ x, y });
  };

  const saveFieldPosition = (fieldId, positionData) => {
    const isUpdating = positions[fieldId] && positions[fieldId].id;
    const method = isUpdating ? 'PUT' : 'POST';
    let url = `/blueprints/${blueprintId}/field_positions`;
    if (isUpdating) url += `/${positions[fieldId].id}`;
    const body = { field_position: { field_id: fieldId, ...positionData } };

    bodyRequest({ url, body, method })
      .then(response => {
        setPositions({
          ...positions,
          [fieldId]: { ...positionData, id: response.id }
        });
      })
      .catch(error => {
        console.error('Error saving position', error);
      });
  };

  const removeFieldPosition = (fieldId) => {
    if (!positions[fieldId] || !positions[fieldId].id) return;

    const url = `/blueprints/${blueprintId}/field_positions/${positions[fieldId].id}`;
    deleteRequest(url)
      .then(() => {
        const updatedPositions = { ...positions };
        delete updatedPositions[fieldId];
        setPositions(updatedPositions);
      })
      .catch(error => {
        console.error('Error removing position', error);
      });
  }

  const navigatePages = (direction) => {
    setCurrentPageIndex(prev => {
      if (direction === 'next' && prev < pagePreviewsUrls.length - 1) {
        return prev + 1;
      } else if (direction === 'prev' && prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  // Function to find the last field position up to the current index
  const findPrevFieldPosition = (currentIndex) => {
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (fields[i].field_type !== 'section') {
        const { id, field_id, ...rest } = positions[fields[i].id] || {};
        return id ? rest : undefined;
      }
    }
    return undefined;
  };

  return (
    <div className="field-positions-form">
      <div className="btn-group-index">
        <div>
          <a href={backButtonLink} className="btn btn-sm btn-primary small mr-2">
            {I18n.t("button.back")}
          </a>
          <a href={previewButtonLink} target="_blank" className="btn btn-sm btn-primary small">
            <i className="far fa-eye"></i> {I18n.t("button.preview")}
          </a>
        </div>

        {/* Navigation Buttons */}
        <div>
          {hasManyPages &&
            <div className="page-navigation-buttons">
              <button onClick={() => navigatePages('prev')} className="btn btn-sm btn-primary">&lt;</button>
              <div className="mx-3">{I18n.t("blueprints.page") + ` ${currentPageIndex + 1}`}</div>
              <button onClick={() => navigatePages('next')} className="btn btn-sm btn-primary">&gt;</button>
            </div>
          }
        </div>
      </div>

      <div className="cards_layout">
        <div className="left-panel">
          <ul>
            {fields.map((field, index) => {
              if (field.field_type === 'section') {
                return <SectionCard key={field.id} field={field} />;
              } else {
                const clipboardPosition = findPrevFieldPosition(index);
                return (
                  <FieldCard
                    key={field.id}
                    ref={fieldCardRefs.current[field.id]}
                    field={field}
                    selectedField={selectedField}
                    handleFieldSelect={handleFieldSelect}
                    positions={positions}
                    clipboardPosition={clipboardPosition}
                    setPositions={setPositions}
                    saveFieldPosition={saveFieldPosition}
                    removeFieldPosition={removeFieldPosition}
                    setCurrentPageIndex={setCurrentPageIndex}
                    maxPageIndex={pagePreviewsUrls.length}
                  />
                );
              }
            })}
          </ul>
        </div>

        <div className="right-panel">
          <div className="pdf-preview">
            <div className={`img-wrapper ${selectedField ? 'edit-mode' : ''}`} onClick={handleImageClick} >
              <img src={pagePreviewsUrls[currentPageIndex]} alt={`Page ${currentPageIndex + 1}`} />

              {Object.entries(positions).map(([fieldId, position]) => {
                const rectBigEnough = position.y2 - position.y1 >= 20 && position.x2 - position.x1 >= 30;
                return (
                  position.page_number === (currentPageIndex + 1) && position.x2 && (
                    <div key={fieldId} className="position-rectangle"
                         style={{
                           left:   `${position.x1}px`,
                           top:    `${position.y1}px`,
                           width:  `${position.x2 - position.x1}px`,
                           height: `${position.y2 - position.y1}px`
                         }}
                         tooltip={fields.find(f => f.id.toString() === fieldId)?.name}
                         onClick={(e) => { e.stopPropagation(); handleFieldSelect(fieldId); }}
                    >
                      {/* Show name if rectangle is big enough */}
                      {rectBigEnough && (
                        <>
                          <span className="field-name">{fields.find(f => f.id.toString() === fieldId)?.name}</span>
                        </>
                      )}

                      {/* Show field position on hover */}
                      <span className={`field-position ${!rectBigEnough ? 'is-small' : ''}`}>
                        <div className="text-left">{I18n.t('blueprints.field_positions.x')}</div> <div>{position.x1}</div>
                        <div className="text-left">{I18n.t('blueprints.field_positions.y')}</div> <div>{position.y1}</div>
                        <div className="text-left">{I18n.t('blueprints.field_positions.w')}</div> <div>{Math.ceil(position.x2 - position.x1)}</div>
                        <div className="text-left">{I18n.t('blueprints.field_positions.h')}</div> <div>{Math.ceil(position.y2 - position.y1)}</div>
                      </span>
                    </div>
                  )
                );
              })}

              {/* Temporary marker for first click */}
              {markerPosition && (
                <div className="marker" style={{ left: markerPosition.x, top: markerPosition.y }}></div>
              )}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldPositions;
