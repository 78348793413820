import React, { useRef, useState, useEffect } from "react"
import Multiselect  from "./multiselect"
import Shortcuts    from "./shortcuts"
import Checklist    from "./checklist"
import sanitizeHtml from 'sanitize-html-react'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

// Helpers
import { getRequest } from '@helpers/javascript/javascript'
/*
  <Autocomplete

    identifier:              String. Unique identifier between items. Usually 'id'
    shownValue:              String. What part of the hash will be displayed
    -----------
    EXAMPLE FOR identifier/shownValue
    Props passed               -> identifier: 'id', shownValue: 'name'
    Expected response format   -> { id: 2, name: 'Miguel' }
    -----------

    fieldName:               String. Field name.
    placeholder:             String. Placeholder for the input field.
    preselected:             Array of objects containing identifier and shown value [{ id: 0, name: 'Olivier' }, { id: 1, name: 'Andrzej' }]
    autocomplete_url:        String. Url to fetch the data for the autocomplete
    onSelectElement:         Function. Trigger something everytume we select/unselect someone
    returnObjects:           Boolean. By default, Autocomplete will return the `identifier` value. If you want full objects, set this to true.
    resetIcon:               Boolean. Will display a little icon to reset the field
    isText:                 In case the field value should be text and not an id, like an address
    --  MULTI SELECT
    multiselect:             True/False. Is it a multiselect ?

    --  SHORTCUTS
    shortcuts:               Array of objects containing preselected values that the user can click on. Don't set or set to false if you don't wanna have shortcuts.
    shortcutTitle:           String. Title for the shortcuts

    --  SECONDARY CHECKLIST
    checklist:               Boolean. True if you have a second level menu after the autocomplete
    checklistKey:            String. String of the keys of children values that comes back after the autocomplete.
    checklistShownvalue:     String. Same than the other shownValue but for the children.
    checkListOnChange:       Function. Callback when selecting/deselecting item.
    -----------------
    Expected format coming from the back for checkboxes
    {shownValue: 'MainName', identifier: 'MainId', checkListKey: [{id: '', checklistShownvalue: ''}]}
    Example :
    {name: 'ClientName', id: 625378, sites: [{id: 12, name: 'SiteName'}]}
    -----------------
  />
*/

const Autocomplete = props => {
  const inputRef = useRef()

  const {
    identifier = "id",
    shownValue = "name",
    autocomplete_url,
    checkListOnChange,
    checklist,
    checklistKey,
    checklistShownvalue,
    className,
    defaultValue,
    fieldId,
    fieldName,
    isText,
    inputClassName,
    datasets,
    multiselect,
    onSelectElement,
    onChange,
    placeholder,
    preSuggestions,
    preselected,
    readonly,
    required,
    resetIcon,
    returnObjects,
    shortcuts,
    shortcutTitle,
    inputOptions
  } = props

  const REFRESH_TIME                            = 150
  const MIN_LETTERS                             = 3

  // The value of the search
  const [searchValue,      setSearchValue]      = useState(defaultValue || '')
  // The suggestions coming back from the search
  const [suggestions,      setSuggestions]      = useState(preSuggestions || [])
  // Array of selected ID's (or whatever `identifier`)
  const [multivalues,      setMultivalues]      = useState([])
  // Array of the objects, containing the `shownValue` and `identifier`
  const [multiObjects,     setMultiObjects]     = useState([])
  // If shortcuts, this is the selected one
  const [shortcutSelected, setShortcutSelected] = useState(0)
  // Highlighted value on the autocomplete
  const [highlighted,      setHighlighted]      = useState(-1)
  // Timeout formaking the autocomplete request
  const [currentTimeout,   setCurrentTimeout]   = useState(null)
  // Check if event listener has already been added
  const [listener,         setListener]   = useState(false)

  useEffect(() => {
    if (preselected) {
      const selection = typeof(preselected) == "string" ? JSON.parse(preselected) : preselected
      setMultiObjects(selection)
      const values = selection.map(v => v[identifier])
      setMultivalues(values)
    }
  }, [])

  useEffect(() => setSearchValue(props.defaultValue   || ''), [props.defaultValue])
  useEffect(() => setSuggestions(props.preSuggestions || []), [props.preSuggestions])
  useEffect(() =>  {
    if(suggestions.length > 0 && !listener) {
      setListener(true)
      document.addEventListener("click", onClickScreen, false)
    } else if(listener) {
      setListener(false)
    }
  }, [suggestions])

  const onClickScreen = e => {
    if(inputRef.current.contains(e.target)) return

    document.removeEventListener("click", onClickScreen, false)
    setSuggestions([])
  }

  const search = query => {
    setSearchValue(query)
    if (query.length === 0)         return cleanList()
    if (query.length < MIN_LETTERS) return

    if(autocomplete_url) {
      if (currentTimeout)             clearTimeout(currentTimeout)
      const timeout = setTimeout(() => {
        getRequest(buildUrl(query))
          .then(setSuggestions)
      }, REFRESH_TIME)

      setCurrentTimeout(timeout)
    }
    if(onChange) onChange(query)
  }

  const buildUrl = value => {
    if (autocomplete_url.includes("?"))
      return autocomplete_url + `&q=${value}`
    else
      return autocomplete_url + `?q=${value}`
  }

  const selectItem = suggestion => {
    if(!suggestion) return

    // If it's a multi select, we add the value to the list
    if (multiselect) {
      if(isNotInList(suggestion)) {
        const selectedMultiObjects = [...multiObjects, suggestion]
        const selectedIds = selectedMultiObjects.map(v => v[identifier])
        setMultiObjects(selectedMultiObjects)
        setMultivalues(selectedIds)
        if (onSelectElement) {
          if (returnObjects) onSelectElement(selectedMultiObjects)
          else               onSelectElement(selectedIds)
        }
      }
      setSearchValue('')
      cleanList()
    // Else we set/replace it
    } else {
      setMultiObjects([suggestion])
      setMultivalues([suggestion[identifier]])
      setShortcutSelected(null)
      setSuggestions([])
      setSearchValue(suggestion[shownValue])

      if (onSelectElement) {
        if (returnObjects) onSelectElement([suggestion])
        else               onSelectElement([suggestion[identifier]])
      }

      if (checklist)       checkListOnChange([suggestion])
    }
  }

  // Highlight the match between user input and suggestions
  const highlight = search => {
    const base           = searchValue
    const saneBase       = sanitizeHtml(base)
    const saneSearch     = sanitizeHtml(search)
    const saneRegex      = saneBase.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') // Escaping Regex characters
    const regex          = new RegExp(saneRegex, 'gi')
    const displayedValue = saneSearch.replace(regex, `<b>${saneBase}</b>`)
    return { __html: displayedValue }
  }

  // Accessibility : select suggested items with arrows
  const onKeyUp = e => {
    e.preventDefault()
    switch (e.key) {
      case   "ArrowUp": moveHighlighted(-1);
      case "ArrowDown": moveHighlighted(1);
      case     "Enter": selectItem(suggestions[highlighted]);
    }
  }

  const moveHighlighted = diff => {
    const newHighlighted = highlighted + diff
    if (newHighlighted < 0 || newHighlighted >= suggestions.length) return
    setHighlighted(newHighlighted)
  }

  // When the user select an item in the suggestions
  const isNotInList = value => multiObjects.filter(el => el[identifier] === value[identifier]).length <= 0

  const applyShortcut = (shortcut, index) => {
    const shortcutIds = shortcut.values.map(v => v[identifier])
    setShortcutSelected(index)
    setMultivalues(shortcutIds)
    setMultiObjects(shortcut.values)
    if (onSelectElement) {
      if (returnObjects) onSelectElement(shortcut.values)
      else onSelectElement(shortcutIds)
    }
    cleanList()
  }

  // Clean the suggestions list
  const cleanList = () => {
    setSuggestions([])
    setHighlighted(-1)
  }

  const resetInput = () => {
    cleanList()
    setMultiObjects([])
    setMultivalues([])
    setSearchValue('')
    setShortcutSelected(null)
    if (onSelectElement) {
      if (returnObjects) onSelectElement([])
      else               onSelectElement(undefined)
    }
    if (checklist)       checkListOnChange([])
    inputRef.current.focus()
  }
  // Multiselect — Remove item from the list of selected items
  const removeItem = item => {
    const multiObjectsCopy = [...multiObjects]
    const index = multiObjectsCopy.findIndex(el => el[identifier] === item[identifier])
    multiObjectsCopy.splice(index, 1)
    const selectedIds = multiObjectsCopy.map(v => v[identifier])
    setMultiObjects(multiObjectsCopy)
    setMultivalues(selectedIds)
    if (onSelectElement) {
      if (returnObjects) onSelectElement(multiObjectsCopy)
      else onSelectElement(selectedIds)
    }
  }

  // Data attributes for stimulus compatibility
  const inputData  = {}
  const hiddenData = {}
  if(datasets) {
    Object.keys(datasets).forEach(dataKey =>{
      const key   = "data-" + dataKey.toString()
      const value = datasets[dataKey]
      isText ? inputData[key] = value : hiddenData[key] = value
    })
  }

  return (
    <div className={`autocomplete__container ${className || ""}`}>
      {/* Input for autocomplete */}
      <div className="autocomplete__input-container">
        <input
          type         = "text"
          id           = {fieldId}
          className    = {inputClassName || "autocomplete__input"}
          name         = {isText ? fieldName : ''}
          placeholder  = {placeholder}
          onChange     = {e => search(e.target.value)}
          value        = {searchValue}
          onKeyUp      = {onKeyUp}
          required     = {required}
          readOnly     = {readonly}
          ref          = {inputRef}
          style        = {resetIcon ? { paddingRight: '44px' } : {}}
          autoComplete = "off"
          {...inputData}
        />

        {/* multivalues should be parsed to array of int */}
        {!isText && multivalues.map((value, index) =>
          <input
            type  = "hidden"
            name  = {fieldName}
            value = {value}
            key   = {index}
            {...hiddenData}
          />
        )}

        {resetIcon && !multiselect &&
          <div className="autocomplete__reset-icon pointer" onClick={resetInput}>
            <FontAwesomeIcon icon="times"/>
          </div>
        }

        {/* Rendered suggestions */}
        {suggestions.length > 0 &&
          <ul className="autocomplete__suggestions">
            {suggestions.map((suggestion, key) => (
              <li
                key       = {key}
                className = {`text-cut autocomplete__suggestions__items ${key === highlighted ? 'autocomplete__suggestions__items--selected' : ''}`}
                onClick   = {() => selectItem(suggestion)}>
                <span dangerouslySetInnerHTML = { highlight(suggestion[shownValue]) }>
                </span>
              </li>
            ))}
          </ul>
        }
      </div>

      {/* Shortcuts */}
      { shortcuts &&
        <Shortcuts
          title                 = {shortcutTitle}
          shortcuts             = {shortcuts}
          shortcutSelectedIndex = {shortcutSelected}
          identifier            = {identifier}
          shownValue            = {shownValue}
          onClickShortcut       = {(shortcut, index) => applyShortcut(shortcut, index)}
        />
      }

      {/* Checklist */}
      { checklist &&
        multiObjects.map((value, key) => {
          return <Checklist
            key           = {key}
            checklistKey  = {checklistKey}
            data          = {value[checklistKey] || []}
            shownValue    = {checklistShownvalue}
            onSelect      = {checkListOnChange}
          />
        })
      }

      {/* Multiselect */}
      { multiselect &&
        <Multiselect
          shownValue       = {shownValue}
          values           = {multiObjects}
          onClickSelected  = {removeItem}
        />
      }
    </div>
  )
}

export default Autocomplete
