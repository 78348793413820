import React, { useRef, useState } from 'react'
import { DirectUpload }            from "@rails/activestorage"
import { FontAwesomeIcon }         from '@fortawesome/react-fontawesome'
import Compressor                  from 'compressorjs';

const MAX_SIZE = 5242880 // 5MB

export const AddFiles = ({ prompt, addedFiles, maxNumber = 5, onAddedFiles, onRemoveFile, onRemoveImage, multiple = false }) => {
  const hiddenFileInput                   = useRef(null); 
  const [loading,       setLoading]       = useState(false)

  const handleClick = () => {
    hiddenFileInput.current.click() 
  }

  const compress = file => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality:   0.6,
        maxWidth:  1024,
        maxHeight: 1024,
        success(result) {
          process(result).then(resolve).catch(reject)
        },
        error: reject
      })
    })
  }
  
  const process = file => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')
      upload.create((error, blob) => {
        if (error) {
          reject(error)
        } else {
          const signedId = blob.signed_id
          const filename = blob.filename
          const url = `/rails/active_storage/blobs/${signedId}/${filename}`
          const filetype = blob.content_type
          const fileObject = { url, filetype, signedId, filename }
          resolve(fileObject)
        }
      })
    })
  }
  
  const addFiles = e => {
    const files = Array.from(e.target.files)
    if (!files) return
    if (files.length + addedFiles.length > maxNumber) return flashAlert(I18n.t("workorders.files.max_file_number"))

  
    const promises = files.map(file => {
      if (file.size > MAX_SIZE) {
        flashAlert(I18n.t('workorders.files.max_file_size'))
        return Promise.resolve(null)
      } else {
        const action = file.type.match('image/*') ? compress : process;
        return action(file)
      }
    })
  
    Promise.all(promises).then(fileObjects => {
      onAddedFiles(fileObjects)
      setLoading(false)
    }).catch(error => {
      flashAlert(error)
      setLoading(false)
    })
  }

  const removeFile = index => {
    const fileType = addedFiles[index].filetype
    if (fileType.match('image/*')) {
      onRemoveImage(index)
    } else {
      onRemoveFile(index)
    }
  }

  return (
    <>
      <div className={ `mb-4 mt-4 add-document-button form ${loading && 'disabled'}` } >
        { 
          ( addedFiles.length > 0 || loading ) && (
            <div className = 'd-flex mb-4 mt-2'>
              { 
                addedFiles.map((file, index) => {
                  return (
                    <div className = "p-relative" key = { file.url }>
                      { 
                        file.filetype.match('image/*') ?
                          <img src={file.url} alt="Preview" className='preview' />
                        : <iframe src={file.url} alt="Preview" className='preview' />
                      }
                      <div className='remove-file' onClick = { () => removeFile(index) }>
                        <FontAwesomeIcon icon = 'times' />
                      </div>
                    </div>
                  )
                })      
              }
            
              { 
                loading && 
                  <div className='preview preview-loading'>
                    <div id='loading-spinner'></div>
                  </div> 
              }
            </div>
          )
        }
        { addedFiles.length < maxNumber &&
          <span onClick = { handleClick }>
            <FontAwesomeIcon icon = 'plus' /> { I18n.t(prompt) }
          </span>
        }
      </div>
      <input 
        type      = "file"
        onChange  = { addFiles }
        ref       = { hiddenFileInput }
        className = 'hidden'
        multiple  = { multiple }
      />
    </>
  );
};