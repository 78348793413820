import { bodyRequest } from '@helpers/javascript/javascript'

export const markAsReadAction = notification => dispatch =>
  bodyRequest({
    method: 'PATCH',
    url:    `/mark_as_read`,
    body:   { notification_id: notification.id },
  }).then(() => dispatch({
    type:    NOTIFICATIONS_MARK.SUCCESS,
    payload: { notification }
  }))

export const NOTIFICATIONS_MARK = {
  SUCCESS: 'MARK_AS_READ_SUCCESS',

  successResponse: ({ state, notification } = {})   => {
    const items = state.items.map(item => item.id === notification.id ? { ...item, read: true } : item)
    return { ...state, items }
  }
}
