import React from          "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const UserLabel = ({ resource, popupAction }) => {
  const user  = resource.resource._resource.extendedProps
  
  const userInfo = info => {
    return (
      <span className = "mt-1"
        onMouseEnter={ e => popupAction({ x: e.pageX, y: e.pageY, content: info }) }
        onMouseLeave={ () => popupAction(false) }
      >
        <FontAwesomeIcon icon='info-circle' className = "ml-1"/>
      </span>
    )
  }

  return (
    <span className = 'flex'>
      <span className = "resource-title-dot" style= {{ backgroundColor: user.color }}></span>
      <span>{ user.name }</span>
      { user.information && userInfo(user.information) }
    </span>
  )
}