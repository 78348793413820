import React        from "react"

// Helpers
import { lineNameFor } from "../helpers"

export default class Destroyed extends React.Component {
  render() {
    const {qline, index} = this.props

    if (!qline.id) return("")

    return(
      <React.Fragment>
        <input
          type="hidden"
          defaultValue={qline.id}
          name={lineNameFor("id", index)}
        />

        <input
          type="hidden"
          name={lineNameFor("_destroy", index)}
          defaultValue="true"
        />
      </React.Fragment>
    )
  }
}
