import React    from "react"
import Combobox from '@shared/combobox'

export const ManagerSelect = ({ managers, manager, onChange }) => {
 return (
  <Combobox 
    options         = { managers }
    onSelectElement = { e => {onChange(e.id)} }
    placeholder     = { I18n.t("workorders.select_manager") }
    value           = { manager || '' }
    label           = { I18n.t("workorders.manager") }
    className       = "form-group"
    required
  />
 )
}