import { getRequest } from '@helpers/javascript/javascript'
import { PATHS }      from '@helpers/javascript/config/CONSTANTS'

const url = workorder => PATHS.workorders.fetch(workorder.id)

// Dispatch
export const refreshWorkorderAction = wo => dispatch => getRequest(url(wo))
  .then(({ workorder }) => dispatch({
    type:    WORKORDER_REFRESH.ACTION,
    payload: { workorder }
  }))

export const replaceWorkorderAction = workorder => dispatch =>
  dispatch({
    type:    WORKORDER_REFRESH.ACTION,
    payload: { workorder }
  })

// Reducer response
export const WORKORDER_REFRESH = {
  ACTION: 'REFRESH_WORKORDER_ACTION',

  call: ({ state, workorder }) => ({
    ...state,
    items: state.items.map(item => item.id == workorder.id ? workorder : item)
  })
}
