// Dispatch
export const departWorkorderAction = id => dispatch =>
  dispatch({
    type:    WORKORDER_DEPARTURE.ACTION,
    payload: { id }
  })

// Reducer response
export const WORKORDER_DEPARTURE = {
  ACTION: 'WORKORDER_DEPARTURE',

  call: ({ state, id }) => {
    const workorder = state.items.find(wo => wo.id === id)
    const editedWorkorder = {
      ...workorder,
      departure_date:     new Date,
      manual_travel_time: 0,
      synced:             false
    }

    const items = state.items.map(wo => wo.id === id ? editedWorkorder : wo)
    return { ...state, items }
  }
}
