import React, { useState,useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const Toggler = props => {

  const [displayed, setDisplayed] = useState(props.opened || false)
  const togglerRef                = useRef(null)

  const toggling = () => {
    if (!displayed && props.onDisplay) props.onDisplay()
    setDisplayed(!displayed)
    setTimeout(() => togglerRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" }), 100)
  }

  return (
    <>
      <div ref={togglerRef}>
        <h3 onClick={toggling}>{props.title + " "} 
          { displayed ? <FontAwesomeIcon icon='caret-up' /> : <FontAwesomeIcon icon='caret-down' />}  
        </h3>
      </div>
      <div className={`toggler-container ${displayed ? 'toggler-container--displayed' : undefined}`}>
        <div>{props.children}</div>
      </div>
    </>
  )
}
