import React    from "react"
import Combobox from "@shared/combobox" 

export const DefaultStorehouse = ({ storehouses, workorderState, setWorkorderState }) => {
  const selectStorehouse = storehouse => {
    setWorkorderState({ ... workorderState, defaultStorehouseId: storehouse.id })
  }

  return (
    <div className='form-group'>
      <label>
        { I18n.t("workorders.default_storehouse") }
      </label>

      <Combobox
        prompt          = { I18n.t("products.account_storehouse") }
        options         = { storehouses }
        onSelectElement = { selectStorehouse }
        value           = { workorderState.defaultStorehouseId }
      />
    </div>
  )
}