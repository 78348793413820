import { combineReducers }  from 'redux'
import { connectRouter }    from 'connected-react-router'

import notices        from './notices/noticesReducer'
import account        from './account/accountReducer'
import user           from './user/userReducer'
import workorders     from './workorders/workorderReducer'
import modale         from './modale/modaleReducer'
import sidemenu       from './sideMenu/sideMenuReducer'
import filters        from './filters/filterReducer'
import notifications  from './notifications/notificationsReducer'

const rootReducer = history => combineReducers({
  notices,
  account,
  user,
  workorders,
  modale,
  sidemenu,
  notifications,
  filters,
  router: connectRouter(history)
})

export default rootReducer
