import React, { useState } from "react"
import Base                from "./base"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { roundNumber, setSubTotals }     from "@react/quotes/form/helpers"

const ExpirationDays = ({quote, replaceQuote}) => {
  const [active, setActive] = useState(false);
  const [days, setDays]     = useState(0)

  const activate = () => {
    setDays(quote.expiration_days)
    setActive(true)
  }

  const onChange = (e) => {
    const expiration_days = parseInt(e.target.value)
    setDays(expiration_days)
  }

  const onCancel = () => {
    setActive(false)
    setDays(quote.expiration_days)
  }

  const setDate = () => {
    const creation_date   = new Date(quote.creation_date.split("/").reverse())
    const expiration_days = days || 0
    const date            = new Date(creation_date.setDate(creation_date.getDate() + expiration_days))
    const expiration_date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    setActive(false)
    replaceQuote({...quote, expiration_date, expiration_days: days})
  }

  return(
    <>
      {
        active
        ? <div>
            <label>{I18n.t("quotes.new.expiration_date")}</label>
            <div className="input-row">
              <input
                type="number"
                min="0"
                step="1"
                className="form-control"
                value={days || ""}
                onChange={onChange}
                required
              />

              <div className="action-buttons">
                {/* Cancel */}
                <div tooltip={I18n.t("button.cancel")}
                     onClick={onCancel}
                >
                  <FontAwesomeIcon
                    className="check-circle"
                    icon="times-circle"
                  />
                </div>

                {/* Apply */}
                <div tooltip={I18n.t("button.apply")}
                     onClick={setDate}
                >
                  <FontAwesomeIcon
                    className="check-circle"
                    icon="check-circle"
                  />
                </div>
              </div>
            </div>
          </div>
        : <p onClick={activate} className="expiration-date">
            {I18n.t("quotes.pdf.valid_until")}: {quote.expiration_date}
          </p>
      }
      <input
        type="hidden"
        name="quote[expiration_date]"
        defaultValue={quote.expiration_date}
      />
    </>
  )
}

const PriceMarkup = ({quote, replaceQuote}) => {
  const [active, setActive] = useState(false);
  const [markup, setMarkup] = useState(quote.price_markup_coefficient);

  const hasPrice = qline => ["product", "regular"].includes(qline.type)

  const onChange = (e) => {
    const price_markup = parseFloat(e.target.value) || 0
    setMarkup(price_markup)
  }

  const onCancel = () => {
    setActive(false)
    setMarkup(quote.price_markup_coefficient)
  }

  const recalculateSalesPrices = () => {
    if (!confirm(I18n.t("quotes.edit.price_markup_confirm"))) return

    quote.qlines = quote.qlines.map(qline => {
      if (hasPrice(qline)) {
        const has_unit_price     = qline.unit_price > 0
        const has_purchase_price = qline.purchase_price > 0

        if (has_unit_price && !has_purchase_price) {
          qline.purchase_price = roundNumber(qline.unit_price / markup)
        } else if(has_purchase_price) {
          qline.unit_price     = roundNumber(qline.purchase_price * markup)
        }
      }
      return qline
    })
    setSubTotals(quote)
    replaceQuote({...quote, price_markup_coefficient: markup })
    setActive(false)
  }

  return(
    <>
      {
        active
        ? <div>
            <label>{I18n.t("quotes.edit.price_markup_input")}</label>
            <div className="input-row">
              {/* Price markup coefficient input */}
              <input
                type="number"
                min="0"
                step="0.1"
                className="form-control"
                value={markup || ""}
                onChange={onChange}
              />

              <div className="action-buttons">
                {/* Cancel */}
                <div tooltip={I18n.t("button.cancel")}
                     onClick={onCancel}
                >
                  <FontAwesomeIcon
                    className="check-circle"
                    icon="times-circle"
                  />
                </div>

                {/* Apply */}
                <div tooltip={I18n.t("button.apply")}
                     onClick={recalculateSalesPrices}
                >
                  <FontAwesomeIcon
                    className="check-circle"
                    icon="check-circle"
                  />
                </div>
              </div>
            </div>
          </div>
        : <p onClick={() => setActive(true)} className="expiration-date">
            {I18n.t("quotes.edit.price_markup")} {quote.price_markup_coefficient}
          </p>
      }
      <input
        type="hidden"
        name="quote[price_markup_coefficient]"
        defaultValue={quote.price_markup_coefficient}
      />
    </>
  )
}

export default class Details extends Base {
  render() {
    const onlyUnique = (value, index, self) => value && self.indexOf(value) === index
    const { quote, replaceQuote } = this.props
    const qlines    = quote.qlines.filter(qline => !qline.destroyed)
    const vatLabels = qlines.map(line => line.vat_rate.label).filter(onlyUnique)

    return(
      <div className="quotes--form-details">
        {vatLabels.map(label => <p key={label}>{label}</p>)}

        <ExpirationDays quote={quote} replaceQuote={replaceQuote} />

        { quote.allow_markup &&
          <PriceMarkup
            quote={quote}
            replaceQuote={replaceQuote}
          />
        }

        <p>{I18n.t("account.iban")}: {quote.account.iban}</p>
        <p>{I18n.t("account.bic")}: {quote.account.bic}</p>
      </div>
    )
  }
}
