import React, { useState } from 'react'
import { ImportsSelect } from "./imports_select"

export const ImportsColumn = props  => {
  const {
    letter,
    column,
    samples,
    columns,
    setColumns
  } = props

  const [active, setActive] = useState(true)
  const toggle = e => {
    setActive(!active)
    setColumns({ ...columns, [column]: undefined })
  }
  return(
    <div className="css-grid-container imports-columns-grid" key={column}>
      <div className="css-grid">
        <div className="header">
          <div className="header-cell d-flex a-i-center" style={{fontWeight: "bold"}}>
            { letter }
          </div>

          <div className="header-cell d-flex a-i-center">
            { column }
          </div>

          <div className="header-cell d-flex a-i-center j-c-end checkbox checkbox-white mb-0">
            <input
              type     = "checkbox"
              id       = {`active_${column}`}
              onChange = {toggle}
            />
            <label htmlFor={`active_${column}`}>
              Skip
            </label>
          </div>
        </div>

        { active && samples.map((sample, index) =>
          <div className="row" key={index}>
            <div className="row-cell with-padding text-left">
              { index + 1 }
            </div>

            <div className="row-cell with-padding text-left">
              { sample }
            </div>

            { index == 0 &&
              <div className="row-cell with-padding text-left imports-columns"
                   style={{gridArea: `span ${samples.length + 1} / span 1`}}
              >
                <label>{ I18n.t("import.assign_column")}</label>
                <ImportsSelect
                  column     = {column}
                  columns    = {columns}
                  setColumns = {setColumns}
                  editing    = {props.editing}
                  type       = {props.type}
                  table      = {props.table}
                />
              </div>
            }
          </div>
        )}
      </div>
    </div>
  )
}

