import React, { useEffect, useState } from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

// Build library of icons
library.add(fas)

// Helper
import { bodyRequest }      from '@helpers/javascript/javascript'

// Components
import { TodayOverview }   from './TodayOverview'
import { DailyWorkorders }  from './DailyWorkorders'
import { DailyStatus }      from './DailyStatus'
import { DailyTechTime }    from './DailyTechTime'
import { YearWorkorders }   from './YearWorkorders'

const Daily = props => {
  const {
    currentTeam,
    accountTeams,
    todayPath,
    workordersByYearPath,
    dailyDataPath,
    invoices,
    trackTime
  } = props

  const getDailyData = () => {
    const userIds = [...new Set(dailyUsers.map(u => u['id']))]
    bodyRequest({
      method: 'POST', url: dailyDataPath,
      body: { users: userIds, date: dailyDate.toString() }
    }).then(setDailyData)
  }

  // Today
  const [dailyUsers,      setDailyUsers]      = useState(currentTeam.values)
  const [dailyDate,       setDailyDate]       = useState(new Date())
  const [dailyWorkorders, setDailyWorkorders] = useState([])
  const [dailyStatus,     setDailyStatus]     = useState([])
  const [dailyTime,       setDailyTime]       = useState([])
  const [dailyData,       setDailyData]       = useState({status: [], time: [], workorders: []})

  useEffect(() => getDailyData(), [])
  useEffect(() => getDailyData(), [dailyUsers, dailyDate])

  useEffect(() => {
    setDailyStatus(dailyData.status)
    trackTime && setDailyTime(dailyData.time)
    setDailyWorkorders(dailyData.workorders)
  }, [dailyData])

  return (
    <>
      {/* Today's resume */}
      <TodayOverview
        url      = {todayPath}
        team     = {dailyUsers}
        invoices = {invoices}
      />

      {/* Resume by year */}
      <YearWorkorders
        url               = {workordersByYearPath}
        users             = {dailyUsers}
        updateDate        = {setDailyDate}
        currentTeam       = {currentTeam}
        accountTeams      = {accountTeams}
        onChangeTeam      = {setDailyUsers}
      />

      <div className="dashboard__daily">
        <DailyStatus     data={dailyStatus} />
        <DailyWorkorders
          dailyDate ={dailyDate}
          updateDate={setDailyDate}
          workorders={dailyWorkorders}
          trackTime ={trackTime}
        />
      </div>

      { trackTime && <DailyTechTime data={dailyTime} />}
    </>
  )
}

export default Daily
