// Modules
import React, { useState, useEffect } from "react"

import { getRequest } from '@react/helpers/fetch'
import { pathname }   from '@react/helpers/fetch'
import { library }    from '@fortawesome/fontawesome-svg-core'
import { fas }        from '@fortawesome/free-solid-svg-icons'
library.add(fas)

// Components
import { Filter } from './filter'
import { Box }    from './box'

const App = ({ technicians, initialFilter, technicianResupply }) => {
  const [loading, setLoading] = useState(true)
  const [filter,  setFilter]  = useState(initialFilter)
  const [boxes,   setBoxes]   = useState([])

  const feedData = () => {
    setLoading(true)

    const path   = location.pathname
    const params = {}
    Object.keys(filter).forEach(key => {
      const value = filter[key]
      if(value) params[key] = value
    })

    if (technicianResupply) params["technician_resupply"] = true
    const url = pathname({ path, params })
    history.replaceState(history.state, document.title, url)
    getRequest(url)
    .then(boxes => {
      setBoxes(boxes)
      setLoading(false)
    })
    .catch(error => {
      console.error(error)
      setLoading(false)
    })
  }

  useEffect(feedData, [filter])

  return(
    <>
      <Filter {...{technicians, filter, setFilter, technicianResupply }}/>

      { loading
        ? <div className="loading-spinner-container-sm h-50vh">
            <div id="loading-spinner"></div>
          </div>
        : boxes.length == 0
          ? <div className="timesheet__sheet">
              <div className='timesheet__sheet__user j-c-center'>
                {I18n.t("client.no_results")}
              </div>
            </div>
          : boxes.map((currentBox, key) =>
            <Box {...{key, currentBox, filter, technicianResupply}} />
          )
      }

      <button hidden onClick={feedData} id="product-dispatcher-refresh" />
    </>
  )
}

export default App

// const mockFilter = {
//   from: "2021-05-11",
//   to:   "2021-05-11",
//   technician: ''
// }
