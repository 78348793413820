import React, { useEffect, useState } from "react"
import { FontAwesomeIcon }            from '@fortawesome/react-fontawesome'
import { jsErbCall }                  from '../../helpers/jsErbCall'

export const DailyWorkorders = ({ dailyDate, workorders, updateDate, trackTime }) => {
  const STATUS_HEIGHT = 400

  const [date, setDate] = useState(dailyDate)

  // When user change date in another component
  useEffect(() => setDate(dailyDate), [dailyDate])

  // When user change date in this component
  const changeDate = diff => setDate(new Date((date.setDate(date.getDate() + diff))))
  useEffect(() => updateDate(date), [date])

  return (
    <div className="dashboard__card dashboard__workorders" style={{ height: `${STATUS_HEIGHT}px` }}>
      <div className="dashboard__workorders__title">
        <div></div>
        <div className="dashboard__workorders__date">
          <div className="dashboard__workorders__date__arrow" onClick={() => changeDate(-1)}><FontAwesomeIcon icon="arrow-left" /></div>
          <h2>{new Date(date).toLocaleString(I18n.locale, { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' })}</h2>
          <div className="dashboard__workorders__date__arrow" onClick={() => changeDate(1)}><FontAwesomeIcon icon="arrow-right" /></div>
        </div>
        <div className="dashboard__workorders__new">
          <a href="/workorders/new" target="_blank">
            <FontAwesomeIcon icon="plus" /> Intervention
          </a>
        </div>
      </div>
      <div className="dashboard__workorderline__container">
        {workorders.length >= 1
          ? workorders.map((workorder, key) => <WorkorderLine key={workorder.id} {...{workorder, trackTime}} />)
          : <div className="dashboard__card--empty">{I18n.t('dashboard.no_data')}</div>
        }
      </div>
    </div>
  )
}

const WorkorderLine = ({workorder, trackTime}) => {
  const getColor = () => {
    switch (workorder.status) {
    case 'open':        return 'red';
    case 'scheduled':   return 'yellow';
    case 'in-progress': return 'blue';
    case 'closed':      return 'green';
    default:            return 'purple';
    }
  }

  const showModale = url => {
    const bigCardContainer = document.getElementById('cards-container');
    const spinnerHTML = `<div class='loading-spinner-container'>
                              <div id='loading-spinner'></div>
                          </div>`;
    bigCardContainer.innerHTML = spinnerHTML;
    document.getElementById('show-big-card-modal-btn').click();
    jsErbCall(url)
  }

  return(
    <div className="dashboard__workorderline">
      <div className={`dashboard__workorderline__badge dashboard__workorderline__badge--${getColor()}`}></div>
      <div className="dashboard__workorderline__info dashboard__workorderline__info--time">
        <div>
          <span className="dashboard__workorderline__icon" >
            <FontAwesomeIcon icon="clock" />
          </span>
          {new Date(workorder.date_planned).toLocaleTimeString(I18n.locale, {hour: '2-digit', minute: '2-digit'})}
        </div>

        { trackTime &&
          <>
            <div>
              <span className="dashboard__workorderline__icon" >
                <FontAwesomeIcon icon="play" />
              </span>
              {workorder.date_started ? new Date(workorder.date_started).toLocaleTimeString(I18n.locale, {hour: '2-digit', minute: '2-digit'}) : "—"}
            </div>

            <div>
              <span className="dashboard__workorderline__icon" >
                <FontAwesomeIcon icon="lock" />
              </span>
              {workorder.date_done ? new Date(workorder.date_done).toLocaleTimeString(I18n.locale, {hour: '2-digit', minute: '2-digit'}) : "—"}
            </div>
          </>
        }
      </div>
      <div className="dashboard__workorderline__info">
        <div className="dashboard__workorderline__title">{workorder.name} — {workorder.title}</div>
        <div className="d-flex">
          <div className="dashboard__workorderline__badge dashboard__workorderline__badge--small" style={{backgroundColor: workorder.technician.color}}></div>
          {workorder.technician.name}
        </div>
      </div>
      <div className="dashboard__workorderline__info">
        <div>
          <FontAwesomeIcon icon="user-circle" className="fa-w-16 mr-3" />
          <a href={workorder.client.link} target="_blank">{workorder.client.name}</a>
        </div>
        <div>
          <FontAwesomeIcon icon="building" className="fa-w-16 mr-3"/>
          <a href={workorder.site.link} target="_blank">{workorder.site.name}</a>
        </div>
      </div>
      <div className="dashboard__workorderline__info dashboard__workorderline__info--btn">
        <div className="dashboard__btn" onClick={() => showModale(workorder.link)}>
          <FontAwesomeIcon icon="eye" />
        </div>
      </div>
    </div>
  )
}
