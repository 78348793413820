import { STATUSES } from '@helpers/javascript/config/CONSTANTS'

// Dispatch
export const workorderTimelessStartAction = id => dispatch =>
  dispatch({
    type:    WORKORDER_TIMELESS.START,
    payload: { id }
  })

export const workorderTimelessCloseAction = id => dispatch =>
  dispatch({
    type:    WORKORDER_TIMELESS.CLOSE,
    payload: { id }
  })

// Reducer response
export const WORKORDER_TIMELESS = {
  START: 'WORKORDER_TIMELESS_START',
  CLOSE: 'WORKORDER_TIMELESS_CLOSE',

  start: ({ state, id }) => ({
    ...state,
    items: state.items.map(item => item.id != id ? item : {
      ...item,
      date_started: new Date(),
      date_done:    new Date(),
      paused:       false,
      status:       STATUSES.IN_PROGRESS,
      synced:       false
    })
  }),

  close: ({ state, id }) => ({
    ...state,
    items: state.items.map(item => item.id != id ? item : {
      ...item,
      date_started: new Date(),
      date_done:    new Date(),
      paused:       false,
      status:       STATUSES.CLOSED,
      synced:       false
    })
  })
}
