import React      from "react"
import LineBase   from "./line_base"

// Components
import Buttons       from "./shared/buttons"
import Destroyed  from "./line_destroyed"
import Combobox   from "@shared/combobox"

// Helpers
import { getRequest } from '@helpers/javascript/javascript'

export default class Blueprint extends LineBase {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    const { index, qline } = this.props
    const { replaceLine }  = this.props.buttonProps

    getRequest(`/quotes/form/${e.id}/blueprint.json`)
      .then(blueprint =>
        replaceLine({
        ...qline,
        description:         blueprint.description,
        ref_product_distrib: blueprint.ref_product_distrib,
        quantity:            blueprint.quantity,
        unit:                blueprint.unit,
        unit_price:          blueprint.unit_price,
        hide_in_pdf:         blueprint.hide_in_pdf,
        option:              blueprint.option,
        purchase_price:      blueprint.purchase_price,
        blueprint_id:        blueprint.blueprint_id,
        type:                blueprint.type
      }, index))
  }

  render() {
    const { index, qline, blueprints, buttonProps } = this.props

    return(
      <div className="quotes--form-qline-container">
        <div className = "text-input">
        <Combobox
            options         = {blueprints}
            value           = {qline.blueprint_id}
            onSelectElement = {this.onChange}
            prompt          = {I18n.t("quotes.qline_blueprints.select")}
            required        = {true}
          />
        </div>

        <Buttons
          index={index}
          qline={qline}
          buttonProps={buttonProps}
        />

        <Destroyed
          index={index}
          qline={qline}
        />
      </div>
    )
  }
}
