// Dispatch
export const updateSignerAction = (id, newSigner) => dispatch =>
  dispatch({
    type:    WORKORDER_UPDATE_SIGNER.ACTION,
    payload: { id, newSigner }
  })

// Reducer response
export const WORKORDER_UPDATE_SIGNER = {
  ACTION: 'WORKORDER_UPDATE_SIGNER',

  call: ({ state, id, newSigner }) => {
    const workorder = state.items.find(wo => wo.id === id)

    const editedWorkorder = {
      ...workorder,
      signer: newSigner,
      synced: false
    }

    const workorders = state.items.map(wo => wo.id === id ? editedWorkorder : wo)
    return { ...state, items: workorders }
  }
}
