// Show edit form
// -- Toggle function
const toggleEls = (baseElement, newElement) => {
  baseElement.classList.toggle('hide')
  newElement.classList.toggle('hide')
}

const ordersInit = () => {
  const orderTable = document.querySelector('#order-container')
  // Show all product informations
  if (orderTable) {
    const showProduct = document.querySelectorAll('.show-product')
    showProduct.forEach((row) => {
      row.addEventListener('click', () => {
        const productDesc = document.querySelector(`#${row.dataset.id}`)
        row.classList.toggle('nohover')
        productDesc.style.backgroundColor = row.style.backgroundColor
        productDesc.classList.toggle('hide')
      })
    })

    // -- Select all buttons
    const editBtns = document.querySelectorAll('.edit-product-btn')
    editBtns.forEach((editBtn) => {
      const selectedProduct = document.querySelector(`#order-${editBtn.dataset.id}`)
      const editForm = document.querySelector(`#productdesc-edit-${editBtn.dataset.id}`)
      const cancelBtn = document.querySelector(`#edit-close-${editBtn.dataset.id}`)
      // -- Toggle on click
      editBtn.addEventListener('click', () => {
        toggleEls(selectedProduct, editForm)
      })
      cancelBtn.addEventListener('click', () => {
        toggleEls(selectedProduct, editForm)
      })
    })
  }
}

export { ordersInit }
