import React, { useState, useCallback, useEffect } from 'react'

// Helpers
import { getRequest, pathname } from '@helpers/javascript/javascript'

// Components
import Combobox          from "@shared/combobox"
import { StepCategory }  from "./step_category"
import { StepPhoto }     from "./step_photo"
import { StepIndicator } from "./step_indicator"
import { StepMove }      from "./step_move"
import Scanner           from "./scanner"

const initialState = {
  material:    {},
  parent:      {children: []},
  child:       {},
  categories:  [],
  loading:     false,
  currentStep: 1,
  error:       false
}

const App = props => {
  const steps = [1, 2, 3, 4]
  const [state, setState] = useState(initialState)

  const {
    material,
    parent,
    child,
    categories,
    loading,
    currentStep,
    error
  } = state

  const fetchMaterial = data => {
    setState({ ...initialState, loading: true })
    const path = '/demands/fetch_material'
    const params = { code: data }
    const url = pathname({ path, params })
    getRequest(url).then(res => {
      selectMaterial(res.material)
    }).catch(_err => setState({...state, error: true}))
  }

  const selectMaterial = useCallback(material => {
    setState({...state, error: false})
    if (material.id) {
      setState({ ...initialState, material, loading: true })
      const path = '/demands/fetch_categories'
      const params = { material: material.id }
      const url = pathname({ path, params })
      getRequest(url).then(categories => {
        const nextStep = categories.length > 0 ? 2 : 4
        setState({
          ...initialState,
          material,
          categories,
          currentStep: nextStep,
          loading:     false
        })
      })
    } else {
      setState({ ...initialState })
    }
  })

  const selectParent = useCallback(parent => {
    const nextStep = parent.children.length > 0 ? 3 : 4
    setState({...state, parent, child: {}, currentStep: nextStep})
  })

  const selectChild = useCallback(child => {
    setState({...state, child, currentStep: 4})
  })

  const label = () => {
    switch(currentStep) {
      // case 1: return I18n.t("workorder_demands.new.title.step1")
      case 2: return material.name
      case 3: return parent.name
      case 4: return I18n.t("workorder_demands.new.title.step4")
    }
  }

  useEffect(() => {
    if (props.material) { selectMaterial(props.material) }
  }, [])

  const minStep = props.material ? 2 : 1

  return (
    <>
      { loading
        ? <div className="demands--spinner-container">
            <div id="demands--spinner"></div>
          </div>
        : <>
            <label className="demands--title">{ label() }</label>

            {currentStep === 1 &&
            <>
              <Combobox
                options         = {props.materials}
                value           = {material.id}
                onSelectElement = {selectMaterial}
                placeholder     = {I18n.t("workorder_demands.new.placeholder_where")}
                required
              />

              <p className='text-center'>{I18n.t('green_landing.or')}</p>

              { error &&
                <p className='text-center demands--error'>
                  {I18n.t("workorder_demands.new.error")}
                </p>
              }

              <Scanner handleScan={fetchMaterial}/>
              </>
            }

            {currentStep === 2 && categories.length > 0 &&
              <StepCategory
                categories = {categories}
                selected   = {parent}
                onSelect   = {selectParent}
              />
            }

            {currentStep === 3 && parent.children.length > 0 &&
              <StepCategory
                categories = {parent.children}
                selected   = {child}
                onSelect   = {selectChild}
              />
            }

            { currentStep === 4 && <StepPhoto /> }

            {/*<StepIndicator {...{ steps, currentStep }} />*/}

            <StepMove {...{steps, state, setState, minStep }} />
          </>
      }

      <input
        defaultValue={material.id || ''}
        type="hidden"
        name="workorder_demand[material_id]"
      />

      <input
        defaultValue={child.id || parent.id || ''}
        type="hidden"
        name="workorder_demand[category_id]"
      />
    </>
  )
}

export default App
