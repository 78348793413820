import React from "react"

import Discount from "./discount"
// Helpers
import { formatNumber } from "../helpers"

export default class Totals extends React.Component {
  render() {
    const { quote, calculations, replaceQuote } = this.props

    return(
      <div className="totals">
        <div className="line sub-total">
          <div>{I18n.t("quotes.pdf.hvta_total")}</div>
          <div>{formatNumber(calculations.subTotal)} {quote.currency_symbol}</div>
        </div>

        <Discount
          quote={quote}
          calculations={calculations}
          replaceQuote={replaceQuote}
        />

        {
          (calculations.purchase > 0) && quote.show_margin
          ? <React.Fragment>
              <div className="line sub-total">
                <div>{I18n.t("quotes.bigcard.purchase")}</div>
                <div>{formatNumber(calculations.purchase)} {quote.currency_symbol}</div>
              </div>

              <div className="line sub-total">
                <div>{I18n.t("quotes.bigcard.margin")}</div>
                <div>{formatNumber(calculations.margin)} %</div>
              </div>

              <div className="line sub-total">
                <div>{I18n.t("quotes.bigcard.coefficient")}</div>
                <div>{formatNumber(calculations.coefficient)}</div>
              </div>
            </React.Fragment>
          : ""
        }

        {
          <div className="line sub-total">
            <div>{I18n.t("quotes.pdf.total_discounted")}</div>
            <div>{formatNumber(calculations.subTotal - calculations.totalDiscount)} {quote.currency_symbol}</div>
          </div>
        }

        {
          Object.entries(calculations.vats).map(vat =>
            <div key={vat[0]} className="line sub-total">
              <div>{I18n.t("quotes.pdf.vat_percentage")} {vat[0]} %</div>
              <div>{formatNumber(vat[1])} {quote.currency_symbol}</div>
            </div>
        )}

        <div className="line total">
          <div>{I18n.t("quotes.pdf.grand")}</div>
          <div>{formatNumber(calculations.total)} {quote.currency_symbol}</div>
        </div>
      </div>
    )
  }
}
