import React, { useState, useRef, useEffect } from "react"
import sanitizeHtml               from 'sanitize-html-react'
import { library }                from '@fortawesome/fontawesome-svg-core'
import { fas }                    from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon }        from '@fortawesome/react-fontawesome'
import { getRequest, pathname }   from  '@react/helpers/fetch'

library.add(fas)
/*
  This is a similar component from the Autocomplete in the desktop app with
  Trimmed functionallity regarding groups multiselects etc etc

  <Autocomplete
    -----------
    EXAMPLE FOR identifier/display
    Props passed               -> identifier: 'id', display: 'name'
    Expected response format   -> { id: 2, name: 'Miguel' }
    -----------

    identifier:      String. Unique identifier between items. Usually 'id'
    display:         String. What part of the hash will be displayed
    options:         Array.  Contains all the suggestions [{ id: 2, name: 'Miguel' }, ...]
    label:           String. Render a label with given string
    className:       String. Optional css class to give to the container
    value:           Number. Default selected option identifier
    disabled:        Boolean
    inputClassName:  String. Optional css class to give to the input
    onSelectElement: Function. Trigger something everytume we select/unselect someone
    required:        Boolean. required input
    feed:            URL. If given, it will work as an autocomplete
    -----------------
  />
*/
const parse = string => string.normalize().toLowerCase()
export const hit   = (target, string) => parse(target).includes(parse(string))
// Highlight the match between user input and suggestions
const highlight = (search, base) => {
  const saneBase       = sanitizeHtml(base)
  const saneSearch     = sanitizeHtml(search)
  const saneRegex      = saneBase.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') // Escaping Regex characters
  const regex          = new RegExp(saneRegex, 'gi')
  const displayedValue = saneSearch.replace(regex, `<b>${saneBase}</b>`)
  return { __html: displayedValue }
}

const Combobox = ({
    identifier = 'id',
    display    = 'name',
    options    = [],
    data       = {},
    inputStyle = {},
    autocomplete = false,
    disabled     = false,
    multi        = false,
    activated    = false,
    resetIcon    = false,
    label,
    className,
    value,
    fieldName,
    onSelectElement,
    onClear,
    onReset,
    prompt,
    placeholder,
    required,
    feed
  }) => {
  const inputRef       = useRef(null)
  const hiddenInputRef = useRef(null)
  const suggestionsRef = useRef(null)
  const timeoutRef     = useRef(null)
  const comboRef       = useRef(null)

  const didSelect      = useRef(false)
  const didMountRef    = useRef(false)

  const promptOption = prompt ? { [display] : prompt  } : {}
  const preOptions   = prompt && !required ? [promptOption, ...options] : options
  const preselected  = value ? options.find(option => option[identifier] == value) || {} : {}

  // Data attributes for the hidden input, makes the component stimulus compatible
  const inputData  = {}
  Object.keys(data).forEach(key =>{
    const dataKey = "data-" + key.toString()
    inputData[dataKey] = data[key]
  })

  const [state, setState] = useState({
    // Current option list
    suggestions: preOptions,
    // The actual selected option
    selected:    preselected,
    // The selection while moving arrows
    highlighted: preselected,
    // Indicates if the list is shown or not
    active:      activated
  })

  const [search, setSearch] = useState(preselected[display])
  const {suggestions, selected, highlighted, active} = state

  const highlightedIndex = suggestions.findIndex(suggestion =>
    suggestion[identifier] == highlighted[identifier]
  )

  const onSearch = query => {
    if (query.length === 0) {
      resetInput()
    } else if (!active) {
      activate()
    } else {
      setSearch(query)
      if (feed) {
        clearTimeout(timeoutRef.current)
        const url = pathname({ path: feed, params: { q: query }})
        timeoutRef.current = setTimeout(() => {
          getRequest(url).then(response => {
            setState({...state,
              suggestions: response,
              highlighted: response[0] || {}
            })
          })
        }, 150)
      } else {
        const hits = options.filter(option => hit(option[display], query))
        setState({...state,
          suggestions: hits,
          highlighted: hits[0] || {}
        })
      }
    }
  }

  const onKeyDown = e => {
    let selection
    switch(e.key) {
      case "ArrowUp":
        selection = suggestions[highlightedIndex - 1] || {}
        setState({...state, highlighted: selection})
        break
      case "ArrowDown":
        selection = suggestions[highlightedIndex + 1] || {}
        setState({...state, highlighted: selection})
        break
      case "Backspace":
        if (onClear && search == undefined) {
          deactivate()
          onClear()
        }
        break
      case "Enter":
        if (active) {
          e.preventDefault()
          e.stopPropagation()
          selectItem(highlighted)
        }
        break
    }
  }

  const toggle     = () => active ? deactivate() : activate()
  const deactivate = () => setState({...state, active: false})
  const activate   = () => setState({...state, active: true})

  const clickItem = (e, selected) => {
    e.preventDefault()
    e.stopPropagation()

    selectItem(selected)
  }

  const selectItem = selected => {
    if (!selected) return

    if (multi) {
      setState({...state, active: false })
      setSearch(undefined)
    } else {
      setState({
        selected:    selected,
        highlighted: selected,
        suggestions: feed ? [selected] : preOptions,
        active:      false
      })

      setSearch(selected[display])
    }
    if (onSelectElement) onSelectElement(selected)
    didSelect.current = true
  }

  const reset = () => {
    setState({
      suggestions: preOptions,
      selected:    {},
      highlighted: {},
      active:      false
    })
    setSearch(undefined)
    if (onReset) onReset()
  }

  const resetInput = (bubble = true) => {
    setState({
      ...state,
      suggestions: preOptions
    })
    setSearch(undefined)
    if (onSelectElement && bubble) onSelectElement(null)
  }

  useEffect(() => {
    if (didMountRef.current) {
      if (active) {
        inputRef.current.focus()
        resetInput(false)
      } else {
        const selectedValue = selected[identifier] ? selected[display] : undefined
        if (!autocomplete) setSearch(selectedValue)
      }
    }
  }, [active])

  useEffect(() => {
    if (didSelect.current && data && fieldName && hiddenInputRef.current) {
      const event = new Event("change")
      hiddenInputRef.current.dispatchEvent(event)
    }
  }, [selected])

  useEffect(() => {
    if (didMountRef.current) {
      setState({...state, selected: preselected})
      setSearch(preselected[display])
    }
  }, [value])

  useEffect(() => {
    if (didMountRef.current && activated && !active) {
      activate()
    }
  }, [activated])

  const closeSuggestions = e => {
    if(comboRef.current && suggestionsRef.current && !comboRef.current.contains(e.target)) {
      deactivate()
    }
  }

  useEffect(() => {
    didMountRef.current = true
    document.addEventListener('mousedown', closeSuggestions)
  }, [])

  const scrollHighlighted = () => {
    if (active) {
      const list = suggestionsRef.current
      if (list) {
        const item = list.querySelectorAll('li')[highlightedIndex]
        if(item) list.scrollTop = item.offsetTop
      }
    }
  }

  scrollHighlighted()

  return (
    <>
      <div className = {`autocomplete__container ${className || ""}`} ref={comboRef}>
        {/* Optional label */}
        { label &&
          <div className="input-group">
            <label required={required}>{label}</label>
          </div>
        }
        <div className="autocomplete__input-container">
          {/* Input for autocomplete */}
          <input
            type         = "text"
            ref          = {inputRef}
            className    = {'autocomplete__input pointer'}
            value        = {sanitizeHtml(search || '')}
            name         = {autocomplete ? fieldName : ''}
            disabled     = {disabled}
            onFocus      = {activate}
            onChange     = {e => onSearch(e.target.value)}
            onKeyDown    = {onKeyDown}
            placeholder  = {placeholder || prompt || selected[display]}
            required     = {required}
            style        = {{ ...inputStyle, paddingRight: '44px' }}
            autoFocus    = {active}
            autoComplete = "off"
          />

          { fieldName && !multi && !autocomplete &&
            <input
              hidden
              ref              = {hiddenInputRef}
              name             = {fieldName}
              required         = {required}
              value            = {selected[identifier] || ''}
              onChange         = {() => {}}
              {...inputData}
            />
          }

          { resetIcon && selected[identifier]
            ? <div className="autocomplete__icon autocomplete__reset" onClick={reset}>
                <FontAwesomeIcon icon="times"/>
              </div>
            : feed
              ? <div className="autocomplete__icon">
                  <FontAwesomeIcon icon="search"/>
                </div>
              : <div className="autocomplete__icon" onClick={disabled ? null : toggle}>
                  <FontAwesomeIcon icon="caret-down"/>
                </div>
          }

          {/* Rendered suggestions */}
          { active && suggestions.length > 0 &&
            <ul className="autocomplete__suggestions" ref={suggestionsRef}>
              { suggestions.map((suggestion, index) => {
                  const isSelected    = suggestion[identifier] == selected[identifier]
                  const isHighlighted = suggestion[identifier] == highlighted[identifier]
                  const classNames    = ["autocomplete__suggestions__items"]
                  if (isSelected) {
                    classNames.push("autocomplete__suggestions__items--selected")
                  } else if (isHighlighted) {
                    classNames.push("autocomplete__suggestions__items--highlighted")
                  }

                  return(
                    <li
                      key       = {index}
                      onClick   = {e => clickItem(e, suggestion)}
                      className = {classNames.join(" ")}
                    >
                      <span dangerouslySetInnerHTML={highlight(suggestion[display], search)}/>
                    </li>
                  )
                }
              )}
            </ul>
          }
        </div>
      </div>
    </>
  )
}

export default Combobox
