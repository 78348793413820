import React                from "react"
import { Task }             from "./task"
import { parsePredicted }   from "@react/helpers/helpers"
import { FontAwesomeIcon }  from "@fortawesome/react-fontawesome"

export const MaterialTasks = ({
  material,
  tasks,
  blueprints,
  workorderState,
  setWorkorderState,
  updateWorkorderDuration
}) => {

  const handleBlueprintChange = (blueprint, index) => {
    const copiedTasks              = [...workorderState.maintenanceTasks]
    const taskToChange             = copiedTasks[index]
    taskToChange.taskBlueprintId   = blueprint.id
    taskToChange.predictedDuration = blueprint.predictedDuration
    setWorkorderState({ ...workorderState, maintenanceTasks: copiedTasks })
  }

  const handleDurationChange = (duration, index) => {
    const parsedDuration = parsePredicted(duration)
    const copiedTasks    = [...workorderState.maintenanceTasks]
    const taskToChange   = copiedTasks[index]
    taskToChange.predictedDuration = parsedDuration
    updateWorkorderDuration(copiedTasks)
  }

  const addTask = () => {
    const newTask = { materialId: material, taskBlueprintId: null, predictedDuration: 60 }
    updateWorkorderDuration([...workorderState.maintenanceTasks, newTask])
  }

  const removeTask = taskIndex => {
    const copiedTasks = [...workorderState.maintenanceTasks]
    copiedTasks.splice(taskIndex, 1)
    setWorkorderState({ ...workorderState, maintenanceTasks: copiedTasks })
    updateWorkorderDuration(copiedTasks)
  }

  return (
    <div className="d-flex flex-column">
      { tasks.map(taskIndex => { 
        return <Task 
            key                   = { taskIndex }
            taskIndex             = { taskIndex }
            isFirstTask           = { taskIndex === tasks[0] }
            task                  = { workorderState.maintenanceTasks[taskIndex] }
            blueprints            = { blueprints.filter(blueprint => workorderState.maintenanceTasks.map(task => task.taskBlueprintId == blueprint.id)) }
            handleBlueprintChange = { handleBlueprintChange }
            handleDurationChange  = { handleDurationChange }
            removeTask            = { removeTask }
          />
      })}
      <a className = 'small flex-end mb-2 text-pointer' onClick = { addTask }>
        <FontAwesomeIcon icon='plus-circle' className="mr-2"/>
        { I18n.t("maintenance.add_task") }
      </a>
    </div>
  )
}