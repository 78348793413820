import React  from "react"
import ComboboxMulti                   from "@shared/combobox_multi"

export const SecondaryTechnicians = ({ technicians, technicianId, onSelect, secondaryTechniciansIds=[] }) => {  
  const selectedTechnicians = technicians.filter(tech => secondaryTechniciansIds.includes(tech.id))

  return (
    <div className="form-group">
      <label>{I18n.t('workorders.secondary_technicians')}</label>
      <ComboboxMulti 
        display         = "name"
        options         = { technicians.slice(1) }
        value           = { selectedTechnicians }
        onChange        = { onSelect }
        prompt          = {  I18n.t("workorders.select") }
        resetIcon
        disabled        = { !technicianId }
      />
    </div>
  )
}