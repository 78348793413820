// Dispatch
export const removeReadingAction = (workorder, readingVersion) => dispatch =>
  dispatch({
    type:    REMOVE_READING.ACTION,
    payload: { workorder, readingVersion }
  })

// Reducer response
export const REMOVE_READING = {
  ACTION: 'REMOVE_READING',

  call: ({ state, workorder, readingVersion }) => (
    {
      ...state,
      items: state.items.map(item => item.id != workorder.id ? item : {
        ...item,
        synced: false,
        readings: item.readings.map(reading => reading.version != readingVersion ? reading :{
          ...reading,
          _destroy: true
        })
      })
    }
  )
}
