import React, { useRef }        from "react"
import { ManagerSelect }        from "./managerSelect"
import { TechnicianSelect }     from "./technicianSelect"
import { SecondaryTechnicians } from "./secondaryTechnicians"
import { DatePlanned }          from "./datePlanned"
import { Calendar }             from "./calendar"
import { SmallCardInfo }        from "./smallCardInfo"
import OverlaySpinner           from "@shared/overlaySpinner"

export const StepTwo = ({ 
  managers, 
  technicians, 
  workorderState, 
  setWorkorderState, 
  setCurrentStep,
  calendar,
  handleSubmit,
  isSubmitting,
  calendarPreferences,
  changeTechnician,
  maximumDuration
}) => {
  const formRef = useRef()

  return(
    <div className = "workorder-form-react step-two">
      <div>
        <form ref = { formRef }>
          <div className = "flexbox planning">
            <Calendar 
              calendar            = { calendar } 
              setWorkorderState   = { setWorkorderState }
              workorderState      = { workorderState }
              calendarPreferences = { calendarPreferences }
              maximumDuration     = { maximumDuration }
            />
            <div className = "planning-right">
              <SmallCardInfo workorderState = { workorderState }/>
              <ManagerSelect 
                managers = { managers } 
                manager  = { workorderState.managerId }
                onChange = { managerId => { setWorkorderState({ ...workorderState, managerId: managerId }) } }
              />
              <TechnicianSelect 
                technicians        = { technicians.filter(tech => !workorderState.secondaryTechniciansIds.includes(tech.id)) }
                technician         = { workorderState.technicianId }
                externalTechnician = { workorderState.externalTechnicianId }
                onTechChange       = { changeTechnician }
                onExternalChange   = { externalId => { setWorkorderState({ ...workorderState, externalTechnicianId: externalId }) } }
              />
              <SecondaryTechnicians
                technicians             = { technicians.filter(tech => tech.id !== workorderState.technicianId) }
                technicianId            = { workorderState.technicianId }
                secondaryTechniciansIds = { workorderState.secondaryTechniciansIds }
                onSelect                = { technicians => { setWorkorderState({ ...workorderState, secondaryTechniciansIds: technicians.map(tech => tech.id) }) } }
              />
              <DatePlanned 
                workorderState    = { workorderState }
                setWorkorderState = { setWorkorderState }
                maximumDuration   = { maximumDuration }
              />
              <div className = "pt-4 planning-buttons">
                <div className = "btn btn-primary small ml-0 mb-2 w-100" 
                    onClick = { () => handleSubmit(formRef.current, false) }
                    disabled = { !!isSubmitting }
                >
                  { I18n.t("workorders.save") }
                </div>
                {
                  workorderState.parentWorkorderId ?
                    <div className = "btn btn-outline-default small ml-0 h-fit-content w-100" 
                      onClick={() => history.back()}
                    >
                      { I18n.t("button.cancel") }
                    </div>
                  :
                    <div className = "btn btn-outline-default small ml-0 h-fit-content w-100" 
                      onClick={ () => setCurrentStep(1) }
                    >
                      { I18n.t("button.back") }
                    </div>
                } 
              </div>
            </div>
          </div>
        </form>
      </div>
      { !!isSubmitting && 
        <OverlaySpinner />
      }
    </div>
  )
}