import React from "react"

export default class Manager extends React.Component {
  onChange(e) {
    e.preventDefault()
    const {managers, quote, replaceQuote} = this.props
    const manager = managers.find(manager => manager.id == e.target.value) || {}
    replaceQuote({...quote, manager})
  }

  render() {
    const { quote, managers } = this.props

    return(
      <div className="form-group">
        <label>{I18n.t("quotes.new.expeditor")}</label>
        <select
          className="form-control"
          value ={quote.manager.id || ""}
          onChange={this.onChange.bind(this)}
          name="quote[manager_id]"
          required
        >
          {managers.map(manager =>
            <option key={manager.id} value={manager.id}>
              {manager.name}
            </option>
          )}
        </select>
      </div>
    )
  }
}
