import React, { useEffect, useState } from "react"

// Helper
import { bodyRequest } from '@helpers/javascript/javascript'

// Components
import { DemandsFilters } from './DemandsFilters'
import { ChartTab }       from './ChartTab'
import { Toggler }        from '@shared/Toggler'
import { Spinner }        from '@components/Spinner/Spinner'

const Demands = ({
  dataPath,
  displayClient,
  categories,
  statuses
}) => {
  const from = new Date(new Date().setDate(1))
  const to   = new Date()

  const [loading,  setLoading]  = useState(true)
  const [date,     setDate]     = useState([from, to])
  const [assets,   setAssets]   = useState({})
  const [category, setCategory] = useState({})
  const [status,   setStatus]   = useState({})
  const [data,     setData]     = useState({})
  const [range,    setRange]    = useState(5)

  const fetchData = () => {
    if (date.length < 2) return

    const body = { date: date.toString() }
    if (assets.client?.id) body.client   = assets.client.id
    if (assets.site?.id)   body.site     = assets.site.id
    if (category?.id)      body.category = category.id
    if (status?.id)        body.status   = status.id

    setLoading(true)
    bodyRequest({ url: dataPath, body }).then(response => {
      setData(response)
      setLoading(false)
    })
  }

  useEffect(() => { fetchData() },  [date, assets, category, status])

  return (
    <>
      <DemandsFilters {...
        {
          date,
          setDate,
          setAssets,
          range,
          setRange,
          category,
          setCategory,
          categories,
          status,
          setStatus,
          statuses,
          displayClient
        }}
      />

      {
        loading
        ? <Spinner />
        : <Toggler title={I18n.t('dashboard.repartition_demands')} opened>
            <ChartTab
              ratios      = { data.ratios }
              range       = { range }
              categories  = { categories }
              setCategory = { setCategory }
              statuses    = { statuses }
              setStatus   = { setStatus }
            />
          </Toggler>
      }
    </>
  )
}

export default Demands
