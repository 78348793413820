import { STATUSES } from '@helpers/javascript/config/CONSTANTS'

// Dispatch
export const closeWorkorderAction = id => dispatch =>
  dispatch({
    type:    WORKORDER_CLOSE.ACTION,
    payload: { id }
  })

// Reducer response
export const WORKORDER_CLOSE = {
  ACTION: 'WORKORDER_CLOSE',

  call: ({ state, id }) => {
    const workorder = state.items.find(wo => wo.id === id)

    const editedWorkorder = {
      ...workorder,
      status:    STATUSES.CLOSED,
      date_done: workorder.date_done === null ? new Date : workorder.date_done,
      synced:    false
    }

    const workorders = state.items.map(wo => wo.id === id ? editedWorkorder : wo)
    return { ...state, items: workorders }
  }
}
