import React        from 'react'

export const ImportsSelect = props => {
  const {
    type,
    table,
    editing,
    column,
    columns,
    setColumns
  } = props

  const fieldKey = columns[column]
  const field    = table[fieldKey]
  const options  = field && field.options ? field.options.join("\r\n") : ""

  const onChange = e => {
    e.preventDefault()
    setColumns({...columns, [column]: e.target.value })
  }

  return(
    <div>
      <select
        name      = {`columns[${column}]`}
        onChange  = {onChange}
        className = 'form-control'
        value     = {fieldKey || ""}
        required  = {field?.required}
      >
        <option value="">{I18n.t("import.select_column")}</option>
        { Object.keys(table).map(key =>
          <option value={key} key={key}>
            { table[key].required && !editing
              ? `${table[key].name} (${I18n.t("property_field.required_short")})`
              : table[key].name
            }
          </option>
        )}
      </select>

      { field &&
        <>
          <p>{ field.explanations }</p>

          <p tooltip={field.tooltip}>
            <i className={field.icon}></i> {field.type}
          </p>

          { field.required && <p>{ I18n.t("property_field.required") }</p> }
        </>
      }
    </div>
  )
}
