import React               from 'react'
import { msToTime }        from "@react/helpers/helpers"
import { buildTimeString } from '@helpers/javascript/date_helpers'

export const NewEventCard = ({ 
  eventInfo, 
  workorderState,
  calendarPreferences
}) => {
  const { 
    passages, 
    technicianId, 
    datePlanned, 
    secondaryTechniciansIds
  } = workorderState

  const newEventInfo = {
    title:    eventInfo.event.title,
    duration: Math.abs(eventInfo.event.end - eventInfo.event.start),
    start:    buildTimeString(eventInfo.event.start),
    end:      buildTimeString(eventInfo.event.end)
  }

  const passageNumber = () => {
    if (!passages) return
    if (eventInfo.event.start.toISOString() === new Date(datePlanned).toISOString()) {
      return 1
    } else {          
      const passage = passages.findIndex(el => el.datePlanned === eventInfo.event.start.toISOString())
      return passage + 2
    }
  }
  const technicianNumber = () => {
    if (eventInfo.event._def?.resourceIds[0] === 'null' || !passages) return
    if (eventInfo.event._def?.resourceIds[0] == technicianId) {
      return 1
    } else {
      const technician = secondaryTechniciansIds.findIndex(el => el == eventInfo.event._def?.resourceIds[0])
      return technician + 2
    }
  }

  const firstLine = () => {
    switch(calendarPreferences.firstLine) {
      case "workorder": return newEventInfo.title
      case "client":    return workorderState.clientName
      case "site":      return workorderState.siteName
    }
  }

  const secondLine = () => {
    switch(calendarPreferences.secondLine) {
      case "city":   return workorderState.siteCity
      case "client": return workorderState.clientName
      case "site":   return workorderState.siteName
    }
  }

  return (
    <div>
      <div className='flexbox-space-between line-one' data-action="application-list#show">
        <b>{firstLine()}</b>
        <div className="duration"><i className="fas fa-hourglass-half mr-1"></i>{msToTime(newEventInfo.duration)}h</div> 
      </div>
      <div className="mt-1 line-two flexbox-space-between ">
        <div className="event-address">{secondLine()}</div>
      </div>
      <div className='mt-1 line-one flexbox-space-between'>
        {newEventInfo.start} - {newEventInfo.end}
        <strong>
          <div className='mt-1 d-flex'>
            { passageNumber() && passageNumber() }
            { technicianNumber() && `.${technicianNumber()}` }
          </div>
        </strong>
      </div>
    </div>
  )
}
