import React, { useState, useEffect, useRef } from 'react'

// Helpers
import Combobox from "@shared/combobox"
import Address  from "@shared/address"

const StorehouseSelect = props => {
  const didMountRef = useRef(false)
  const {
    name = "storehouse",
    address,
    prefix,
    storehouses,
    required,
    data,
    onChange,
    prompt
  } = props

  const [storehouse, setStorehouse] = useState(props.storehouse)

  const nameFor = attribute  => prefix + `[${attribute}]`

  useEffect(() => {
    if (storehouses.length == 1) setStorehouse(storehouses[0])
  }, [])

  useEffect(() => {
    if (didMountRef.current) {
      if (onChange) onChange(storehouse)
      if (address) {
        const addressInput = document.getElementById("address_field")
        addressInput.value = storehouse.address
      }
    } else {
      didMountRef.current = true
    }
  }, [storehouse])

  return(
    <>
      { storehouses.length >= 1 &&
        <Combobox
          options         = {storehouses}
          value           = {storehouse?.id}
          onSelectElement = {setStorehouse}
          required        = {required}
          prompt          = {prompt}
          data            = {data}
        />
      }

      { storehouse &&
        <>
          <input
            name     = {nameFor(`${name}_id`)}
            value    = {storehouse.storehouse_id}
            onChange = {() => {}}
            hidden
          />

          <input
            name     = {nameFor(`${name}_type`)}
            value    = {storehouse.storehouse_type}
            onChange = {() => {}}
            hidden
          />
        </>
      }
    </>
  )
}

export default StorehouseSelect
