// Modules
import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { hit }     from "@shared/combobox"
import { library } from '@fortawesome/fontawesome-svg-core'
import { far }     from '@fortawesome/free-regular-svg-icons'

library.add(far)

const OFFSET  = 9
const nameFor = (m, a) => `materials[${m.id}[${a}]`

const App = props => {
  const imgRef = useRef(null)

  const [state, setState] = useState({
    current:     null,
    allocated:   props.allocated,
    unallocated: props.unallocated,
    query:       ""
  })

  const { allocated, unallocated, current, query } = state

  const move = material => {
    if (current) return

    setState({
      current:     material,
      allocated:   allocated.filter(m => m.id != material.id),
      unallocated: [{...material}, ...unallocated],
      query:       ""
    })
  }

  const allocate = event => {
    if (!current) return

    const rect = imgRef.current.getBoundingClientRect()
    const x    = event.clientX - rect.left - OFFSET
    const y    = event.clientY - rect.top  - OFFSET

    setState({
      current:     null,
      allocated:   [...allocated, {...current, area_x: x, area_y: y} ],
      unallocated: unallocated.filter(m => m.id != current.id),
      query:       ""
    })
  }

  const reset = event => {
    event.preventDefault()

    setState({
      current:     null,
      allocated:   props.allocated,
      unallocated: props.unallocated,
      query:       ""
    })
  }

  const back = event => {
    event.preventDefault()

    history.back()
  }

  let sortedUnallocated = query == ""
    ? unallocated
    : unallocated.filter(material => hit(material.title, query))

  sortedUnallocated = sortedUnallocated.sort((a, b) => a.title.localeCompare(b.title))

  return(
    <>
      <div className="d-flex-center my-4" style={{minWidth: "1000px"}}>
        <div style = {{ position: 'relative' }}>
          <img
            src         = {props.background}
            onMouseDown = {allocate}
            ref         = {imgRef}
            style       = {{ cursor: current ? 'crosshair' : 'inherit' }}
          />

          { allocated.map(material =>
              <div
                className = "p-absolute text-pointer"
                style     = {{ top:  material.area_y, left: material.area_x }}
                tooltip   = {material.title}
                key       = {material.id}
                onClick   = {() => move(material)}
              >
                <div>
                  <FontAwesomeIcon icon={["far", "dot-circle"]}/>
                </div>
              </div>
            )
          }
        </div>
      </div>

      <div className="acc-preferences-toc">
        <div className="btn-box">
          <h6>{I18n.t("materials.title_plural")}</h6>

          <hr/>

          { unallocated.length > 5 &&
              <input
                onChange    = {e => setState({...state, query: e.target.value })}
                value       = {query}
                className   = "form-control"
                placeholder = {I18n.t("workorders.how_filter")}
              />
          }

          { sortedUnallocated.map(material =>
              <p
                key       = {material.id}
                onClick   = {() => setState({...state, current: material})}
              >
                <strong
                  className ="text-pointer"
                  style     = {{ textDecoration: current?.id == material.id ? "underline" : "none"}}
                >
                  { material.title }
                </strong>
              </p>
          )}
        </div>
      </div>

      <div className="invoice-float-buttons">
        { current &&
          <button
            className="btn btn-danger mr-3"
            onClick={() => setState({...state, current: null})}
          >
            {I18n.t("button.cancel")}
          </button>
        }

        <button className="btn btn-outline-default" onClick={back}>
          {I18n.t("button.back")}
        </button>

        <button className="btn btn-outline-default" onClick={reset}>
          {I18n.t("filters.reset")}
        </button>

        <button type="submit" className="btn btn-primary">
          {I18n.t("button.save")}
        </button>
      </div>

      {allocated.map(material =>
        <React.Fragment key={material.id}>
          <input
            type        = "hidden"
            name        = {nameFor(material, "area_x")}
            defaultValue= {material.area_x}
          />

          <input
            type         = "hidden"
            name         = {nameFor(material, "area_y")}
            defaultValue = {material.area_y}
          />
        </React.Fragment>
      )}
    </>
  )
}

export default App



