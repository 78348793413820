import { getRequest }         from '@helpers/javascript/javascript'
import { parameterizeFilter } from '@helpers/javascript/javascript'
import { pathname }           from '@helpers/javascript/javascript'
import { PATHS }              from '@helpers/javascript/config/CONSTANTS'

import { fetchNotificationsAction } from '@redux/notifications/actions/fetch'

// Dispatch
const fetchWorkordersBegin = () => ({
  type: WORKORDER_FETCH.BEGIN
})

const fetchWorkordersSuccess = workorders => ({
    type: WORKORDER_FETCH.SUCCESS,
    payload: { ...workorders }
})

export const fetchWorkordersAction = filters => dispatch => {
  const params = parameterizeFilter(filters)
  const path = PATHS.workorders.index
  const url = pathname({ path, params })
  history.replaceState(history.state, document.title, url)
  dispatch(fetchNotificationsAction())
  dispatch(fetchWorkordersBegin())

  getRequest(url)
  .then (data => {
    document.body.scrollTo({ top: 0, behavior: 'smooth' })
    dispatch(fetchWorkordersSuccess(data))
  })
}

// Reducer response
export const WORKORDER_FETCH = {
  BEGIN:   'FETCH_WORKORDERS_BEGIN',
  SUCCESS: 'FETCH_WORKORDERS_SUCCESS',

  clearResponse: ({ state }) => ({...state, items: [] }),
  beginResponse: ({ state }) => ({...state, loading: true }),

  successResponse: ({ 
    state, 
    workorders, 
    today_workorders, 
    absences, 
    recap,
    total_entries, 
    total_pages 
  }) => {
    const mergeWorkorders = (locals, remotes) => {
      const unsynced = workorder => workorder.syncing || !workorder.syncing
      const unsyncedLocals = locals.filter(unsynced)

      if(!remotes) return locals

      return remotes.map(remote => {
        const local = unsyncedLocals.find(unsynced => remote.id == unsynced.id)
        if(!local)                          return remote
        if(local.error)                     return remote
        if(local.version == remote.version) return remote

        if(local.syncing)                   return local
        if(!local.synced)                   return local

        return remote
      })
    }

    return {
      ...state,
      total_entries,
      total_pages,
      loading:           false,
      loadingNext:       false,
      items:             mergeWorkorders(state.items, workorders),
      today_workorders:  mergeWorkorders(state.today_workorders, today_workorders),
      absences:          absences,
      recap:             recap
 }
  }
}
