import React, { useState, useEffect }  from "react"

const Input = props => {
  const {
    id,
    name,
    defaultValue,
    type="text",
    onChange,
    placeholder,
    step = 1,
    className = "form-control",
    required = false,
    label = null,
    disabled = false
  } = props

  const [value, setValue] = useState(defaultValue || '')
  useEffect(() => setValue(defaultValue), [defaultValue])

  const handleChange = e => {
    setValue(e.target.value)
    if (onChange) onChange(e.target.value)
  }

  return (
    <div className="form-group">
      {label && 
        <label 
          className="control-label" 
          required = { required }
        >
          { label }
        </label>
      }
      <input id = { id }
        name        = { name }
        type        = { type } 
        className   = { className }
        value       = { value || '' } 
        onChange    = { handleChange } 
        required    = { required } 
        placeholder = { placeholder }
        step        = { step }
        disabled    = { disabled }
      />
    </div>
  )
}

export default Input