import React from "react"

const ALLOWED_STATUSES = [
  "In preparation",
  "Sent",
  "Rejected",
  "Accepted"
]

const FORBIDDEN_STATUSES = [
  "Partially invoiced",
  "Invoiced"
]

export default class Status extends React.Component {
  onChange(e) {
    e.preventDefault()
    const {quote, replaceQuote} = this.props
    const status = e.target.value
    replaceQuote({...quote, status})
  }

  render() {
    return(
      <div className="form-group">
        <label>{I18n.t("quotes.bigcard.status")}</label>
        {
          FORBIDDEN_STATUSES.includes(this.props.quote.status)
          ? <input
              className="form-control"
              value={I18n.t(`quotes.bigcard.${this.props.quote.status}`)}
              disabled
            />
          : <select
              className="form-control"
              value ={this.props.quote.status}
              onChange={this.onChange.bind(this)}
              name="quote[status]"
              required
            >
              { ALLOWED_STATUSES.map(status =>
                  <option value={status} key={status}>
                    {I18n.t(`quotes.bigcard.${status}`)}
                  </option>
              )}
            </select>
        }
      </div>
    )
  }
}
