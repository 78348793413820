// This should be moved to stimulus, react or the trash bin
const calculateFormula = () => {
  // Formula with index code
  const formula = document.querySelector('.formula-value')

  // Calculate result
  const result = document.querySelector('#result')

  // Div who tells if formula is valid or not
  const validFormula = document.querySelector('#valid-formula')
  const saveFormula = document.querySelector('#save-formula')

  try {
    const matches = formula.value.match(/\{([^}]+)\}/g)
    let newFormula = formula.value
    if (matches) {
      const matchesSet = [...new Set(matches)]
      matchesSet.forEach((indexCode) => {
        const indexValue = document.querySelector(`div.btn[data-sign=' ${indexCode} ']`).dataset.lastvalue
        const regExp = new RegExp(indexCode, 'g')
        newFormula = newFormula.replace(regExp, indexValue)
      })
    }

    // Removing non-secure characters before eval() /!\ Important
    let resultString = newFormula.replace(/[^-()\d/*+.]/g, '')
    resultString = eval(resultString)

    if (resultString === undefined) { resultString = '' }
    result.value = resultString

    validFormula.classList.add('formula-valid')
    validFormula.innerHTML = `<i class=\'far fa-check-circle\'></i> ${validFormula.dataset.correct}`
    saveFormula.disabled = false
    saveFormula.style.cursor = 'inherit'
  } catch (error) {
    validFormula.classList.remove('formula-valid')
    validFormula.innerHTML = `<i class=\'far fa-check-circle\'></i> ${validFormula.dataset.wrong}`
    saveFormula.disabled = true
    saveFormula.style.cursor = 'disallowed'
  }
}


const formulaCalculatorInit = () => {
  const formulaContainer = document.querySelector('#formula-container')
  if (!formulaContainer) return

  const calculator  = document.querySelector('.calculator')
  const indices     = document.querySelector('.indices-container')

  calculator.addEventListener('click', calculateFormula)
  indices.addEventListener('click', calculateFormula)
}

export { formulaCalculatorInit }
