// Dispatch
export const updateTimeAction = (id, line, value) => dispatch =>
  dispatch({
    type: WORKORDER_TIME.ACTION,
    payload: { id, line, value }
  })

// Reducer response
export const WORKORDER_TIME = {
  ACTION: 'WORKORDER_TIME',

  call: ({ state, id, line, value }) => {
    const workorder = state.items.find(wo => wo.id === id)

    if (line === 'date_started' || line === 'date_done') {
      value = new Date(value)
    }

    const editedWorkorder = {
      ...workorder,
      [line]: value,
      synced: false
    }

    const workorders = state.items.map(wo => wo.id === id ? editedWorkorder : wo)
    return { ...state, items: workorders }
  }
}
