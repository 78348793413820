import React from "react"

export default class AnnualTable extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange         = this.handleChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
  }

  handleChange(event) {
    const newValue = event.target.value
    this.props.handleMonthChange(this.props.index, newValue, event)
  }

  handleCheckboxChange(event) {
    this.props.handleCheckbox(this.props.index)
  }

  render () {
    return(
      <tr>
        <td className="no-padding text-left">
          {this.props.monthName}
        </td>

        <td className="no-padding">
          <input
            type  = "hidden"
            name  = "contract[invoice_monthly_percentage][]"
            value = {Math.round(this.props.value * 10000000) / 1000000000}
          />

          { this.props.editable
            ? <input
                type      = "number"
                value     = {this.props.value}
                onChange  = {this.handleChange}
                step      = "0.01"
                min       = "0"
                max       = "100"
                className = "small-input"
                required
              />
            : <div className="checkbox checkbox-primary">
                <input
                  type     = "checkbox"
                  checked  = {this.props.checked}
                  onChange = {this.handleCheckboxChange}
                  id       = {`month-${this.props.monthName}`}
                />

                <label htmlFor={`month-${this.props.monthName}`}>&nbsp;&nbsp;&nbsp;</label>
              </div>
          }
        </td>
        <td className="no-padding text-right">
          {Math.round(this.props.value * this.props.total) / 100}
        </td>
      </tr>
    )
  }
}
