import React, { useRef, useEffect, useState } from 'react'
import { jsRequest } from "@react/helpers/fetch"

const EventCard = ({ info, 
  showDate, 
  showDuration, 
  filter, 
  setFilter, 
  setShowPopup, 
  setShowSmallCard, 
  externalCard=false ,
  setFilterOpen
}) => {
  const eventRef = useRef(null)
  const [showMarker, setShowMarker] = useState(false)

  const categoryBorder = () => {
    const border = document.createElement("DIV")
    border.classList.add("category-border")
    border.style = `background: ${info.category_color}`
    return border
  }

  useEffect(() => {
    if (eventRef) {
      // This is because we don't have direct access to the fullcalendar Event component
      eventRef.current.closest('.fc-event').appendChild(categoryBorder())
    }
  }, [eventRef])

  const handleEventClick = e => {
    showModal('#show-workorder-modal')
    jsRequest(info.show_url)
  }

  const showSameSiteWorkorders = e => {
    e.stopPropagation();
    setFilter({
      ...filter,  
      site:           info.site_id, 
      site_name:      info.site,
      text:           '',
      showOpen:       true
    })
    setFilterOpen(true)
  }

  const handleMouseEnter = (event) => {
    setShowMarker(true)
    if (externalCard) {
      setShowSmallCard(
        { externalCard: {
            position: {clientX: event.clientX, clientY: event.clientY}, 
            info
          }
        }
      )
    }
  }

  const handleMouseLeave = () => {
    setShowMarker(false)
    setShowSmallCard(false)
  }

  return (
    <div onClick={handleEventClick} 
         ref          ={eventRef} 
         onMouseEnter ={e=>handleMouseEnter(e)} 
         onMouseLeave ={handleMouseLeave}
    >
      <div className='flexbox-space-between line-one' data-action="application-list#show">
        <b>{info.title}</b>
        { showDuration &&
          <div className="duration"><i className="fas fa-hourglass-half mr-1"></i>{info.duration}h</div>
        }
      </div>
      <div className="mt-1 line-two flexbox-space-between ">
        <div className="event-address">{info.second_line}</div>
      </div>
      { showDate &&
        <div className='mt-1 line-three'>
          {info.event_start} - {info.event_end}
        </div>
      }
      { showMarker && 
        <div className="mr-1 related-workorders"
          onClick={showSameSiteWorkorders}
          onMouseEnter={e => setShowPopup({x: e.pageX, y: e.pageY, content: I18n.t('calendar.show_same_site_workorders')})}
          onMouseLeave={() => setShowPopup(false)}
        >
          <i className="fas fa-map-marker-alt"></i>
        </div>
      }
    </div>
  )
}

export default EventCard
