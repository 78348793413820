import { SIDEMENU } from './toggle'

const initialState = {
  display: false,
  displayFilters: false,
  displayCreation: false
}

export default function sideMenuReducer(state = initialState, action) {
  switch (action.type) {

  case SIDEMENU.TOGGLE:
    return SIDEMENU
      .toggleResponse({
        state:  state,
        active: !state.display
      })

  case SIDEMENU.TOGGLE_FILTERS:
    return SIDEMENU
      .toggleFiltersResponse({
        state:  state,
        active: !state.displayFilters
      })

  case SIDEMENU.TOGGLE_CREATION:
    return SIDEMENU
      .toggleCreationResponse({
        state:  state,
        active: !state.displayCreation
      })

  default:
    return state
  }
}
