import React, { useEffect, useState } from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

// Build library of icons
library.add(fas)

// Helper
import { bodyRequest } from "@helpers/javascript/javascript"

// Components
import { SalesTable }   from "./SalesTable"
import { SalesFilters } from "./SalesFilters"
import { TopTypes }     from "./TopTypes"
import { YearTotal }    from "./YearTotal"
import { MonthPrev }    from "./MonthPrev"

const CLIENT = 'by_client'

const SalesStatistics = ({
  url,
  years,
  types,
  months,
  previous,
  currency
}) => {
  const [loading, setLoading] = useState(true)
  const [data,    setData]    = useState({})
  const [filters, setFilters] = useState({ 
                                  type: CLIENT, 
                                  year: new Date().getFullYear(),
                                  previous: previous[0] 
                                })

  useEffect(() => {
    setLoading(true)
    const body = { query: filters }
    setLoading(true)
    bodyRequest({ url, body }).then(response => {
      setData(response.sales)
      setLoading(false)
    })
  }, [filters])

  return (
    <>
      <SalesFilters 
        years                 = { years } 
        previous              = { previous }
        types                 = { types } 
        filters               = { filters }
        setFilters            = { setFilters }
      />
      <SalesTable data = { data } months={ months } loading = { loading } currency = { currency } />
      <TopTypes   data = { data } type = { filters.type } currency = { currency } />
      <MonthPrev  data = { data } loading={ loading } currency = { currency } filters = { filters } />
      <YearTotal  data = { data.props_year_total } currency = { currency } />
    </>    
  )
}

export default SalesStatistics
