import React              from "react"
import { ResponsiveLine } from '@nivo/line'
import { EmptyCard }      from "./EmptyCard"
import { parseMoney }     from "../../../application/helpers/helpers"

export const YearTotal = ({ data, currency }) => {
  if (!data) return

  const STATUS_HEIGHT = 400

  return (
    <div className="dashboard__card mr-3 w-92vw">
      <h3>{I18n.t(`dashboard.yearly_turnover`)}</h3>
      { !data.length ? <EmptyCard />
          :
        <div style={{ height: `${STATUS_HEIGHT}px`}}> 
          <ResponsiveLine
            data       = {data}
            margin     = {{ top: 50, right: 160, bottom: 50, left: 120 }}
            xScale     = {{ type: 'point' }}
            yScale     = {{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
            layers     = {['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
            label      = {({ value }) => `${parseMoney(value, currency)}`}
            axisBottom = {{
              orient:        'bottom',
              tickSize:       5,
              tickPadding:    5,
              tickRotation:   0,
              legend:         '',
              legendOffset:   36,
              legendPosition: 'middle'
            }}
            axisLeft={{
              orient:         'left',
              tickSize:       5,
              tickPadding:    5,
              tickRotation:   0,
              legendOffset:   -40,
              legend:         '',
              legendPosition: 'middle',
              format:         value => `${parseMoney(value, currency)}`
            }}
            tooltip = { ({ point }) => (
              <div style={{background: 'white'}}>
                {point.data.x} : {parseMoney(point.data.y, currency)}
              </div>
            )}
            colors     = {line => line.color}
            pointSize  = {10}
            pointColor = {{ "from":"color" }}
            pointBorderWidth  = {2}
            pointBorderColor  = {{ from: 'serieColor' }}
            pointLabel        = "Tech time"
            pointLabelYOffset = {-12}
            useMesh = {true}
            enablePointLabel={true}
            legends = {[{
              anchor:            'bottom-right',
              direction:         'column',
              justify:           false,
              translateX:        100,
              translateY:        0,
              itemsSpacing:      0,
              itemDirection:     'left-to-right',
              itemWidth:         80,
              itemHeight:        20,
              itemOpacity:       0.75,
              symbolSize:        12,
              symbolShape:       'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              
            }]}
          />     
        </div>
      }
    </div>
  )
}
