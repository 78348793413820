import React, { useState }      from "react"
import { getRequest, pathname } from '@helpers/javascript/javascript'
import Combobox                 from '@shared/combobox'

export const TechnicianSelect = ({
  technicians, 
  technician, 
  externalTechnician,
  onTechChange,
  onExternalChange
}) => {
  const [externalTechnicians, setExternalTechnicians] = useState(false)

  const fetchExternal = tech => {
    const path   = "/workorders/form/external_technician"
    const params = { technician : tech.id }
    const format = "json"
    const url    = pathname({ path, params, format })
    getRequest(url).then(setExternalTechnicians)
  }

  const handleTechnicianSelect = tech => {
    onTechChange(tech.id)
    setExternalTechnicians(false)

    if (!tech.id) return

    if (tech.external) {
      fetchExternal(tech)
    }
  }

  const handleExternalSelect = externalTechnician => {
    onExternalChange(externalTechnician.id)
  }

  return (
    <>
      <Combobox 
        options         = { technicians }
        onSelectElement = { handleTechnicianSelect }
        placeholder     = { I18n.t("workorders.select") }
        value           = { technician }
        label           = { I18n.t("workorders.head_technician") }
        className       = "form-group"
      />

      {externalTechnicians && 
        <Combobox 
          options         = { externalTechnicians }
          onSelectElement = { handleExternalSelect }
          placeholder     = { I18n.t("workorders.select") }
          value           = { externalTechnician }
          label           = { I18n.t("workorders.external_technician") }
          className       = "form-group"
        />
      }
    </>
  )
}