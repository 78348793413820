import React      from "react"
import LineBase   from "./line_base"

// Components
import Destroyed  from "./line_destroyed"
import Buttons    from "./shared/buttons"
import Combobox   from "@shared/combobox"

// Helpers
import { getRequest } from '@helpers/javascript/javascript'

export default class Template extends LineBase {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.state = { ...this.state, templateId: "" }
  }

  onChange(template) {
    const { qline } = this.props
    const { showConfirm, addTemplate } = this.props.templateProps

    const templateId = template.id
    this.setState({ templateId })
    if (!templateId) return
    if(showConfirm && !confirm(I18n.t("quotes.new.add_template_confirm"))) return

    const qlineCurrency = qline.currency
    getRequest(`/quotes/form/${templateId}/template.json?currency=${qlineCurrency}`)
    .then(addTemplate)
  }

  render() {
    const { index, qline, buttonProps } = this.props
    const { templates } = this.props.templateProps

    return(
      <div className="quotes--form-qline-container">
        <div className = "text-input">
          <Combobox
            options         = {templates}
            value           = {this.state.templateId}
            display         = "title"
            onSelectElement = {this.onChange}
            prompt          = {I18n.t("quotes.new.select_blueprint")}
            required        = {true}
          />
        </div>

        <Buttons
          index       = {index}
          qline       = {qline}
          buttonProps = {buttonProps}
        />

        <Destroyed
          index={index}
          qline={qline}
        />
      </div>
    )
  }
}
