import { MODALE } from './actions'

const initialState = {
  display: false,
  type: null,
  key: null,
  index: null,
  error: null,
  refreshIndex: true
}

export default function modaleReducer(state = initialState, action) {
  switch (action.type) {
  case MODALE.OPEN:
    return MODALE
      .openResponse({
        state: state,
        display: true,
        refreshIndex: false,
        type: action.payload.type,
        index: action.payload.key,
        key: action.payload.key
      })

  case MODALE.CLOSE:
    return MODALE
      .closeResponse({
        state: initialState
      })

  default:
    return state
  }
}
