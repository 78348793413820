import React from 'react'

export default class TimesheetRow extends React.Component {
  render() {
    const { workorder, showModale } = this.props

    return (
        <div
          className = "timesheet__sheet__row  timesheet__sheet__row--workorder"
          onClick   = { () => showModale(workorder.show_url) }>

          <div title={workorder.title} className="timesheet__sheet__row__wo-title">
            {workorder.name} — {workorder.title}
          </div>

          <div title={workorder.site} className="timesheet__sheet__row__site">
            {workorder.site} — {workorder.address}
          </div>

          <div title={workorder.departure_date} className='timesheet__sheet__row__date'>
            {workorder.departure_date}
          </div>

          <div title={workorder.travel_time} className='timesheet__sheet__row__time'>
            {workorder.travel_time}
          </div>
          
          <div title={workorder.date_started} className='timesheet__sheet__row__date'>
            {workorder.date_started}
          </div>
          
          <div title={workorder.date_done} className='timesheet__sheet__row__date'>
            {workorder.date_done}
          </div>

          <div title={workorder.time_taken} className='timesheet__sheet__row__time'>
            {workorder.time_taken}
          </div>
        </div>
    )
  }
}
