import React, { useState } from "react"
import { bodyRequest, pathname }      from '@helpers/javascript/javascript'
import { StepOne }                    from "./stepOne/stepOne"
import { StepTwo }                    from "./stepTwo/stepTwo"
import { convertKeysToSnake }         from "@react/helpers/helpers"

export const TYPES = 
  {
    reactive: 'Reactive', 
    preventive: 'Preventive', 
    installation: 'Installation'
  }

const App = props => {
  const { 
    workorder, 
    categories, 
    managers, 
    technicians, 
    checkboxes,
    calendar,
    storehouses,
    taskBlueprints,
    displayUnpaid,
    allowRecurrency,
    availableMaterials,
    calendarPreferences,
    maximumDuration
  } = props

  const [workorderState, setWorkorderState] = useState(workorder)
  const [currentStep,    setCurrentStep]    = useState(1)
  const [siteMaterials,  setSiteMaterials]  = useState(availableMaterials)
  const [isSubmitting,   setIsSubmitting]   = useState(false)

  const handleSubmit = (form, upload) => {
    if(!form.reportValidity()) return
    setIsSubmitting(true)
    let workorder = { ...workorderState }
    workorder.extraFieldAnswersAttributes = { ...workorderState.extraFieldAnswers }
    workorder.invoiceLinesAttributes      = { ...workorderState.invoiceLines }
    if (workorderState.workorderType != TYPES.preventive ) {
      workorder.maintenanceTasksAttributes = workorderState.materials.map(material => {return { materialId: material }})
    }
                                              
    const path   = '/workorders'
    const body   = {
      upload:    !!upload,
      workorder: convertKeysToSnake(workorder),
      passages:  convertKeysToSnake(workorderState.passages),
      email:     { technician: workorderState.emailTechnician,
                   requester:  workorderState.emailRequester },
      photo:     { images: workorderState.photos.map(photo => photo.signedId) },
      files:     workorderState.files.map(file => { return { legend: file.filename, file: file.signedId } })
    }

    if (workorderState.workorderType == TYPES.preventive ) {
      body.maintenance_plan = convertKeysToSnake({maintenance_tasks_attributes: workorderState.maintenanceTasks })
      if (workorderState.recurrency) {
        body.maintenance_plan = convertKeysToSnake({ ...body.maintenance_plan, ...workorderState.rrule, addRecurrency: true })
      }
    } 

    const url = pathname({ path })
    bodyRequest({ url, body }).then(res => {
      window.location.href = (res.path)
    }).catch(err => setIsSubmitting(false))
  }

  const changeTechnician = technicianId => {
    if (technicianId) {
      setWorkorderState({ ...workorderState, technicianId: technicianId })
    } else {
      setWorkorderState({ ...workorderState, technicianId: technicianId, secondaryTechniciansIds: [] })
    }
  }

  const changeAssets = assets => {
    const site          = assets.site
    const materials     = site.materials
    const contactChange = workorderState.contactId !== assets.contact.id
    if (materials) {
      setSiteMaterials(materials.map(material => ({ materialId: material.id, name: material.name })))
    } else { setSiteMaterials([])}
    setWorkorderState(
      {
        ...workorderState, 
        workorderType:    contactChange ? workorderState.workorderType :TYPES.reactive,
        clientId:         assets.client.id, 
        clientName:       assets.client.name,
        siteId:           site.id,
        siteName:         site.name, 
        address:          assets.site.address,
        contactId:        assets.contact.id,
        materials:        contactChange ? workorderState.materials : [],
        maintenanceTasks: contactChange ? workorderState.maintenanceTasks : [],
        technicianId:     site.default_technician_id || workorderState.technicianId
      }
    )
  }
  
  return(
    <>
      {
        currentStep === 1 && !workorderState.parentWorkorderId &&
          <StepOne 
            workorderState          = { workorderState }
            setWorkorderState       = { setWorkorderState }
            categories              = { categories.categories }
            checkboxes              = { checkboxes }
            categoryFields          = { categories.categoryFields }
            setCurrentStep          = { setCurrentStep }
            storehouses             = { storehouses }
            blueprints              = { taskBlueprints }
            siteMaterials           = { siteMaterials }
            changeAssets            = { changeAssets }
            displayUnpaid           = { displayUnpaid }
            allowRecurrency         = { allowRecurrency }
            handleSubmit            = { handleSubmit }
            managers                = { managers }
            technicians             = { technicians }
            isSubmitting            = { isSubmitting }
            changeTechnician        = { changeTechnician }
            maximumDuration         = { maximumDuration }
          />
      }
      {
        (currentStep === 2 || workorderState.parentWorkorderId) &&
          <StepTwo 
            workorderState      = { workorderState }
            setWorkorderState   = { setWorkorderState }
            managers            = { managers }
            technicians         = { technicians }
            setCurrentStep      = { setCurrentStep }
            calendar            = { calendar }
            handleSubmit        = { handleSubmit }
            isSubmitting        = { isSubmitting }
            calendarPreferences = { calendarPreferences }
            changeTechnician    = { changeTechnician }
            maximumDuration     = { maximumDuration }
          />
      }
    </>
  )
}

export default App