// Dispatch
export const resumeWorkorderAction = id => dispatch =>
  dispatch({
    type:    WORKORDER_RESUME.ACTION,
    payload: { id }
  })

// Reducer response
export const WORKORDER_RESUME = {
  ACTION: 'WORKORDER_RESUME',

  call: ({ state, id }) => {
    const workorder      = state.items.find(wo => wo.id === id)
    const pausedInterval = parseInt((new Date - new Date(workorder.start_pause)) / 60000)
    const pausedTotal    = workorder.paused_time + pausedInterval

    const editedWorkorder = {
      ...workorder,
      start_pause: null,
      paused:      false,
      paused_time: pausedTotal,
      synced:      false
    }

    const workorders = state.items.map(wo => wo.id === id ? editedWorkorder : wo)
    return { ...state, items: workorders }
  }
}

