import React, { useState, useEffect } from 'react';

// Helpers
import { formatNumber } from "../quotes/form/helpers";

const BulkBuildForm = props => {

  const SAVE_PURCHASE_ORDER          = 'savePurchaseOrder';
  const VALIDATE_PURCHASE_ORDER      = 'validatePurchaseOrder';
  const USE_CURRENT_STOCK            = 'use_current_stock';
  const KEEP_CURRENT_STOCK           = 'keep_current_stock';
  const RESTOCK_AT_RECOMMENDED_LEVEL = 'restock_at_recommended_level';

  const defaultQuantityCalcMethod    = KEEP_CURRENT_STOCK;

  const {
    products: initialProducts,
    restockingForWorkorders,
  } = props;


  const [productList,        setProducts]           = useState(initialProducts);
  const [quantityCalcMethod, setQuantityCalcMethod] = useState(defaultQuantityCalcMethod);
  const [allSave,            setAllSave]            = useState(true);
  const [allValidate,        setAllValidate]        = useState(false);
  const [totalValue,         setTotalValue]         = useState();

  useEffect(() => {
    recalculateOrderQuantities();
  }, [quantityCalcMethod]);

  useEffect(() => {
    recalculateTotal()
  }, [productList]);

  useEffect(() => {
    if (allSave) {
      setProducts(prevProducts => prevProducts.map(product => ({
        ...product,
        savePurchaseOrder: true
      })));
    } else {
      setProducts(prevProducts => prevProducts.map(product => ({
        ...product,
        savePurchaseOrder:     false,
        validatePurchaseOrder: false
      })));
      setAllValidate(false);
    }
  }, [allSave]);

  useEffect(() => {
    if (allValidate) {
      setProducts(prevProducts => prevProducts.map(product => ({
        ...product,
        savePurchaseOrder:     true,
        validatePurchaseOrder: true
      })));
      setAllSave(true);
    } else {
      setProducts(prevProducts => prevProducts.map(product => ({
        ...product,
        validatePurchaseOrder: false
      })));
    }
  }, [allValidate]);

  const recalculateTotal = () => {
    let total = 0;
    productList.forEach(product => {
      if (product.savePurchaseOrder) total += (product.orderQuantity * product.purchasePriceCents) / 100;
    });
    setTotalValue(total)
  }

  const getOrderQuantity = (product, method) => {
    let toOrder = 0;
    switch (method) {
      case USE_CURRENT_STOCK:
        toOrder = product.stockWorkordersNeed - (product.stockTotal + product.stockComing);
        return toOrder > 0 ? toOrder : 0;

      case KEEP_CURRENT_STOCK:
        return product.stockWorkordersNeed;

      case RESTOCK_AT_RECOMMENDED_LEVEL:
        toOrder = Math.max(product.stockLowLevel, product.stockRecommendedLevel)
                    - (product.stockTotal + product.stockComing - product.stockWorkordersNeed);
        return toOrder > 0 ? toOrder : 0;

      default:
        return product.orderQuantity;
    }
  }

  const recalculateOrderQuantities = () => {
    if (!restockingForWorkorders) return // Recalculating with different methods only when workorders are present

    const updatedProducts = productList.map(product => ({
      ...product,
      orderQuantity: getOrderQuantity(product, quantityCalcMethod)
    }));

    setProducts(updatedProducts);
  }

  const changeQuantityCalcMethod = e => {
    setQuantityCalcMethod(e.currentTarget.value);
  }

  const onSupplierChange = (e, productId) => {
    const productListIndex              = productList.findIndex(product => product.id === productId)
    const copiedProductList             = [ ...productList ]
    const productToUpdate               = productList.find(product => product.id === productId)
    const initialProductPrice           = productToUpdate.purchasePriceCents
    const supplierId                    = e.target.value
    const supplierProductPrice          = productToUpdate.suppliers.find(supplier => supplier.supplierId == supplierId)?.purchasePriceCents
    const purchasePriceCents            = supplierProductPrice || initialProductPrice
    const newProduct                    = { ...productToUpdate, supplierId, purchasePriceCents }
    copiedProductList[productListIndex] = newProduct

    setProducts(copiedProductList)
  }

  const onChange = (e, productId, attribute) => {
    const updatedProducts = productList.map(product => {
      if (product.id === productId) {
        return {
          ...product,
          [attribute]: e.currentTarget.value
        };
      }
      return product;
    });
    setProducts(updatedProducts);
  }

  const onCheckboxChange = (productId, attribute) => {
    const updatedProducts = productList.map(product => {
      if (product.id === productId) {
        const updatedProduct = {
          ...product,
          [attribute]: !product[attribute]
        };

        // If savePurchaseOrder is being unchecked, also uncheck validatePurchaseOrder.
        if (attribute === SAVE_PURCHASE_ORDER && product.savePurchaseOrder) {
            updatedProduct.validatePurchaseOrder = false;
        }

        // If validatePurchaseOrder is being checked, also check savePurchaseOrder.
        if (attribute === VALIDATE_PURCHASE_ORDER && !product.validatePurchaseOrder) {
            updatedProduct.savePurchaseOrder = true;
        }

        return updatedProduct;
      }
      return product;
    });
    setProducts(updatedProducts);
  }

  const toggleAllSaveCheckbox = () => {
    setAllSave(!allSave)
  }

  const toggleAllValidateCheckbox = () => {
    setAllValidate(!allValidate)
  }

  // Workorders needs column visible / hidden
  const gridColumnsStyle = restockingForWorkorders
    ? "1fr 3.5fr 2fr 0.6fr 1fr 0.6fr 0.6fr 0.6fr 1fr 1fr 0.6fr 0.6fr"
    : "1fr 3.5fr 2fr 0.6fr 1fr 0.6fr 0.6fr 1fr 1fr 0.6fr 0.6fr";

  const totalValueStyle = restockingForWorkorders
    ? "3 / span 8"
    : "3 / span 7";

  return (
    <>
      { restockingForWorkorders &&
        <div className="form-group w-30">
          <label htmlFor="quantityCalcMethod">{I18n.t('purchase_orders.autogeneration.quantity_calculation_method')}</label>
          <select
            className = "form-control"
            id        = "quantityCalcMethod"
            value     = {quantityCalcMethod}
            onChange  = {changeQuantityCalcMethod}
          >
            <option value={USE_CURRENT_STOCK}>{I18n.t('purchase_orders.autogeneration.use_current_stock')}</option>
            <option value={KEEP_CURRENT_STOCK}>{I18n.t('purchase_orders.autogeneration.keep_current_stock')}</option>
            <option value={RESTOCK_AT_RECOMMENDED_LEVEL}>{I18n.t('purchase_orders.autogeneration.restock_at_recommended_level')}</option>
          </select>
        </div>
      }

      <div className="css-grid-container bulk-purchase-orders-grid">
        <div className="css-grid" style={{gridTemplateColumns: gridColumnsStyle}}>
          <div className="header">
            <div className="text-left">{I18n.t("purchase_orders.autogeneration.product_reference")}</div>
            <div className="text-left">{I18n.t("purchase_orders.autogeneration.product_designation")}</div>
            <div className="text-left">{I18n.t("purchase_orders.autogeneration.supplier")}</div>
            <div>{I18n.t("purchase_orders.autogeneration.stock_unit")}</div>
            <div>{I18n.t("purchase_orders.autogeneration.stock_quantity")}</div>
            <div>{I18n.t("purchase_orders.autogeneration.stock_quantity_min")}</div>
            <div>{I18n.t("purchase_orders.autogeneration.stock_quantity_recommended")}</div>
            { restockingForWorkorders &&
              <div>{I18n.t("purchase_orders.autogeneration.stock_workorders_need")}</div>
            }
            <div>{I18n.t("purchase_orders.autogeneration.order_quantity")}</div>
            <div>{I18n.t("purchase_orders.autogeneration.order_value")}</div>

            <div className="checkbox_container">
              <div>{I18n.t("button.save")}</div>
              <Checkbox
                id                = 'checkbox-toggle-save'
                name              = 'checkbox-toggle-save'
                checked           = {allSave}
                hiddenInput       = {false}
                onChangeCheckbox  = {() => toggleAllSaveCheckbox()}
              />
            </div>

            <div className="checkbox_container">
              <div>{I18n.t("button.validate")}</div>
              <Checkbox
                id                = 'checkbox-toggle-validate'
                name              = 'checkbox-toggle-validate'
                checked           = {allValidate}
                hiddenInput       = {false}
                onChangeCheckbox  = {() => toggleAllValidateCheckbox()}
              />
            </div>
          </div>

          <div className="main">
            { productList.length > 0 && productList.map((product) =>
                <PurchaseRow
                  product                 = {product}
                  onChange                = {onChange}
                  onSupplierChange        = {onSupplierChange}
                  onCheckboxChange        = {onCheckboxChange}
                  restockingForWorkorders = {restockingForWorkorders}
                  suppliers               = {product.suppliers.length && product.suppliers}
                  preselectSupplier       = {product.suppliers.length == 1}
                  key                     = {product.id}
                />
              )
            }
          </div>

          <div className="total_row" style={{gridColumn: totalValueStyle}}>
            {I18n.t("purchase_orders.autogeneration.total_value")} {formatNumber(totalValue)}
          </div>
        </div>
      </div>
    </>
  )
}

const Checkbox = ({ id, name, checked, onChangeCheckbox, hiddenInput = true }) => (
  <div className="checkbox checkbox-primary">
    { hiddenInput &&
        <input
          type         ="hidden"
          defaultValue ="0"
          name         ={name}
        />
    }

    <input
      type         = "checkbox"
      id           = {id}
      checked      = {checked}
      onChange     = {onChangeCheckbox}
      defaultValue = "1"
      name         = {name}
    />

    <label
      className = "control-label mb-3"
      htmlFor   = {id}
    ></label>
  </div>
);

const PurchaseRow = ({
  product,
  onChange,
  onSupplierChange,
  onCheckboxChange,
  restockingForWorkorders,
  suppliers,
  preselectSupplier
}) => (
  <>
    <div className="d-none">
      <input
        type         = "hidden"
        name         = {`purchase_order_lines[${product.id}][product_id]`}
        defaultValue = {product.id}
      />
    </div>

    <div className="pl-2">
      <a data-toggle = "modal"
         data-target = "#remote-modal"
         data-action = "click->modal#show"
         data-route  = { product.path }
      >
        { product.reference }
      </a>
    </div>

    <div className="pl-2">
      <a data-toggle = "modal"
         data-target = "#remote-modal"
         data-action = "click->modal#show"
         data-route  = { product.path }
      >
        { product.designation }
      </a>
    </div>

    <div className="">
      <select
        className   = "form-control"
        onChange    = {e => onSupplierChange(e, product.id, 'supplierId')}
        value       = {product.supplierId || ''}
        name        = {`purchase_order_lines[${product.id}][supplier_id]`}
      >
        { !preselectSupplier &&
            <option value='' key={0}>{I18n.t("purchase_orders.select_supplier")}</option>
        }
        { suppliers.map(supplier =>
            <option value={supplier.supplierId} key={supplier.supplierId}>{supplier.name}</option>
          )
        }
      </select>
    </div>

    <div className="text-center">{ product.unit }</div>

    <div className="text-right pr-2">{ product.stockTotalShow }</div>

    <div className="text-right pr-2">{ formatNumber(product.stockLowLevel) }</div>

    <div className="text-right pr-2">{ formatNumber(product.stockRecommendedLevel) }</div>

    { restockingForWorkorders &&
      <div className="text-right pr-2">{ formatNumber(product.stockWorkordersNeed) }</div>
    }

    <div className="text-right pr-2">
      <input
        className = "form-control text-right"
        type      = "number"
        step      = "0.01"
        value     = { product.orderQuantity }
        name      = {`purchase_order_lines[${product.id}][quantity]`}
        onChange  = { e => onChange(e, product.id, 'orderQuantity') }
      />
    </div>

    <div className="text-right pr-2">
      { product.savePurchaseOrder ?
        formatNumber((product.orderQuantity * product.purchasePriceCents) / 100) :
        '-'
      }
    </div>

    <input
      type         = "number"
      step         = "0.01"
      value        = { product.purchasePriceCents }
      onChange     = {() => {}}
      name         = {`purchase_order_lines[${product.id}][price]`}
      hidden
    />

    <div className="text-center">
      <Checkbox
        id                = {`checkbox-save_purchase_order-${product.id}`}
        name              = {`purchase_order_lines[${product.id}][save_purchase_order]`}
        checked           = {product.savePurchaseOrder}
        onChangeCheckbox  = {() => onCheckboxChange(product.id, 'savePurchaseOrder')}
      />
    </div>

    <div className="text-center">
      <Checkbox
        id                = {`checkbox-validate_purchase_order-${product.id}`}
        name              = {`purchase_order_lines[${product.id}][validate_purchase_order]`}
        checked           = {product.validatePurchaseOrder}
        onChangeCheckbox  = {() => onCheckboxChange(product.id, 'validatePurchaseOrder')}
      />
    </div>
  </>
);

export default BulkBuildForm
