import React from 'react'

export const StepCategory = props => {
  const {
    categories,
    selected,
    onSelect
  } = props

  return (
    <div className="demands--selection">
      { categories.map(category =>
          <div
            className="demands--selection-box"
            key={category.id}
            onClick={() => onSelect(category)}
          >
            <input
              className="hidden-radio"
              type="radio"
              value={category.id}
              defaultChecked={category.id === selected.id}
            />

            <label className="demands--selection-btn" htmlFor={category.name} lang={I18n.locale}>

            <div dangerouslySetInnerHTML={{ __html: category?.icon }}></div>
              <p>{category.name}</p>
            </label>
          </div>
      )}
    </div>
  )
}
