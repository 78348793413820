import React            from "react"
import SharedFlatpickr  from "@shared/flatpickr"

// Components
import TechnicianSelect from '@shared/technician_select'
import AssetSelect      from '@shared/assetSelect'

export const AdvancedFilters = ({
  setTechnicians,
  date,
  setDate,
  setAssets,
  teams,
  displayClient
})=> {
  const options = {
    required: {
      client: false,
      site:   false,
    },
    showLabels: {
      client: false,
      site:   false
    },
    placeholder: {
      client: I18n.t('dashboard.filter_client'),
      site:   I18n.t('dashboard.filter_site')
    },
    displayClient
  }

  return (
    <div className="dashboard__card dashboard__filters">
      <div className="d-flex j-c-between a-i-center f-column">
        <SharedFlatpickr
          options = {{
            mode:   "range",
            inline: true,
            locale: I18n.locale
          }}
          value    = {date}
          onChange = {setDate}
        />
        <span className="dashboard__date">
          {I18n.t('dashboard.date', { from: date[0].toLocaleDateString(I18n.locale), to: (date[1] ? date[1].toLocaleDateString(I18n.locale) : '…')})}
        </span>
      </div>
      
      <div className="flex-item-50">
        <TechnicianSelect
          teams    = {teams}
          onChange = {setTechnicians}
          prompt   = {I18n.t("dashboard.filter_tech")}
        />
      </div>

      <div className="flex-item-grow-shrink-a">
        <AssetSelect
          type     = 'site'
          onChange = {setAssets}
          options  = {options}
        />
      </div>
    </div>
  )
}
