import React from "react"

// Components
import SharedFlatpickr     from "@shared/flatpickr"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Combobox            from "@shared/combobox"

export const Filter = ({ technicians, filter, setFilter, technicianResupply }) => {

  const changeTechnician = technician => {
    setFilter({...filter, technician: technician?.id })
  }

  const changeDate = range => {
    if(range.length < 2) return

    const from = moment(range[0]).format("YYYY-MM-DD")
    const to   = moment(range[1]).format("YYYY-MM-DD")
    setFilter({...filter, from, to})
  }

  const period = [new Date(filter.from), new Date(filter.to)]

  const triggerPrint = event => {
    event.preventDefault()
    window.print()
  }

  return(
    <div className="timesheet__filters no-print">
      {!technicianResupply &&
        <div className="minw-25">
          <div className="timesheet__filters__title">
            {I18n.t('workorders.select_period')}
          </div>

          <SharedFlatpickr
            options   = {{ mode: "range", locale: I18n.locale, dateFormat: "d/m/Y" }}
            className = "form-control flatpickr-nobg h-41px"
            onChange  = {changeDate}
            value     = {period}
          />
        </div>
      }

      <div className={`f-g-1 timesheet__filters--middle${technicianResupply ? ' m-0' : ''}`} >
        <div className="timesheet__filters__title">
          {I18n.t('product_dispatcher.filter')}
        </div>

        <div className="form-group">
          <Combobox
            prompt          = {I18n.t("product_dispatcher.all_technicians")}
            options         = {technicians}
            value           = {filter.technician || ''}
            onSelectElement = {changeTechnician}
          />
        </div>
      </div>

      <div className="no-print">
        <div className="timesheet__filters__title">
          {I18n.t('users.timesheets.actions')}
        </div>

        <div className="timesheet__actions">
          <button onClick={triggerPrint} className="ml-2 btn btn-primary h-41px">
            <FontAwesomeIcon icon="print" /> {I18n.t('users.timesheets.print')}
          </button>
        </div>
      </div>
    </div>
  )
}
