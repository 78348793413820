import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const SalesFilters = ({
  types,
  years,
  previous,
  filters,
  setFilters
}) => {
  const [year, setYear] = useState(filters.year)

  const handleChange = (type, value) => {
    setFilters({ ...filters, [type]: value })
  }

  const changeYear = newYear => {
    setYear(newYear)
    handleChange('year', newYear)
  }

  if (!types || !years) return <div className="dot-elastic"></div>

  return (
    <div id="product-container">
      <div className="dashboard__card dashboard__filters j-c-between">
        <div className="d-flex j-c-between a-i-center w-100">
          <div className="dashboard__year__selectors">
            <div className="d-flex j-c-center a-i-center">
              {
                year > years[0] &&
                <span onClick={() => changeYear(year - 1)}>
                  <FontAwesomeIcon icon="arrow-left" />
                </span>
              }
              <h2>{year}</h2>
              {
                year < years[years.length -1] &&
                <span onClick={() => changeYear(year + 1)}>
                  <FontAwesomeIcon icon="arrow-right" />
                </span>
              }
            </div>    
          </div>

          <div className="d-flex j-c-end w-100">
            <select
              className = "form-control w-fit mr-2"
              name      = "previous"
              value     = { filters.previous }
              onChange  = { e => { handleChange('previous', e.target.value) } }
            >
              { previous.map(month => {
                return (
                  <option 
                    value = { month }
                    key   = { month }
                  >
                    { I18n.t(`invoices.sales_statistics.comparison_years_${month}`) }
                  </option>
                )
              })}
            </select>
            {types.map(type => {
              return (
                <div 
                  key       = { type[1] } 
                  className = {`autocomplete__shortcut mb-0 ${type[1] == filters.type ? 'autocomplete__shortcut--selected' : ''}`} 
                  onClick   = { () => { handleChange('type', type[1]) } }>
                  { type[0] }
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}