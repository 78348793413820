// Dispatch
export const addWorkorderAction = workorder => dispatch => dispatch({
  type:    ADD_WORKORDER.ACTION,
  payload: { workorder }
})

// Reducer response
export const ADD_WORKORDER = {
  ACTION: 'ADD_WORKORDER',

  call: ({ state, workorder }) => {
    const existingWorkorder = state.items.find(w => w.id == workorder.id)
    if (existingWorkorder) {
      return { ...state }
    }
    return {...state, items: [workorder, ...state.items]}
  }
}
