import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { lineCalculations, formatNumber, roundNumber } from '../../helpers'

const PriceCoefficient = ({qline, index, calculations, replaceLine}) => {
  const [active, setActive] = useState(false);
  const [coefficient, setCoefficient] = useState(calculations.coefficientPrice);

  const activateInput = () => {
    setCoefficient(roundNumber(calculations.coefficientPrice))
    setActive(true)
  }

  const onChange = (e) => {
    const coefficient = parseFloat(e.target.value) || ""
    setCoefficient(coefficient)
  }

  const onCancel = () => {
    setActive(false)
    setCoefficient(calculations.coefficientPrice)
  }

  const recalculateSalesPrice = () => {
    if (typeof(coefficient) != 'number') return

    const new_price = roundNumber(qline.purchase_price * coefficient)
    replaceLine({...qline, unit_price: new_price}, index)
    setActive(false)
  }

  return(
    <>
      {
        active
        ? <div className="calculation details">
            <div className="text-cut">{I18n.t("quotes.bigcard.coefficient")}</div>
            <div style={{width: '60px'}}>
              {/* Price coefficient input */}
              <input
                type="number"
                min="0"
                step="0.1"
                className="form-control"
                value={coefficient || ""}
                onChange={onChange}
              />

              <div className="action-buttons">
                {/* Cancel */}
                <div tooltip={I18n.t("button.cancel")}
                     onClick={onCancel}
                >
                  <FontAwesomeIcon
                    className="check-circle"
                    icon="times-circle"
                  />
                </div>

                {/* Apply */}
                <div tooltip={I18n.t("button.apply")}
                     onClick={recalculateSalesPrice}
                >
                  <FontAwesomeIcon
                    className="check-circle"
                    icon="check-circle"
                  />
                </div>
              </div>
            </div>
          </div>
        : <div className="calculation details coefficient" onClick={activateInput}>
            <div>{I18n.t("quotes.bigcard.coefficient")}</div>
            <div>{formatNumber(calculations.coefficientPrice)}</div>
          </div>
      }
    </>
  )
}

export default class Calculations extends React.Component {
  render() {
    const { qline, index, replaceLine, showMargin, showPriceCoefficient } = this.props
    const calculations = lineCalculations(this.props.qline)

    return(
      <div className="calculations">
        <div className="calculation">
          <div>Total</div>
          <div>{formatNumber(calculations.total)} {qline.currency_symbol}</div>
        </div>

        {
          showMargin &&
          <React.Fragment>
            <div className="calculation details">
              <div className="text-cut">
                {I18n.t("quotes.bigcard.purchase")}
              </div>
              <div>{formatNumber(calculations.purchase)} {qline.currency_symbol}</div>
            </div>

            <div className="calculation details">
              <div className="text-cut">
                {I18n.t("quotes.bigcard.margin")}
              </div>
              <div>{formatNumber(calculations.margin)} %</div>
            </div>

            {/* Coefficient price */}
            { showPriceCoefficient
              ? <PriceCoefficient
                  qline={qline}
                  index={index}
                  calculations={calculations}
                  replaceLine={replaceLine}
                />
              : <div className="calculation details">
                  <div className="text-cut">
                    {I18n.t("quotes.bigcard.coefficient")}
                  </div>
                  <div>{formatNumber(calculations.coefficient)}</div>
                </div>
            }

          </React.Fragment>
        }

      </div>
    )
  }
}
