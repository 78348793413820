const TIME_GRID_WEEK           = 'timeGridWeek'
const RESOURCE_TIME_GRID_DAY   = 'resourceTimeGridDay'
const RESOURCE_TIME_LINE       = 'resourceTimeline'
const RESOURCE_TIME_LINE_WEEK  = 'resourceTimelineWeek'
const ASSIGNMENT               = 'assignment'
const RESOURCE_TIME_LINE_MONTH = 'resourceTimelineMonth'

export const CALENDAR_VIEWS = {
  day:        RESOURCE_TIME_LINE,
  week:       RESOURCE_TIME_LINE_WEEK,
  assignment: ASSIGNMENT,
  month:      RESOURCE_TIME_LINE_MONTH,
  agenda_day: RESOURCE_TIME_GRID_DAY,
  agenda:     TIME_GRID_WEEK
}

export const getCurrentView = (name, weekWidth, monthWidth) => {
  let slotInterval, slotDuration, slotMinWidth

  if (name == CALENDAR_VIEWS.month && monthWidth) {
    slotInterval = { days: 1 }
    slotDuration = { days: 1 }
    slotMinWidth = monthWidth
  } else if (name == CALENDAR_VIEWS.assignment || name == CALENDAR_VIEWS.month) {
    slotInterval = { days: 1 }
    slotDuration = { days: 1 }
    slotMinWidth = undefined
  } else if (name === CALENDAR_VIEWS.week) {
    slotDuration = { hours: 2 }
    slotMinWidth = weekWidth
  } else {
    slotInterval = { hours:   1  }
    slotDuration = { minutes: 30 }
    slotMinWidth = undefined
  }
  return { name, slotMinWidth, slotInterval, slotDuration }
}
