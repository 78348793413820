import React from "react"

export default class AddButtons extends React.Component {

  render() {
    const {quote, onClick, index} = this.props

    return(
      <div className="row-buttons">
        <div className="button"
          onClick={ e => onClick("regular", index)}
        >
          {"+ " + I18n.t('quotes.new.line')}
        </div>

        <div className="button"
          onClick={ e => onClick("group", index)}
        >
          {"+ " + I18n.t('quotes.new.qline_group')}
        </div>

        <div className="button"
          onClick={ e => onClick("blueprint", index)}
        >
          {"+ " +  I18n.t('quotes.new.qline_blueprint')}
        </div>

        <div className="button"
          onClick={ e => onClick("descriptive", index)}
        >
          {"+ " +  I18n.t('quotes.new.descriptive_line')}
        </div>

        { quote.account.allow_products &&
          <div className="button"
            onClick={ e => onClick("product", index)}
          >
            {"+ " +  I18n.t('quotes.new.product')}
          </div>
        }

        <div className="button"
          onClick={ e => onClick("break", index)}
        >
          {"+ " +  I18n.t('quotes.new.page_break')}
        </div>

        <div className="button"
          onClick={ e => onClick("subtotal", index)}
        >
          {"+ " + I18n.t('quotes.new.subtotal')}
        </div>
      </div>
    )
  }
}
