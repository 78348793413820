// Modules
import React, { useState } from 'react'
import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas }             from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

library.add(fas)

// Components
import QrReader from 'react-qr-reader'

const formQRCode = props => {
  const { qrCodeMap } = props
  const [qrcodeActive, setQrCodeActive] = useState(false)

  const toggleQrCode = () => {
    setQrCodeActive(!qrcodeActive)
  }

  const onScan = data => {
    if (!data) return

    setQrCodeActive(!qrcodeActive)

    const parsedData = parseQRString(data)

    for (const field in qrCodeMap) {
      const fillableField = document.querySelector(`[qr_code_fillable='${qrCodeMap[field]}']`)
      if(fillableField) fillableField.value = parsedData[field]
    }
  }

  const parseQRString = data => {
    return Object.fromEntries(data.split('\n').map(el => el.split(';').map(el => el.trim()) ))
  }

  const onError = error => {
    console.error('QR code reading error: ', error.message);
  }

  return(
    <>
      { qrcodeActive
        ? <div>
            <QrReader
              delay   ={300}
              onError ={onError}
              onScan  ={onScan}
              style   ={{ width: '100%', 'max-width': '400px', 'paddingLeft': '10px' }}
            />
            <div className="btn btn-sm btn-default my-2 ml-2" onClick={toggleQrCode}>
              {I18n.t("button.cancel")}
            </div>
          </div>
        : <div className="custom-form-sub-field mb-2" onClick={toggleQrCode}>
            <div className="btn btn-sm btn-default ml-0" onClick={toggleQrCode}>
              <FontAwesomeIcon icon="qrcode" /> Scan QR
            </div>
          </div>
      }
    </>
  )
}

export default formQRCode
