import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomNumberInput from './CustomNumberInput';
import Checkbox from '@shared/checkbox/checkbox';

const FieldCard = React.forwardRef(({
    field,
    selectedField,
    positions,
    clipboardPosition,
    setPositions,
    saveFieldPosition,
    removeFieldPosition,
    handleFieldSelect,
    setCurrentPageIndex,
    maxPageIndex },
    ref) => {

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let updatedPosition = { ...position };
    updatedPosition['width']  = updatedPosition.x2 - position.x1;
    updatedPosition['height'] = updatedPosition.y2 - position.y1;
    updatedPosition[name] = value;

    if (['x1', 'y1', 'width', 'height'].includes(name)) {
      updatedPosition[name] = Math.ceil(parseFloat(value)) || 0;
      if (['x1', 'width'].includes(name)) {
        updatedPosition.x2 = updatedPosition.x1 + updatedPosition.width;
      } else if (['y1', 'height'].includes(name)) {
        updatedPosition.y2 = updatedPosition.y1 + updatedPosition.height;
      }
    } else if (name === 'page_number') {
        const pageNumber = Math.min(parseInt(value) || 1, maxPageIndex);
        updatedPosition[name] = pageNumber;
        const newPageIndex = pageNumber - 1;
        if (newPageIndex >= 0 && newPageIndex < maxPageIndex) {
          setCurrentPageIndex(newPageIndex);
        }
    } else if (name === 'font_size') {
        updatedPosition[name] = parseInt(value) || 10;
    } else if (name === 'font') {
        updatedPosition[name] = value || "Helvetica";
    }
    setPositions(prev => ({ ...prev, [field.id]: updatedPosition }));
  };

  const handleCheckboxChange = (name) => (checked) => {
    setPositions(prevPositions => ({
      ...prevPositions,
      [field.id]: { ...prevPositions[field.id], [name]: checked },
    }));
  };

  const handleSavePosition = (e) => {
    e.stopPropagation();
    saveFieldPosition(field.id, position);
    handleFieldSelect(null);
  };

  const handleRemovePosition = (e) => {
    e.stopPropagation();
    const isConfirmed = window.confirm(I18n.t("blueprints.field_positions.remove_prompt"));
    if (!isConfirmed) return;

    removeFieldPosition(field.id, position);
    handleFieldSelect(null);
  }

  const pasteClipboardPosition = () => {
    setPositions(prev => ({ ...prev, [field.id]: clipboardPosition }));
  }

  const position      = positions[field.id] || {};
  const positionIsSet = Object.keys(position).length > 0
  const isSelected    = selectedField === field.id;
  const width         = Math.ceil(position.x2 - position.x1);
  const height        = Math.ceil(position.y2 - position.y1);
  const requiresFontSettings = [
    "text",
    "static",
    "description",
    "number",
    "float",
    "date",
    "date_time",
    "yes_no",
    "select",
    "select_prefill"].includes(field.data_type);

  return (
    <li ref={ref} className={`${isSelected ? 'selected' : ''}`}>

      <div className="flexbox-space-between" onClick={() => handleFieldSelect(field.id)}>

        { !isSelected &&
          (positionIsSet ?
            <>
              <div className='header-name'>{field.name}</div>
              <div className="header-name-and-position-info">
                <div className="field-name">{field.name}</div>
                <div className="field-positions-info">
                  <div>{I18n.t('blueprints.field_positions.x')} {position.x1}</div>
                  <div>{I18n.t('blueprints.field_positions.y')} {position.y1}</div>
                  <div>{I18n.t('blueprints.field_positions.w')} {width}</div>
                  <div>{I18n.t('blueprints.field_positions.h')} {height}</div>
                </div>
              </div>
            </> :
            <div>{field.name}</div>
          )
        }

        <div>
          { !isSelected &&
            (positionIsSet ?
              <FontAwesomeIcon icon="check-circle" style={{color: 'green'}} /> :
              <FontAwesomeIcon icon="times-circle" style={{color: 'lightgrey'}} />
            )
          }
        </div>
      </div>

      {isSelected && (
        <>
          <div>{field.name}</div>
          <div className="field-card-inputs mt-3 mb-2">
            <label>{I18n.t("blueprints.field_positions.size")}</label>
            <div>
              <CustomNumberInput
                name="width"
                value={width || ''}
                onChange={handleInputChange}
              />
              <div className="input-label">
                <label>{I18n.t("blueprints.field_positions.width")}</label>
              </div>
            </div>

            <div>
              <CustomNumberInput
                name="height"
                value={height || ''}
                onChange={handleInputChange}
              />
              <div className="input-label">
                <label>{I18n.t("blueprints.field_positions.height")}</label>
              </div>
            </div>

            <label>{I18n.t("blueprints.field_positions.position")}</label>
            <div>
              <CustomNumberInput
                name="x1"
                value={position.x1 || ''}
                onChange={handleInputChange}
              />
              <div className="input-label">
                <label>X</label>
              </div>
            </div>

            <div>
              <CustomNumberInput
                name="y1"
                value={position.y1 || ''}
                onChange={handleInputChange}
              />
              <div className="input-label">
                <label>Y</label>
              </div>
            </div>

            {/* Page selection */}
            <label>{I18n.t("blueprints.field_positions.page")}</label>
            <div>
              <CustomNumberInput
                name="page_number"
                value={position.page_number || ''}
                onChange={handleInputChange}
              />
            </div>
            <div></div>

            { requiresFontSettings && (
              <>
                <div className="spacing-row"></div>

                {/* Font Selection */}
                <label>{I18n.t("blueprints.field_positions.font")}</label>
                <div>
                  <select
                    className="form-control"
                    name="font"
                    value={position.font || 'Helvetica'}
                    onChange={handleInputChange}
                  >
                    <option value="Helvetica">Helvetica</option>
                    <option value="Times-Roman">Times-Roman</option>
                    <option value="Courier">Courier</option>
                  </select>
                </div>

                {/* Font Size Input */}
                <div>
                  {(!position.font_size_auto) &&
                    <CustomNumberInput
                      name="font_size"
                      value={position.font_size || 10}
                      onChange={handleInputChange}
                    />
                  }
                </div>

                <div></div>
                <div className="d-flex" style={{'gridColumn': 'span 2', 'overflow': 'hidden'}}>
                  {/* Auto Font Size */}
                  <Checkbox
                    name      = 'font_size_auto'
                    checked   = {position.font_size_auto}
                    id        = {`font_size_auto-${field.id}`}
                    onChange  = {handleCheckboxChange('font_size_auto')}
                    label     = {I18n.t("blueprints.field_positions.font_size_auto")}
                    className = "form-group"
                  />

                  {/* Bold Checkbox */}
                  <Checkbox
                    name      = 'bold'
                    checked   = {position.bold || false}
                    id        = {`bold-${field.id}`}
                    onChange  = {handleCheckboxChange('bold')}
                    label     = {I18n.t("blueprints.field_positions.bold")}
                    className = "form-group ml-3"
                  />

                  {/* Italic Checkbox */}
                  <Checkbox
                    name      = 'italic'
                    checked   = {position.italic || false}
                    id        = {`italic-${field.id}`}
                    onChange  = {handleCheckboxChange('italic')}
                    label     = {I18n.t("blueprints.field_positions.italic")}
                    className = "form-group ml-3"
                  />
                </div>

                <div></div>
                <div>
                  {/* Color Picker */}
                  <div>
                    <label className="my-0 py-0">{I18n.t("blueprints.field_positions.fill_color")}</label>
                    <input
                      type="color"
                      name="fill_color"
                      value={position.fill_color || '#000000'} // Default to black if no color is specified
                      onChange={handleInputChange}
                      className="form-control color-picker w-100 px-2"
                    />
                  </div>
                </div>
              </>
            )}

          </div>

          {/* Paste previous / Cancel / Remove / Save */}
          <div className="flex-end mt-3">
            { clipboardPosition &&
              <button className="btn btn-sm btn-default" onClick={(e) => {
                e.stopPropagation();
                pasteClipboardPosition();
              }}>
                {I18n.t("blueprints.field_positions.paste_previous_position")}
              </button>
            }

            <button className="btn btn-sm btn-default ml-2" onClick={(e) => {
              e.stopPropagation();
              handleFieldSelect(null);
            }}>
              {I18n.t("button.cancel")}
            </button>

            <button className="btn btn-sm btn-danger ml-2" onClick={handleRemovePosition}>
              {I18n.t("button.remove")}
            </button>

            <button className="btn btn-sm btn-primary ml-2" onClick={handleSavePosition}>
              {I18n.t("button.save")}
            </button>
          </div>
        </>
      )}
    </li>
  );
});

export default FieldCard;
