import React          from "react"
import sanitizeHtml   from 'sanitize-html-react'

import Autocomplete   from '@react/shared/autocomplete'

import { getRequest } from '@react/helpers/fetch'

export const fetchClient = (clientId, filter, setFilter) => {
  getRequest(`/clients/${clientId}/build.json`)
  .then(client => {
    const asset = {
      name: I18n.t("sites.site"),
      type: "client",
      id:   client.id
    }
    setFilter({ ...filter, client, asset })
  })
}

export const selectSite = (siteId, filter, setFilter) => {
  const site   = filter.client.sites.find(s => s.id == siteId)
  const asset  = {
    name: I18n.t("materials.title"),
    type: "site",
    id:   site.id
  }
  setFilter({ ...filter, site, asset })
}

export const selectMaterial = (materialId, filter, setFilter) => {
  const material = filter.site.materials.find(m => m.id == materialId)
  const asset = {
    name: I18n.t("materials.sub_materials"),
    type: "material",
    id:   material.id
  }
  setFilter({ ...filter, material, asset })
}

export const AssetFilter = ({ filter, setFilter }) => {

  const { client, site, material } = filter

  const avaiableSites = filter.client
    ? filter.client.sites
    : []

  const resetClient = e => {
    delete filter.client
    delete filter.site
    delete filter.material
    delete filter.asset

    setFilter({ ...filter })
    // Cheap autofocus
    setTimeout(() => {
      document.querySelector("input.autocomplete__input").focus()
    }, 100)
  }

  const resetSite = e => {
    delete filter.site
    delete filter.material

    const asset = {
      name: I18n.t("sites.site"),
      type: "client",
      id:   client.id
    }
    setFilter({ ...filter, asset })
  }

  const resetMaterial = e => {
    delete filter.material

    const asset = {
      name: I18n.t("materials.title"),
      type: "site",
      id:   site.id
    }
    setFilter({ ...filter, asset })
  }

  return (
    <React.Fragment>
      { !client &&
        <div className="form-group">
          <Autocomplete
            placeholder      = {I18n.t("operational_costs.all_clients")}
            autocomplete_url = {"/clients/autocomplete.json"}
            onSelectElement  = {clients => fetchClient(clients[0].id, filter, setFilter)}
            returnObjects    = {true}
            inputOptions     = {{ autofocus: true }}
          />
        </div>
      }

      { client &&
        <div className="form-group">
          <input
            defaultValue={client.name}
            className="form-control pointer"
            onClick={resetClient}
            readOnly
          />
        </div>
      }

      { client && !site && avaiableSites.length > 0 &&
        <div className="form-group">
          <select
            value={''}
            onChange={e => selectSite(e.currentTarget.value, filter, setFilter)}
            className="form-control"
            style={{ height: '41px' }}
          >
            <option value="">{I18n.t("operational_costs.all_sites")}</option>

            { avaiableSites.map(site =>
              <option value={site.id} key={site.id}>{site.name}</option>
            )}
          </select>
        </div>
      }

      { site &&
        <div className="form-group">
          <input
            defaultValue={site.name}
            className="form-control pointer"
            onClick={resetSite}
            readOnly
          />
        </div>
      }

      { site && !material && site.materials.length > 0 &&
        <div className="form-group">
          <select
            value={''}
            onChange={e => selectMaterial(e.currentTarget.value, filter, setFilter)}
            className="form-control"
            style={{ height: '41px' }}
          >
            <option value="">{I18n.t("operational_costs.all_materials")}</option>

            { site.materials.map(material =>
              <option value={material.id} key={material.id}>{sanitizeHtml(material.name)}</option>
            )}
          </select>
        </div>
      }

      { material &&
        <div className="form-group">
          <input
            defaultValue={sanitizeHtml(material.name)}
            className="form-control pointer"
            onClick={resetMaterial}
            readOnly
          />
        </div>
      }
    </React.Fragment>

  )
}
