import { defaultFilter } from './filterReducer'
// Toggle Filter Icon
export const changeFilterStatusAction = newStatus => dispatch => dispatch({
  type: FILTERS.CHANGE_STATUS,
  payload: {
    status: newStatus,
    search: ''
  }
})

// Reactive Toggle
export const toggleReactiveAction = old_type => dispatch => dispatch({
  type: FILTERS.TOGGLE_REACTIVE,
  payload: {
    workorder_type: { Reactive: !old_type },
    search: ''
  }
})

// Preventive Toggle
export const togglePreventiveAction = old_type => dispatch => dispatch({
  type: FILTERS.TOGGLE_PREVENTIVE,
  payload: {
    workorder_type: { Preventive: !old_type },
    search: ''
  }
})

// Installation Toggle
export const toggleInstallationAction = old_type => dispatch => dispatch({
  type: FILTERS.TOGGLE_INSTALLATION,
  payload: {
    workorder_type: { Installation: !old_type },
    search: ''
  }
})

// Open distance filters toggle
export const toggleByDistanceAction = location => dispatch => dispatch({
  type: FILTERS.BY_DISTANCE,
  payload: { location }
})

// Technician select
export const selectTechnicianAction = technician => dispatch => dispatch({
  type: FILTERS.SELECT_TECHNICIAN,
  payload: { technician }
})

// Category select
export const selectCategoryAction = category => dispatch => dispatch({
  type: FILTERS.SELECT_CATEGORY,
  payload: { category }
})

// Date Selection action
export const changeDateAction = newDateDiff => dispatch => dispatch({
  type: FILTERS.CHANGE_DATE,
  payload: {
    date: newDateDiff,
    search: ''
  }
})

// Search action
export const searchWorkordersAction = search => dispatch => dispatch({
  type: FILTERS.SEARCH_WORKORDERS,
  payload: {
    search: search,
    status: ''
  }
})

// Reset action
export const resetFiltersAction = () => dispatch => {
  dispatch({ type: FILTERS.RESET })
}

export const nextPageAction = () => dispatch => {
  dispatch({ type: FILTERS.NEXT_PAGE })
}

// Instantiate actions names
export const FILTERS = {
  TOGGLE_FILTERS:      'TOGGLE_FILTERS',
  CHANGE_STATUS:       'CHANGE_STATUS',
  NEXT_PAGE:           'NEXT_PAGE',
  TOGGLE_REACTIVE:     'TOGGLE_REACTIVE',
  TOGGLE_PREVENTIVE:   'TOGGLE_PREVENTIVE',
  TOGGLE_INSTALLATION: 'TOGGLE_INSTALLATION',
  BY_DISTANCE:         'BY_DISTANCE',
  SELECT_TECHNICIAN:   'SELECT_TECHNICIAN',
  SELECT_CATEGORY:     'SELECT_CATEGORY',
  CHANGE_DATE:         'CHANGE_DATE',
  SEARCH_WORKORDERS:   'SEARCH_WORKORDERS',
  RESET:               'RESET_FILTERS',

  toggleFiltersResponse:    ({ state, rotate, show_filters })         => ({ ...state, rotate, show_filters}),
  nextPageResponse:         ({ state })                               => ({ ...state, page: state.page + 1 }),
  changeStatusResponse:     ({ state, status, search, page })         => ({ ...state, status, search, date: 0, page }),
  toggleTypeResponse:       ({ state, workorder_type, search, page }) => ({ ...state, workorder_type, search, page }),
  byDistanceResponse:       ({ state, by_distance, page })            => ({ ...state, by_distance, page }),
  selectTechnicianResponse: ({ state, technician, page })             => ({ ...state, technician, page }),
  selectCategoryResponse:   ({ state, category, page })               => ({ ...state, category, page }),
  changeDateResponse:       ({ state, date, search, page })           => ({ ...state, date, search, page }),
  searchWorkordersResponse: ({ state, search })                       => ({ ...state, search, page: 1 }),
  resetFiltersResponse:     ({ state })                               => ({ ...defaultFilter })
}
