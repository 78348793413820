import React           from "react"
import SharedFlatpickr from "@shared/flatpickr"

export default class Passage extends React.Component {
  state = {}

  remove = e => {
    e.preventDefault()
    const { removePassage, index } = this.props
    removePassage(index)
  }

  changeDate = dates => {
    const { changePassageDate, index } = this.props
    changePassageDate(index, dates)
  }

  changeTechnician = (e) => {
    e.preventDefault()
    const technician = e.target.value
    const { changePassageTechnician, index } = this.props
    changePassageTechnician(index, technician)
  }

  changePredictedDuration = dates => {
    const { changePassageDuration, index } = this.props
    changePassageDuration(index, dates)
  }

  toggleExtra = e => {
    const { togglePassageExtra, index } = this.props
    togglePassageExtra(index)
  }

  getDate(duration) {
    const date     = new Date()
    const hours    = duration / 60
    const minutes  = duration % 60
    date.setHours(hours)
    date.setMinutes(minutes)

    return date
  }

  render () {
    const { index, passage, durations, technicians } = this.props

    return (
      <div className="passages-grid__row">
        <div>
          <SharedFlatpickr
            options={{
              locale:     I18n.locale,
              enableTime: true,
              dateFormat: "d/m/Y - H:i"
            }}
            name        = "passages[][date_planned]"
            className   = "form-control flatpickr-nobg text-center"
            placeholder = {I18n.t("workorders.datetime_placeholder")}
            onChange    = {this.changeDate}
            value       = {passage.date}
          />
        </div>

        <div>
          <SharedFlatpickr
            options={{
              locale:     I18n.locale,
              enableTime: true,
              noCalendar: true,
              minTime:    "00:15",
              maxTime:    "12:00"
            }}
            className = "form-control flatpickr-nobg text-center"
            onChange  = {this.changePredictedDuration}
            value     = {this.getDate(passage.predicted_duration)}
          />

          <input type="hidden"
                 name="passages[][predicted_duration]"
                 defaultValue={passage.predicted_duration}
          />
        </div>

        <div>
          <select
            className= "form-control"
            name= "passages[][technician_id]"
            value= {passage.technician}
            onChange= {this.changeTechnician}
          >
            <option value="">
              {I18n.t("open")}
            </option>
            {technicians.map((technician, key) =>
              <option key={key} value={technician[1]}>
                {technician[0]}
              </option>
            )}
          </select>
        </div>

        <div className="checkbox checkbox-primary d-flex pl-2">
          <input type     = "checkbox"
                 value    = "true"
                 name     = "passages[][include_extra]"
                 id       = {`passages_${index}_include_extra`}
                 checked  = {passage.extra}
                 onChange = {this.toggleExtra}
          />
          <label htmlFor={`passages_${index}_include_extra`} />
        </div>

        <div className="text-center">
          <a className="minus-circle mr-0"
             href="#"
             onClick={this.remove}
          >
            <i className="fa fa-minus-circle "></i>
          </a>
        </div>
      </div>
    )
  }
}
