import React, { useState, useEffect } from 'react'
import { minToString, showMoney } from '@helpers/javascript/date_helpers'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { fetchClient, selectSite, selectMaterial } from "./assetFilter"

const ASC   = "asc"
const DESC  = "desc"

const NAME     = "name"
const COUNT    = "count"
const TIME     = "time"
const LABOR    = "labor"
const PRODUCTS = "products"
const TOTAL    = "total"

const AMOUNT   = "amount"

const CLIENT   = "client"
const SITE     = "site"
const MATERIAL = "material"

export const CostsTable = ({ filter, setFilter, totals, lines }) => {
  const [sorting, setSorting]      = useState(true)
  const [order,   setOrder]        = useState({ column: NAME, direction: ASC })

  const getSort = () => {
    if(order.direction == ASC) {
      switch(order.column) {
        case NAME     : return (a, b) => a.name.localeCompare(b.name)
        case COUNT    : return (a, b) => a.count           - b.count
        case TIME     : return (a, b) => a.time            - b.time
        case LABOR    : return (a, b) => a.labor.amount    - b.labor.amount
        case PRODUCTS : return (a, b) => a.products.amount - b.products.amount
        case TOTAL    : return (a, b) => a.total.amount    - b.total.amount
      }
    } else {
      switch(order.column) {
        case NAME     : return (a, b) => b.name.localeCompare(a.name)
        case COUNT    : return (a, b) => b.count           - a.count
        case TIME     : return (a, b) => b.time            - a.time
        case LABOR    : return (a, b) => b.labor.amount    - a.labor.amount
        case PRODUCTS : return (a, b) => b.products.amount - a.products.amount
        case TOTAL    : return (a, b) => b.total.amount    - a.total.amount
      }
    }
  }

  let orderedLines = lines

  const sum = array => array.reduce((a, b) => a + b, 0)
  const dig = (obj, key, secondKey) => secondKey ? obj[key][secondKey] : obj[key]
  const calculateSum = (key, secondKey) => sum(lines.map(line => dig(line, key, secondKey)))
  const diff = (key, secondKey)  => dig(totals, key, secondKey) - calculateSum(key, secondKey)

  // This will include an extra lines with the workorders without material assigned
  if(filter.asset && [SITE, MATERIAL].includes(filter.asset.type)) {
    const count = diff(COUNT)
    if(count > 0) {
      orderedLines = [...orderedLines, {
        id       : 0,
        type     : MATERIAL,
        name     : filter.asset.type == MATERIAL ? filter.asset.name : I18n.t("operational_costs.no_material"),
        count    : count,
        time     : diff(TIME),
        products : {
          amount   : diff(PRODUCTS, AMOUNT),
          currency : totals.products.currency
        },
        labor: {
          amount   : diff(LABOR, AMOUNT),
          currency : totals.labor.currency
        },
        total: {
          amount   : diff(TOTAL, AMOUNT),
          currency : totals.total.currency
        }
      }]
    }
  }

  orderedLines = orderedLines.sort(getSort())

  useEffect(() => { setSorting(false) }, [order])

  const changeOrder = column => {
    const direction = column == order.column ? (order.direction == ASC ? DESC : ASC) : ASC
    setSorting(true)
    setOrder({ column, direction })
  }

  const setAsset = line => {
    if(line.type == CLIENT)   fetchClient(line.id, filter, setFilter)
    if(line.type == SITE)     selectSite(line.id, filter, setFilter)
    if(line.type == MATERIAL) {
      // Workorders without assigned equipment
      if(line.id == 0) return

      selectMaterial(line.id, filter, setFilter)
    }
  }

  return(
    <React.Fragment>
      <div className="timesheet__sheet">
        <div className='timesheet__sheet__row  timesheet__sheet__row--headers'
             style={{ gridTemplateColumns: `2fr repeat(5, 1fr)` }}
        >
          <div className="pointer" onClick={() => { changeOrder(NAME) }}>
            {filter.asset?.name || I18n.t("client.client")}
            &nbsp;
            { order.column == NAME && order.direction == ASC &&
              <FontAwesomeIcon icon="caret-up" />
            }
            { order.column == NAME && order.direction == DESC &&
              <FontAwesomeIcon icon="caret-down" />
            }
          </div>

          <div className="pointer text-center" onClick={() => { changeOrder(COUNT) }}>
            {I18n.t('operational_costs.number')}
            &nbsp;
            { order.column == COUNT && order.direction == ASC &&
              <FontAwesomeIcon icon="caret-up" />
            }
            { order.column == COUNT && order.direction == DESC &&
              <FontAwesomeIcon icon="caret-down" />
            }
          </div>

          <div className="pointer text-center" onClick={() => { changeOrder(TIME) }}>
            {I18n.t('operational_costs.time')}
            &nbsp;
            { order.column == TIME && order.direction == ASC &&
              <FontAwesomeIcon icon="caret-up" />
            }
            { order.column == TIME && order.direction == DESC &&
              <FontAwesomeIcon icon="caret-down" />
            }
          </div>

          <div className="pointer text-center" onClick={() => { changeOrder(LABOR) }}>
            {I18n.t('operational_costs.labor')}
            &nbsp;
            { order.column == LABOR && order.direction == ASC &&
              <FontAwesomeIcon icon="caret-up" />
            }
            { order.column == LABOR && order.direction == DESC &&
              <FontAwesomeIcon icon="caret-down" />
            }
          </div>

          <div className="pointer text-center" onClick={() => { changeOrder(PRODUCTS) }}>
            {I18n.t('operational_costs.products')}
            &nbsp;
            { order.column == PRODUCTS && order.direction == ASC &&
              <FontAwesomeIcon icon="caret-up" />
            }
            { order.column == PRODUCTS && order.direction == DESC &&
              <FontAwesomeIcon icon="caret-down" />
            }
          </div>

          <div className="pointer text-center" onClick={() => { changeOrder(TOTAL) }}>
            {I18n.t('operational_costs.total')}
            &nbsp;
            { order.column == TOTAL && order.direction == ASC &&
              <FontAwesomeIcon icon="caret-up" />
            }
            { order.column == TOTAL && order.direction == DESC &&
              <FontAwesomeIcon icon="caret-down" />
            }
          </div>
        </div>

        { sorting &&
          <div className="loading-spinner-container-sm g-c-span-row"
               style={{minHeight: '100vh'}}
          >
            <div id="loading-spinner"></div>
          </div>
        }

        { !sorting && orderedLines.map(line =>
          <div className="timesheet__sheet__row timesheet__sheet__row--workorder"
               style={{ gridTemplateColumns: `2fr repeat(5, 1fr)` }}
               key={line.id}
               onClick={() => setAsset(line)}
          >
            <div>{line.name}</div>

            <div className="text-right">{line.count}</div>

            <div className="text-right">{minToString(line.time)}</div>

            <div className="text-right">{showMoney(line.labor)}</div>

            <div className="text-right">{showMoney(line.products)}</div>

            <div className="text-right">{showMoney(line.total)}</div>
          </div>
        )}

        { orderedLines.length == 0 && !sorting &&
          <div className="timesheet__sheet__row timesheet__sheet__row--workorder">
            <div className="text-center g-c-span-row">
              {I18n.t("operational_costs.no_results")}
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  )
}
