import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const SmallCardInfo = ({ 
  workorderState
}) => {

  return(
    <div className = "small_cards__item selected-card">
      <div className= "top-card-workorders-index" tooltip={ workorderState.title ? workorderState.title : workorderState.description }>
        <h6 className= "text-cut">
          { workorderState.title ? workorderState.title : workorderState.description }
        </h6>
      </div>
      <a
        href        = "#"
        style       = {{ display: 'block' }}
        data-toggle = "modal"
        data-target = "#template-modal"
        data-route  = { `/client/modal/${workorderState.clientId}` }
        data-action = "click->client--modal#show"
        className   = "pointer button-link mb-1 text-cut"
      >
        <FontAwesomeIcon icon = "user-circle" className= "mr-1"/>
        { workorderState.clientName }
      </a>
      <a
        href        = "#"
        style       = {{ display: 'block' }}
        data-toggle = "modal"
        data-target = "#template-modal"
        data-route  = { `/site/modal/${workorderState.siteId}` }
        data-action = "click->client--modal#show"
        className   = "pointer button-link mb-1 text-cut"
      >
        <FontAwesomeIcon icon = "building"  className= "mr-1"/>
        { workorderState.siteName }
      </a>
      <a 
        style     = {{ display: 'block' }}
        className = "text-cut mb-1" 
        href      = {`https://www.google.com/maps/search/?api=1&query=${workorderState.address}`}
        target    = "_blank" >
        <FontAwesomeIcon icon = "location-arrow"  className= "mr-1"/>
        { workorderState.address }
      </a>
    </div>
  )
}