export const toggleSideMenuAction = () => {
  return dispatch => { dispatch({type: SIDEMENU.TOGGLE}) }
}

export const toggleFiltersAction = () => {
  return dispatch => { dispatch({type: SIDEMENU.TOGGLE_FILTERS}) }
}

export const toggleCreationAction = () => {
  return dispatch => { dispatch({type: SIDEMENU.TOGGLE_CREATION}) }
}

export const SIDEMENU = {
  TOGGLE: 'TOGGLE_SIDEMENU',
  TOGGLE_FILTERS: 'TOGGLE_FILTERS',
  TOGGLE_CREATION: 'TOGGLE_CREATION',
  toggleResponse: ({state, active}) => ({...state, display: active }),
  toggleFiltersResponse: ({state, active}) => ({...state, displayFilters: active }),
  toggleCreationResponse: ({state, active}) => ({...state, displayCreation: active })
}


