import React       from "react"
import Base        from "../base"
import {sortableContainer, sortableElement} from 'react-sortable-hoc';

// Lines
import Product     from "./line_product"
import Break       from "./line_break"
import Blueprint   from "./line_blueprint"
import Descriptive from "./line_descriptive"
import Regular     from "./line_regular"
import SubTotal    from "./line_subtotal"
import Group       from "./line_group"
import ImportCsv   from "./line_import_csv"
import Template    from "./line_template"
import Destroyed   from "./line_destroyed"
import AddButtons  from "./add_buttons"
import Totals      from "./totals"

// Helpers
import { setSubTotals, quoteCalculations } from "../helpers"
const buildQline = (type, quote) => {
  const baseLine = {
    active:          true,
    option:          false,
    hide_in_pdf:     false,
    can_be_deleted:  true,
    vat_rate:        quote.site.vat_rate || {},
    discount:        quote.client.discount,
    currency_symbol: quote.currency_symbol,
    currency:        quote.currency,
    product:         {}
  }

  switch (type) {
    case "product":
      return({...baseLine, type: "product"})
    case "break":
      return({...baseLine, type: "break", active: false})
    case "blueprint":
      return({...baseLine, type: "blueprint"})
    case "subtotal":
      return({...baseLine, type: "subtotal", description: I18n.t("quotes.bigcard.subtotal")})
    case "group":
      return({...baseLine, type: "group"})
    case "template":
      return({...baseLine, type: "template"})
    case "descriptive":
      return({...baseLine, type: "descriptive"})
    case "regular":
      return({...baseLine, type: "regular"})
    case "import_csv":
      return({...baseLine, type: "import_csv"})
  }
}

const Qline = sortableElement(({quote, order, qline, lineProps}) =>
  {
    switch(qline.type) {
      case "product":
        return(
          <Product
            key         = {qline.id}
            quote       = {quote}
            qline       = {qline}
            index       = {order}
            vats        = {lineProps.vats}
            showCode    = {lineProps.showCode}
            showMargin  = {lineProps.showMargin}
            buttonProps = {lineProps.buttonProps}
          />
        )
      case "break":
        return(
          <Break
            key         = {qline.id}
            qline       = {qline}
            index       = {order}
            buttonProps = {lineProps.buttonProps}
          />
        )
      case "blueprint":
        return(
          <Blueprint
            key         = {qline.id}
            qline       = {qline}
            index       = {order}
            blueprints  = {lineProps.blueprints}
            buttonProps = {lineProps.buttonProps}
          />
        )
      case "subtotal":
        return(
          <SubTotal
            key         = {qline.id}
            qline       = {qline}
            index       = {order}
            showCode    = {lineProps.showCode}
            buttonProps = {lineProps.buttonProps}
          />
        )
      case "group":
        return(
          <Group
            key         = {qline.id}
            qline       = {qline}
            index       = {order}
            groupProps  = {lineProps.groupProps}
            buttonProps = {lineProps.buttonProps}
          />
        )
      case "template":
        return(
          <Template
            key           = {qline.id}
            qline         = {qline}
            index         = {order}
            templateProps = {lineProps.templateProps}
            buttonProps   = {lineProps.buttonProps}
          />
        )
      case "descriptive":
        return(
          <Descriptive
            key         = {qline.id}
            qline       = {qline}
            index       = {order}
            showCode    = {lineProps.showCode}
            buttonProps = {lineProps.buttonProps}
          />
        )
      case "regular":
        return(
          <Regular
            key         = {qline.id}
            quote       = {quote}
            qline       = {qline}
            index       = {order}
            showCode    = {lineProps.showCode}
            showMargin  = {lineProps.showMargin}
            vats        = {lineProps.vats}
            buttonProps = {lineProps.buttonProps}
          />
        )
      case "import_from_csv":
        return(
          <ImportCsv
            key         = {qline.id}
            quote       = {quote}
            qline       = {qline}
            index       = {order}
            addFromCsv  = {lineProps.addFromCsv}
            buttonProps = {lineProps.buttonProps}
          />
        )
    }
  }
)

const QlinesContainer = sortableContainer(({quote, qlines, lineProps}) =>
  <div className="qlines-container">
    {qlines.map((qline, index) =>
      <Qline
        key          = {`${index}-${qline.active}`}
        index        = {index}
        order        = {index}
        quote        = {quote}
        qline        = {qline}
        lineProps    = {lineProps}
        disabled     = {qline.active}
      />
    )}
  </div>
)

export default class Qlines extends Base {
  constructor(props) {
    super(props)
    this.replaceLine = this.replaceLine.bind(this)
    this.changeOrder = this.changeOrder.bind(this)
    this.moveLine    = this.moveLine.bind(this)
    this.addLine     = this.addLine.bind(this)
    this.addGroup    = this.addGroup.bind(this)
    this.addTemplate = this.addTemplate.bind(this)
    this.addFromCsv  = this.addFromCsv.bind(this)
  }

  changeOrder({oldIndex, newIndex}) { this.moveLine(oldIndex, newIndex) }

  moveLine(index, newIndex) {
    const { quote, replaceQuote } = this.props
    const qlines = quote.qlines
    if (newIndex >= qlines.length) { newIndex = qlines.length }
    qlines.splice(newIndex, 0, qlines.splice(index, 1)[0])
    setSubTotals(quote)
    replaceQuote(quote)
  }

  replaceLine(qline, index) {
    const { quote, replaceQuote } = this.props
    quote.qlines[index] = qline
    if (qline.destroyed) {
      const qlines         = quote.qlines.filter(qline => !qline.destroyed)
      const destroyedLines = quote.qlines.filter(qline => qline.destroyed)
      quote.qlines = [...qlines, ...destroyedLines]
    }
    setSubTotals(quote)
    replaceQuote(quote)
  }

  addLine(type, index) {
    const { quote, replaceQuote } = this.props
    let   buffer   = quote.qlines
    const previous = buffer.splice(0, index)
    const qline    = buildQline(type, quote)
    quote.qlines = [...previous, qline, ...buffer]
    setSubTotals(quote)
    replaceQuote(quote)
  }

  addGroup(group, amount, index) {
    const { quote, replaceQuote } = this.props
    // Set lines as inactive & it's vat rate
    group.forEach(line => {
      if (line.type == "subtotal") {
        line.quantity = amount
      } else if (line.quantity) {
        line.quantity = line.quantity * amount
      }

      line.active   = false
      line.vat_rate = quote.site ? quote.site.vat_rate : {}
      line.discount = quote.client.discount
    })
    let buffer = quote.qlines
    // Remove the actual group line
    buffer.splice(index, 1)
    // Divide qlines in 2 arrays, between the index
    const previous = buffer.splice(0, index)
    quote.qlines = [...previous, ...group, ...buffer]
    setSubTotals(quote)
    replaceQuote(quote)
  }

  addFromCsv(new_lines, index) {
    const { quote, replaceQuote } = this.props
    new_lines.forEach(line => line.active = false)
    let buffer = quote.qlines
    // Remove the actual group line
    buffer.splice(index, 1)
    // Divide qlines in 2 arrays, between the index
    const previous = buffer.splice(0, index)
    quote.qlines = [...previous, ...new_lines, ...buffer]
    setSubTotals(quote)
    replaceQuote(quote)
  }

  addTemplate(template) {
    const { quote, replaceQuote } = this.props
    template.qlines.forEach(line => {
      line.active   = false
      line.vat_rate = quote.site ? quote.site.vat_rate : {}
    })

    replaceQuote({
      ...quote,
      title:        template.title,
      qlines:       template.qlines,
      observations: template.observations
    })
  }

  render() {
    const { quote, replaceQuote } = this.props

    const qlines         = quote.qlines.filter(qline => !qline.destroyed)
    const destroyedLines = quote.qlines.filter(qline => qline.destroyed)
    const calculations   = quoteCalculations(quote)

    const lineProps = {
      showCode:     quote.account.show_code,
      showMargin:   quote.show_margin,
      vats:         quote.account.vat_rates,
      blueprints:   quote.account.blueprints,
      groupProps: {
        groups:   quote.account.line_groups,
        addGroup: this.addGroup,
      },
      templateProps: {
        templates:   quote.account.templates,
        addTemplate: this.addTemplate,
        showConfirm: qlines.length > 1
      },
      buttonProps: {
        addLine:       this.addLine,
        replaceLine:   this.replaceLine,
        moveLine:      this.moveLine,
        allowCsvLine:  quote.account.csv_qlines,
        allowProducts: quote.account.allow_products,
        new_record:    quote.new_record
      },
      addFromCsv:     this.addFromCsv
    }

    return(
      <React.Fragment>
        <div className="quotes--form-table">

          { quote.show_table_header &&
            <div className="quotes--form-table-header">
              <div className="quotes--form-table-row">
                { quote.account.show_code
                  ? <div className="code-column">
                      {I18n.t("quotes.pdf.service")}
                    </div>
                  : ""
                }
                <div className="description-column">
                  {I18n.t("quotes.pdf.description")}
                </div>
                <div className="price-column">
                  {I18n.t("quotes.pdf.price")}
                </div>
                <div className="amount-column">
                  {I18n.t("quotes.pdf.quantity")}
                </div>
                <div className="unit-column">
                  {I18n.t("quotes.pdf.unit")}
                </div>
                <div className="vat-column">
                  {I18n.t("quotes.pdf.vat")}
                </div>
                <div className="total-column">
                  {I18n.t("quotes.pdf.hvta")}
                </div>
              </div>
            </div>
          }

          <QlinesContainer
            quote        = {quote}
            qlines       = {qlines}
            lineProps    = {lineProps}
            onSortEnd    = {this.changeOrder}
            pressDelay   = {200}
            lockAxis     = "y"
          />

          {
            destroyedLines.map((qline, index) =>
              <Destroyed
                key   = {index}
                index = {qlines.length + index}
                qline = {qline}
              />
            )
          }
        </div>

        <div className="quotes--form-table-footer">
          <AddButtons
            quote   = {quote}
            onClick = {this.addLine}
            index   = {qlines.length}
          />

          <Totals
            quote        = {quote}
            calculations = {calculations}
            replaceQuote = {replaceQuote}
          />
        </div>
      </React.Fragment>
    )
  }
}
