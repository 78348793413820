import React, { useState, useEffect, useRef } from 'react';
const FontSize       = window.Quill.import('attributors/style/size')
const FontStyle      = window.Quill.import('attributors/style/font')
const AlignStyle     = window.Quill.import('attributors/style/align')

const fontSizes    = ['10pt', '8pt', '9pt', '12pt', '14pt']
FontSize.whitelist = fontSizes

window.Quill.register(FontSize,       true)
window.Quill.register(AlignStyle,     true)
window.Quill.register(FontStyle,      true)

const normalToolbar = [
  [{ 'size': fontSizes }],
  ['bold', 'italic', 'underline', {'color': []}],
  [{ 'align': null}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
  [{'list': 'ordered'}, {'list': 'bullet'}],
  ['clean', 'link']
]

const blogToolbar = [
  [{ 'font': [] }, { 'size': fontSizes }],
  [ 'bold', 'italic', 'underline', 'strike' ],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'script': 'super' }, { 'script': 'sub' }],
  [{ 'header': ['1', '2', '3', '4', '5', '6'] }, 'blockquote', 'code-block' ],
  [{ 'list': 'ordered' }, { 'list': 'bullet'}],
  [ 'direction', { 'align': [] }],
  [ 'link', 'image', 'video', 'formula' ],
  [ 'clean' ]
]

const toolbar = config => config == "blog" ? blogToolbar : normalToolbar

const QuillInput = props => {
  const {
    text,
    name,
    onChange,
    config
  } = props

  const quillRef    = useRef(null)
  const didMountRef = useRef(false)
  const onChangeRef = useRef();
  onChangeRef.current = onChange;

  const [value, setValue] = useState(null)

  useEffect(() => {
    const quill = new window.Quill(quillRef.current, {
      theme: 'snow',
      modules: {
        toolbar:   toolbar(config),
        clipboard: true,
        history:   true,
        keyboard:  true
      }
    })

    quill.clipboard.dangerouslyPasteHTML(text)
    quill.on('text-change', (_delta, _oldDelta, source) => {
      if (source == 'user') {
        const content = quill.getSemanticHTML().replaceAll("<p></p>", "<p><br></p>")
        if (onChangeRef.current) {
          onChangeRef.current(content);
        }
        setValue(content)
      }
    })
    didMountRef.current = true
  }, [])

  useEffect(() => {
    if (didMountRef.current && text != value) {
      const quill = window.Quill.find(quillRef.current)
      quill.clipboard.dangerouslyPasteHTML(text)
      setValue(text)
    }
  }, [text])

  return (
    <>
      <div ref = {quillRef} />
      <textarea
        value = {value || ''}
        name  = {name}
        hidden
        readOnly
      />
    </>
  ) 
};

export default QuillInput;
