import React, { useState } from "react"
import SharedFlatpickr     from "@shared/flatpickr"
import Combobox            from '@shared/combobox'

const maxPassages = 20
const formatDate  = date    => moment(date).format("DD/MM/YYYY - HH:mm")
const getDuration = duration => {
  const hours   = duration / 60
  const minutes = duration % 60
  const date = moment().startOf('day').hour(hours).minute(minutes)
  return date.format("HH:mm")
}
const diffPassage = (p1, p2) => p1.number.split(".")[0] != p2.number.split(".")[0]

const Passage = props => {
  const {
    technicians,
    index,
    passages,
    setPassages
  } = props

  const passage   = passages[index]
  const marginTop = !passage.workorder_id && passage.number != "1.1"
  let   minDate   = moment().startOf('day').toDate()
  if(index > 0 && passage.editable) {
    const prevPassages = passages.slice(0, index).filter(p =>
      p.editable && !p.workorder_id && diffPassage(p, passage)
    )
    const prevPassage = prevPassages[prevPassages.length - 1]
    if(prevPassage) {
      const duration = prevPassage["predicted_duration"]
      minDate = moment(prevPassage.date_planned).add(duration, "minutes").toDate()
    }
  }

  const inputName    = attribute =>
  `parent_workorder[workorders_attributes][${index}][${attribute}]`

  const onChangeDate = dates => {
    const date_planned = dates[0]
    if(passage.workorder_id) {
      setPassages(passages.map(p => p.id != passage.id ? p : {
        ...passage,
        date_planned
      }))
    } else {
      let date = moment(date_planned)
      setPassages(passages.map((p, i) => {
        if (i < index)  return p
        if (i == index) return {...p, date_planned}

        if(!p.workorder_id) {
          date.add(1, 'days')
          switch(date.day()) {
            case 6: date.add(1, 'days')
            case 0: date.add(1, 'days')
          }
        }
        return {...p, date_planned: date.toDate()}
      }))
    }
  }

  const onChangeTechnician = technician => {
    setPassages(passages.map(p => p.id != passage.id ? p : {
      ...passage,
      technician
    }))
  }

  const onChangePredictedDuration = dates => {
    const date = dates[0]
    const predicted_duration = date.getHours() * 60 + date.getMinutes()
    setPassages(passages.map(p => p.id != passage.id ? p : {
      ...passage,
      predicted_duration
    }))
  }

  return(
    <div key={passage.id} className="g-row">
      <input hidden defaultValue={passage.id} name={inputName("id")} />
      <div className={marginTop ? 'mt-4' : ''}>
        {passage.number}
      </div>

      <div className={marginTop ? 'mt-4' : ''}>
        {
          passage.editable
          ? <SharedFlatpickr
              options={{
                locale:     I18n.locale,
                enableTime: true,
                dateFormat: "d/m/Y - H:i",
                minDate   : minDate
              }}
              name        = {inputName("date_planned")}
              className   = "form-control flatpickr-nobg text-center"
              placeholder = {I18n.t("workorders.datetime_placeholder")}
              onChange    = {onChangeDate}
              value       = {formatDate(passage.date_planned)}
              required
            />
          : <input
              className    = "form-control text-center"
              defaultValue = {formatDate(passage.date_planned)}
              disabled
            />
        }

      </div>

      <div className={marginTop ? 'mt-4' : ''}>
        { passage.editable
          ? <SharedFlatpickr
              options={{
                locale:     I18n.locale,
                enableTime: true,
                noCalendar: true
              }}
              className = "form-control flatpickr-nobg text-center"
              onChange  = {onChangePredictedDuration}
              value     = {getDuration(passage.predicted_duration)}
            />
          : <input
              className    = "form-control text-center"
              defaultValue = {getDuration(passage.predicted_duration)}
              disabled
            />
        }

        { passage.editable &&
          <input
            name     = {inputName("predicted_duration")}
            value    = {passage.predicted_duration}
            onChange = {() => {}}
            hidden
          />
        }

      </div>

      <div className={marginTop ? 'mt-4' : ''}>
        { passage.editable
          ? <Combobox
              display         = "name"
              fieldName       = {inputName("technician_id")}
              options         = {technicians}
              value           = {passage.technician?.id}
              onSelectElement = {onChangeTechnician}
              prompt          = {I18n.t("open")}
            />
          : <input
              className    = "form-control"
              defaultValue = {passage.technician.name}
              disabled
            />
        }

      </div>
    </div>
  )
}

const EditPassages = props => {
  const [passages, setPassages] = useState(props.passages)

  return(
    <div className="d-grid" style={{ gridTemplateColumns: "1fr 3fr 3fr 3fr"}}>
      <div className="g-header g-header__sticky">
        <div></div>
        <div>{I18n.t("workorders.date_planned")}</div>
        <div>{I18n.t("workorders.predicted_duration")}</div>
        <div>{I18n.t("workorders.technician")}</div>
      </div>

      { passages.map((passage, index) =>
          <Passage
            key         = {index}
            technicians = {props.technicians}
            index       = {index}
            passages    = {passages}
            setPassages = {setPassages}
          />
        )
      }
    </div>
  )
}

export default EditPassages
