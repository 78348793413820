import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Components
import Destroyed  from "./line_destroyed"
import Buttons    from "./shared/buttons"

// Helpers
import { formRequest } from '@helpers/javascript/javascript'
import { PATHS }       from '@helpers/javascript/config/CONSTANTS'

const ImportCsv = props => {
  const { index, quote, qline, addFromCsv, buttonProps } = props
  const [uploading, setUploading] = useState(false)
  const [filename, setFilename]   = useState(null)
  const [showHelp, setShowHelp]   = useState(false)
  const fileInput                 = useRef(null)

  const handleCsvUpload = () => {
    const file = fileInput.current.files && fileInput.current.files[0];
    if (!file) return

    const form = new FormData()
    form.append("currency", quote.currency.iso_code)
    form.append("files[]", file, file.name)
    setUploading(true)

    const uploadUrl = `/quotes/form/lines_from_csv.json`
    formRequest({ url: uploadUrl, form })
      .then(result => {
        addFromCsv(result.lines, index)
        setUploading(false)
        if (result.notice) alert(result.notice)
      })
      .catch(error => {
        console.error(error)
        alert(error)
        onCancel()
      })
  }

  const onCancel = () => {
    fileInput.current.value = null
    setFilename(null)
    setUploading(false)
  }

  return(
    <div className="quotes--form-qline-container">
      <div className="import-csv">

        {/* File input field */}
        <input type="file"
               accept=".csv"
               hidden
               ref={fileInput}
               onChange={() => setFilename(fileInput.current.files[0].name)}
        />

        {
          !filename && !uploading &&
          <>
            {/* Select file button */}
            <div className="file-input-button"
                 onClick={() => fileInput.current.click()}
            >
              {"+  " + I18n.t("quotes.new.select_file")}
            </div>
            {/* File structure help */}
            <div className="ml-2"
                 tooltip={I18n.t("quotes.new.select_file_help")}
                 onClick={() => setShowHelp(!showHelp)}
            >
              <small><FontAwesomeIcon icon="info-circle" /></small>
            </div>
          </>
        }

        {
          showHelp && fileStructureHelp(qline)
        }

        {/* File selected & action buttons */}
        {
          filename && !uploading &&
          <>
            <div className="filename">{I18n.t("quotes.new.selected_file")} <b>{filename}</b></div>
            <div className="action-buttons">
              {/* Cancel */}
              <div className=""
                   tooltip={I18n.t("button.cancel")}
                   onClick={onCancel}
              >
                <FontAwesomeIcon
                  className="check-circle"
                  icon="times-circle"
                />
              </div>

              {/* Upload button */}
              <div className=""
                   tooltip={I18n.t("button.apply")}
                   onClick={handleCsvUpload}
              >
                <FontAwesomeIcon
                  className="check-circle"
                  icon="check-circle"
                />
              </div>
            </div>
          </>
        }

        {
          uploading &&
          <div className="loading"><div className="dot-elastic"></div></div>
        }

      </div>

      <Buttons
        index={index}
        qline={qline}
        buttonProps={buttonProps}
      />

      <Destroyed
        index={index}
        qline={qline}
      />
    </div>
  )
}

const fileStructureHelp = (qline) => {
  return(
    <div className="file-structure-info">
      <div className="css-grid-container">

        <div className="css-grid w-100" style={{gridTemplateColumns: '3fr 4fr 2.5fr 1fr'}}>
          {/* header */}
          <div className="header">
            <div className="header-cell text-left">
              {I18n.t("import.table_headers.field")}
            </div>

            <div className="header-cell text-left">
              {I18n.t("import.table_headers.explanation")}
            </div>

            <div className="header-cell text-left">
              {I18n.t("import.table_headers.type")}
            </div>

            <div className="header-cell text-center">
              {I18n.t("import.table_headers.mandatory")}
            </div>
          </div>

          {/* rows */}
          { Object.entries(qline.csv_table).map(([key, data], i) =>
              <div className="row text-left" key={i}>
                <div className="row-cell with-padding text-left">
                   {data.name}
                </div>

                <div className="row-cell with-padding text-left">
                   {data.description}
                </div>

                <div className="row-cell with-padding text-left">
                  <FontAwesomeIcon icon={data.type.icon} />
                  {data.type.text}
                </div>

                <div className="row-cell with-padding text-left">
                  { data.required && <FontAwesomeIcon icon='check' /> }
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default ImportCsv
