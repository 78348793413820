import React, { useState, useEffect } from "react"
/*
  data:            Array of objects. Identifier and shown value. Ex: [{ name: 'Site', id: '723', checked: true }]
  identifier:      String. Used to find the identifier which is the key in the data object . In the previous example, it's 'id'
  shownValue:      String. Used to know what to display. It's the key in the data object. In the previous example it's 'name'
*/

const Checklist = ({ data = [], shownValue, checklistKey, onSelect }) => {

  const [checkboxes, setCheckboxes] = useState(data)

  useEffect(() => setCheckboxes(data), [data])

  const toggleValue = index => {
    const newCheckboxes = checkboxes.map((check, i) => i != index ? check : {
      ...check,
      checked: !check.checked
    })
    setCheckboxes(newCheckboxes)
    if(onSelect) onSelect(newCheckboxes)
  }

  return (
    <>
      {checkboxes.map((check, key) => {
        return (
          <div key={key} className="checkbox form-group checkbox-primary">
            <input
              id             = {`${checklistKey}-${key}`}
              type           = "checkbox"
              defaultChecked = {check.checked}
              onChange       = {() => toggleValue(key)}
            />
            <label htmlFor={`${checklistKey}-${key}`} className="control-label">
              {check[shownValue]}
            </label>
          </div>
        )
      })}
    </>
  )
}

export default Checklist
