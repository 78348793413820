import { getRequest }      from '@helpers/javascript/javascript'
import { addNoticeAction } from '@redux/notices/actions'

// Action dispatch
export const duplicateFormAction = (workorderId, formKey, formId) => dispatch => {

  const url = `/interventions_blueprints/${formId}/prefill`

  getRequest(url)
    .then(data => {
      dispatch({
        type: DUPLICATE_CUSTOM_FORM.ACTION,
        payload: { workorderId, formKey, data }
      })
      dispatch(addNoticeAction({ message: I18n.t('interventions.prefill_form_confirmation'), type: 'notice' }))
    })
}

// Reducer response
export const DUPLICATE_CUSTOM_FORM = {
  ACTION: 'DUPLICATE_CUSTOM_FORM',

  call: ({ state, id, formKey, newForm }) => {
    const workorder = state.items.find(wo => wo.id === id)
    const forms = [...workorder.custom_forms_answers]
    forms.splice(formKey, 1, newForm)

    const editedWorkorder = {
      ...workorder,
      custom_forms_answers: forms,
      synced: false
    }

    const workorders = state.items.map(wo => wo.id === id ? editedWorkorder : wo)
    return { ...state, items: workorders }
  }
}
