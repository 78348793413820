export const updateTaskAction = payload => (
  dispatch => dispatch({
    type:     UPDATE_TASK.ACTION,
    payload:  payload
  })
)

// Reducer response
export const UPDATE_TASK = {
  ACTION: 'UPDATE_TASK',

  call: ({ state, workorderId, task }) => ({
    ...state,
    items: state.items.map(workorder => workorder.id != workorderId ? workorder : {
      ...workorder,
      synced: false,
      maintenance_tasks: workorder.maintenance_tasks.map(t => t.id == task.id ? task : t)
    })
  })
}
