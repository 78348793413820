import React from "react"
import Base  from "./base"

export default class Site extends Base {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    e.preventDefault()
    const quote  = this.props.quote
    const clientSites  = quote.client.sites
    const site = clientSites.find(c_s => c_s.id == e.target.value)

    if (site?.id) {
      this.deactivate()
      quote.qlines.forEach(qline => qline.vat_rate = site.vat_rate)
      this.props.replaceQuote({...quote, site: site, qlines: quote.qlines})
    } else {
      this.props.replaceQuote({...quote, site: {}})
    }
  }

  render() {
    const { quote, replaceQuote } = this.props
    const client = quote.client
    const site   = quote.site

    return(
      this.state.active || !site.id
      ? <div className="form-group">
          <label>{I18n.t("workorders.site")}</label>

          <select
            className = "form-control"
            name      = "quote[site_id]"
            value     = {site.id || ""}
            onChange  = {this.onChange}
            onBlur    = {this.deactivate}
            disabled  = {!client.id}
            required
          >
            <option value="">{I18n.t("workorders.select_site")}</option>
            {client.sites.map(clientSite =>
              <option value={clientSite.id} key={clientSite.id}>
                {clientSite.name}
              </option>
            )}
          </select>
        </div>
      : <div className="quotes--form-pointer pb-0" onClick={this.activate}>
          <div className="quotes--form-title">
            {I18n.t("workorder_pdf.site")}
          </div>
          <div>{site.name}</div>
          <div>{site.address}</div>
          <input type="hidden" name="quote[site_id]" defaultValue={quote.site.id} />
        </div>
    )
  }
}
