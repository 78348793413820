// Offline Actions
import { WORKORDER_DEPARTURE }        from './actions/depart'
import { WORKORDER_START }            from './actions/start'
import { WORKORDER_PAUSE }            from './actions/pause'
import { WORKORDER_RESUME }           from './actions/resume'
import { WORKORDER_STOP  }            from './actions/stop'
import { WORKORDER_DELIVER }          from './actions/deliver'
import { WORKORDER_TIMELESS }         from './actions/timeless'
import { WORKORDER_CLOSE }            from './actions/close'
import { WORKORDER_SYNC  }            from './actions/sync'
import { WORKORDER_RETRY }            from './actions/retry'
import { WORKORDER_UPDATE_REPORT }    from './actions/updateReport'
import { WORKORDER_UPDATE_SIGNER }    from './actions/updateSigner'
import { WORKORDER_UPDATE_SIGNATURE } from './actions/updateSignature'
import { WORKORDER_TIME }             from './actions/updateTime'
import { WORKORDERS_TODAY }           from './actions/today'
import { WORKORDERS_STORE }           from './actions/store'

import { ADD_PHOTO }                  from './actions/photos/add'
import { UPDATE_PHOTO }               from './actions/photos/update'
import { REMOVE_PHOTO }               from './actions/photos/remove'

import { ADD_CUSTOM_FORM }            from './actions/forms/add'
import { REMOVE_CUSTOM_FORM }         from './actions/forms/remove'
import { DUPLICATE_CUSTOM_FORM }      from './actions/forms/duplicate'

import { ADD_READING }                from './actions/readings/add'
import { REMOVE_READING }             from './actions/readings/remove'

import { UPDATE_TASK }                from './actions/tasks/update'

// TODO ACTIONS
import { WORKORDER_FETCH      }       from './actions/fetch'
import { WORKORDER_FETCH_PAGE }       from './actions/fetchPage'
import { WORKORDER_REFRESH }          from './actions/refresh'
import { ADD_WORKORDER }              from './actions/addWorkorder'


const initialState = {
  items:             [],
  today_workorders:  [],
  absences:          [],
  recap:             null,
  total_pages:       null,
  total_entries:     null,
  loading:           false,
  loadingNext:       false
}

export default function workorderReducer(state = initialState, action) {
  switch (action.type) {

    // SET TODAY WORKORDER IF TODAY AND OFFLINE
    case WORKORDERS_TODAY.SET:
      return WORKORDERS_TODAY
        .setResponse({ state })

    // FETCH WORKORDERS
    case WORKORDER_FETCH.CLEAR:
      return WORKORDER_FETCH
        .clearResponse({ state })

    case WORKORDER_FETCH.BEGIN:
      return WORKORDER_FETCH
        .beginResponse({ state })

    case WORKORDER_FETCH.SUCCESS:
      return WORKORDER_FETCH
        .successResponse({
          state: state,
          workorders: action.payload.workorders,
          today_workorders: action.payload.today_workorders,
          absences: action.payload.absences,
          recap: action.payload.recap,
          total_entries: action.payload.total_entries,
          total_pages: action.payload.total_pages })

    case WORKORDER_FETCH_PAGE.BEGIN:
      return WORKORDER_FETCH_PAGE
        .beginResponse({state})

    case WORKORDER_FETCH_PAGE.SUCCESS:
      return WORKORDER_FETCH_PAGE
        .successResponse({
          state: state,
          workorders:    action.payload.workorders,
          total_entries: action.payload.total_entries,
          total_pages:   action.payload.total_pages
        })

    case WORKORDER_REFRESH.ACTION:
      return WORKORDER_REFRESH
        .call({
          state:     state,
          workorder: action.payload.workorder,
        })

    // Add show workorder
    case ADD_WORKORDER.ACTION:
      return ADD_WORKORDER
        .call({
          state:     state,
          workorder: action.payload.workorder,
        })

    // Store workorder for offline usage
    case WORKORDERS_STORE.ACTION:
      return WORKORDERS_STORE
        .call({
          state:     state,
          workorder: action.payload.workorder
        })

    // START WORKORDER TRAVEL
    case WORKORDER_DEPARTURE.ACTION:
      return WORKORDER_DEPARTURE.call({
        state: state,
        id:    action.payload.id
      })

    // START WORKORDER
    case WORKORDER_START.ACTION:
      return WORKORDER_START.call({
        state:           state,
        id:              action.payload.id,
        proximity:       action.payload.proximity_start
      })

    // PAUSE WORKORDER
    case WORKORDER_PAUSE.ACTION:
      return WORKORDER_PAUSE
        .call({
          state: state,
          id:    action.payload.id
        })

    // RESUME WORKORDER
    case WORKORDER_RESUME.ACTION:
      return WORKORDER_RESUME
        .call({
          state: state,
          id:    action.payload.id
        })

    // STOP WORKORDER
    case WORKORDER_STOP.ACTION:
      return WORKORDER_STOP
        .call({
          state:          state,
          id:             action.payload.id,
          proximity:      action.payload.proximity_stop
        })

    // DELIVER WORKORDER
    case WORKORDER_DELIVER.ACTION:
      return WORKORDER_DELIVER
        .call({
          state: state,
          id:    action.payload.id
        })

    // COMPLETE WORKORDER
    case WORKORDER_TIMELESS.START:
      return WORKORDER_TIMELESS
        .start({
          state: state,
          id:    action.payload.id
        })

    case WORKORDER_TIMELESS.CLOSE:
      return WORKORDER_TIMELESS
        .close({
          state: state,
          id:    action.payload.id
        })

    // CLOSE WORKORDER
    case WORKORDER_CLOSE.ACTION:
      return WORKORDER_CLOSE
        .call({
          state:   state,
          id:      action.payload.id,
          history: action.payload.history,
          shouldRedirect: action.payload.shouldRedirect
        })

    // Update report
    case WORKORDER_UPDATE_REPORT.ACTION:
      return WORKORDER_UPDATE_REPORT
        .call({
          state:     state,
          id:        action.payload.id,
          newReport: action.payload.newReport
        })

    // Update signer
    case WORKORDER_UPDATE_SIGNER.ACTION:
      return WORKORDER_UPDATE_SIGNER
        .call({
          state:     state,
          id:        action.payload.id,
          newSigner: action.payload.newSigner
        })

    // Update signature
    case WORKORDER_UPDATE_SIGNATURE.ACTION:
      return WORKORDER_UPDATE_SIGNATURE
        .call({
          state:        state,
          id:           action.payload.id,
          newSignature: action.payload.newSignature
        })

    // Update time (date_started, paused, done)
    case WORKORDER_TIME.ACTION:
      return WORKORDER_TIME
        .call({
          state:     state,
          id:        action.payload.id,
          line:      action.payload.line,
          value:     action.payload.value
        })

    // Photos
    case ADD_PHOTO.ACTION:
      return ADD_PHOTO
        .call({
          state:             state,
          workorder:         action.payload.workorder,
          base64:            action.payload.base64,
          isPurchaseReceipt: action.payload.isPurchaseReceipt
        })

    case UPDATE_PHOTO.LEGEND:
      return UPDATE_PHOTO
        .updateLegend({
          state:   state,
          id:      action.payload.id,
          version: action.payload.version,
          legend:  action.payload.legend
        })

    case UPDATE_PHOTO.MATERIAL:
      return UPDATE_PHOTO
        .updateMaterial({
          state:    state,
          id:       action.payload.id,
          version:  action.payload.version,
          material: action.payload.material
        })

    case UPDATE_PHOTO.IMAGE:
      return UPDATE_PHOTO
        .updateImage({
          state:   state,
          id:      action.payload.id,
          version: action.payload.version,
          image:   action.payload.image
        })

    case REMOVE_PHOTO.ACTION:
      return REMOVE_PHOTO
        .call({
          state:   state,
          id:      action.payload.id,
          version: action.payload.version
        })

    // Custom forms
    case ADD_CUSTOM_FORM.ACTION:
      return ADD_CUSTOM_FORM
        .call({
          state: state,
          id:    action.payload.id,
          form:  action.payload.form
        })

    case DUPLICATE_CUSTOM_FORM.ACTION:
      return DUPLICATE_CUSTOM_FORM
        .call({
          state:   state,
          id:      action.payload.workorderId,
          formKey: action.payload.formKey,
          newForm: action.payload.data
        })

    case REMOVE_CUSTOM_FORM.ACTION:
      return REMOVE_CUSTOM_FORM
        .call({
          state:   state,
          id:      action.payload.id,
          formKey: action.payload.formKey
        })

    // READINGS
    case ADD_READING.ACTION:
      return ADD_READING
        .call({...action.payload, state: state})

    case REMOVE_READING.ACTION:
      return REMOVE_READING
        .call({...action.payload, state: state})

    // CHANGE MAINTENANCE TASK
    case UPDATE_TASK.ACTION:
      return UPDATE_TASK
        .call({...action.payload, state: state})

    // SYNC WORKORDER
    case WORKORDER_SYNC.LOCK:
      return WORKORDER_SYNC
        .lockResponse({
          state: state,
          workorder: action.payload.workorder
        })

    case WORKORDER_SYNC.BEGIN:
      return WORKORDER_SYNC
        .beginResponse({
          state: state,
          workorder: action.payload.workorder
        })

    case WORKORDER_SYNC.SUCCESS:
      return WORKORDER_SYNC
        .successResponse({
          state:     state,
          workorder: action.payload.workorder
        })

    case WORKORDER_SYNC.RETRY:
      return WORKORDER_SYNC
        .retryResponse({
          state:     state,
          workorder: action.payload.workorder
        })

    case WORKORDER_SYNC.FAILURE:
      return WORKORDER_SYNC
        .failureResponse({
          state:     state,
          workorder: action.payload.workorder,
          error:     action.payload.error
        })

    case WORKORDER_RETRY.ACTION:
      return WORKORDER_RETRY
        .call({
          state: state,
          id:    action.payload.id
        })

    default: {
      return state
    }
  }
}
