// Dispatch
export const updatePhotoLegendAction = (id, version, legend) => dispatch =>
  dispatch({
    type:    UPDATE_PHOTO.LEGEND,
    payload: { id, version, legend }
  })

export const updatePhotoMaterialAction = (id, version, material) => dispatch =>
  dispatch({
    type:    UPDATE_PHOTO.MATERIAL,
    payload: { id, version, material }
  })

export const updatePhotoImageAction = (id, version, image) => dispatch =>
  dispatch({
    type:    UPDATE_PHOTO.IMAGE,
    payload: { id, version, image }
  })

// Reducer response
export const UPDATE_PHOTO = {
  LEGEND:   'UPDATE_PHOTO_LEGEND',
  MATERIAL: 'UPDATE_PHOTO_MATERIAL',
  IMAGE:    'UPDATE_PHOTO_IMAGE',

  updateLegend: ({ state, id, version, legend }) => ({
    ...state,
    items: state.items.map(workorder => workorder.id != id ? workorder : {
      ...workorder,
      synced: false,
      photos: workorder.photos.map(photo => photo.version != version ? photo : {
        ...photo,
        synced: false,
        legend
      })
    })
  }),

  updateMaterial: ({ state, id, version, material }) => ({
    ...state,
    items: state.items.map(workorder => workorder.id != id ? workorder : {
      ...workorder,
      synced: false,
      photos: workorder.photos.map(photo => photo.version != version ? photo : {
        ...photo,
        synced: false,
        material
      })
    })
  }),

  updateImage: ({ state, id, version, image }) => ({
    ...state,
    items: state.items.map(workorder => workorder.id != id ? workorder : {
      ...workorder,
      synced: false,
      photos: workorder.photos.map(photo => photo.version != version ? photo : {
        ...photo,
        synced: false,
        photo: image
      })
    })
  })
}
