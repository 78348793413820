// Actions
export const storeWorkorderAction = workorder => dispatch => dispatch({
    type: WORKORDERS_STORE.ACTION,
    payload: { workorder }
})

// Reducer response
export const WORKORDERS_STORE = {
  ACTION: 'WORKORDERS_STORE',
  call: ({ state, workorder }) => {
    let workorders = [...state.today_workorders]
    const index = state.today_workorders.findIndex(wo => wo.id == workorder.id)
    if (index < 0) {
      workorders = [...workorders, workorder]
    } else {
      workorders[index] = workorder
    }

    return {
      ...state,
      today_workorders: workorders
    }
  }
}
