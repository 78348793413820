import { NOTIFICATIONS_TOGGLE }   from './actions/toggle'
import { NOTIFICATIONS_FETCH }    from './actions/fetch'
import { NOTIFICATIONS_MARK }     from './actions/mark'
import { NOTIFICATIONS_MARK_ALL } from './actions/markAll'
import { NOTIFICATIONS_COUNT }    from './actions/updateCount'

const initialState = {
  items: [],
  display: false,
  error: null,
  unread: 0
}

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {

  case NOTIFICATIONS_TOGGLE.CLICK:
    return NOTIFICATIONS_TOGGLE
      .clickResponse({
        state: state,
        display: action.payload.display,
      })

  case NOTIFICATIONS_FETCH.ACTION:
    return NOTIFICATIONS_FETCH
      .call({
        state: state,
        items: action.payload.notifications.items,
        unread: action.payload.notifications.unread
      })

  case NOTIFICATIONS_MARK.BEGIN:
    return NOTIFICATIONS_MARK
      .beginResponse({ state: state })

  case NOTIFICATIONS_MARK.SUCCESS:
    return NOTIFICATIONS_MARK
      .successResponse({
        state:        state,
        notification: action.payload.notification
      })

  case NOTIFICATIONS_MARK_ALL.SUCCESS:
    return NOTIFICATIONS_MARK_ALL
      .successResponse({ state: state })

  case NOTIFICATIONS_COUNT.UPDATE:
    return NOTIFICATIONS_COUNT
      .updateResponse({
        state: state,
        unread: action.payload.newCount
      })

  default:
    return state
  }
}
