import React, { useState, useEffect } from "react"
import { hourToString } from '@helpers/javascript/date_helpers'
import { ResponsiveBar } from '@nivo/bar'
import { BoxLegendSvg } from "@nivo/legends";

export const DailyTechTime = ({ data }) => {
  const STATUS_HEIGHT = 400

  const BarLegend = ({ height, legends, width }) => (
    <div className="dashboard__tootip--bold">
      {legends.map(legend => (
        <BoxLegendSvg
          key={JSON.stringify(legend.data.map(({ id }) => id))}
          {...legend}
          containerHeight={height}
          containerWidth={width}
        />
      ))}
    </div>
  )
  const colors   = { 'travel_time': "#9EDCF5", 'intervention_time': "#2C98FF", 'paused_time': "#f7f06d" }
  const keys     = ['travel_time', 'intervention_time', 'paused_time']

  const [total, setTotal] = useState(0)
  useEffect(() => {
    const currentTotal = data.map((v) => v.travel_time + v.paused_time + v.intervention_time).reduce((a, b) => a + b, 0)
    setTotal(currentTotal)
  }, [data])

  return (
    <div className="dashboard__card dashboard__daily--time" style={{ height: `${STATUS_HEIGHT}px` }}>
      { total === 0
        ? <div className="dashboard__card--empty">{I18n.t('dashboard.no_data')}</div>
        : <ResponsiveBar
          data        = {data}
          keys        = {keys}
          indexBy     = "technician"
          margin      = {{ top: 0, right: 80, bottom: 120, left: 80 }}
          padding     = {0.3}
          colors      = {bar => colors[bar.id]}
          borderColor = {{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisBottom  = {{
            tickRotation: -30,
            legend: '',
            legendPosition: 'start',
            legendOffset: 100
          }}
          axisLeft    = {{
            legend: I18n.t('dashboard.hours'),
            legendPosition: 'middle',
            legendOffset: -60
          }}
          labelTextColor = {{ from: 'color', modifiers: [['darker', 1.6]] }}
          layers         = {["grid", "axes", "bars", "markers", BarLegend]}
          tooltip        = {props => (
            <div>
              {props.id === 'travel_time' ? I18n.t('dashboard.travel_time') : props.id === 'intervention_time' ? I18n.t('dashboard.intervention_time') : I18n.t('dashboard.paused_time')} — {hourToString(props.value)}
            </div>
          )}
          // legends = {[{
          //     dataFrom: 'keys',
          //     anchor:   'bottom-right',
          //     data: keys.map((id, _index) => ({
          //       id,
          //       color: colors[id],
          //       label: id === 'travel_time' ? I18n.t('dashboard.travel_time') : id === 'intervention_time' ? I18n.t('dashboard.intervention_time') : I18n.t('dashboard.paused_time')
          //     })),
          //     effects: [{
          //         on:    'hover',
          //         style: { itemOpacity: 1 }
          //     }]
          // }]}
        />
      }
    </div>
  )
}
