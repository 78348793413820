import * as Sentry from '@sentry/react';

// Redux basics
import { createStore, compose, applyMiddleware } from 'redux'

// Async fetch
import thunk from 'redux-thunk'

// Offline
import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig     from '@redux-offline/redux-offline/lib/defaults';
import defaultQueue      from '@redux-offline/redux-offline/lib/defaults/queue';

// Browser history
// import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware }     from 'connected-react-router'

// Import the root reducer
import rootReducer     from './mainReducer'
import { HEADERS }     from '@helpers/javascript/config/CONSTANTS'
// Add error redux action
import { addNoticeAction } from '@redux/notices/actions'

// REDUX DEV TOOLS
let composeEnhancers
if (process.env.NODE_ENV === 'development' || 'staging' || 'review') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose
} else {
  composeEnhancers = compose
}

let initialState = {}

// Add Sentry to Redux
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

// History Router middleware
export const history = createBrowserHistory()
const reduxRouterMiddleware = routerMiddleware(history)

// Offline configuration
const reduxOfflineConfiguration = {
  ...offlineConfig,
  persistOptions: {
    blacklist: ['modale', 'errors', 'sidemenu', 'filters'],
  },

  queue: {
    ...defaultQueue,
    enqueue(outBox, action)  {
      if (action.type === "WORKORDER_SYNC_BEGIN") {
        const workorder = action.meta.offline.effect.json.workorder
        const queueIndex = outBox.findIndex(req => req.workorder_id === workorder.id)
        if (queueIndex >= 0) {
          outBox[queueIndex] = action
          return outBox
        }
      }
      return [...outBox, action]
    }
  },

  effect: (effect, _action) => {
    return fetch(effect.url,
      {
        method: effect.method,
        headers: HEADERS,
        credentials: 'same-origin',
        body: JSON.stringify(effect.json)
      }
    ).then(response => {
      if (!response.ok) { return Promise.reject(response) }
      return response.json()
    }).catch(async response => {
      const error = await response.json().then(text => text)
      return Promise.reject({ statusText: response.statusText, status: response.status, error })
    }).then(data => Promise.resolve(data))
  },

  discard: (response, _action, retries) => {
    try {
      if (response.error.notice) {
        if (retries === 0) store.dispatch(addNoticeAction({ message: response.error.notice, type: 'notice' }))
        return true
      }

      if (response.error.error) {
        if (retries === 0) store.dispatch(addNoticeAction({ message: response.error.error, type: 'error' }))
        Sentry.captureException(new Error(response));
        return 400 <= response.status && response.status < 500
      }

      if (response.statusText !== undefined) {
        if (retries === 0) store.dispatch(addNoticeAction({ message: response.statusText, type: 'error' }))
        Sentry.captureException(new Error(response));
        return 400 <= response.status && response.status < 500
      }
    } catch(response) {
      if (retries === 0) store.dispatch(addNoticeAction({ message: 'Synchronization failed', type: 'error' }))
      Sentry.captureException(new Error(response));
      return false
    }
    return false
  }
}

// Offline middleware init.
const {
  middleware: offlineMiddleware,
  enhanceReducer,
  enhanceStore
} = createOffline(reduxOfflineConfiguration);

// Setup middleware list
const middlewareList = [
  thunk,
  reduxRouterMiddleware,
]
const middleware = applyMiddleware(...middlewareList, offlineMiddleware);

// Setting up the store with our configuration
export const store = createStore(
  enhanceReducer(rootReducer(history)),
  initialState,
  composeEnhancers(enhanceStore, middleware, sentryReduxEnhancer)
)

// export const history = syncHistoryWithStore(history, store)
