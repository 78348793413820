import React, { useState } from "react"
import { parseMoney }      from "../../../application/helpers/helpers"

export const SalesTable = ({
  data, loading, months, currency
}) => {
  if (loading) return (
    <div className="bg-white d-flex j-c-center a-i-center mb-4 h-41px">
      <div className="dot-elastic"></div>
    </div>   
  )
  const [open, setOpen] = useState(false)

  const members = open ? Object.entries(data.props_month_type.members) : Object.entries(data.props_month_type.members).slice(0, 5) 

  return (
    <div id="products-table-container" className="mb-4">
      <table id="products-table">
        <thead>
          <tr id="table-header">
            <th id="sales-analysis-query" className = "sales_analysis text-cut">{ I18n.t(`invoices.sales_statistics.${data.query_type}`) }</th>
            <th id="sales-analysis-total" className = "sales_analysis text-cut">{ I18n.t("invoices.sales_statistics.total") }</th>
            { months.map(month => {
                return <th key = { month } id="sales-analysis-month" className = "sales_analysis text-cut">{ month }</th>
              })
            }
          </tr>
        </thead>
        <tbody>
          { 
            !data.props_month_type ? 
              <tr>
                <td colSpan="14">
                  { I18n.t("invoices.sales_statistics.no_invoices") }
                </td>
              </tr>
            :
            <>
              <tr className = "show-product sales-analysis-line">
                <td className = "m-0 px-2 py-0 text-right font-weight-bold"></td>
                <td className = "m-0 px-2 py-0 text-right font-weight-bold">{parseMoney(data.props_month_type.totals.total, currency)}</td>
                { Object.entries(data.props_month_type.totals).map((sale, i) => {
                  return <td key = { i } className = "m-0 px-2 py-0 text-cut text-right font-weight-bold">{ parseMoney(sale[1], currency) || '-' }</td> 
                })}
              </tr>

              { members && 
                members.map((line, i) => {
                  return (
                    <tr key = { i } className = "show-product sales-analysis-line">
                      <td className = "m-0 px-2 py-0 text-left">{ line[0] }</td>
                      <td className = "m-0 px-2 py-0 text-cut text-right font-weight-bold">{ parseMoney(line[1].total, currency) || '-' }</td>
              
                      { Object.entries(line[1]).map((sale, i) => {
                        return <td key = { i } className = "m-0 px-2 py-0 text-cut text-right">{ parseMoney(sale[1], currency) || '-' }</td>
                      })}
                    </tr>
                  )
                })
              }

            </>
          }
        </tbody>
      </table>
      <div className="d-flex j-c-end">
        { open && Object.entries(data.props_month_type.members).length > 5 &&
          <div className="btn btn-primary mt-4" onClick={() => setOpen(!open)}>
            { I18n.t("invoices.sales_statistics.show_less")}
          </div>
        }

        { !open && Object.entries(data.props_month_type.members).length > 5 &&
          <div className="btn btn-primary mt-4" onClick={() => setOpen(!open)}>
            { I18n.t("invoices.sales_statistics.show_all")}
          </div>
        }
      </div>
    </div>
  )
}
