import * as Sentry from '@sentry/react'
import { store } from '@redux/store'
import { addNoticeAction }    from '@redux/notices/actions'
import { fetchSessionAction } from '@redux/user/fetch'
import { HEADERS } from '@helpers/javascript/config/CONSTANTS'


const unauthenticatedMessages = Object.keys(I18n.translations).map(locale =>
  I18n.t("devise.failure.unauthenticated", { locale })
)

const limitedMessages = Object.keys(I18n.translations).map(locale =>
  I18n.t("users_permissions.session_limited", { locale })
)

export const csrfToken = () => {
  const meta = document.querySelector('meta[name="csrf-token"]')
  return meta.getAttribute("content")
}

const getHeaders = () => {
  const headers = { ...HEADERS }
  headers['X-CSRF-Token'] = csrfToken()
  return headers
}

const unauthenticated = error => unauthenticatedMessages.includes(error)
const limited         = error => limitedMessages.includes(error)

export const shouldAlertError = errorMessage => {
  const ignoreList = [
    "Unprocessable Entity",
    "Not Found",
    "Load failed",
    "Timeout"
  ]

  if (ignoreList.includes(errorMessage)) {
    return false
  } else {
    return true
  }
}

const shouldRaiseSentry = error => {
  if (error.name == "Error") return false

  const ignoreList = [
    "Unexpected end of JSON input",
    "Failed to fetch",
    "Load failed"
  ]

  ignoreList.concat(unauthenticatedMessages)
  ignoreList.concat(limitedMessages)

  if (ignoreList.includes(error.message)) {
    return false
  } else {
    return true
  }
}

const resolveRequest = (resolve, data) => {
  if (data && data.hasOwnProperty('error')) {
    if(unauthenticated(data.error)) {
      const user = store.getState().user
      const { id, unique_session_id } = user
      store.dispatch(fetchSessionAction(id, unique_session_id))
    } else if (limited(data.error)) {
      window.location.href = "/limit_session"
    } else if (shouldAlertError(data.error)) {
      store.dispatch(addNoticeAction({ message: data.error, type: 'error' }))
    }

    throw new Error(data.error)
  } else if (data && data.hasOwnProperty('notice')) {
    store.dispatch(addNoticeAction({ message: data.notice, type: 'notice' }))
  } else if (data && data.hasOwnProperty('success')) {
    store.dispatch(addNoticeAction({ message: data.success, type: 'success' }))
  }

  return resolve(data)
}

const rejectRequest = (reject, error) => {
  if(shouldRaiseSentry(error)) Sentry.captureException(error)
  reject(error)
}

export const getRequest = url => {
  return new Promise((resolve, reject) => {
    fetch(url,
      {
        headers:     getHeaders(),
        credentials: 'same-origin'
      }
    )
    .then(response => response.json())
    .then(data     => resolveRequest(resolve, data))
    .catch(error   => rejectRequest(reject, error))
  })
}

export const bodyRequest = ({ method = 'POST', url, body = {} }) => {
  return new Promise((resolve, reject) => {
    fetch(url,
      {
        method:      method,
        headers:     getHeaders(),
        credentials: 'same-origin',
        body:        JSON.stringify(body)
      }
    )
    .then(response => response.json())
    .then(data     => resolveRequest(resolve, data))
    .catch(error   => rejectRequest(reject, error))
  })
}

export const postRequest = ({ url, body = {} }) => (
  bodyRequest({ method: 'POST', url, body })
)

export const patchRequest = ({ url, body = {} }) => (
  bodyRequest({ method: 'PATCH', url, body })
)

export const deleteRequest = url => bodyRequest({ method: 'DELETE', url })

export const formRequest = ({ method = 'POST', url, form }) => {
  return new Promise((resolve, reject) => {
    const headers = getHeaders()
    form.append("authenticity_token", csrfToken())
    delete headers['Content-Type']
    fetch(url,{ method, headers, credentials: 'same-origin', body: form })
    .then(response => response.json())
    .then(data     => resolveRequest(resolve, data))
    .catch(error   => rejectRequest(reject, error))
  })
}


export const parseParams = string => {
  if (string == '') return {}

  const parsed = {}

  string   = string.substring(string.indexOf('?') + 1)
  const p1 = string.split('&')
  p1.map((value) => {
    const params = value.split('=')
    parsed[params[0]] = params[1]
  })

  return parsed
}

export const parameterize = object => {
  const params = new URLSearchParams
  Object.keys(object).forEach(key => {
    object[key] && params.set(key, object[key])
  })
  return params
}

export const pathname = ({ path, format, params = {} }) => {
  if (format) { path = path.replace(/\..*$/,"")}
  const url    = new URL(window.location.origin)
  url.pathname = path
  if (format) { url.pathname = url.pathname + `.${format}`}
  Object.keys(params).forEach(key => url.searchParams.set(key, params[key]))
  return url.pathname + url.search
}

export const getSearch = parameter => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(parameter)
}

// This is a fucking spaguetti, I should refactor it when i have time
// Basically it takes a filter state object and returns the corresponding url
export const parameterizeFilter = filter => {
  const filterObject = {}
  // Date or status
  if (filter.search && filter.search !== '') {
    filterObject.search = filter.search
    if (filter.page !== '') {
      filterObject.page = filter.page
    }
  } else {
    if (filter.status === 'date') {
      if (filter.date !== 0) { filterObject.date = filter.date }
    } else {
      filterObject.status = filter.status
    }
    if (filter.technician) {
      filterObject.technician = filter.technician
    }
    if (filter.category) {
      filterObject.category = filter.category
    }
    if (filter.page !== '') {
      filterObject.page = filter.page
    }
    if (filter.workorder_type) {
      const typeArray = new Array
      Object.keys(filter.workorder_type).forEach(key => {
        if (filter.workorder_type[key]) typeArray.push(key)
      })
      filterObject.workorder_type = typeArray
    }
    if (filter.by_distance) {
      filterObject.by_distance  = filter.by_distance
    }
  }

  // From object to url params
  return filterObject
}

export const clearLocalData = () => {
  if (!confirm(I18n.t("mobile.actions.clear_cache_confirm"))) return

  localStorage.clear()

  caches.keys()
  .then(cacheKeys => Promise.all(
    cacheKeys.map(cacheKey => caches.delete(cacheKey))
  ))

  navigator.serviceWorker.getRegistrations()
  .then(registrations => {
    registrations.forEach(registration => registration.unregister())
    window.location.reload()
  })
}

export const buildReferrerPath = referrer => {
  switch(referrer?.type) {
    case 'workorder': return `/mobile/interventions/${referrer.id}`
    case 'demand':    return `/mobile/demands/${referrer.id}`
    case 'invoice':   return `/mobile/invoices/${referrer.id}`
    case 'site':      return `/mobile/sites/${referrer.id}`
    case 'material':  return `/mobile/materials/${referrer.id}`
    default:          null
  }
}

export const truncateString = (str, maxLength) => {
  if(!str) return ''

  if (str.length <= maxLength) {
    return str
  }
  return str.slice(0, maxLength) + '...'
}

export const navigatorLocale = () => {
  const locale = navigator.language || navigator.userLanguage
  return locale.split("-")[0]
}

const parse      = string => string.normalize().toLowerCase()
export const hit = (target, string) => parse(target).includes(parse(string))

export const calcDist = (origin, destination) => {
  const R     = 6371 * 1000 // km
  const lat1  = origin.latitude
  const lon1  = origin.longitude
  const lat2  = destination.latitude
  const lon2  = destination.longitude
  const dLat  = toRad(lat2-lat1)
  const dLon  = toRad(lon2-lon1)
  const rLat1 = toRad(lat1)
  const rLat2 = toRad(lat2)

  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(rLat1) * Math.cos(rLat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
  const d = R * c
  return d.toFixed(0)
}

const toRad = value => value * Math.PI / 180
