import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

// Style
import './Spinner.scss'

export const Spinner = () => {

  // const [showHelpButtons, setShowHelpButtons] = useState(false)

  // Display reload button after 10 seconds
  // useEffect(() => { setTimeout(() => setShowHelpButtons(true), 10000) }, [])

  return (
    <div id="loading-spinner-container">
      <div id="loading-spinner"></div>
      {/* {showHelpButtons &&
        <React.Fragment>
          <Link to='/mobile/interventions'>
            <button>Back to home page</button>
          </Link>
          <button onClick={() => window.location.reload()}>Reload the app. You will loose all unsynced modifications</button>
        </React.Fragment>
      } */}
    </div>
  )
}
