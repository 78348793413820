// Dispatch
export const addCustomFormAction = (id, form) => dispatch =>
  dispatch({
    type: ADD_CUSTOM_FORM.ACTION,
    payload: { id, form }
  })

// Reducer response
export const ADD_CUSTOM_FORM = {
  ACTION: 'ADD_CUSTOM_FORM',

  call: ({ state, id, form }) => ({
    ...state,
    items: state.items.map(item => item.id != id ? item : {
      ...item,
      synced: false,
      custom_forms_answers: [
        ...item.custom_forms_answers,
        {
          id:           null,
          blueprint_id: form.id,
          name:         form.name
        }
      ]
    })
  })
}
