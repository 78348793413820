// To remove a photo, we only need the workorder index and the photo index inside that workorder

// Dispatch
export const removeCustomFormAction = (id, formKey) => dispatch =>
  dispatch({
    type: REMOVE_CUSTOM_FORM.ACTION,
    payload: { id, formKey }
  })

// Reducer response
export const REMOVE_CUSTOM_FORM = {
  ACTION: 'REMOVE_CUSTOM_FORM',

  call: ({ state, id, formKey } = {}) => {
    const workorder = state.items.find(wo => wo.id === id)

    const forms = [...workorder.custom_forms_answers]
    forms.splice(formKey, 1)

    const editedWorkorder = {
      ...workorder,
      custom_forms_answers: forms,
      synced: false
    }

    const workorders = state.items.map(wo => wo.id === id ? editedWorkorder : wo)
    return { ...state, items: workorders }
  }
}
