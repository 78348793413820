// Actions
export const setTodayWorkordersAction = () => dispatch => dispatch({
    type: WORKORDERS_TODAY.SET
})
// Reducer response
export const WORKORDERS_TODAY = {
  SET: 'WORKORDERS_TODAY_SET',
  setResponse: ({ state }) => ({
    ...state,
    items:         state.today_workorders,
    loading:       false,
    loadingNext:   false,
    total_entries: state.today_workorders.length,
    total_pages:   1
  })
}
