import React           from "react"
import SharedFlatpickr from "@shared/flatpickr"

import Base from "./base"

export default class CreationDate extends Base {

  constructor(props) {
    super(props)
    this.state = {active: props.quote.creation_date === ""}
  }

  onChange(_selectedDates, dateStr) {
    const { quote, replaceQuote } = this.props
    this.deactivate()
    replaceQuote({ ...quote, creation_date: dateStr })
  }

  render() {
    const { quote } = this.props

    const flatpickrOptions = {
      inline: true,
      dateFormat: "d/m/Y",
      locale: I18n.locale
    }

    return(
      this.state.active
        ? <div className="form-group">
            <label>{I18n.t("quotes.new.creation_date")}</label>

            <SharedFlatpickr
              className="form-control flatpickr-nobg text-center"
              value    = {quote.creation_date}
              onChange = {this.onChange}
              options = {flatpickrOptions}
              name="quote[creation_date]"
              required
            />
          </div>
        : <div onClick={this.activate}
            className="quotes--form-pointer text-right pr-0"
          >
            {I18n.t("quotes.pdf.issued")}
            {quote.creation_date}
            <input type="hidden" name="quote[creation_date]" defaultValue={quote.creation_date} />
          </div>
    )
  }
}
