import React      from "react"
import LineBase   from "./line_base"

// Components
import Buttons      from "./shared/buttons"
import Calculations from "./shared/calculations"
import Inputs       from "./shared/inputs"
import Option       from "./shared/option"
import HideInPdf    from "./shared/hide_in_pdf"
import ProductForm  from "./shared/product_form"

import Inactive     from "./line_inactive"

import Autocomplete from '../../../shared/autocomplete'
import QuillInput from '@shared/quillInput'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Helpers
import { getRequest, pathname }     from '@helpers/javascript/javascript'
import { lineNameFor, roundNumber } from "../helpers"

export default class Product extends LineBase {
  constructor(props) {
    super(props)
    this.state             = {...this.state, create: false}
    this.activateCreate    = this.activateCreate.bind(this)
    this.deactivateCreate  = this.deactivateCreate.bind(this)
    this.onChange          = this.onChange.bind(this)
    this.reset             = this.reset.bind(this)
    this.selectProduct     = this.selectProduct.bind(this)
    this.changeDescription = this.changeDescription.bind(this)
  }

  activateCreate() {
    this.setState({create: true})
  }

  deactivateCreate() {
    this.setState({create: false})
  }

  onChange(productId) {
    const path   = `/quotes/form/${productId}/product`
    const format = "json"
    const params = {}
    if (this.props.quote.client.id) params.client = this.props.quote.client.id
    const url = pathname({ path, format, params })

    getRequest(url).then(this.selectProduct)
  }

  reset() {
    const { index, qline } = this.props
    const { replaceLine }  = this.props.buttonProps

    replaceLine({...qline, product: {}}, index)
  }

  changeDescription(description) {
    const { replaceLine }  = this.props.buttonProps
    const { index, qline } = this.props

    replaceLine({...qline, description}, index)
  }

  selectProduct(product) {
    const { index, qline } = this.props
    const { replaceLine }  = this.props.buttonProps

    this.deactivateCreate()

    let purchase_price = product.purchase_price
    let unit_price     = product.sale_price

    const has_unit_price     = unit_price     > 0
    const has_purchase_price = purchase_price > 0
    const allow_markup       = this.props.quote.allow_markup
    const markup             = this.props.quote.price_markup_coefficient

    if (allow_markup && has_unit_price && !has_purchase_price) {
      purchase_price = roundNumber(unit_price / markup)
    } else if (allow_markup && !has_unit_price && has_purchase_price) {
      unit_price     = roundNumber(purchase_price * markup)
    }

    replaceLine({
      ...qline,
      description:         product.html,
      ref_product_distrib: product.ref_product_brand,
      unit:                product.unit,
      product,
      purchase_price,
      unit_price
    }, index)
  }

  render() {
    const { index, quote, qline, vats, showCode, showMargin, buttonProps } = this.props
    const { replaceLine } = this.props.buttonProps

    return(
      qline.active
      ? <div className="quotes--form-qline-container">
          { this.state.create
            ? <ProductForm
                submitProduct={this.selectProduct}
                deactivate={this.deactivateCreate}
              />
            : <React.Fragment>
                <div className="quotes--form-full-row">
                  <label>{I18n.t("quotes.new.product")}</label>
                  { qline.product.id
                    ? <div onClick={this.reset}>
                        <input
                          className="form-control"
                          defaultValue={qline.product.name}
                          disabled
                        />

                        <input
                          type="hidden"
                          defaultValue={qline.product.id}
                          name={lineNameFor("product_id", index)}
                        />
                      </div>
                    : <Autocomplete
                        identifier       = "id"
                        shownValue       = "name"
                        placeholder      = {I18n.t("workorders.minimum_2_characters")}
                        autocomplete_url = {"/products/autocomplete.json"}
                        inputClassName   = "form-control"
                        onSelectElement  = {this.onChange}
                      />
                  }

                </div>

                <div className="quotes--form-full-row quotes--form-bottom-link">
                  <div onClick={this.activateCreate}>
                    <FontAwesomeIcon
                      className="default"
                      icon="plus-circle"
                      onClick={this.add}
                    />
                    &nbsp;
                    {I18n.t('interventions.add_unlisted_product')}
                  </div>
                </div>

                <div className="text-input">
                  <QuillInput
                    text={qline.description}
                    name={lineNameFor('description', index)}
                    onChange={this.changeDescription}
                  />
                </div>

                <Calculations
                  qline={qline}
                  index={index}
                  replaceLine={replaceLine}
                  showMargin={showMargin}
                  showPriceCoefficient={quote.allow_markup}
                />

                <Inputs
                  index={index}
                  quote={quote}
                  qline={qline}
                  replaceLine={replaceLine}
                  vats={vats}
                  showMargin={showMargin}
                />

                <div className="checkboxes">
                  <Option
                    index={index}
                    qline={qline}
                    replaceLine={replaceLine}
                  />

                  <HideInPdf
                    index={index}
                    qline={qline}
                    replaceLine={replaceLine}
                  />
                </div>

                <Buttons
                  index={index}
                  qline={qline}
                  buttonProps={buttonProps}
                />

                <input
                  type="hidden"
                  name={lineNameFor("id", index)}
                  defaultValue={qline.id}
                />

                <input
                  type="hidden"
                  name={lineNameFor("order", index)}
                  defaultValue={index + 1}
                />
              </React.Fragment>
          }
        </div>
      : <Inactive
          index={index}
          qline={qline}
          showCode={showCode}
          buttonProps={buttonProps}
        />
    )
  }
}
