import React, { useState, useEffect } from "react"
import { Material }                           from "./material"
import { MaterialTasks }                      from "./materialTasks"
import { Recurrency }                         from "./recurrency"
import { TYPES }                              from "../app"
import Combobox                               from "@shared/combobox"
import Checkbox                               from "@shared/checkbox/checkbox"

export const Materials = ({
  materials,
  workorderState,
  setWorkorderState,
  blueprints,
  allowRecurrency
}) => {
  const remainingMaterials = materials.filter(mat => !workorderState.materials.includes(mat.materialId))
  const addMaterial = material => {
    setNewMaterial(null)
    setWorkorderState({...workorderState, materials: [...workorderState.materials, material.materialId]})
  }

  const emptyMaterial = () => {
    return (
      <Combobox
        identifier      = { "materialId" }
        options         = { remainingMaterials }
        value           = { '' }
        onSelectElement = { addMaterial }
        placeholder     = { I18n.t("workorders.select_material") }
      />
    )
  }

  const [newMaterial, setNewMaterial] = useState(emptyMaterial())

  const removeMaterial = material => {
    const newMaterials = [...workorderState.materials]
    const materialToRemove = newMaterials.findIndex(mat => mat === material)
    newMaterials.splice(materialToRemove, 1)
    const copiedMaintenanceTasks = [...workorderState.maintenanceTasks]
    const newMaintenanceTasks = copiedMaintenanceTasks.filter(task => task.materialId != material)
    setWorkorderState({ ...workorderState, materials: newMaterials, maintenanceTasks: newMaintenanceTasks })
  }

  const updateWorkorderDuration = tasks => {
    const totalDuration = tasks.reduce((sum, task) => sum + task.predictedDuration, 0)
    setWorkorderState({ ...workorderState, predictedDuration: totalDuration || 60, maintenanceTasks: tasks })
  }

  useEffect(() => {
    const maintenanceTasks = [...workorderState.maintenanceTasks]
    workorderState.materials.map(mat => {
      if (workorderState.workorderType !== TYPES.preventive) return
      const newTask = { materialId: mat, taskBlueprintId: null, predictedDuration: 60 }
      if (maintenanceTasks.filter(task => task.materialId == mat).length == 0) {
        maintenanceTasks.push(newTask)
      }
    })
    updateWorkorderDuration(maintenanceTasks)
  }, [workorderState.materials])

  useEffect(() => {
    setNewMaterial(emptyMaterial())
  }, [remainingMaterials.length])
  
  return (
    <div className = "form-group">
      <div className ="cocoon-grid mb-2">
        <div className ="cocoon-grid__header">
          <label className = "g-c-span-3">{ I18n.t("materials.materials") }</label>
        </div>
      </div>
      {
        workorderState.materials.map((material, index) => {
          return (
            <div key = { index }>
              <Material       
                index          = { index }         
                material       = { material } 
                materials      = { materials }
                removeMaterial = { removeMaterial }
                workorderState = { workorderState }
              />
              { workorderState.workorderType === TYPES.preventive &&
                  <MaterialTasks 
                    material                = { material}
                    tasks                   = { workorderState.maintenanceTasks.map((_, i) => i).filter(i => workorderState.maintenanceTasks[i].materialId == material) }
                    blueprints              = { blueprints }
                    workorderState          = { workorderState }
                    setWorkorderState       = { setWorkorderState }
                    updateWorkorderDuration = { updateWorkorderDuration }
                  />
              }
            </div>
          )
        })
      }
      {
        remainingMaterials.length > 0 && !workorderState.materials.some(mat => mat === null) && 
          newMaterial
      }
      {
        workorderState.workorderType === TYPES.preventive && allowRecurrency && 
        <div className = "mt-4">
          <Checkbox
            id           = { "add_recurrency" }
            label        = { I18n.t("maintenance.add_recurrency") }
            checked      = { workorderState.recurrency }
            onChange     = { value => setWorkorderState({ ... workorderState, recurrency: value }) }
            className    = "checkbox-primary"
          />
    
          { !!workorderState.recurrency && 
              <Recurrency 
                workorderState    = { workorderState }
                setWorkorderState = { setWorkorderState }
              />
          }
        </div>
      }
    </div>
  )
}