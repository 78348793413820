import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Checkbox            from '@shared/checkbox/checkbox'
import Combobox            from '@shared/combobox'
import SharedFlatpickr     from "@shared/flatpickr"

const Filter = ({
  filter,
  setFilter,
  resources,
  setResources,
  partsStatusPermitted,
  filterOpen,
  setFilterOpen
}) => {

  const handleAgencySelect = agency => {
    let newResources = [...resources]

    if (agency.id) {
      const agencyResources    = newResources.filter(res => res.agencyId === agency.id)
      const nonAgencyResources = newResources.filter(res => res.agencyId !== agency.id)

      agencyResources.map(resource => resource.unchecked = false)
      nonAgencyResources.map(resource => resource.unchecked = true)
    } else {
      newResources.map(resource => resource.unchecked = false)
    }
    setResources(newResources)
    setFilter({...filter, agency: agency.id || '' })
  }

  const handleCategorySelect = category => {
    setFilter({...filter, category: category.id || '' })
  }

  return (
    <div className="calendar-filters">
      <div className = "calendar-filter-toggle" onClick={() => setFilterOpen(!filterOpen)}>
        {filterOpen ? <FontAwesomeIcon icon='caret-down' /> : <FontAwesomeIcon icon='caret-right' />}
        {I18n.t("workorders.filter.filters")}
      </div>

      <div className={`calendar-filter${filterOpen ? ' filter-open' : ''}`}>
        { filter.agencies?.length > 0 &&
          <div className="form-group">
            <Combobox
              options         = {filter.agencies}
              onSelectElement = {handleAgencySelect}
              placeholder     = {I18n.t("agencies.select_agency")}
              prompt          = {I18n.t("agencies.select_agency")}
              value           = {filter.agency}
            />
          </div>
        }

        { filter.categories?.length > 0 &&
          <div className="form-group">
            <Combobox
              options         = {filter.categories}
              onSelectElement = {handleCategorySelect}
              placeholder     = {I18n.t("categories.select")}
              prompt          = {I18n.t("categories.select")}
              value           = {filter.category}
            />
          </div>
        }

        {filter.site &&
          <div className="form-group">
            <input
              type="text"
              className="form-control mb-2 filter-site"
              value={filter.site_name}
              disabled
            />
            <FontAwesomeIcon icon="times" onClick={()=>setFilter({...filter, site: '', site_name: ''})}/>
          </div>
        }

        <div className="form-group">
          <input
            type="text"
            className="form-control mb-2 filter-search"
            value={filter.address || ""}
            placeholder={I18n.t("workorders.filter.location")}
            onChange={e=>setFilter({...filter, address: e.currentTarget.value })}
          />
          <FontAwesomeIcon icon="location-arrow" />
        </div>

        {filter.address &&
          <div className="radius">
            <div className="mb-1">{I18n.t("workorders.filter.radius")}</div>
            <input
              type="range"
              min="1"
              max="50"
              id="location-radius"
              value={filter.radius}
              onChange={e=>setFilter({...filter, radius: e.currentTarget.value })}
              className="slider"
            />
            <div className="text-right mb-2">{filter.radius || 2} km</div>
          </div>
        }
        <div className="form-group w-100" tooltip={I18n.t("workorders.filter.tooltip")}>
          <input
            type="text"
            className="form-control filter-search"
            value={filter.text || ""}
            placeholder={I18n.t("workorders.filter.search_placeholder")}
            onChange={e=>setFilter({...filter, text: e.currentTarget.value })}
          />
          <FontAwesomeIcon icon="search" />
        </div>

        {filter.showOpen &&
          <div className="period-input">
            <SharedFlatpickr
              options = {{
                mode:   "range",
                locale: I18n.locale,
                allowInput: true,
                dateFormat: "d/m",
                onClose: period=>setFilter({...filter, period: period })
              }}
              value={filter.period}
              className = "form-control mb-4 filter-search"
              placeholder={I18n.t("calendar.filter_open")}
            />
            <FontAwesomeIcon icon="calendar-alt" />
            { filter.period && <FontAwesomeIcon icon="times" onClick={() => setFilter({...filter, period: '' })}/> }
          </div>
        }

        { partsStatusPermitted &&
          <Checkbox
            checked   = {filter.showReceivedParts}
            id        = "parts-status-checkbox"
            onChange  = {()=>setFilter({...filter, showReceivedParts: !filter.showReceivedParts })}
            label     = {I18n.t("calendar.show_awaiting_parts_workorders")}
            className = "form-group"
          />
        }

        <Checkbox
          checked   = {filter.showOpen}
          id        = "open-checkbox"
          onChange  = {()=>setFilter({...filter, showOpen: !filter.showOpen })}
          label     = {I18n.t("calendar.show_open_workorders")}
          className = "form-group"
        />

        { filter.showOpen &&
          <Checkbox
            checked   = {filter.filterCalendar}
            id        = "filter-open"
            onChange  = {()=>setFilter({...filter, filterCalendar: !filter.filterCalendar})}
            label     = {I18n.t('workorders.filter.filter_calendar')}
            className = "form-group"
          />
        }
      </div>  
    </div>
  )
}

export default Filter
