import React, { useState, useEffect } from "react"
import { ResponsiveRadar } from '@nivo/radar'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { Toggler } from '../../shared/Toggler'
import { hourToString } from '@helpers/javascript/date_helpers'

export const FilteredTimeRepartition = ({ data }) => {
  const STATUS_HEIGHT = 500

  const [filteredTime,         setFilteredTime]         = useState([])
  const [filteredTimeAverage,  setFilteredTimeAverage]  = useState([])
  const [filteredTimeOverview, setFilteredTimeOverview] = useState([])

  useEffect(() => {
    if (data.total && data.average && data.overview){
      setFilteredTime(data.total)
      setFilteredTimeAverage(data.average)
      setFilteredTimeOverview(data.overview)
    }
  }, [data])

  const checkTotalPresent = () => {
    if (!data.total) return false
    let isDataPresent = false
    data.total.forEach(user => {
      if (user[I18n.t('dashboard.travel_time')] !== 0 || user[I18n.t('dashboard.delay')] !== 0 || user[I18n.t('dashboard.start_stop')] !== 0 || user[I18n.t('dashboard.predicted_time')] !== 0 || user[I18n.t('dashboard.paused_time')] !== 0) {
        isDataPresent = true
      }
    })
    return isDataPresent
  }

  const checkAveragePresent = (userName, average) => {
    if (!data.average) return false
    let isDataPresent = false
    average.forEach(data => {
      if (data[userName] !== 0) isDataPresent = true
    })
    return isDataPresent
  }

  const checkOverviewPresent = () => {
    if (!data.overview) return false
    let isDataPresent = false
    data.overview.forEach(user => {
      user.data.forEach(value => {
        if (value.y !== 0) isDataPresent = true
      })
    })
    return isDataPresent
  }

  const colors = {
    [I18n.t('dashboard.travel_time')]:     "#E7EEF6",
    [I18n.t('dashboard.delay')]:           "#9EDCF5",
    [I18n.t('dashboard.start_stop_time')]: "#2C98FF",
    [I18n.t('dashboard.predicted_time')]:  "#E7EEF6",
    [I18n.t('dashboard.paused_time')]:     "#f7f06d"
  }

  const getName = key => key === 0 ? I18n.t('dashboard.team_average') : filteredTime[key - 1].user

  return (
    <>
      <Toggler title={I18n.t('dashboard.time_repartition')} opened={true}>
        <div className="dashboard__card d-flex f-wrap j-c-between">
          {filteredTimeAverage.map((tech, key) => {
            return (
              <div className="d-flex f-column minw-30 m-t-1" key={key} style={{ height: '500px' }}>
                <h3>{key === 0 ? I18n.t('dashboard.team_average') : I18n.t('dashboard.user_average', { user: getName(key) })}</h3>
                {checkAveragePresent(getName(key), tech)
                  ? <ResponsiveRadar
                      data      = {tech}
                      keys      = {[key === 0 ? I18n.t('dashboard.team_average') : filteredTime[key - 1].user]}
                      indexBy   = "type"
                      margin    = {{ top: 40, right: 20, bottom: 140, left: 20 }}
                      curve     = "linearClosed"
                      gridShape = "linear"
                      dotSize   = {10}
                      dotBorderWidth = {2}
                      enableDotLabel = {false}
                      colors         = {[`${key === 0 ? "#2C98FF" : filteredTime[key - 1].color}`]}
                      isInteractive  = {true}
                      tooltipFormat  = { value => hourToString(value) }
                    />
                  : <div className="dashboard__card--empty">{I18n.t('dashboard.no_data')}</div>
                }
            </div>
            )
          })
        }
        </div>
        <div className="dashboard__card" style={{ height: `${STATUS_HEIGHT}px` }}>
          { checkTotalPresent()
            ? <ResponsiveBar
              data      = {filteredTime}
              groupMode = "stacked"
              keys      = {[
                I18n.t('dashboard.travel_time'),
                // I18n.t('dashboard.delay'),
                I18n.t('dashboard.start_stop_time'),
                // I18n.t('dashboard.predicted_time'),
                I18n.t('dashboard.paused_time')
              ]}
              indexBy   = "user"
              margin    = {{ top: 50, right: 60, bottom: 150, left: 60 }}
              padding   = {0.3}
              layout    = "vertical"
              colors    = {bar => colors[bar.id]}
              borderColor = {{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisBottom={{
                tickRotation: -30,
                legend: '',
                legendPosition: 'start',
                legendOffset: 100
              }}
              axisLeft={{
                tickSize:       5,
                tickPadding:    5,
                tickRotation:   0,
                legend:         I18n.t('dashboard.hours'), // To translate
                legendPosition: 'middle',
                legendOffset:   -40
              }}
              tooltip={props => (
                <div className="dashboard__tooltip">
                  <strong>{props.indexValue}</strong><br/>
                  {props.id}: {hourToString(props.value)}
                </div>
              )}
              labelSkipWidth  = {12}
              labelSkipHeight = {12}
              labelTextColor  = {{ from: 'color', modifiers: [['darker', 1.6]] }}
              legends={[
                {
                  dataFrom:       'keys',
                  anchor:         'bottom-left',
                  direction:      'row',
                  justify:        false,
                  translateX:     -20,
                  translateY:     150,
                  itemsSpacing:   2,
                  itemWidth:      150,
                  itemHeight:     20,
                  itemDirection:  'left-to-right',
                  itemOpacity:    0.85,
                  symbolSize:     20,
                  effects: [{
                      on: 'hover',
                      style: { itemOpacity: 1 }
                  }]
                }
              ]}
              animate         = {true}
              motionStiffness = {90}
              motionDamping   = {15}
            />
            : <div className="dashboard__card--empty">{I18n.t('dashboard.no_data')}</div>
          }
        </div>
      </Toggler>
      <Toggler title={I18n.t('dashboard.mttr_average')} opened={true}>
        <div className="dashboard__card" style={{ height: `${STATUS_HEIGHT}px` }}>
          { checkOverviewPresent()
            ? <ResponsiveLine
                data       = {filteredTimeOverview}
                margin     = {{ top: 50, right: 160, bottom: 50, left: 60 }}
                xScale     = {{ type: 'point' }}
                yScale     = {{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                layers     = {['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
                axisBottom = {{
                  orient:        'bottom',
                  tickSize:       5,
                  tickPadding:    5,
                  tickRotation:   0,
                  legend:         '',
                  legendOffset:   36,
                  legendPosition: 'middle'
                }}
                axisLeft={{
                  orient:         'left',
                  tickSize:       5,
                  tickPadding:    5,
                  tickRotation:   0,
                  legend:         I18n.t('dashboard.hours'),
                  legendOffset:   -40,
                  legendPosition: 'middle'
                }}
                tooltip = { ({ point }) => (
                  <div>
                    <strong>{point.serieId}</strong><br />
                    {point.data.x} : {hourToString(point.data.y)}
                  </div>
                )}
                colors     = {line => line.color}
                pointSize  = {10}
                pointColor = {{ "from":"color" }}
                pointBorderWidth  = {2}
                pointBorderColor  = {{ from: 'serieColor' }}
                pointLabel        = "Tech time"
                pointLabelYOffset = {-12}
                useMesh = {true}
                legends = {[{
                  anchor:            'bottom-right',
                  direction:         'column',
                  justify:           false,
                  translateX:        100,
                  translateY:        0,
                  itemsSpacing:      0,
                  itemDirection:     'left-to-right',
                  itemWidth:         80,
                  itemHeight:        20,
                  itemOpacity:       0.75,
                  symbolSize:        12,
                  symbolShape:       'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [{
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                    }
                  }]
                }]}
              />
            : <div className="dashboard__card--empty">{I18n.t('dashboard.no_data')}</div>
          }
        </div>
      </Toggler>
    </>
  )
}
