import React          from 'react'

const OverlaySpinner = () => {
  return (
    <>
      <div className="submit-overlay">
      </div>
      <div id="loading-spinner-container">
        <div id="loading-spinner"></div>  
      </div>
    </>
  )
}

export default OverlaySpinner