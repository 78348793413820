import React from 'react'

export const StepIndicator = props => {

  const { steps, currentStep } = props

  return(
    <div className="demands--steps">
      {steps.map((step, key) =>
        <span key={step} className={"demands--step " + (currentStep === step ? 'active' : '')} />
      )}
    </div>
  )
}
