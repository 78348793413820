// Dispatch
export const stopWorkorderAction = (id, success) => dispatch =>
  dispatch({
    type:    WORKORDER_STOP.ACTION,
    payload: { id, proximity_stop: success }
  })


// Reducer response
export const WORKORDER_STOP = {
  ACTION: 'WORKORDER_STOP',

  call: ({ state, id, proximity }) => {
    const workorder = state.items.find(wo => wo.id === id)

    let pausedInterval, pausedTotal

    if (workorder.paused) {
      pausedInterval = parseInt((new Date - workorder.start_pause) / 1000)
      pausedTotal    = workorder.paused_time + pausedInterval
    } else {
      pausedTotal    = workorder.paused_time
    }

    const editedWorkorder = {
      ...workorder,
      date_done:      new Date,
      paused:         false,
      paused_time:    pausedTotal,
      synced:         false,
      proximity_stop: proximity
    }

    const workorders = state.items.map(wo => wo.id === id ? editedWorkorder : wo)
    return { ...state, items: workorders }
  }
}
