import React, { useState } from 'react'

// Components
import QrReader from 'react-qr-reader'

const Scanner = ({handleScan}) => {
  const [allowScan, setAllowScan] = useState(false)
  const [error,     setError]     = useState('')
  const [scanning,  setScanning]  = useState(false)

  const onScan = data => {
    if (scanning) return

    setError('')
    if (!data) return

    setScanning(true)
    if (handleScan) {
      handleScan(data)
    } else {
      handleRawData(data)
    }
  }

  const handleRawData = data => {
    try {
      const url = new URL(data)
      if (window.location.host.includes(url.host)) {
        window.location.href = data
      } else {
        throw new TypeError
      }
    } catch (TypeError) {
      setError(I18n.t('workorder_demands.scan_invalid'))
    }
  }

  const qr_reader =
    <QrReader
      delay   = {300}
      onError = {() => setError(I18n.t('workorder_demands.scan_error'))}
      onScan  = {onScan}
      style   = {{ width: '100%' }}
    />

  return (
    <>
      {error}
      {allowScan
        ? qr_reader
        :
          <div className="btn demands--scan-button" onClick={() => setAllowScan(true)}>
            <i className="fas fa-qrcode" />
            { I18n.t('workorder_demands.scan_code') }
          </div>
      }
    </>
  )
}

export default Scanner
