import React, { useState, useEffect } from "react"
import { FontAwesomeIcon }            from '@fortawesome/react-fontawesome'
import SharedFlatpickr                from "@shared/flatpickr"
import { Recurrency }                 from './recurrency'
import Combobox                       from "@shared/combobox"

const DEFAULT_DURATION = 60
const defaultTask = { predictedDuration: DEFAULT_DURATION }

const nameFor = (attribute, index) => `maintenance_plan[maintenance_tasks_attributes][${index}][${attribute}]`

export const TypePreventive = ({ materials, blueprints, allowRecurrency }) => {
  const [tasks,      setTasks]      = useState([defaultTask])
  const [recurrency, setRecurrency] = useState(false)

  // This is a dirty way to modify an external react component
  useEffect(() => {
    const predictedInput = document.querySelector("#hidden_predicted_duration")
    if (predictedInput) {
      const durations      = tasks.map(task => task.predictedDuration)
      const value          = durations.reduce((a, b) => a + b, 0)
      predictedInput.value = value
      predictedInput.dispatchEvent(new Event("change"))
    }
  }, [tasks])

  const addTask = e => {
    e.preventDefault()
    setTasks([...tasks, defaultTask])
  }

  const updateTask = (newTask, index) => {
    setTasks(tasks.map((task, taskIndex) => taskIndex == index ? newTask : task))
  }

  const setMaterialId = (materialId, index) => {
    const task       = tasks[index]

    updateTask({...task, materialId }, index)
  }

  const setTaskBlueprintId = (taskBlueprintId, index) => {
    const task              = tasks[index]
    const blueprint         = blueprints.find(blueprint => blueprint.id == taskBlueprintId)
    const predictedDuration = blueprint && blueprint.predictedDuration || 60

    updateTask({...task, taskBlueprintId, predictedDuration }, index)
  }

  const setPredictedDuration = (dates, index) => {
    const hours             = dates[0].getHours()
    const minutes           = dates[0].getMinutes()
    const predictedDuration = hours * 60 + minutes
    const task              = tasks[index]

    updateTask({...task, predictedDuration }, index)
  }

  const getDate = duration => {
    const hours    = Math.floor(duration / 60)
    const minutes  = duration % 60

    return `${hours}:${minutes}`
  }

  const removeTask = (e, index) => {
    e.preventDefault()
    if(tasks.length <= 1) return

    tasks.splice(index, 1)
    setTasks([...tasks])
  }

  return(
    <>
      <div className="d-grid g-t-col-12">
        <div className="g-c-span-4 first-row mb-2">
          <label required>{I18n.t("maintenance.material")}</label>
        </div>

        <div className="g-c-span-4 first-row mb-2">
          <label required>{I18n.t("maintenance.task")}</label>
        </div>

        <div className="g-c-span-3 first-row mb-2">
          <label>{I18n.t("maintenance.predicted_duration")}</label>
        </div>

        <div className="g-c-span-1 first-row mb-2"></div>

        { tasks.map((task, index) =>
          <React.Fragment key={index}>

            <div className="g-c-span-4 mb-2 mr-2">
              <Combobox
                fieldName       = {nameFor("material_id", index)}
                options         = {materials}
                value           = {task.materialId}
                required        = {true}
                onSelectElement = {material => setMaterialId(material?.id, index)}
                prompt          = {I18n.t("workorders.select_material")}
              />
            </div>

            <div className="g-c-span-4 mb-2 mr-2">
              <Combobox
                display         = "showTitle"
                fieldName       = {nameFor("task_blueprint_id", index)}
                options         = {blueprints}
                value           = {task.taskBlueprintId}
                required        = {true}
                onSelectElement = {taskBlueprint => setTaskBlueprintId(taskBlueprint?.id, index)}
                prompt          = {I18n.t("workorders.select_task_blueprint")}
              />
            </div>

            <div className="g-c-span-3 mb-2">
              <SharedFlatpickr
                options={{
                  locale:     I18n.locale,
                  enableTime: true,
                  noCalendar: true
                }}
                className = "form-control flatpickr-nobg text-center"
                onChange  = {dates => setPredictedDuration(dates, index)}
                value     = {getDate(task.predictedDuration)}
              />

              <input
                name     = {nameFor("predicted_duration", index)}
                value    = {task.predictedDuration}
                onChange = {() => {}}
                hidden
              />
            </div>

            <div className="g-c-span-1 mb-2 d-flex-center" style={{ fontSize: '20px' }}>
              { tasks.length > 1 &&
                <a href="#" className="mr-2" onClick={e => removeTask(e, index) }>
                  <FontAwesomeIcon icon = "minus-circle"/>
                </a>
              }
            </div>
          </React.Fragment>
        )}

        <div className="g-c-span-row flex-end">
          <a href="#" onClick={addTask} className="form-group small flex-end">
            <FontAwesomeIcon icon = "plus-circle"/>
            &nbsp;
            { I18n.t("maintenance.add_task") }
          </a>
        </div>
      </div>

      { allowRecurrency &&
        <div className="checkbox checkbox-primary">
          <input
            type      = "checkbox"
            id        = "add_recurrency"
            name      = "maintenance_plan[add_recurrency]"
            value     = "1"
            onChange  = {e => setRecurrency(!recurrency)}
          />
          <label htmlFor="add_recurrency">
            {I18n.t("maintenance.add_recurrency")}
          </label>
        </div>
      }

      { allowRecurrency && recurrency && <Recurrency/> }
    </>
  )
}
