import React  from "react"
import { library }          from '@fortawesome/fontawesome-svg-core'
import { fas }              from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome'

library.add(fas)

{/*
    title:           String. Title of the block.
    values:          Array. Array of hash that contains the identifier and the shownValue. Ex: [{ id: 1, name: 'Nerea <3' }]
    shownValue:      String. Key from the hash that will be displayed. Ex: 'name'.
    onClickSelected: Callback when you click on the item with the value of the item which is a hash. Ex: { id: 1, name: 'Nerea <3' }
*/}

const Multiselect = ({ title, values, shownValue, onClickSelected, hideCounter }) => {

  if(!values && values.length == 0) return null

  return (
    <>
      {title && <div className="timesheet__filters__title">{title}</div>}
      { !hideCounter &&
        <div className="multiselect__counter">
          {values.length}
          &nbsp;
          {values.length > 1 ? I18n.t('clients.selected_plural') : I18n.t('clients.selected_singular')}
        </div>
      }
      <div className="multiselect__container">
        {values.map((value, key) => (
          <span
            key       = {key}
            onClick   = {() => onClickSelected(value)}
            className = "multiselect__item"
          >
            {value[shownValue]}
            &nbsp;
            &nbsp;
            <FontAwesomeIcon icon="times"/>
          </span>
        ))}
      </div>
    </>
  )
}

export default Multiselect
