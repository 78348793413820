import React from "react"
import { ResponsivePie } from '@nivo/pie'

const CELL_HEIGHT = 450
const PIE_HEIGHT  = 350

const noValue = data => data ? data.map(v => v.value).reduce((a, b) => a + b, 0) === 0 : true

const EmptyCard = () => {
  return(
    <div
      className="dashboard__card--empty"
      style={{ height: `${PIE_HEIGHT}px` }}
    >
      {I18n.t('dashboard.no_data')}
    </div>
  )
}

const Ratio = ratio => {
  const hideLegends = ratio.ratio.hideLegends

  const commonProps = {
    margin:         { top: 80, right: 80, bottom: 80, left: 80 },
    startAngle:     0,
    innerRadius:    0.5,
    padAngle:       0.7,
    cornerRadius:   3,
    borderWidth:    1,
    height:         PIE_HEIGHT,
    colors:         d => d.color,
    radialLabel:    d => d.percentage || d.label,
    slicesLabel:    d => d.label ,
    radialLabelsTextColor:      "#333333",
    radialLabelsLinkStrokeWidth: 1,
    radialLabelsLinkColor:      { from: 'color' },
    slicesLabelsSkipAngle:      10,
    slicesLabelsTextColor:      "#333333",
    animate:          true,
    motionStiffness:  90,
    motionDamping:    15,
    ...(!hideLegends) && {legends: [{
      anchor:        'bottom',
      direction:     'row',
      translateY:    56,
      itemWidth:     100,
      itemHeight:    18,
      itemTextColor: '#999',
      symbolSize:    18,
      symbolShape:   'circle',
      effects: [{
        on:    'hover',
        style: { itemTextColor: '#000' }
      }]
    }]}
  }
  
  const customProps = {
    margin:         { top: 80, right: 40, bottom: 80, left: 120 },
    startAngle:     0,
    innerRadius:    0.5,
    padAngle:       0.7,
    cornerRadius:   3,
    borderWidth:    1,
    height:         PIE_HEIGHT,
    colors:         d => d.color,
    radialLabel:    d => d.percentage,
    slicesLabel:    d => d.label ,
    radialLabelsTextColor:      "#333333",
    radialLabelsSkipAngle:      10,
    radialLabelsLinkStrokeWidth: 1,
    radialLabelsLinkDiagonalLength: 0,
    radialLabelsLinkColor:      { from: 'color' },
    slicesLabelsSkipAngle:      10,
    slicesLabelsTextColor:      "#333333",
    animate:          true,
    motionStiffness:  90,
    motionDamping:    15,
    legends: [{
      anchor:        'top-left',
      direction:     'column',
      itemWidth:     50,
      itemHeight:    20,
      itemTextColor: '#999',
      translateX:    -120,
      translateY:    -80,
      symbolSize:    18,
      symbolShape:   'circle',
      effects: [{
        on:    'hover',
        style: { itemTextColor: '#000' }
      }]
    }]
  }
  
  const pieProps = ratio.ratio.custom ? customProps : commonProps

  return(
    <div style={{ height: `${CELL_HEIGHT}px` }}>
      <h3>{ratio.ratio.title}</h3>
      { noValue(ratio.ratio.data)
        ? <EmptyCard />
        : <ResponsivePie {...pieProps} data={ratio.ratio.data}/>
      }
    </div>
  )
}

export const FilteredRatios = ({ ratios }) => {
  return (
    <div className="dashboard__card">
      <div className="dashboard__ratios_container">
        { Object.keys(ratios).map(key =>
          <Ratio key={key} ratio={ratios[key]} />
        )}
      </div>
    </div>
  )
}
