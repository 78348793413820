import React from "react"
import Base from "./base"

export default class Title extends Base {

  constructor(props) {
    super(props)
    this.state = {active: props.quote.title ? false : true}
  }

  onChange(e) {
    e.preventDefault()
    const { quote, replaceQuote } = this.props
    replaceQuote({...quote, title: e.target.value})
  }

  render() {
    const { quote } = this.props

    return(
      this.state.active || !quote.title
        ? <div className="form-group">
            <label>{I18n.t("quotes.new.title")}</label>

            <input type="text"
              className="form-control"
              value={quote.title || ""}
              onChange={this.onChange}
              onFocus={this.activate}
              onBlur={this.deactivate}
              name="quote[title]"
              required
            />
          </div>
        : <div className="quotes--form-pointer" onClick={this.activate}>
            <div className="quotes--form-title">
              {quote.title}
            </div>
            <input type="hidden" name="quote[title]" value={quote.title} onChange={() => {}} />
          </div>
    )
  }
}
