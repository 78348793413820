import React from "react"

import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas }             from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

//Components
import TimesheetRow from './timesheetRow'

// Helpers
import { jsErbCall } from '../helpers/jsErbCall'

export default class TimesheetUser extends React.Component {

  // Tables are open by default
  state = { closed: false }

  render() {
    const { sheet }  = this.props
    const { closed } = this.state

    const toggleTimesheet = () => this.setState({ closed: !closed })

    const showModale = url => {
      const bigCardContainer = document.getElementById('cards-container');
      const spinnerHTML = `<div class='loading-spinner-container'>
                              <div id='loading-spinner'></div>
                          </div>`;
      bigCardContainer.innerHTML = spinnerHTML;
      document.getElementById('show-big-card-modal-btn').click();
      jsErbCall(url)
    }

    const hasWorkorders = sheet.total_workorders > 0

    return (
      <div className="timesheet__sheet">
        {/* Togglable table header (tech name, …) */}
        <div className  = {`timesheet__sheet__user ${closed || !hasWorkorders ? 'timesheet__sheet__user--closed' : ''}`}
              onClick   = {hasWorkorders ? toggleTimesheet : null}
        >
          <div>
            <div className="timesheet__sheet__bullet" style={{ background: sheet.user.color }}></div>
            {sheet.user.name}
          </div>

          <div>
            {/* Workorder count */}
            {
              [
                sheet.total_workorders,
                I18n.t('users.timesheets.workorders'),
                I18n.t('users.timesheets.time_in'),
                sheet.total_time
              ].join(' ')
            }
            {/* Toggle arrow */}
            { hasWorkorders &&
              <div className={`ml-3 timesheet__sheet__arrow  ${this.state.closed ? '' : 'timesheet__sheet__arrow--down'}`}>
                <FontAwesomeIcon icon="caret-down" />
              </div>
            }
          </div>
        </div>

        {/* Headers of the column table */}
        { hasWorkorders &&
          <div className={`timesheet__sheet__row  timesheet__sheet__row--headers ${closed ? 'timesheet__sheet--closed' : ''}`}>
            <div title={I18n.t('users.timesheets.headers.name')} className='timesheet__sheet__row--headers__wo-title'>{I18n.t('users.timesheets.headers.name')}</div>
            <div title={I18n.t('users.timesheets.headers.site')} className='timesheet__sheet__row--headers__site'>{I18n.t('users.timesheets.headers.site')}</div>
            <div title={I18n.t('users.timesheets.headers.departure_date')} className='timesheet__sheet__row--headers__date'>{I18n.t('users.timesheets.headers.departure_date')}</div>
            <div title={I18n.t('users.timesheets.headers.travel_time')} className='timesheet__sheet__row--headers__time'>{I18n.t('users.timesheets.headers.travel_time')}</div>
            <div title={I18n.t('users.timesheets.headers.date_started')} className='timesheet__sheet__row--headers__date'>{I18n.t('users.timesheets.headers.date_started')}</div>
            <div title={I18n.t('users.timesheets.headers.date_done')} className='timesheet__sheet__row--headers__date'>{I18n.t('users.timesheets.headers.date_done')}</div>
            <div title={I18n.t('users.timesheets.headers.time_taken')} className='timesheet__sheet__row--headers__time'>{I18n.t('users.timesheets.headers.time_taken')}</div>
          </div>
        }

        { hasWorkorders
          ? <div className={`timesheet__sheet__workorders ${closed ? 'timesheet--closed' : ''}`}>
              {sheet.days.map((day, index) => (
                <React.Fragment key={index}>

                  {/* Date header */}
                  <div className="timesheet__sheet__date">
                      <FontAwesomeIcon icon="calendar" />
                      <span className="timesheet__sheet__date_content">{new Date(day.date).toLocaleDateString(I18n.locale)}</span>
                      {' — ' + day.total_workorders + ' ' + I18n.t('users.timesheets.workorders')}
                      {' ' + I18n.t('users.timesheets.time_in') + ' ' + day.total_time}
                  </div>

                  {day.workorders.map((workorder, index) => (
                    <TimesheetRow
                      key        = {index}
                      workorder  = {workorder}
                      showModale = {showModale}
                    />
                  ))}
                </React.Fragment>
              ))}
            </div>
          : ''}
      </div>
    )
  }
}
