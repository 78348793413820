import React, { useState } from "react"

export const BoxProduct = ({ product, updateProduct, technicianResupply }) => {
  const [edited, setEdited] = useState(false)
  const onChange = e => {
    setEdited(true)
    let transmit = parseFloat(e.target.value)
    if (transmit) transmit = Math.round(transmit * 1000) / 1000
    const stocks   = {...product.stocks, transmit}
    updateProduct({ ...product, stocks })
  }

  return (
    <div className="timesheet__sheet__row timesheet__sheet__row--product timesheet__dispatcher-grid">
      <div>
        {product.ref}
      </div>

      <div>
        <a  tabIndex    = "1"
            data-toggle = "modal"
            data-target = "#remote-modal"
            data-action = "click->modal#show"
            data-route  = {product.path}
        >
          {product.designation}
          {product.unit && <span> ({product.unit})</span>}
        </a>
      </div>

      <div className="j-c-end">
        {product.stocks.needed}
      </div>

      <div className="j-c-end">
        {product.stocks.technician}
      </div>

      <div className="j-c-end">
        {product.stocks.source}
      </div>

      <div className="j-c-end">
        {/*Technician has stock and can resupply, partially or entirelly*/}
        { (product.stocks.transmit > 0 || edited)&&
          <input
            className = "form-control text-right"
            type      = "number"
            step      = "0.01"
            min       = "0"
            max       = {product.stocks.source}
            value     = {product.stocks.transmit || ''}
            onChange  = {onChange}
          />
        }

        {/*Technician lacks stocks and cannot resupply at all*/}
        { product.stocks.transmit == 0 && product.stocks.missing && !edited &&
          <span className="low-stock">
            {product.stocks.missing}
          </span>
        }

        {/*Technician has stock*/}
        { product.stocks.transmit <= 0 && !product.stocks.missing && !edited &&
          <span className="in-stock">0</span>
        }
      </div>
    </div>
  )
}
