import React from 'react'

// Components
import SharedFlatpickr     from "@shared/flatpickr"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ComboboxMulti       from "@shared/combobox_multi"

// Containers
import { AssetFilter }     from "./assetFilter"

const displayDate = date => moment(date).format("DD/MM/YYYY")
const filterTypes = [
  { id: "Reactive",     name: I18n.t("workorders.reactive")},
  { id: "Preventive",   name: I18n.t("workorders.preventive") },
  { id: "Installation", name: I18n.t("workorders.installation") }
]
const createdFromTypes = [
  { id: "Quote",     name: I18n.t("invoices.filter.created_from.installation") },
  { id: "Workorder", name: I18n.t("invoices.filter.created_from.reactive") },
  { id: "Contract",  name: I18n.t("invoices.filter.created_from.preventive") }
]

export const CostsFilters = ({ filter, setFilter, showRevenue }) => {
  const triggerPrint = event => {
    event.preventDefault()
    window.print()
  }

  const toggleType = types => {
    setFilter({ ...filter, type: types })
  }

  const toggleCreatedFromType = types => {
    if (types.some(type => type.id == "Workorder")) {
      const el = types.find(type => type.id == "Workorder")
      types.push(types.splice(types.indexOf(el), 1)[0]);
    }
    setFilter({ ...filter, createdFrom: types })
  }

  const changeDate = range => {
    if(range.length < 2) return

    filter.from = moment(range[0]).format("YYYY-MM-DD")
    filter.to   = moment(range[1]).format("YYYY-MM-DD")
    setFilter({...filter})
  }

  const period = [new Date(filter.from), new Date(filter.to)]

  const range = {
    from: displayDate(filter.from),
    to:   displayDate(filter.to)
  }

  return(
    <div>
      <div className="timesheet__filters">
        <div>
          <div className="timesheet__filters__title">
            {I18n.t('operational_costs.period')}
          </div>

          <SharedFlatpickr
            options={{
              mode: "range",
              inline: true,
              locale: I18n.locale
            }}
            onChange={changeDate}
            value={period}
        />

          <strong className="mt-2 text-center">
            {I18n.t("workorders.date_period", range)}
          </strong>
        </div>

        <div className="f-g-1 timesheet__filters--middle">
          <div className="timesheet__filters__title">
            {I18n.t('operational_costs.filter')}
          </div>

          <AssetFilter filter={filter} setFilter={setFilter} />

          <div style={{maxWidth: '600 px'}} className="form-group" >
            <ComboboxMulti
              options         = {filterTypes}
              onChange        = {toggleType}
              prompt          = {I18n.t("operational_costs.all")}
              value           = {filter.type}
            />
          </div>

          {showRevenue && 
            <div style={{maxWidth: '600 px'}} className="form-group" >
              <ComboboxMulti
                options         = {createdFromTypes}
                onChange        = {toggleCreatedFromType}
                prompt          = {I18n.t("operational_costs.from_invoice")}
                value           = {filter.createdFrom}
              />
            </div>
          }
        </div>

        <div>
          <div className="timesheet__filters__title">
            {I18n.t('users.timesheets.actions')}
          </div>

          <div className="timesheet__actions">
            <a href="#" onClick={triggerPrint}>
              <FontAwesomeIcon icon="print" /> {I18n.t('users.timesheets.print')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
