import { ACCOUNT_FETCH } from './fetch'

const initialState = {
  technicians: [],
  modules:     {},
  categories:  [],
  preferences: {
    panels:            {},
    mobile_db_options: {
      client:  {},
      contact: {},
      site:    {}
    }
  },
}

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_FETCH.ACTION:
      return ACCOUNT_FETCH
        .call({
          state:   state,
          account: action.payload.account
        })

    default:
      return state
  }
}

export default accountReducer


