// Sync has been changed, it will be done in a background process in rails
// The process is as following:
// 1. Any modification done in the react app, will change the synced status
//    'synced' of workorder to false.
// 2. A workorder that is not synced will get callbacks from useEffect to trigger
//    the redux sync action.
// 3. When it triggers we assume an optimistic response from the back so the
//    the redux state will change the synced and syncing attributes to true
// 4. A request will arrive to the server with the payload and a quick response
//    will be given when the background job is triggered
// 5. If the response has errors we will trigger the failure response and increase
//    the retries counter by one, also will update the synced and syncing
//    attributes to false
// 6. If the response from the web worker (not the background worker) is OK, a
//    websocket will be opened to recieve the response from the background job
// 7. Background job finishes and sends the updated workorder back to the consumer
// 8. Version is checked, if it is the same, redux state of the workorder is replaced.

import { PATHS, STATUSES }            from '@helpers/javascript/config/CONSTANTS'
import { getRequest }                 from '@helpers/javascript/javascript'
import { bodyRequest }                from '@helpers/javascript/javascript'
import { shouldAlertError }           from '@helpers/javascript/javascript'
import { cableSubscription }          from '@helpers/javascript/cable/cable'

import { store }                      from '@redux/store'
import { addNoticeAction }            from '@redux/notices/actions'
import { syncWorkorderBeginAction   } from '@redux/workorders/actions/sync'
import { syncWorkorderSuccessAction } from '@redux/workorders/actions/sync'
import { syncWorkorderFailureAction } from '@redux/workorders/actions/sync'
import { syncWorkorderRetryAction   } from '@redux/workorders/actions/sync'
import { resetFiltersAction }         from '@redux/filters/filters'
import { push }                       from 'connected-react-router'
export class Synchronizer  {
  channel     = 'WorkorderSyncChannel'
  static timeout    = 3000
  static maxRetries = 4
  handlers    = { received: response => this._receive(response) }

  constructor(workorder) {
    this.version    = workorder.version
    this.retries    = 0
    this.workorder  = workorder
    this.identifier = {
      channel:   this.channel,
      workorder: this.workorder.id
    }
    this.subscription = cableSubscription(this.identifier, this.handlers)
  }

  transmit = () => {
    if(this.workorder.error) return

    store.dispatch(syncWorkorderBeginAction(this.workorder))

    const method = 'PATCH'
    const url    = PATHS.workorders.sync(this.workorder.id)
    const body   = { workorder: stripWorkorder(this.workorder) }

    bodyRequest({ method, url, body })
    .then(response => {
      const { failure, message, workorder } = response
      failure ? this._fail(message) : this.beginTimeout()
    })
  }

  beginTimeout = () => {
    this.retryTimeout = setTimeout(() => {
      if(this.succeeded) return

      const url = PATHS.workorders.version(this.workorder.id)
      getRequest(url).then(data => {
        if(this.version == data.version) {
          // For some reason, the websocket response did not arrived
          this._success(data.version)
        } else {
          this._retry()
        }
      })
    }, Synchronizer.timeout)
  }

  // Private methods
  _receive = data => {
    // It comes as a string in dev, as an object in prod
    const response = typeof(data) == 'string' ? JSON.parse(data) : data

    const { failure, message, version } = response
    if(this.succeeded) return this._log(`Dropped ${version}`)

    response.failure ? this._fail(message) : this._success(version)
  }

  _retry = () => {
    this.retries += 1
    if(this.retries > Synchronizer.maxRetries) {
      this._fail("Timeout")
    } else {
      store.dispatch(syncWorkorderRetryAction(this.workorder))
      this.beginTimeout()
      this._log('Retry')
    }
  }

  _success = version => {
    this.succeeded = true
    this.retryTimeout && clearTimeout(this.retryTimeout)

    if(this._drop(version)) return

    // Get the server data
    getRequest(PATHS.workorders.fetch(this.workorder.id))
    .then(response => {
      const workorder = response.workorder
      if(this._drop(workorder.version)) return

      this.subscription.unsubscribe()
      this._log('Success')
      store.dispatch(syncWorkorderSuccessAction(workorder))
      if(workorder.authorize_invoice) return

      if(workorder.status == STATUSES.CLOSED) {
        const pathname = store.getState().router.location.pathname
        if (pathname == PATHS.workorders.show(workorder.id)) {
          store.dispatch(resetFiltersAction())
          if (store.getState().account.portal_active) {
            store.dispatch(push(PATHS.demands.index))
          } else {
            store.dispatch(push(PATHS.workorders.index))
          }
        }
      }
    })
  }

  _drop = version => {
    const workorders = store.getState().workorders.items
    const workorder  = workorders.find(wo => wo.id == this.workorder.id)

    if(workorder) {
      // The analized version should be equal to the redux version
      // and the synchronizer version
      // The workorder also must be unlocked

      const unlocked = !workorder.locked
      const sVersion = version == this.version
      const rVersion = version == workorder.version

      if(unlocked && sVersion && rVersion) return false
    }

    this.subscription.unsubscribe()
    this._log(`Dropped ${version}`)
    return true
  }

  _fail = error => {
    this.retry && clearTimeout(this.retry)
    if(shouldAlertError(error)) {
      store.dispatch(addNoticeAction({ message: error, type: 'error' }))
    }
    store.dispatch(syncWorkorderFailureAction(this.workorder, error))
    this.subscription.unsubscribe()
    this._log('Failure')
  }

  _log = message => console.info(this.channel, this.version, message)
}

const syncKeys = [
  "id",
  "status",
  "departure_date",
  "date_planned",
  "date_started",
  "date_done",
  "paused",
  "paused_time",
  "start_pause",
  "manual_travel_time",
  "report",
  "time_taken",
  "signature",
  "signer",
  "version",
  "photos",
  "extra_field_answers",
  "maintenance_tasks",
  "readings",
  "proximity_start",
  "proximity_stop"
]

const stripWorkorder = workorder => {
  const stripped = {}
  syncKeys.forEach(key => stripped[key] = workorder[key])
  return stripped
}
