import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Helpers
import { formatNumber } from "../helpers"

const Discount = ({quote, calculations, replaceQuote}) => {
  const [active, setActive] = useState(false)

  const onChange = e => {
    const discount = parseFloat(e.target.value) || 0
    replaceQuote({...quote, discount: discount})
  }

  if(active) return(
    <div className="line sub-total">
      <div className="form-group">
        <label htmlFor="quote_discount">
          {I18n.t("invoices.discount")}
        </label>

        <input
          className="form-control"
          type="number"
          step="0.01"
          min="0"
          max="100"
          name="quote[discount]"
          id="quote_discount"
          value={quote.discount || ""}
          onChange={onChange}
        />
      </div>

      <div className="confirm-button m-0"
           tooltip={I18n.t("button.apply")}
           tooltip_position="right"
      >
        <FontAwesomeIcon
          icon="check-circle"
          onClick={() => setActive(false)}
        />
      </div>
    </div>
  )

  return(
    <div className="line sub-total quotes--form-pointer"
      onClick={() => setActive(true)}
    >
      <div className="text-cut">
        {I18n.t("quotes.pdf.discount", {discount: formatNumber(quote.discount)})}
      </div>

      <div>{formatNumber(calculations.totalDiscount)} {quote.currency_symbol}</div>
      <input type="hidden" name="quote[discount]" defaultValue={quote.discount} />
    </div>
  )
}

export default Discount
