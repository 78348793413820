import React, { useState } from 'react'
import Autocomplete from '../shared/autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Helpers
import { getRequest }   from '@helpers/javascript/javascript'
import { bodyRequest, pathname } from '@react/helpers/fetch'

const InvoiceSelect = ({ invoices, onInvoiceChange }) => (
  <select
    className   = "form-control bank_transactions__invoice_select"
    onChange    = {e => onInvoiceChange(e)}
    name        = {'invoice_id'}
  >
    { invoices.length > 0 && <option key={-1} value=''>{I18n.t("bank_transactions.actions.select")}</option> }
    { invoices.map(invoice =>
        <option value={invoice.id}
                key={invoice.id}
                className={invoice.suggestedMatch ? 'bank_transactions__suggested_match' : ''}
        >
          {invoice.display}
        </option>
      )
    }
  </select>
);

const SelectedInvoiceLink = ({ invoice, handleToggleEditInvoice }) => (
  <>
    <div key={invoice.id}><a href={invoice.path} target="_blank" tooltip={invoice.tooltip}>{invoice.number}</a></div>
    <div className="pointer px-1" onClick={handleToggleEditInvoice}>
      <FontAwesomeIcon icon="times-circle" style={{color: 'grey'}} />
    </div>
  </>
);

const PaymentFromBankTransaction = props => {

  const [bankTransaction, setBankTransaction]     = useState(props);
  const [invoiceSelectMode, setInvoiceSelectMode] = useState(true);
  const [invoices, setInvoices]                   = useState([]);
  const [invoice, setInvoice]                     = useState();

  const onClientChange = (clientArray) => {
    const client = clientArray[0]
    const params = {
      client:                 client,
      remittance_information: bankTransaction.remittanceInformation
    }
    const url = pathname({ path: props.buildInvoicesForClientUrl, params, format: 'json' })
    getRequest(url)
      .then(invoices => {
        setInvoices(invoices)
        setInvoiceSelectMode(true)
      })
  }

  const onInvoiceChange = (e) => {
    const invoiceId = e.target.value
    const inv = invoices.find(invoice => invoice.id == invoiceId) || {}
    setInvoice(inv)
    setInvoiceSelectMode(false)
  }

  const handleToggleEditInvoice = () => {
    setInvoice()
    setInvoiceSelectMode(!invoiceSelectMode)
  }

  const registerTransaction = () => {
    const url = `/payments_from_bank_transactions/${bankTransaction.id}/create`;
    const body = { invoice_id: invoice.id }
    bodyRequest({ url, body })
      .then(bank_transaction => setBankTransaction(bank_transaction))
      .catch(error => console.error(error))
  }

  const ignoreTransaction = () => {
    if (confirm(I18n.t("bank_transactions.actions.ignore_confirm"))) {
      getRequest(`/payments_from_bank_transactions/${bankTransaction.id}/ignore`)
        .then(bank_transaction => {
          setBankTransaction(bank_transaction)
          setInvoices([])
          setInvoice(null)
        })
        .catch(error => console.error(error))
      setInvoice(null)
      setInvoiceSelectMode(true)
    } else {
      return
    }
  }

  const revertTransaction = () => {
    getRequest(`/payments_from_bank_transactions/${bankTransaction.id}/revert`)
      .then(bank_transaction => setBankTransaction(bank_transaction))
      .catch(error => console.error(error))
  }

  return(
    <div style={{display: 'contents'}} className={`${bankTransaction.ignored ? "bank_transactions__ignored" : ""}`}>
      {/*status*/}
      <div className="text-center">
        { bankTransaction.registered && <FontAwesomeIcon icon="check-circle" style={{color: 'green'}} /> }
        { bankTransaction.ignored && <FontAwesomeIcon icon="times-circle" /> }
      </div>

      {/*value_date*/}
      <div className="text-center pr-2">{ bankTransaction.valueDate }</div>

      {/*counterpart_name*/}
      <div className="text-left pr-2">{ bankTransaction.counterpartName }</div>

      {/*remittance_information*/}
      <div className="text-left pr-2">{ bankTransaction.remittanceInformation }</div>

      {/*amount*/}
      <div className="text-right pr-2">
        { bankTransaction.amount }
      </div>

      {/*client_search*/}
      <div className="text-left pr-2">
        { bankTransaction.registered
          ? bankTransaction.assignedClients.map((client, key) => <div key={key}><a href={client.path} target="_blank">{client.name}</a></div>)
          : !bankTransaction.ignored &&
            <Autocomplete
              placeholder      = {I18n.t("workorders.select_client")}
              autocomplete_url = {"/clients/autocomplete.json"}
              onSelectElement  = {onClientChange}
            />
        }
      </div>

      {/*invoice_select & invoice link*/}
      <div className="text-right pr-2 d-flex justify-content-end">
        {/* if unprocessed: show invoice select OR invoice link + edit (X) sign */}
        { bankTransaction.unprocessed && invoiceSelectMode &&
          <InvoiceSelect invoices        = {invoices}
                         onInvoiceChange = {onInvoiceChange}
          />
        }
        { bankTransaction.unprocessed && !invoiceSelectMode && invoice &&
          <SelectedInvoiceLink invoice={invoice} handleToggleEditInvoice={handleToggleEditInvoice} />
        }
        {/* if registered / ignored */}
        { bankTransaction.registered &&
          bankTransaction.assignedInvoices.map(invoice =>
            <div key={invoice.id}><a href={invoice.path} target="_blank" tooltip={invoice.tooltip}>{invoice.number}</a></div>
          )
        }
      </div>

      {/*actions*/}
      <div className="text-center d-flex-center">
        { invoice && bankTransaction.unprocessed &&
          <div className="pointer px-1" onClick={registerTransaction}>
            <FontAwesomeIcon icon="check-circle" size='lg' style={{color: 'green'}} />
          </div>
        }
        { bankTransaction.unprocessed &&
          <div className="pointer px-1" onClick={ignoreTransaction}>
            <FontAwesomeIcon icon="times-circle" size='lg' style={{color: '#eb4d3d'}} />
          </div>
        }
        { bankTransaction.ignored &&
          <div className="pointer px-1" onClick={revertTransaction} tooltip={I18n.t("bank_transactions.actions.recycle")} >
            <FontAwesomeIcon icon="recycle" style={{color: 'lightgrey'}} />
          </div>
        }
      </div>

    </div>
  )
}

export default PaymentFromBankTransaction
