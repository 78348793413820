import React, { useEffect, useRef } from 'react'
import { Draggable } from "@fullcalendar/interaction"
import { jsRequest } from "@react/helpers/fetch"

import EventCard from './eventCard'

const OpenEventsList = ({ 
  workorders, 
  calendar, 
  views, 
  filter, 
  setFilter, 
  setShowPopup, 
  setShowSmallCard,
  setFilterOpen
}) => {
  const openEventsListRef = useRef(null)
  const draggableRef      = useRef(null)
  
  useEffect(() => {
    if (!workorders) return

    draggableRef.current = new Draggable(openEventsListRef.current, {
      itemSelector: ".draggable-event",
      appendTo: document.querySelector('.calendar-right'),
      eventData: function (eventEl) {
        const info = JSON.parse(eventEl.getAttribute('data-info'))
        let duration = info.duration
        let create = false
        let extendedProps = { info: info }
        return {
          duration,
          create,
          extendedProps,
        };
      },
    });

    return () => {
      if (draggableRef.current) {
        draggableRef.current.destroy()
        draggableRef.current = null
      }
    };
  }, [workorders])

  const openWorkorder = ev => {
    showModal('#show-workorder-modal')
    jsRequest(ev.info.show_url)
  }

  if (!calendar)   return null
  if (!workorders) return null

  const isDraggable = calendar.currentData.viewSpec.type !== views.agenda

  return (
    <div className="open-event-list-wrapper">
      <div ref={openEventsListRef} className='open-events-list my-2'>
        {workorders.map(ev => {
          return (
            <div className={`fc-event top-event ${isDraggable ? 'draggable-event' : ''}`}
              style={{borderLeft: `4px solid ${ev.borderColor}`}}
              key={ev.info.workorder_id}
              data-info={JSON.stringify(ev.info)}
              onClick={() => openWorkorder(ev)}
            >
              <EventCard
                info            ={ev.info}
                showDate        ={false}
                filter          ={filter}
                setFilter       ={setFilter}
                setShowPopup    ={setShowPopup}
                setShowSmallCard={setShowSmallCard}
                setFilterOpen   ={setFilterOpen}
                showDuration
                externalCard
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default OpenEventsList
