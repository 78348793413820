import { FILTERS } from './filters'
import { TYPES }   from '@helpers/javascript/config/CONSTANTS'

const search = new URL(window.location.href).searchParams
let filterTypes = search.get('workorder_type') || ""
filterTypes.split(',')

export const defaultFilter = {
  technician:       '',
  category:         '',
  status:           'date',
  date:             0,
  search:           '',
  by_distance:      null,
  page:             1,
  workorder_type: {
    Preventive:   true,
    Reactive:     true,
    Installation: true
  }
}

const initialState = {
  technician:       search.get('technician')  || defaultFilter.technician,
  category:         search.get('category')    || defaultFilter.category,
  status:           search.get('status')      || defaultFilter.status,
  date:             search.get('date')        || defaultFilter.date,
  search:           search.get('search')      || defaultFilter.search,
  by_distance:      search.get('by_distance') || defaultFilter.by_distance,
  page:             1,
  workorder_type: {
    Preventive:   filterTypes ? filterTypes.includes(TYPES.PREVENTIVE)   : defaultFilter.workorder_type.Preventive,
    Reactive:     filterTypes ? filterTypes.includes(TYPES.REACTIVE)     : defaultFilter.workorder_type.Reactive,
    Installation: filterTypes ? filterTypes.includes(TYPES.INSTALLATION) : defaultFilter.workorder_type.Installation
  }
}

export default function filterReducer(state = initialState, action) {
  switch (action.type) {
  case FILTERS.CHANGE_STATUS:
    return FILTERS.changeStatusResponse({
      state: state,
      status: action.payload.status,
      search: action.payload.search,
      page: 1
    })

  case FILTERS.TOGGLE_REACTIVE:
    return FILTERS.toggleTypeResponse({
      state: state,
      workorder_type: { ...state.workorder_type, Reactive: action.payload.workorder_type.Reactive },
      search: action.payload.search,
      page: 1
    })

  case FILTERS.TOGGLE_PREVENTIVE:
    return FILTERS.toggleTypeResponse({
      state: state,
      workorder_type: { ...state.workorder_type, Preventive: action.payload.workorder_type.Preventive },
      search: action.payload.search,
      page: 1
    })

  case FILTERS.TOGGLE_INSTALLATION:
    return FILTERS.toggleTypeResponse({
      state: state,
      workorder_type: { ...state.workorder_type, Installation: action.payload.workorder_type.Installation },
      search: action.payload.search,
      page: 1
    })

  case FILTERS.BY_DISTANCE:
    return FILTERS.byDistanceResponse({
      state: state,
      by_distance: action.payload.location,
      page: 1
    })

  case FILTERS.NEXT_PAGE:
    return FILTERS.nextPageResponse({ state })

  case FILTERS.SELECT_TECHNICIAN:
    return FILTERS.selectTechnicianResponse({
      state:      state,
      technician: action.payload.technician,
      page:       1
    })
  
  case FILTERS.SELECT_CATEGORY:
    return FILTERS.selectCategoryResponse({
      state:      state,
      category:   action.payload.category,
      page:       1
    })

  case FILTERS.CHANGE_DATE:
    return FILTERS.changeDateResponse({
      state:           state,
      date:            action.payload.date,
      search:          action.payload.search,
      page:            1
    })

  case FILTERS.SEARCH_WORKORDERS:
    return FILTERS.searchWorkordersResponse({
      state: state,
      search: action.payload.search
    })

  case FILTERS.RESET:
    return FILTERS.resetFiltersResponse({ state: initialState })

  default:
    return state
  }
}
