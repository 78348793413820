import React               from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { pathname }        from '@react/helpers/fetch'

export const Material = ({
  material,
  materials,
  removeMaterial
}) => {
  const selectedMaterial = materials.find(mat => mat.materialId == material)
  
  const historyLink = () => {
    const path = '/workorders'
    const params = { 'workorders_filter_form[material]': material }
    return pathname({ path, params })
  }

  return (
    <div className="d-flex client-details gap-5">
      <div
        data-toggle = "modal"
        data-target = "#template-modal"
        data-route  = { `/material/modal/${material}` }
        data-action = "click->client--modal#show"
        className   = "button-link"
        tooltip     = { I18n.t("materials.folder")}
      >
        { selectedMaterial.name }
      </div>

      <a href     = { historyLink() }
        target    = "_blank"
        tooltip   = { I18n.t("materials.see_history") }
      >
        <FontAwesomeIcon icon = "history"/>
      </a>

      <a onClick={() => removeMaterial(material) } className = "txt-blue"> 
        <FontAwesomeIcon icon="times"/>
      </a>
    </div>
  )
}