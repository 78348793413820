import React from "react"
import Base  from "./base"

export default class Contact extends Base {
  constructor(props) {
    super(props)
    this.state = {active: !props.quote.contact.id}
  }

  onChange(e) {
    e.preventDefault()
    const contactId = e.target.value
    const { quote, replaceQuote } = this.props
    const siteContacts = quote.site.contacts
    const contact = siteContacts.find(siteContact => siteContact.id == contactId)
    this.deactivate()
    replaceQuote({...quote, contact: contact || {}})
  }

  render() {
    const { quote, replaceQuote } = this.props
    const site    = quote.site
    const contact = quote.contact

    return(
      this.state.active || !contact.id
        ? site.id && site.contacts.length > 0
          ? <div className="form-group">
              <label>{I18n.t("workorders.contact")}</label>

              <select className="form-control"
                value={contact ? contact.id || "" : ""}
                onChange={this.onChange}
                onBlur={this.deactivate}
                disabled={!site.id}
                name="quote[contact_id]"
              >
                <option value="">
                  {I18n.t("workorders.select_contact")}
                </option>
                {site.contacts.map(siteContact =>
                  <option value={siteContact.id}
                          key={siteContact.id}>
                    {siteContact.name}
                  </option>
                )}
              </select>
            </div>
          : <input
              type="hidden"
              name="quote[contact_id]"
              defaultValue={null}
            />
        : <div className="quotes--form-pointer pt-0 pb-0" onClick={this.activate}>
            <div>{contact.display}</div>
            <input
              type="hidden"
              name="quote[contact_id]"
              defaultValue={contact.id}
            />
          </div>
    )
  }
}
