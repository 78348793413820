import React    from "react"
import LineBase from "./line_base"

// Components
import Buttons  from "./shared/buttons"

// Helpers
import { lineNameFor } from "../helpers"

const resetAttributes = [
  "ref_product_distrib",
  "description",
  "discount",
  "quantity",
  "purchase_price",
  "unit_price",
  "vat_rate_id",
  "product_id",
  "is_option"
]

export default class Blueprint extends LineBase {

  render() {
    const { index, qline, buttonProps } = this.props

    return(
      qline.active
      ? <div onClick={this.deactivate} className="quotes--form-qline-container">
          <Buttons
            index={index}
            qline={qline}
            buttonProps={buttonProps}
          />

          <input
            type="hidden"
            name={lineNameFor("id", index)}
            defaultValue={qline.id}
          />

          <input
            type="hidden"
            defaultValue="1"
            name={lineNameFor("is_page_break", index)}
          />

          <input
            type="hidden"
            name={lineNameFor("order", index)}
            defaultValue={index + 1}
          />

          {
            resetAttributes.map((attribute, resetIndex) =>
              <input
                type="hidden"
                key={resetIndex}
                name={lineNameFor(attribute, index)}
                defaultValue=""
              />
            )
          }
        </div>
      : <div className="quotes--form-pointer quotes--form-table-row" onClick={this.activate}>
          <div className="quotes--form-page-break">{I18n.t('quotes.new.page_break')}</div>

          <Buttons
            index={index}
            qline={qline}
            buttonProps={buttonProps}
          />

          <input
            type="hidden"
            name={lineNameFor("id", index)}
            defaultValue={qline.id}
          />

          <input
            type="hidden"
            name={lineNameFor("order", index)}
            defaultValue={index + 1}
          />

          <input
            type="hidden"
            defaultValue="1"
            name={lineNameFor("is_page_break", index)}
          />

          {
            resetAttributes.map((attribute, resetIndex) =>
              <input
                type="hidden"
                key={resetIndex}
                name={lineNameFor(attribute, index)}
                defaultValue=""
              />
            )
          }
        </div>
    )
  }
}
