import React from "react"
import { ResponsiveBar } from '@nivo/bar'
import { EmptyCard }     from "./EmptyCard"
import { parseMoney }    from "../../../application/helpers/helpers"

export const MonthPrev = ({ data, currency, filters }) => {
  const STATUS_HEIGHT     = 600

  if (!data) return

  const CustomBarComponent = ({ bars, ...props }) => (
    <g>
      {bars.map(bar => (
        <g
          key={bar.key}
          transform={`translate(${bar.x},${bar.y})`}
          onMouseEnter={event => {
            props.showTooltip(
              <div style={{ color: 'black', background: 'white', padding: '5px' }}>
                {`${bar.data.indexValue} ${bar.data.id} : ${parseMoney(bar.data.value, currency)}`}
              </div>,
              event
            );
          }}
          onMouseMove={event => {
            props.showTooltip(
              <div style={{ color: 'black', background: 'white', padding: '5px' }}>
                {`${bar.data.indexValue} ${bar.data.id} : ${parseMoney(bar.data.value, currency)}`}
              </div>,
              event
            );
          }}
          onMouseLeave={props.hideTooltip}
        >
          <rect width={bar.width} height={bar.height} fill={bar.color} />
          <text
            transform={`translate(${bar.width / 2}, ${bar.height / 2}) rotate(-90)`}
            textAnchor="middle"
            alignmentBaseline="middle"
            style={{ fontSize: '11px', fontWeight: '400' }}
          >
            { parseMoney(bar.data.value, currency) }
          </text>
        </g>
      ))}
    </g>
  );

  const layers = () => {
    if (filters.previous <= 3) {
      return ['grid', 'axes', 'bars', 'markers', 'legends', CustomBarComponent]
    } else {
      return ['grid', 'axes', 'bars', 'markers', 'legends']
    }
  }

  return (
    <div className="dashboard__card mr-3 w-92vw">
      <h3>{I18n.t(`dashboard.monthly_turnover`) } { I18n.t(`invoices.sales_statistics.years_${filters.previous}`)}</h3>
      {
        !data.props_month_prev?.length ? <EmptyCard />
        :
        <div style={{ height: `${STATUS_HEIGHT}px`}}>
          <ResponsiveBar
            data        = {data.props_month_prev}
            groupMode   = "grouped"
            keys        = {Object.keys(data.props_month_prev[0]).slice(0,-1)}
            indexBy     = "month"
            margin      = {{ top: 50, right: 60, bottom: 80, left: 120 }}
            padding     = {0.3}
            enableLabel = {false}
            layout      = "vertical"
            layers      = {layers()}
            label       = {({ value }) => `${parseMoney(value, currency)}`}
            borderColor = {{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisBottom  = {{
              tickRotation: -30,
              legend: '',
              legendPosition: 'start',
              legendOffset: 100
            }}
            axisLeft    = {{
              tickSize:       5,
              tickPadding:    5,
              tickRotation:   0,
              legendPosition: 'middle',
              legendOffset:   -40,
              format:         value => `${parseMoney(value, currency)}`
            }}
            labelSkipWidth  = {12}
            labelSkipHeight = {12}
            labelTextColor  = {{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
              {
                dataFrom:       'keys',
                anchor:         'bottom',
                direction:      'row',
                justify:        false,
                translateX:     -20,
                translateY:     70,
                itemsSpacing:   2,
                itemWidth:      150,
                itemHeight:     20,
                itemDirection:  'left-to-right',
                itemOpacity:    0.85,
                symbolSize:     20,
                symbolShape:   'circle',
                effects: [{
                    on: 'hover',
                    style: { itemOpacity: 1 }
                }]
              }
            ]}
            animate 
            motionStiffness = {90}
            motionDamping   = {15}
            tooltip = { ({ value, id }) => (
              <div style={{background: 'white'}}>
                {id} : {parseMoney(value, currency)}
              </div>
            )}
          />
        </div>
      }
    </div>
  )
}
