// Dispatch
export const retryWorkorderAction = id => dispatch =>
  dispatch({
    type:    WORKORDER_RETRY.ACTION,
    payload: { id }
  })

// Reducer response
export const WORKORDER_RETRY = {
  ACTION: 'WORKORDER_RETRY',

  call: ({ state, id }) => ({
    ...state,
    items: state.items.map(workorder => workorder.id != id ? workorder : {
      ...workorder,
      synced:    false,
      syncing:   false,
      retries:   0,
      show_sync: true,
      error:     null
    })
  })
}
