import React               from "react"
import SharedFlatpickr     from "@shared/flatpickr"
import Combobox            from "@shared/combobox"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getDate }         from "@react/helpers/helpers"

export const Task = ({
  task, 
  taskIndex,
  isFirstTask,
  blueprints,
  handleBlueprintChange,
  handleDurationChange,
  removeTask
}) => {

  return (
    <div className = "material-task-grid">
      <Combobox
        options         = { blueprints }
        display         = 'showTitle'
        value           = { task.taskBlueprintId }
        className       = "mr-2"
        onSelectElement = { blueprint => handleBlueprintChange(blueprint, taskIndex) }
        required
      />
      <SharedFlatpickr
        options={{
          locale:     I18n.locale,
          enableTime: true,
          noCalendar: true
        }}
        className   = "form-control flatpickr-nobg text-center"
        onChange    = { duration => handleDurationChange(duration, taskIndex) }
        value       = { getDate(task.predictedDuration) }
      />
      <div>
        { !isFirstTask &&
          <a className="text-pointer ml-2" onClick={() => removeTask(taskIndex) }> 
            <FontAwesomeIcon icon="minus-circle"/>
          </a>
        }
      </div>
    </div>
  )
}