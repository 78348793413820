import React, { useEffect }  from "react"
import { Passage }           from "./passage"
import { FontAwesomeIcon }   from "@fortawesome/react-fontawesome"
import { skipWeekends }      from "@react/helpers/helpers"
import { TYPES }             from "../app"

export const DatePlanned = ({
  workorderState,
  setWorkorderState,
  maximumDuration
}) => {
  const addPassages = number => {
    const copiedPassages = [...workorderState.passages]
    
    for (let i = 0; i < number; i++) {
      let previousPassage = copiedPassages[copiedPassages.length - 1]

      let newDatePlanned
      if (previousPassage) {
        newDatePlanned = moment(previousPassage.datePlanned)
      } else {
        newDatePlanned = moment(new Date(workorderState.datePlanned).toISOString())
      }

      newDatePlanned = skipWeekends(newDatePlanned.add(1, "day"))

      copiedPassages.push({
        datePlanned:  newDatePlanned,
        includeExtra: true,
        predictedDuration: workorderState.predictedDuration,
        technicianId: workorderState.technicianId
      })
    }
    setWorkorderState({ ...workorderState, passages: copiedPassages })
  }

  const changePassageDate = (date, index) => {
    const copiedPassages  = [...workorderState.passages]
    const selectedDatePlanned = index === null ? workorderState.datePlanned : copiedPassages[index].datePlanned 
    const interval = date - new Date(selectedDatePlanned)
    const passagesToUpdate = copiedPassages.filter(({datePlanned}) => new Date(datePlanned) >= new Date(selectedDatePlanned))

    passagesToUpdate.map(passage => passage.datePlanned = skipWeekends(moment(new Date(new Date(passage.datePlanned).setTime(new Date(passage.datePlanned).getTime() + interval)))))
    if (index === null) {
      setWorkorderState({ ...workorderState, passages: copiedPassages, datePlanned: skipWeekends(moment(date)) })
    } else {
      setWorkorderState({ ...workorderState, passages: copiedPassages })
    }
  }

  const changePassageDuration = (duration, index) => {
    const passageDuration = duration > maximumDuration ? maximumDuration : duration
    if (index === null) {
      setWorkorderState({ ...workorderState, predictedDuration: passageDuration })
    } else {
      const copiedPassages  = [...workorderState.passages]
      const passageToChange = copiedPassages[index]
      passageToChange.predictedDuration = passageDuration
      setWorkorderState({ ...workorderState, passages: copiedPassages })
    }
  }

  const removePassage = index => {
    const copiedPassages = [...workorderState.passages]
    copiedPassages.splice(index, 1)
    setWorkorderState({ ...workorderState, passages: copiedPassages })
  }

  useEffect(() => {
    const copiedPassages = [...workorderState.passages]
    copiedPassages.map((passage) => {
      passage['technicianId'] = workorderState.technicianId
    })
    setWorkorderState({ ...workorderState, passages: copiedPassages })
  }, [workorderState.technicianId])

  return (
    <>
      <div className = "date-and-duration">
        <div></div>
        <label className = "control-label" htmlFor = "workorder_date_planned">
          { I18n.t("workorders.due_date") }
        </label>
        <label className = "control-label"
               htmlFor   = "workorder_predicted_duration"
        >
          { I18n.t("workorders.duration_in_min") }
        </label>
      </div>
      <div className = "flexbox-space-between p-relative date-and-duration">
        <Passage 
          index                   = { null }
          datePlanned             = { new Date(workorderState.datePlanned).toISOString() } 
          predictedDuration       = { workorderState.predictedDuration }
          changePassageDate       = { changePassageDate }
          changePassageDuration   = { changePassageDuration }
        />
      </div>
      { workorderState.workorderType !== TYPES.preventive &&
        <>
          {
            workorderState.passages.map((pass, index) => {
              return (
                <div className = "flexbox-space-between p-relative date-and-duration">
                  <Passage 
                    key                     = { index }
                    index                   = { index }
                    datePlanned             = { pass.datePlanned } 
                    predictedDuration       = { pass.predictedDuration }
                    changePassageDate       = { changePassageDate }
                    changePassageDuration   = { changePassageDuration }
                    removePassage           = { removePassage }
                    minDate                 = { new Date(workorderState.datePlanned) }
                  />
                </div>
              )
          })}
          
          { 
            workorderState.technicianId && workorderState.passages.length <= 20 &&
              <div className="flex-end ">
                <a className = "small mr-2 text-pointer" 
                    onClick   = { () => addPassages(1) }
                >
                  <FontAwesomeIcon icon="plus-circle" className = "mr-2" />
                  { I18n.t("workorders.add_one_passage") }
                </a>
              </div>
          }

          { 
            workorderState.technicianId && workorderState.passages.length <= 15 &&
              <div className="flex-end">
                <a className = "small mr-2 text-pointer" 
                    onClick = { () => addPassages(5) }>
                  <FontAwesomeIcon icon="plus-circle" className = "mr-2" />
                  { I18n.t("workorders.add_five_passages") }
                </a>
              </div>
          }
        </>
      }
    </>
  )
}