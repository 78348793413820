import React from 'react'

const SmallCard = ({e}) => {
  let smallCard
  let position
  if (e.externalCard) {
    smallCard = e.externalCard.info
    position  = e.externalCard.position
  } else {
    if (e.event._def.extendedProps.absence_id) return null

    smallCard = e.event._def.extendedProps.info
    position  = e.jsEvent
  }
  

  return (
    <div className='new-tooltip' style={{top: position.clientY + 5, left: position.clientX + 5}}>
      <div className='tooltip-title'>
        <div>
          <p>
            {smallCard.title}
          </p>
        </div>

        <div className='hour small'>
          <p>
            <i className='far fa-clock blue'></i>
            {smallCard.event_start}
          </p>
        </div>
      </div>

      <div className='tooltip-container'>
        <div className='small'>
          <p>
            {smallCard.description}
          </p>
        </div>

          <div className='small tooltip-feature'>
            <p>
              <i className='fas fa-route'></i>
              {smallCard.travel_time}
            </p>
          </div>

        { smallCard.partner &&
          <div className='small tooltip-feature'>
            <p>
              <i className='fas fa-handshake'></i>
              {smallCard.partner}
            </p>
          </div>
        }

        <div className='small tooltip-feature'>
          <p>
            <i className='fas fa-user-circle'></i>
            {smallCard.client}
          </p>
        </div>

        <div className='small tooltip-feature'>
          <p>
            <i className='fas fa-building'></i>
            {smallCard.site}
          </p>
        </div>
        <div className='small tooltip-feature'>
          <p>
            <i className='fas fa-location-arrow'></i>
            {smallCard.address}
          </p>
        </div>
      </div>
      {smallCard.technician &&
        <div className='technician-feature'>
          <div className='technician-dot' style={{background: smallCard.technician_color}}></div>
          <div>
            <p>
              <b>&nbsp;&nbsp;{smallCard.technician}</b>
            </p>
          </div>
        </div>
      }
    </div>
  ) 
}

export default SmallCard