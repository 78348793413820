import React, { useState, useEffect, useRef } from 'react'
import sanitizeHtml from 'sanitize-html-react'

import Combobox      from '@shared/combobox'
import { getRequest, pathname } from '@helpers/javascript/javascript'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TYPES = {
  client:   'client',
  site:     'site',
  material: 'material'
}

const DEFAULTS = {
  disabled: {
    client: false,
    site: false,
    material: false
  },
  required:   {
    client: true,
    site: true,
    material: true
  },
  showLabels: {
    client: true,
    site: true,
    material: true
  },
  inputNames: {
    client: "client_id",
    site: "site_id",
    material: "material_id",
    contact: "contact_id"
  },
  labels: {
    client:   I18n.t("client.client"),
    site:     I18n.t("sites.site"),
    address:  I18n.t("workorders.address"),
    contact:  I18n.t("contacts.contact"),
    material: I18n.t("materials.material")
  },
  placeholder: {
    client:      I18n.t("workorders.select_client"),
    site:        I18n.t("workorders.select_site"),
    contact:     I18n.t("workorders.select_contact"),
    no_contact:  I18n.t("client.no_contact"),
    material:    I18n.t("workorders.select_material"),
    noMaterial:  I18n.t("maintenance.no_material")
  }
}

const toDataSet = dataObject => {
  const data  = {}
  Object.keys(dataObject).forEach(key =>{
    const dataKey = "data-" + key.toString()
    data[dataKey] = dataObject[key]
  })
  return data
}

const feedUrl = (clientId, params = {}) => {
  const path   = `/clients/${clientId}/build`
  const format = 'json'
  const url    = pathname({ path, format, params })
  return url
}

const filterContacts = (client, site) => {
  if (!client.id) return []

  return client.contacts.filter(contact =>
    !contact.site || contact.site.id == site.id
  )
}

const AssetSelect = props => {
  const clientRef   = useRef(null)
  const siteRef     = useRef(null)
  const contactRef  = useRef(null)
  const materialRef = useRef(null)
  const didMountRef = useRef(null)

  const {
    name,
    type     = TYPES.client,
    data     = {},
    options  = {},
    datasets = {},
    onChange = false
  } = props

  const {
    displayClient      = true,
    displayAddress     = false,
    displayContact     = false,
    displayHistory     = false,
    displayUnpaid      = false,
    displayObservation = false,
    displayLink        = false,
    printData          = false
  } = options

  const disabled    = { ...DEFAULTS.disabled,  ...options.disabled    || {} }
  const showLabels  = {...DEFAULTS.showLabels,  ...options.showLabels  || {} }
  const inputNames  = {...DEFAULTS.inputNames,  ...options.inputNames  || {} }
  const required    = {...DEFAULTS.required,    ...options.required    || {} }
  const labels      = {...DEFAULTS.labels,      ...options.labels      || {} }
  const placeholder = {...DEFAULTS.placeholder, ...options.placeholder || {} }

  const dataClient   = toDataSet(datasets.client || {})
  const dataSite     = toDataSet(datasets.site || {})
  const dataMaterial = toDataSet(datasets.material || {})
  const dataContact  = toDataSet(datasets.contact || {})

  const [state, setState] = useState({
    client:   {},
    site:     {},
    material: {},
    contact:  {},
    mounted:  false
  })

  const { client, site, material, contact, mounted } = state

  const sites     = client.sites    || []
  const materials = site.materials  || []
  const contacts  = filterContacts(client, site)

  useEffect(() => {
    if (data.client) {
      getRequest(feedUrl(data.client, { include_contacts: displayContact }))
      .then(client => {
        const site     = data.site     ? client.sites.find(clientSite => clientSite.id == data.site) || {} : {}
        const contact  = data.contact  ? client.contacts.find(clientContact => clientContact.id == data.contact) || {} : {}
        const material = data.material ? site.materials.find(siteMaterial => siteMaterial.id == data.material) || {} : {}
        setState({ client, site, material, contact, mounted: true })
      })
    } else if(data.sites && data.site) {
      const site = data.sites.find(site => site.id == data.site) || {}
      setState({...state, site, mounted: true })
    } else {
      setState({...state, mounted: true })
    }
  }, [])

  useEffect(() => {
    if(didMountRef.current && client && clientRef.current && datasets.client) {
      clientRef.current.dispatchEvent(new Event("change"))
    }
  }, [client])

  useEffect(() => {
    if(didMountRef.current && site && siteRef.current && datasets.site) {
      siteRef.current.dispatchEvent(new Event("change"))
    }
  }, [site])

  useEffect(() => {
    if(didMountRef.current && material && materialRef.current && datasets.material) {
      materialRef.current.dispatchEvent(new Event("change"))
    }
  }, [material])

  useEffect(() => {
    if(didMountRef.current && contact && contactRef.current && datasets.contact) {
      contactRef.current.dispatchEvent(new Event("change"))
    }
  }, [contact])

  useEffect(() => {
    if(mounted) { didMountRef.current = true }
  }, [mounted])

  const updateState = newState => {
    if (onChange) onChange(newState)
    setState(newState)
  }

  const selectClient = newClient => {
    setState({ client: {}, site: {}, material: {}, contact: {} })

    if(newClient?.id) {
      getRequest(feedUrl(newClient.id, { include_contacts: displayContact }))
      	.then(clientResponse => {
          updateState({
          	client:   clientResponse,
          	site:     clientResponse.sites.length == 1 ? clientResponse.sites[0] : {},
          	material: {},
          	contact:  {}
          })
        })
    } else if (onChange) {
      onChange({ client: {}, site: {}, material: {}, contact: {} })
    }
  }

  const feedSite = newSite => {
    if(newSite?.client) {
      getRequest(feedUrl(newSite.client)).then(clientResponse => {
        const clientSite = clientResponse.sites.find(clientSite => clientSite.id == newSite.id)
        updateState({
          client:   clientResponse,
          site:     clientSite,
          material: {},
          contact:  {}
        })
      })
    }
  }

  const selectSite = newSite => {
    updateState({
      ...state,
      site:     newSite || {},
      material: {}
    })
  }

  const selectContact = newContact => {
    updateState({ ...state, contact: newContact || {} })
  }

  const selectMaterial = newMaterial => {
    updateState({ ...state, material: newMaterial || {} })
  }

  const clientObject  = { id: client.id, name: client.name }
  const clientOptions = client.id ? [clientObject] : data.clients || []
  const clientFeed    = data.clients ? null : "/clients/autocomplete.json"
  const siteFeed      = data.sites || client.id ? null : "/sites/autocomplete.json"

  return(
    <>
      { displayClient &&
        <div className="form-group">
          {showLabels.client &&
            <label required={required.client}>{labels.client}</label>
          }

          <Combobox
            options         = {clientOptions}
            value           = {client.id}
            required        = {required.client}
            disabled        = {disabled.client}
            onSelectElement = {selectClient}
            feed            = {clientFeed}
            placeholder     = {placeholder.client}
            onReset         = {() => selectClient()}
            resetIcon
          />

          { name &&
            <input
              name         = {`${name}[${inputNames.client}]`}
              defaultValue = {client.id}
              ref          = {clientRef}
              hidden
              {...dataClient}
            />
          }

          <div className="client-details">
            { displayObservation && client.observation &&
              <div className="no-margins observation mt-2"
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(client.observation) }}
              >
              </div>
            }

            { displayUnpaid && client.unpaid_invoices && client.unpaid_invoices.count > 0 &&
              <div className="mt-2">
                <a href={client.unpaid_invoices.url}
                   className="pointer color-open"
                   target="_blank"
                >
                  { I18n.t(
                    "client.unpaid_invoices",
                    { number: client.unpaid_invoices.count }
                    )
                  }
                </a>
              </div>
            }

            { displayLink && client.modal_url &&
              <div
                data-toggle = "modal"
                data-target = "#template-modal"
                data-route  = {client.modal_url}
                data-action = "click->client--modal#show"
                className   = "pointer button-link mt-2"
              >
                { I18n.t("client.folder") }
              </div>
            }
          </div>
        </div>
      }

      { name && client.id && !displayClient &&
        <input
          name         = {`${name}[${inputNames.client}]`}
          defaultValue = {client.id}
          ref          = {clientRef}
          hidden
          {...dataClient}
        />
      }

      {/*Site*/}
      { (type == TYPES.material || type == TYPES.site) &&
        <div className="form-group">
          {showLabels.site &&
            <label required={required.site}>{labels.site}</label>
          }

          <Combobox
            options         = {data.sites || sites}
            value           = {site.id}
            required        = {required.site}
            disabled        = {disabled.site}
            onSelectElement = {siteFeed ? feedSite : selectSite}
            placeholder     = {placeholder.site}
            feed            = {siteFeed}
            onReset         = {() => selectSite()}
            resetIcon
          />

          { name &&
            <input
              name         = {`${name}[${inputNames.site}]`}
              defaultValue = {site.id}
              ref          = {siteRef}
              required     = {required.site}
              hidden
              {...dataSite}
            />
          }

          <div className="client-details">
            { displayObservation && site.observation &&
              <div className="no-margins observation mt-2"
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(site.observation) }}
              >
              </div>
            }

            { displayLink && site.modal_url &&
              <div
                data-toggle = "modal"
                data-target = "#template-modal"
                data-route  = {site.modal_url}
                data-action = "click->client--modal#show"
                className   = "pointer button-link mt-2 mr-1"
              >
                { I18n.t("sites.folder") }
              </div>
            }

            { displayHistory && site.history_url &&
              <a href      ={site.history_url}
                 target    ="_blank"
                 className = "pointer button-link mt-2"
              >
                {I18n.t('sites.site_history')}
                <FontAwesomeIcon icon='external-link-alt' className="ml-2"/>
              </a>
            }
          </div>
        </div>
      }

      { displayAddress && site.address &&
        <div className="form-group">
          {showLabels.site &&
            <label required={required.address}>{labels.address}</label>
          }
          <input
            className="form-control not-disabled"
            value={site.address}
            disabled
          />
        </div>
      }

      {/*Contact*/}
      { displayContact  &&
        <div className="form-group">
          <label required={required.contact}>{labels.contact}</label>

          <Combobox
            display         = "name"
            options         = {contacts}
            value           = {contact.id}
            required        = {required.contact}
            onSelectElement = {selectContact}
            data            = {dataContact}
            onReset         = {() => selectContact()}
            resetIcon
            placeholder     = {
              client.id && contacts.length == 0
              ? placeholder.no_contact
              : placeholder.contact
            }
          />

          { name &&
            <input
              name         = {`${name}[${inputNames.contact}]`}
              defaultValue = {contact.id}
              ref          = {contactRef}
              required     = {required.contact}
              hidden
              {...dataContact}
            />
          }
        </div>
      }

      {/*Material*/}
      { type == TYPES.material &&
        <div className="form-group">
          {showLabels.material &&
            <label required={required.material}>{labels.material}</label>
          }

          <Combobox
            display         = "name"
            options         = {materials}
            value           = {material.id}
            required        = {required.material}
            disabled        = {disabled.material}
            onSelectElement = {selectMaterial}
            data            = {dataMaterial}
            onReset         = {() => selectMaterial()}
            resetIcon
            placeholder     = {
              site.id && materials.length == 0
                ? placeholder.noMaterial
                : placeholder.material
            }
          />

          <div className="client-details">
            { displayLink && material.modal_url &&
              <div
                data-toggle = "modal"
                data-target = "#template-modal"
                data-route  = {material.modal_url}
                data-action = "click->client--modal#show"
                className   = "pointer button-link mt-2 mr-1"
              >
                { I18n.t("sites.folder") }
              </div>
            }
          </div>

          { name &&
            <input
              name         = {`${name}[${inputNames.material}]`}
              defaultValue = {material.id}
              ref          = {materialRef}
              required     = {required.material}
              hidden
              {...dataMaterial}
            />
          }
        </div>
      }

      { printData &&
        <div id="asset-print" hidden>
          {JSON.stringify(state)}
        </div>
      }
    </>
  )
}

export default AssetSelect
