import React from 'react'

export const StepPhoto = () => {
  return (
    <>
      <textarea        
        placeholder     = {I18n.t("workorder_demands.new.enter_comment")}
        className       = "demands--comments"
        name            = "workorder_demand[description]"
        data-controller = "autoSize"
        data-target     = "autoSize.value"
        data-action     = "input->autoSize#changeHeight"
      />

      <div className="demands--tab__photo"
       data-controller="workorders--demands--photos"
       data-workorders--demands--photos-input-name="demand_pictures[]"
      >
        <input
          type        = "file"
          accept      = "image/*"
          data-action = "change->workorders--demands--photos#upload"
          data-target = "workorders--demands--photos.input"
          capture     = "camcorder"
          hidden
        />

        <div
          className   = "demands--btn__camera"
          data-action = "click->workorders--demands--photos#click"
          data-target = "workorders--demands--photos.button"
        >
          <div className="demands--btn__camera--icon">
            <i className="fas fa-camera"></i>
          </div>
        </div>

        <div
          data-target = "workorders--demands--photos.container"
          className   = "demand-photo-grid"
        />
      </div>  
    </>
  )
}
