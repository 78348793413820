import React, { useState, useEffect } from "react"
import { ResponsiveAreaBump } from '@nivo/bump'

export const FilteredAmountRepartition = ({ data }) => {
  const STATUS_HEIGHT = 400
  const [amounts, setAmounts] = useState([{id: '', color: "#000000", data: [{x: 0, y: 0}]}])
  useEffect(() => setAmounts(data), [data])
  return (
    <div className="dashboard__card" style={{ height: `${STATUS_HEIGHT}px` }}>
      <ResponsiveAreaBump
        data       = {amounts}
        margin     = {{ top: 40, right: 150, bottom: 40, left: 150 }}
        spacing    = {10}
        colors     = {line => line.color}
        blendMode  = "multiply"
        startLabel = "id"
        tooltip    = { ({ serie }) => (
          <div className="dashboard__tooltip">
            <strong>{serie.id}</strong>
          </div>
        )}
        axisTop={{
          tickSize:       5,
          tickPadding:    5,
          tickRotation:   0,
          legend:         '',
          legendPosition: 'middle',
          legendOffset:   -36
        }}
        axisBottom={{
          tickSize:       5,
          tickPadding:    5,
          tickRotation:   0,
          legend:         '',
          legendPosition: 'middle',
          legendOffset:   32
        }}
      />
    </div>
  )
}
