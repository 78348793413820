import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas }             from '@fortawesome/free-solid-svg-icons'
import Combobox            from "@shared/combobox"

library.add(fas)

const nameFor = (task, attribute) => `workorder[maintenance_tasks_attributes][${task.material.id}][${attribute}]`

const TaskInputs = ({ tasks, materials }) => {
  const findMaterial = task => materials.find(material => task.material.id == material.id)

  const tasksToCreate  = tasks.filter(task => !task.id &&  findMaterial(task))
  const tasksToDestroy = tasks.filter(task =>  task.id && !findMaterial(task))

  return (
    <React.Fragment>
      { tasksToCreate.map(task =>
          <input
            name           = {nameFor(task, "material_id")}
            defaultValue   = {task.material.id}
            key            = {task.material.id}
            hidden
          />
        )
      }

      { tasksToDestroy.map(task =>
          <React.Fragment key = {task.id}>
            <input
              name           = {nameFor(task, "id")}
              defaultValue   = {task.id}
              hidden
            />
            <input
              name           = {nameFor(task, "_destroy")}
              defaultValue   = {1}
              hidden
            />
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

const TypeReactive = ({ tasks, materialsIds = [] }) => {
  const siteMaterials = tasks.map(task => task.material)

  const initialMaterials = () => {
    let    selectedTasks  = tasks.filter(task => materialsIds.includes(task.material.id))
    return selectedTasks.map(task => task.material)
  }

  const [materials, setMaterials] = useState(initialMaterials())

  const availableMaterials = siteMaterials.filter(siteMaterial =>
    !materials.find(material => material.id == siteMaterial.id)
  )

  const pushMaterial = material => {
    if(material?.id) setMaterials([...materials, material])
  }

  const popMaterial = material => {
    setMaterials(materials.filter(m => m.id != material.id))
  }

  if(availableMaterials.length == 0 && materials.length == 0) return null

  return(
    <div className="form-group">
      <label>{ I18n.t("navigation.materials") }</label>
      { materials.map(material =>
        <div
          key       = { material.id }
          className = "d-flex client-details gap-5"
        >
          <a href   = { material.path }
             target = "_blank"
             tooltip = { I18n.t("materials.folder") }
          >
            { material.name }
            &nbsp;
            <FontAwesomeIcon icon = "external-link-alt"/>
          </a>

          <a href    = { material.history }
             target  = "_blank"
             tooltip = { I18n.t("materials.see_history") }
          >
            <FontAwesomeIcon icon = "history"/>
          </a>

          <a onClick={ e => popMaterial(material) }>
            <FontAwesomeIcon icon="times" className="txt-blue"/>
          </a>

        </div>
      )}

      { availableMaterials.length > 0 &&
        <div className="form-group">
          <Combobox
            value           = ""
            options         = {availableMaterials}
            onSelectElement = {pushMaterial}
            prompt          = {I18n.t("workorders.select_material")}
          />
        </div>
      }
      <TaskInputs materials={materials} tasks={tasks} />
    </div>
  )
}

export default TypeReactive
