import React           from "react"
import Passage         from "./passage"
import SharedFlatpickr from "@shared/flatpickr"

export default class AddPassages extends React.Component {
  maxPassages = 20

  constructor(props) {
    super(props)
    this.state = {
      passages:           [],
      date_planned:       Date.parse(this.props.date_planned),
      next_date:          Date.parse(this.props.date_planned),
      predicted_duration: this.props.predicted_duration,
      extra:              true,
      inputValue:         1
    }
    this.predictedInput = React.createRef()
  }

  // TO REFACTOR
  // This is a dirty way to update the react component externally
  // The reason behind this is that the maintenance tasks predicted duration
  // needs to update this react component but the whole form is not completely
  // in react.
  componentDidMount(props, state) {
    this.predictedInput.current.addEventListener("change", this.forceUpdatePredicted)
  }

  forceUpdatePredicted = e => {
    const predicted_duration = parseInt(this.predictedInput.current.value) || 0
    this.setState({ predicted_duration })
  }

  addPassages = e => {
    e.preventDefault()
    const { passages, next_date, predicted_duration, extra } = this.state
    let date         = next_date
    let times        = this.state.inputValue || 1
    const technician = document.querySelector("#tech_id").value
    // For the moment it is not in the react component, so it is needed to access the DOM
    // until the multi tech selector(checkboxes), are refactored

    while(passages.length < this.maxPassages && times > 0) {
      date = this.getNextDate(date)
      passages.push({ date, technician, predicted_duration, extra })
      times--
    }
    this.setState({ passages, next_date: date, inputValue: 1 })
  }

  removePassage = index => {
    const passages = this.state.passages
    passages.splice(index, 1)
    passages.sort((a,b) => (a.date > b.date) ? 1 : -1)
    const next_date = passages[passages.length - 1]?.date || Date.parse(this.props.date_planned)
    this.setState({ passages, next_date })
  }

  changePassageTechnician = (index, technician) => {
    const passages = this.state.passages
    passages[index].technician = technician
    this.setState({ passages })
  }

  changePredictedDuration = dates => {
    const predicted_duration = this.parsePredicted(dates)
    this.setState({ predicted_duration })
  }

  changePassageDuration = (index, dates) => {
    const passages = this.state.passages
    passages[index].predicted_duration = this.parsePredicted(dates)
    this.setState({ passages })
  }

  changeDate = selectedDates => {
    const date_planned = selectedDates[0]
    this.setState({ date_planned, passages: [], next_date: date_planned })
  }

  changePassageDate = (index, date) => {
    const passages = this.state.passages
    passages[index].date = date
    this.setState({ passages, next_date: date })
  }

  onChangeValue = e => {
    const inputValue = e.currentTarget.value || ''
    this.setState({ inputValue })
  }

  toggleExtra = () => {
    const extra = !this.state.extra
    const passages = this.state.passages.map(passage => ({ ...passage, extra }))
    this.setState({ extra, passages })
  }

  togglePassageExtra = index => {
    const passages = this.state.passages
    passages[index].extra = !passages[index].extra
    this.setState({ passages })
  }

  // SOME HELPER METHODS
  getNextDate(date) {
    // The dates they come as integers or date objects
    // It has to be converted to an integer
    if(typeof(date) != "number") { date = date.getTime() }
    // Add one day, and copy the date in another memory address
    const next_date = new Date(date + 86400000)
    // Avoid weekends
    while (next_date.getDay() == 0 || next_date.getDay() == 6) {
      next_date.setDate(next_date.getDate() + 1)
    }
    return next_date
  }

  getDate(duration) {
    const date     = new Date()
    const hours    = duration / 60
    const minutes  = duration % 60
    date.setHours(hours)
    date.setMinutes(minutes)

    return date
  }

  parsePredicted = dates => {
    const hours   = dates[0].getHours()
    const minutes = dates[0].getMinutes()
    return hours * 60 + minutes
  }

  render () {
    const {technicians} = this.props

    return(
      <React.Fragment>
        <div className="flexbox-space-between mobile-double double">
          {/*Date planned*/}
          <div className="form-group">
            <label className="control-label" htmlFor="workorder_date_planned">
              {I18n.t("workorders.due_date")}
            </label>

            <SharedFlatpickr
              options={{
                locale:     I18n.locale,
                enableTime: true,
                dateFormat: "d/m/Y - H:i"
              }}
              name        = "workorder[date_planned]"
              className   = "form-control flatpickr-nobg text-center"
              placeholder = {I18n.t("workorders.datetime_placeholder")}
              onChange    = {this.changeDate}
              value       = {this.state.date_planned}
              dataAction  = "change->workorders--form--techAvailability#checkDates"
              dataTarget  = "workorders--form--techAvailability.datePlanned"
              required
            />
          </div>

          {/*Predicted duration*/}
          <div className="form-group">
            <label className="control-label"
                   htmlFor="workorder_predicted_duration"
            >
              {I18n.t("workorders.predicted_duration")}
            </label>

            <SharedFlatpickr
              options={{
                locale:     I18n.locale,
                enableTime: true,
                noCalendar: true
              }}
              id          = "workorder_predicted_duration"
              className   = "form-control flatpickr-nobg text-center"
              onClose     = {this.changePredictedDuration}
              value       = {this.getDate(this.state.predicted_duration)}
              dataAction  = "change->workorders--form--techAvailability#checkDates"
              dataTarget  = "workorders--form--techAvailability.predictedDuration"
            />

            <input type="hidden"
                   id="hidden_predicted_duration"
                   name="workorder[predicted_duration]"
                   defaultValue={this.state.predicted_duration}
                   ref={this.predictedInput}
            />
          </div>
        </div>

        <div className="mb-5" id="workorder_passages">
          {/*Passages Header*/}
          {this.state.passages.length > 0 &&
            <div className="passages-grid">
              <div className="passages-grid__header">
                <div>
                  <label>
                    {I18n.t('workorders.date_planned')}
                  </label>
                </div>

                <div>
                  <label>
                    {I18n.t("workorders.predicted_duration")}
                  </label>
                </div>

                <div>
                  <label>
                    {I18n.t('workorders.technician')}
                  </label>
                </div>

                <div className="checkbox checkbox-primary d-flex pl-2">
                  <input type     = "checkbox"
                         id       = "passages_extra"
                         checked  = {this.state.extra}
                         onChange = {this.toggleExtra}
                  />
                  <label htmlFor="passages_extra"/>
                </div>

                <div className="pointer text-blue d-flex-center minus-circle mr-0"
                     tooltip={I18n.t("workorders.passages_include_extra")}
                     onClick={this.toggleExtra}
                >
                  <i className="fas fa-info-circle" ></i>
                </div>
              </div>

              {this.state.passages.map((passage, index) =>
                <Passage
                  key                     = {index}
                  index                   = {index}
                  passage                 = {passage}
                  technicians             = {technicians}
                  removePassage           = {this.removePassage}
                  changePassageTechnician = {this.changePassageTechnician}
                  changePassageDate       = {this.changePassageDate}
                  changePassageDuration   = {this.changePassageDuration}
                  togglePassageExtra      = {this.togglePassageExtra}
                />
              )}
            </div>
          }

          { this.state.passages.length < this.maxPassages &&
            <div className="flex-end">
              <a className="small mr-2" href="#" onClick={this.addPassages}>
                <i className="fa fa-plus-circle mr-2"></i>
                {I18n.t("workorders.add_more_passages")}
              </a>

              <input
                type      = "number"
                step      = "1"
                className = "form-control ml-2 flex-item-5 text-right pointer"
                value     = {this.state.inputValue}
                onChange  = {this.onChangeValue}
                onFocus   = {() => this.setState({ inputValue: ''})}
              />
            </div>
          }

        </div>
      </React.Fragment>
    )
  }
}
