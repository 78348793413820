import React           from 'react'
import SharedFlatpickr from "@shared/flatpickr"

const MiniCalendar = ({calendar, currentDate, showWeekends}) => {
  const goToDate = dateArray => {
    const [date] = dateArray
    calendar.gotoDate(moment(date).format('YYYY-MM-DDTHH:mm:ss'))
  }

  const disableWeekends = (date) => {
    return showWeekends ? false : (date.getDay() === 0 || date.getDay() === 6);
  }

  return (
    <div className="flatpickr-wrapper">
      <SharedFlatpickr
        value={currentDate || new Date()}
        onChange={goToDate}
        options={{
          inline: true,
          altInput: true,
          altInputClass: "hidden",
          disable: [disableWeekends],
          locale: I18n.locale
        }}
      />
    </div>
  )
}

export default MiniCalendar
