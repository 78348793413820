import React, { useState, useEffect, useRef } from 'react'

// Helpers
import sanitizeHtml from 'sanitize-html-react'

// To use this component :
// Action you want to apply <CheckBox onChange={function} />
// Default value <CheckBox checked={true || false} />

const Checkbox = ({
  id,
  onChange,
  name,
  checked,
  disabled,
  color,
  className = "",
  value     = '1',
  label     = "&nbsp;"
}) => {
  const didMountRef = useRef(false)
  const [isChecked, setIsChecked] = useState(checked)

  const onCheck = e => {
    setIsChecked(e.target.checked)
    if (onChange) onChange(e.target.checked)
  }

  useEffect(() => {
    if(didMountRef.current) {
      setIsChecked(checked)
    } else {
      didMountRef.current = true
    }
  }, [checked])

  return (
    <div className={`checkbox ${className} ${color ? 'custom-color' : 'checkbox-primary'}`}>
      <input
        id           = {id}
        type         = "checkbox"
        name         = {name}
        onChange     = {onCheck}
        checked      = {isChecked}
        defaultValue = {value}
      />

      <label
        htmlFor={id}
        className={disabled ? "checkbox-disabled" : ""}
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(label) }}
        style={color ? {"--checkbox-color": color} : {}}
      />
    </div>
  )
}

export default Checkbox
