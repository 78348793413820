import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const canSubmit = (qline) => {
  if (!qline.active) return false

  switch(qline.type) {
    case "break":       return true
    case "subtotal":    return true
    case "blueprint":   return false
    case "group":       return false
    case "template":    return false
    case "descriptive": return true
    case "product":
      return (
        (qline.quantity && qline.quantity > 0 && qline.quantity >= (qline.quantity_invoiced || 0)) &&
        (qline.unit_price && qline.unit_price != 0) &&
        qline.product.id && qline.vat_rate.id
      )
    case "regular":
      return (
        (qline.quantity && qline.quantity > 0) &&
        (qline.unit_price && qline.unit_price != 0) &&
        qline.vat_rate.id
      )
  }
}

// Propagation is stopped because it will activate or deactivate the lines
export default class Buttons extends React.Component {
  constructor(props) {
    super(props)
    this.state         = {order: props.index + 1, ordering: false}
    this.deactivate    = this.deactivate.bind(this)
    this.destroy       = this.destroy.bind(this)
    this.add           = this.add.bind(this)
    this.onChange      = this.onChange.bind(this)
    this.activateOrder = this.activateOrder.bind(this)
    this.order         = this.order.bind(this)
    this.applyOrder    = this.applyOrder.bind(this)
    this.orderRef      = React.createRef()
  }

  add(e) {
    e.stopPropagation()
    const { index }   = this.props
    const { addLine } = this.props.buttonProps

    addLine("regular", index + 1)
  }

  destroy(e) {
    e.stopPropagation()
    const { index,  qline } = this.props
    const { replaceLine }   = this.props.buttonProps
    if(!qline.can_be_deleted) return

    replaceLine({...qline, destroyed: true}, index)
  }

  deactivate() {
    const { index, qline }  = this.props
    const { replaceLine }   = this.props.buttonProps
    replaceLine({...qline, active: false}, index)
  }

  onChange(e) {
    e.preventDefault()
    const { index, qline } = this.props
    const { replaceLine }  = this.props.buttonProps
    const type = e.target.value
    const withoutValue = {
      unit:           undefined,
      quantity:       undefined,
      unit_price:     undefined,
      purchase_price: undefined
    }
    if (type == "product") {
      replaceLine({...qline, type}, index)
    } else if (type == "break") {
      replaceLine({...qline, type, ...withoutValue, active: false}, index)
    } else if (type == "blueprint") {
      replaceLine({...qline, type}, index)
    } else if (type == "subtotal") {
      replaceLine({...qline, type, ...withoutValue, active: false, description: I18n.t("quotes.bigcard.subtotal")}, index)
    } else if (type == "group") {
      replaceLine({...qline, type}, index)
    } else if (type == "template") {
      replaceLine({...qline, type}, index)
    } else if (type == "descriptive") {
      replaceLine({...qline, type, ...withoutValue}, index)
    } else if (type == "regular") {
      replaceLine({...qline, type}, index)
    } else if (type == "import_csv") {
      replaceLine({...qline, type}, index)
    }
  }

  activateOrder(e) {
    e.stopPropagation()
    this.setState({ordering: true})
  }

  order(e) {
    e.preventDefault()
    const value = parseInt(e.target.value)
    if (value && (value < 0)) return

    this.setState({order: e.target.value})
  }

  applyOrder(e) {
    e.stopPropagation()
    if (!this.state.order) return

    const newIndex = parseInt(this.state.order)
    if (!newIndex) return

    const { moveLine } = this.props.buttonProps
    this.setState({ordering: false, order: newIndex})
    moveLine(this.props.index, newIndex - 1)
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.ordering == this.state.ordering) return
    if (!this.orderRef.current) return

    this.orderRef.current.focus()
  }

  render() {
    const { index, qline } = this.props
    const { new_record, allowCsvLine, allowProducts } = this.props.buttonProps

    const deleteButtonStyle = qline.can_be_deleted ? {} : { cursor: 'not-allowed' };
    const deleteButtonTooltip = qline.can_be_deleted
        ? I18n.t("quotes.new.remove_line")
        : I18n.t("quotes.new.remove_line_not_allowed");

    return(
      <React.Fragment>
        { qline.active
          ? <div className="text-input">
              <label>
                {I18n.t('quotes.new.qline_type')}
              </label>

              <select
                className="form-control"
                value={qline.type}
                onChange={this.onChange}
              >
                <option value="regular">
                  {I18n.t('quotes.new.line')}
                </option>

                <option value="group">
                  {I18n.t('quotes.new.qline_group')}
                </option>

                { new_record &&
                  <option value="template">
                    {I18n.t('quotes.new.template')}
                  </option>
                }

                <option value="blueprint">
                  {I18n.t('quotes.new.qline_blueprint')}
                </option>

                <option value="descriptive">
                  {I18n.t('quotes.new.descriptive_line')}
                </option>

                { allowProducts &&
                  <option value="product">
                    {I18n.t('quotes.new.product')}
                  </option>
                }

                <option value="break">
                  {I18n.t('quotes.new.page_break')}
                </option>

                <option value="subtotal">
                  {I18n.t('quotes.new.subtotal')}
                </option>

                { allowCsvLine &&
                  <option value="import_from_csv">
                    {I18n.t('quotes.new.import_from_csv')}
                  </option>
                }
              </select>
            </div>
          : ""
        }

        {
          canSubmit(qline) || this.props.submit
          ? <div className="confirm-button"
                 tooltip={I18n.t("quotes.new.close_editor")}
                 tooltip_position="right"
            >
              <FontAwesomeIcon
                icon="check-circle"
                onClick={this.props.submit || this.deactivate}
              />
            </div>
          : ""
        }

        <div className="quotes--form-line-buttons left">
          <div tooltip={deleteButtonTooltip} tooltip_position="left">
            <FontAwesomeIcon
              className="default"
              style={deleteButtonStyle}
              icon="minus-circle"
              onClick={this.destroy}
            />
          </div>

          <div tooltip={I18n.t("quotes.new.add_line_below")} tooltip_position="left">
            <FontAwesomeIcon
              className="default"
              icon="plus-circle"
              onClick={this.add}
            />
          </div>
        </div>

        { this.state.ordering
          ? <div
              className="quotes--form-line-buttons ordering"
              onClick={e => e.stopPropagation()}
            >
              <input
                type="number"
                step="1"
                className="form-control"
                value={this.state.order}
                onChange={this.order}
                ref={this.orderRef}
              />

              <FontAwesomeIcon icon="check-circle" onClick={this.applyOrder} />
            </div>
          : <div
              className="quotes--form-line-buttons right"
              onClick={this.activateOrder}
              tooltip={I18n.t("quotes.new.modify_order")}
              tooltip_position="right"
            >
              {index + 1}
            </div>
        }
      </React.Fragment>
    )
  }
}
