import React from "react"

export default class Base extends React.Component {
  constructor(props) {
    super(props)
    this.activate = this.activate.bind(this)
    this.deactivate = this.deactivate.bind(this)
    if (this.onChange) this.onChange = this.onChange.bind(this)
    this.state = {active: false}
    this.inputRef = React.createRef()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.inputRef.current && prevState.active != this.state.active) {
      this.inputRef.current.focus()
    }
  }

  activate() {
    this.setState({active: true})
  }

  deactivate() {
    this.setState({active: false})
  }
}
