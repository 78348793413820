export const openModale = (type, key) => ({
  type: MODALE.OPEN,
  payload: { type, key }
})

export const closeModale = () => ({
  type: MODALE.CLOSE
})

// Dispatching through Redux
export const openModaleAction = (type, key) => {
  return dispatch => { dispatch(openModale(type, key)) }
}

export const closeModaleAction = () => {
  return dispatch => { dispatch(closeModale()) }
}

// Instantiate actions names
export const MODALE = {
  OPEN:  'OPEN_MODALE',
  CLOSE: 'CLOSE_MODALE',

  openResponse: ({ state, display, refreshIndex, type, index, key } = {}) => ({
    ...state, display, refreshIndex, type, index, key
  }),

  closeResponse: ({ state } = {} ) => ({ ...state })
}
