import React, { useState, useEffect, useRef }  from "react"

const HEIGHT = 80
const TextArea = props => {
  const {
    id,
    name,
    defaultValue,
    title,
    onChange,
    placeholder,
    className = "form-control",
    required = false,
    label = null
  } = props
  const [height, setHeight] = useState(`${HEIGHT}px`)
  const [value, setValue] = useState(defaultValue || '')
  useEffect(() => setValue(defaultValue), [defaultValue])
  const valueRef = useRef(null)

  useEffect(() => {
    if (valueRef.current.scrollHeight > HEIGHT) {
      setHeight(valueRef.current.scrollHeight + 'px')
    }
  }, [value])

  const handleChange = e => {
    setValue(e.target.value)
    if (onChange) onChange(e.target.value)
  }

  return (
    <div className = "form-group">
      {label && 
        <label 
          className = "control-label" 
          required  = { required }
        >
          { label }
        </label>
      }
      <textarea
        ref         = { valueRef } 
        id          = { id }
        name        = { name }
        title       = { title }
        className   = { className }
        value       = { value || '' } 
        onChange    = { handleChange } 
        required    = { required } 
        placeholder = { placeholder }
        style       = {{ height }}
      />
    </div>
  )
}

export default TextArea