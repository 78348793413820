import React, { useEffect, useState, useRef } from 'react';
import { pathname, postRequest } from '@helpers/javascript/javascript';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import sanitizeHtml from 'sanitize-html-react';

const ChatbotBox = () => {
  const messagesEndRef = useRef(null);
  const chatBotRef     = useRef(null);

  const [state, setState] = useState({
    messages: [],
    conversation_id: null,
    input: '',
    isOpen: false,
    isBotTyping: false,
  });

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (state.input.trim() === '') return;

    const newMessage = { sender: 'user', text: state.input };
    setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, newMessage],
      input: '',
      isBotTyping: true,
    }));

    const body = { input: state.input, conversation_id: state.conversation_id };

    const path = '/chatbots';
    const url    = pathname({ path })
    postRequest({ url, body }).then(response => {
      setState((prevState) => ({
        ...prevState,
        isBotTyping: false,
        conversation_id: response.conversation_id,
        messages: [...prevState.messages, { sender: 'bot', text: response.message }],
      }));
    }).catch(error => {
      setState((prevState) => ({
        ...prevState,
        isBotTyping: false,
        messages: [
          ...prevState.messages,
          { sender: 'bot', text: I18n.t('chatbox.error_message') },
        ],
      }))
    });
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [state.messages]);

  useEffect(() => {
    if (!state.isOpen) return;

    const handleClickOutside = (event) => {
      if (chatBotRef.current && !chatBotRef.current.contains(event.target)) {
        setState((prevState) => ({ ...prevState, isOpen: false }));
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [state.isOpen]);

  const toggleChatBot = () => {
    setState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }));
  };

  return (
    <div className="chat-bot-container">
      {state.isOpen ? (
        <div className="chat-bot open" ref={chatBotRef}>
          <div className="chat-bot-header">
            <button className="close-button" onClick={toggleChatBot}>
              &times;
            </button>
            {I18n.t('chatbox.welcome_message')}
          </div>
          <div className="chat-bot-body">
            <div className="chat-messages">
              <div className={`chat-message bot`} key="002">
                <div className="chat-message-bubble"
                     dangerouslySetInnerHTML={{ __html: I18n.t('chatbox.intro_message') }}
                />
              </div>
              {state.messages.map((msg, idx) => (
                <div
                  key={idx}
                  className={`chat-message ${msg.sender} ${
                    msg.sender === 'user' ? 'owned' : ''
                  }`}
                >
                  <div
                    className="chat-message-bubble"
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(msg.text) }}
                  />
                </div>
              ))}
              {state.isBotTyping && (
                <div className="chat-message bot typing-indicator">
                  {I18n.t('chatbox.bot_is_typing')}
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
            <form className="chat-input" onSubmit={handleSendMessage}>
              <input
                type="text"
                placeholder={I18n.t('chatbox.type_your_message')}
                value={state.input}
                onChange={(e) => setState({ ...state, input: e.target.value })}
              />
              <button type="submit">{I18n.t('button.send')}</button>
            </form>
          </div>
        </div>
      ) : (
        <button className="chat-bot-toggle-button" onClick={toggleChatBot}>
          <FontAwesomeIcon icon={faComments} size="2x" />
        </button>
      )}
    </div>
  );
};

export default ChatbotBox;
