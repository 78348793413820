import React from 'react'


export const StockDisplay = ({ stock }) => {

  return(
    <div className="product-stock-modify-modal flex-end pt-0 pb-2">
      <div>{ I18n.t("products.currently_in_stock") } &nbsp;</div>
      <div className="product-stock-value">
        { stock.stock_quantity }
      </div>
    </div>
  )
}
// stock[source]
