import React from 'react'

const TimeRange = ({slotMinTime, slotMaxTime, setSlotMinTime, setSlotMaxTime}) => {

  return (
    <div id="calendar-multi-slider">
      <div className="text-center"></div>
      <div className="multi-slider">
        <input
          type="range"
          min="0"
          max="24"
          id="calendar-min-hour"
          value={slotMinTime}
          onChange={(e)=>{setSlotMinTime(e.target.value)}}
          className="slider"

        />

        <input
          type="range"
          min="0"
          max="24"
          id="calendar-max-hour"
          value={slotMaxTime}
          onChange={(e)=>{setSlotMaxTime(e.target.value)}}
          className="slider"

        />
      </div>
      <div className='text-center'>
        {slotMinTime}h - {slotMaxTime}h
      </div>
    </div>
  )
}

export default TimeRange