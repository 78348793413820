import { getRequest }  from '@helpers/javascript/javascript'
import { bodyRequest } from '@helpers/javascript/javascript'
import { PATHS }       from '@helpers/javascript/config/CONSTANTS'

import { addNoticeAction } from '@redux/notices/actions'
import { push }            from 'connected-react-router'

export const fetchUserAction = () => dispatch => getRequest(PATHS.users.fetch)
.then(response => dispatch({
  type: FETCH.USER,
  payload: { user: response.user }
}))

export const fetchSessionAction = (id, unique_session_id) => dispatch => {
  if (id && unique_session_id) {
    return (
      bodyRequest({ url: PATHS.sessions.fetch, body: { id, unique_session_id } })
      .then(response => {
        if (response.unauthorized) {
          const message = I18n.t("devise.failure.unauthenticated")
          const type    = 'error'
          dispatch(addNoticeAction({ message, type }))
        } else if (response.unique_session_id) {
          if (response.unique_session_id != unique_session_id) {
            if (response.hasOwnProperty("form_authenticity_token")) {
              const meta = document.querySelector('meta[name="csrf-token"]')
              meta.setAttribute('content', response.form_authenticity_token)
            }

            dispatch({
              type: FETCH.SESSION,
              payload: { unique_session_id: response.unique_session_id }
            })
            dispatch(push(PATHS.workorders.index))
          }
        }
      })
    )
  }
}

export const FETCH = {
  USER: 'FETCH_USER_ACTION',
  fetchUserResponse: user => user,
  SESSION: 'FETCH_SESSION_ACTION',
  fetchSessionResponse: (state, unique_session_id) => (
    { ...state, unique_session_id }
  )
}
