import { fullcalendar } from 'fullcalendar-scheduler'
import { jsRequest }    from "@react/helpers/fetch"

const calendarInit = () => {
  $(() => {
    const calendarElement = document.getElementById('calendar');
    if (!calendarElement) return

    const calendar = $('#calendar').fullCalendar({
      // put your options and callbacks here
      setOption:           'contentHeight',
      defaultView:          calendarElement.dataset.view,
      defaultDate:          calendarElement.dataset.date,
      nowIndicator:         true,
      schedulerLicenseKey: '0186674522-fcs-1526459371',
      contentHeight:        $('#calendar').height() - 75,
      aspectRatio:          1,
      minTime:              calendarElement.dataset.minTime,
      maxTime:              calendarElement.dataset.maxTime,
      slotWidth:            60,
      editable:             true,
      selectable:           true,
      allDaySlot:           true,
      slotLabelFormat:      'HH',
      timeFormat:           'H:mm',
      scrollTime:           '07:00:00',
      locale:               calendarElement.dataset.locale,
      weekends:             JSON.parse(calendarElement.dataset.weekends),
      columnFormat: {
        weekday:  'short',
        day:      '2-digit',
        month:    'numeric'
      },
      resourceLabelText: I18n.t('calendar.resources'),
      resources: {
        url: '/calendar/tech_list',
        type: 'GET',
        data: {
          view_type: calendarElement.dataset.view
        },
        error: function() { alert('Error fetching teams!') }
      },
      resourceOrder: 'open,title',
      eventSources: [
        {
          url: '/calendar/event_list',
          type: 'GET',
          data: {
            view_type: calendarElement.dataset.view
          },
          error: function() { alert('Error fetching events!') }
        }
      ],
      header: {
        left:   `prev,timelineDay,timelineWeek,timelineMonth,agendaDay,${calendarElement.dataset.viewEnableAgendaWeek == 'true' && 'agendaWeek'},next title`,
        center: false,
        right:  false,
      },
      views: {
        agendaWeek: {
          buttonText:         I18n.t('preferences.calendar.view_agenda'),
          titleFormat:        'DD MMMM, YYYY',
          columnHeaderFormat: 'ddd DD/MM'
        },

        agendaDay: {
          buttonText:        I18n.t('preferences.calendar.view_agenda_day'),
          titleFormat:       'dddd DD MMMM, YYYY'
        },

        timelineDay: {
          buttonText:        I18n.t('preferences.calendar.view_day'),
          titleFormat:       'dddd DD MMMM, YYYY',
          slotLabelFormat:   ['HH'],
          slotDuration:      '00:30',
          slotWidth:         '10',
          resourceAreaWidth: '20%'
        },

        timelineWeek: {
          buttonText:         I18n.t('preferences.calendar.view_week'),
          slotDuration:       '02:00',
          slotWidth:          calendarElement.dataset.slotWidthWeek,
          aspectRatio:        1.6,
          titleFormat:        'DD MMMM, YYYY',
          slotLabelFormat:    ['ddd DD/MM', 'H'],
          resourceAreaWidth:  '20%',
          scrollTime:         moment() - moment().startOf('week') - moment.duration(6, 'hours')
        },

        timelineMonth: {
          buttonText:         I18n.t('preferences.calendar.view_month'),
          type:               'timeline',
          slotDuration:       "24:00",
          slotWidth:          calendarElement.dataset.slotWidthMonth,
          editable:           false,
          selectable:         false,
          titleFormat:        'DD MMMM, YYYY',
          slotLabelFormat:    ['DD', 'ddd'],
          resourceAreaWidth:  '20%',
          scrollTime:         moment() - moment().startOf('month')
        }
      },

      eventResize(event, delta, revertFunc) {
        if (!event.start || !event.end) revertFunc()

        const start = event.start.format('YYYY-MM-DD HH:mm')
        const end   = event.end.format('YYYY-MM-DD HH:mm')
        if (start && end) {
          $.ajax({
            type: 'POST',
            url: '/calendar/update_resize',
            data: {
              authenticity_token: $('[name="csrf-token"]')[0].content,
              event: { workorder_id: event.info.workorder_id, start: start, end: end }
            },
            success () {
              $('#calendar').fullCalendar('refetchEvents')
            },
            error () {
              revertFunc()
            }
          })
        } else {
          return revertFunc()
        }
      },

      eventAfterRender: function(event, element, view) {
        const fixRecurringEventCollision = view => {
          let timeLine = view.name.indexOf('timeline') !== -1;
          $.each($('.fc-bgevent-container'), function() {
            let $col = $(this);
            let $elements = $col.find('.absence-event');
            for (let i=0; i<$elements.length; i++) {
              let collissions = $elements.length
              for (let j=0; j<collissions; j++) {
                if (timeLine) {
                  $elements[i+j].style.height = (100/collissions) + '%';
                  $elements[i+j].style.top = (100/collissions)*(j) + '%';
                } else {
                  $elements[i+j].style.width = (100/collissions) + '%';
                  $elements[i+j].style.left = (100/collissions)*(j) + '%';
                }
              }
              i += collissions;
            }
          });
        }
        fixRecurringEventCollision(view);
      },

      eventDrop(event, delta, revertFunc) {
        const payload = {
          authenticity_token: $('[name="csrf-token"]')[0].content,
          event: {
            workorder_id: event.info.workorder_id,
            time_change:  delta._data,
            resourceId:   event.resourceId
          }
        }

        $.ajax({
          type: 'POST',
          url: '/calendar/update_drop',
          data: payload,
          success () { $('#calendar').fullCalendar('refetchEvents')},
          error   () { revertFunc() }
        })
      },

      eventClick(event) {
        const bigCardContainer = document.getElementById('cards-container');
        const spinnerHTML =
          ` <div class='loading-spinner-container'>
              <div id='loading-spinner'></div>
          </div>
          `
        bigCardContainer.innerHTML = spinnerHTML
        document.getElementById('show-big-card-workorder-modal-btn').click()
        jsRequest(event.info.show_url)
      },

      eventRender (event, element, view) {
        element.find('.fc-content').empty()
        element.css('background-color', '')
        element[0].dataset.resource = event.resourceId

        if (event.rendering == 'background') {
          renderAbsence(event, element)
        } else {
          switch(view.type) {
            case "timelineDay":   return renderTimelineDayEvent(event, element)
            case "timelineWeek":  return renderTimelineWeek(event, element)
            case "timelineMonth": return renderTimeLineMonth(event, element)
            case "agendaDay":     return renderAgendaDay(event, element)
            case "agendaWeek":    return renderAgendaWeek(event, element)
          }
        }
      },

      resourceRender(resourceObj, labelTds, bodyTds) {
        if (resourceObj.color != null) {
          const dot = `<div class="resource-title-dot" style='background-color: ${resourceObj.color}'></div> `
          if (labelTds.find('.fc-cell-text')[0]) {
            labelTds.find('.fc-cell-text').prepend(dot)
          } else {
            labelTds.prepend(dot)
          }
        }
      },

      viewRender(view, element) {
        const selected_date = view.start.format('Y-M-D')
        // Replace date
        const url = `/workorders/calendar?selected_date=${selected_date}`
        const new_state = window.location.origin + url
        history.replaceState(history.state, document.title, new_state)
        // Flatpicker mini calendar date update
        const sideCalendarInput = document.getElementById('flatpickr-field')
        sideCalendarInput.value = selected_date
        document.getElementById('set-flatpickr-date').click()

        // Handle change of view
        if (view.type != calendarElement.dataset.view) {
          changeViewType(view.type)
        }

        // agendaDay view table has to be horizontally scrollable
        if (view.type == "agendaDay") {
          const width = document.querySelector(".main-calendar").clientWidth -
          document.getElementById("calendar-controls").clientWidth -
          50

          const resourceWidth = 150
          const resourceAmount = Object.keys(view.calendar.resourceManager.resourcesById).length
          let scrollWidth = resourceWidth * resourceAmount
          if (scrollWidth < width) scrollWidth = width

          element.css('width', width)
          $(".fc-view-container table").css('width',scrollWidth)
        } else {
          element.css('width', '100%')
          $(".fc-view-container table").css('width','')
        }
      },

      eventAfterAllRender(view) {
        document.querySelectorAll('.calendar-apply-filter').forEach((filterButton) => {
          filterButton.dispatchEvent(new Event("filter"))
        })
      },

      eventMouseover(event, jsEvent, view) {
        const popoversActive = (calendarElement.dataset.popoversActive == 'true')
        if (!popoversActive) return false

        const container = document.createElement('div')

        container.innerHTML = event.tooltip
        container.classList.add('tooltiptopicevent')
        $('.fc-view-container').append(container)

        $(this).mouseover(function (e) {
          $(this).css('z-index', 10000)
          $('.tooltiptopicevent').fadeIn('500')
          $('.tooltiptopicevent').fadeTo('10', 1.9)
        })

        $(this).mousemove(function (e) {
          $('.tooltiptopicevent').css('left', e.pageX - 350 )
          const containerHeight = $('.tooltiptopicevent')[0].clientHeight
          let offsetY
          if(window.outerHeight < containerHeight + e.pageY + 100) {
            offsetY = containerHeight * -1
          } else {
            offsetY = 10
          }
          $('.tooltiptopicevent').css('top',  e.pageY + offsetY)
        });
      },

      eventMouseout(event, jsEvent, view) {
        $(this).css('z-index', 8)
        $('.tooltiptopicevent').remove()
      },

      select(start, end, resource1, resource2, resource3) {
        const datePlanned = start.format()
        const predictedDuration = `${(end - start) / 60000}`
        const technician = resource3?.technic   ? resource3?.id :  'open'
        const manager    = resource3?.parentId || resource3?.id || 'open'
        let   resource   = ''
        if (technician  != 'open') resource += `&technician=${technician}`
        if (manager     != 'open') resource += `&manager=${manager}`
        document.location = `new?date_planned=${datePlanned}&predicted_duration=${predictedDuration}${resource}`
      }
    });

    const changeViewType = viewType => {
      // Update resource params
      calendarElement.dataset.view = viewType
      $('#calendar').fullCalendar('option', {
        resources: {
          ...$('#calendar').fullCalendar('option', 'resources'),
          data: { view_type: viewType }
        }
      })
      // Remove event sources & add a new one as they can't be updated
      const eventSource = {
        ...$('#calendar').fullCalendar('option', 'eventSources')[0],
        data: { view_type: viewType }
      }
      $('#calendar').fullCalendar('removeEventSources')
      $('#calendar').fullCalendar('addEventSource', eventSource)
      // Refect resources and events
      $('#calendar').fullCalendar('refetchResources')
      $('#calendar').fullCalendar('refetchEvents')
    }

    // Partial should be rendered on the back end
    const renderTimelineDayEvent = (event, element) => {
      const container = document.createElement('div')
      container.classList.add('fc-title')

      const title = `<p class='calendar-title dark-title'>${event.info.title}</p>`
      const code  = `<p>${event.info.code} - ${event.info.city}</p>`
      const time  = `<p>${event.start.format("HH:mm")} - ${event.end.format("HH:mm")}</p>`

      container.innerHTML += title
      container.innerHTML += code
      container.innerHTML += time

      element.find(".fc-content").append(container)
    }

    const renderTimelineWeek = (event, element) => {
      const container = document.createElement('div')
      container.classList.add('fc-title')

      const title = `<p class='calendar-title dark-title'>${event.info.title}</p>`
      const code  = `<p>${event.info.code} - ${event.info.city}</p>`
      const time  = `<p>${event.start.format("HH:mm")} - ${event.end.format("HH:mm")}</p>`

      container.innerHTML += title
      container.innerHTML += code
      container.innerHTML += time

      element.find(".fc-content").append(container)
    }

    const renderTimeLineMonth = (event, element) => {
      const container = document.createElement('div')
      container.classList.add('fc-title')

      const title = `<p class='calendar-title dark-title'>${event.info.title}</p>`
      const code  = `<p>${event.info.code} - ${event.info.type}</p>`
      const time  = `<p>${event.start.format("HH:mm")} - ${event.end.format("HH:mm")}</p>`

      container.innerHTML += title
      container.innerHTML += code
      container.innerHTML += time

      element.find(".fc-content").append(container)
    }

    const renderAgendaDay = (event, element) => {
      const container = document.createElement('div')
      container.classList.add('fc-title')

      const title = `<p class='calendar-title dark-title'>${event.info.title}</p>`
      const code  = `<p>${event.info.code} - ${event.info.type}</p>`
      const city  = `<p>${event.info.city}</p>`
      const site  = `<p>${event.info.site}</p>`
      const time  = `<p>${event.start.format("HH:mm")} - ${event.end.format("HH:mm")}</p>`

      container.innerHTML += title
      container.innerHTML += code
      container.innerHTML += city
      container.innerHTML += site
      container.innerHTML += time

      element.find(".fc-content").append(container)
    }

    const renderAgendaWeek = (event, element) => {
      element.css('background-color', event.info.technician_color)

      const container = document.createElement('div')
      container.classList.add('fc-title')

      const title = `<p class='calendar-title'>${event.info.title}</p>`
      const code  = `<p>${event.info.code} - ${event.info.city}</p>`
      const time  = `<p>${event.start.format("HH:mm")} - ${event.end.format("HH:mm")}</p>`

      container.innerHTML += title
      container.innerHTML += code
      container.innerHTML += time

      element.find(".fc-content").append(container)
    }

    const renderAbsence = (event, element) => {
      element.css('background-color', event.user_color)
      const container = document.createElement('div')
      container.classList.add('fc-title')
      container.innerHTML = `${event.title}`
      element.append(container)
    }

    document.getElementById('calendar-refetch').addEventListener('click', function () {
      $('#calendar').fullCalendar('refetchEvents')
    })

    document.getElementById('calendar-set-date').addEventListener('change', function () {
      const selectedDate = document.getElementById('calendar-set-date').value
      $('#calendar').fullCalendar('gotoDate', Date.parse(selectedDate))
    })

    document.getElementById('calendar-multi-slider').addEventListener('setRange', e => {
      const minTime = e.target.dataset.minTime
      const maxTime = e.target.dataset.maxTime
      $('#calendar').fullCalendar('option', { minTime, maxTime })
    })

    document.getElementById('weekends').addEventListener('change', e => {
      $('#calendar').fullCalendar('option', 'weekends', e.target.checked)
    })
  })
}

export { calendarInit }
