import React from 'react'

const TooltipPopup = ({ showPopup }) => {
  return (
    <>
      { showPopup &&
        <div className='new-tooltip white-space-pre-line' 
          style=
            {{
              position: 'absolute', 
              left: showPopup.x+10+'px', 
              top: showPopup.y-30+'px'
            }}
        >
          <div className='small' dangerouslySetInnerHTML={{__html: showPopup.content}}></div>
        </div>
      }
    </>
  )
}

export default TooltipPopup
