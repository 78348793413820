import React from "react"

export default class PriceInput extends React.Component {
  componentDidMount() {
    const input = document.getElementById("contract_beginning_price")
    input.addEventListener("subtotal", this.props.onChange)
  }

  render () {
    return(
      <div className="form-group">
        <label htmlFor="contract_beginning_price">
          {I18n.t("maintenance.price")}
        </label>

        <input
          className    = "form-control"
          step         = "0.01"
          type         = "number"
          value        = {this.props.value}
          name         = "contract[beginning_price]"
          id           = "contract_beginning_price"
          data-target  = "financial--total.subtotalValue"
          readOnly
          required
        />
      </div>
    )
  }
}
