import React, { useState }  from "react"
import { ResponsiveBar }    from '@nivo/bar'
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome'

const CELL_HEIGHT = 450
const TAB_HEIGHT  = 350

const noValue = data => data ? data.map(v => v.value).reduce((a, b) => a + b, 0) === 0 : true

const EmptyCard = () => {
  return(
    <div
      className="dashboard__card--empty"
      style={{ height: `${TAB_HEIGHT}px` }}
    >
      {I18n.t('dashboard.no_data')}
    </div>
  )
}

const Ratio = ({ ratio , range = 0, horizontal, collection, setItem }) => {
  const data = ratio.data.filter(data => data.value >= range)

  const horizontalProps = {
    height:      data.length * 40,
    margin:      { left: 140, right: 20 },
    enableGridY: false,
    axisLeft:    { tickSize: 0, tickPadding: 20},
  }

  const verticalProps = {
    width:       (data.length * 40) > 300 ? data.length * 40 : 300,
    height:      CELL_HEIGHT,
    margin:      { bottom: 170, left: 0, right: 20, top: 20 },
    enableGridY: false,
    axisBottom:  { tickSize: 0, tickPadding: 20, tickRotation: -90},
    axisLeft:    { tickSize: 0, tickPadding: 20}
  }

  const barProps = horizontal ? horizontalProps : verticalProps
  
  const onClick = selected => {
    const item = collection.find(item => item.id == selected.data.id)
    if (setItem) setItem(item)
  }

  return(
    <div>
      <h3>{ratio.title}</h3>

      { noValue(data)
        ? <EmptyCard />
        : <ResponsiveBar
            data    = { data }
            layout  = { horizontal ? 'horizontal' : 'vertical' }
            padding = { .2 }
            onClick = { onClick }
            indexBy = "name"
            colors         = { bar => bar.data.color }
            tooltip        = { bar => bar.data.name }
            labelTextColor = { bar => bar.data.data.text }
            animate
            {...barProps}
          />
      }
    </div>
  )
}

export const ChartTab = ({ ratios, range, categories, setCategory, statuses, setStatus }) => {
  const [horizontal, setHorizontal] = useState(true)
  const toggleHorizontal = () => {
    setHorizontal(!horizontal)
  }

  return (
    <div className="dashboard__card">
      <div className="btn btn-primary btn-sm small mr-2" onClick={toggleHorizontal}>
        {horizontal ? 
          <>
            <FontAwesomeIcon icon='chart-bar' /> {I18n.t('dashboard.vertical')}
          </>
           :
          <> 
            <FontAwesomeIcon icon="bars" /> {I18n.t('dashboard.horizontal')}
          </>
        }
      </div>

      <div className={`dashboard__demands_container_${horizontal ? 'horizontal' : 'vertical'}`}>
        <Ratio
          ratio       = { ratios.category_ratio }
          range       = { range }
          horizontal  = { horizontal }
          collection  = { categories }
          setItem     = { setCategory }
        />

        <Ratio
          ratio       = { ratios.status_ratio }
          range       = { range }
          collection  = { statuses }
          setItem     = { setStatus }
          horizontal  = { horizontal }
        />
      </div>
    </div>
  )
}
