import React                       from "react"
import SharedFlatpickr             from "@shared/flatpickr"
import { FontAwesomeIcon }         from "@fortawesome/react-fontawesome"
import { parsePredicted, getDate } from "@react/helpers/helpers"

export const Passage = ({
  index, 
  datePlanned, 
  predictedDuration, 
  changePassageDate, 
  changePassageDuration,
  removePassage,
  minDate,
  showPassageNumber = true
}) => {

  return (
    <>
      { showPassageNumber &&  
        <div className= "form-group">
          { index === null ? '1' : index + 2 }
        </div>
      }
      <div className = "form-group">
        <SharedFlatpickr
          options={{
            locale:     I18n.locale,
            enableTime: true,
            dateFormat: "d/m/Y - H:i",
            minDate:    minDate ? minDate : null
          }}
          
          className   = "form-control flatpickr-nobg text-center"
          placeholder = { I18n.t("workorders.datetime_placeholder")}
          onChange    = { date => { changePassageDate(date[0], index) } }
          value       = { new Date(datePlanned) }
        />
      </div>

      <div className = "form-group"> 
        <SharedFlatpickr
          options={{
            locale:     I18n.locale,
            enableTime: true,
            noCalendar: true
          }}
          className   = "form-control flatpickr-nobg text-center"
          onClose     = { duration => { changePassageDuration(parsePredicted(duration), index) } }
          value       = { getDate(predictedDuration) }
        />
      </div>
      {removePassage &&
        <a onClick={ () => removePassage(index) } className = "remove-icon text-pointer">
          <FontAwesomeIcon icon="minus-circle" />
        </a>
      }
    </>
  )
}