import React from 'react';

const CustomNumberInput = ({ name, value, onChange }) => {
    const increment = () => onChange({ target: { name, value: Number(value) + 1 } });
    const decrement = () => onChange({ target: { name, value: Number(value) - 1 } });

    return (
        <div className="custom-number-input">
            <input className="form-control" type="number" name={name} value={value} onChange={onChange} />
            <button type="button" onClick={decrement} className="arrow-button">-</button>
            <button type="button" onClick={increment} className="arrow-button">+</button>
        </div>
    );
};

export default CustomNumberInput;
