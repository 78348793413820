// Modules
import React, { useState, useRef } from "react"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas }             from '@fortawesome/free-solid-svg-icons'

// Components
import TypeReactive        from './typeReactive'
import { TypePreventive }  from './typePreventive'

library.add(fas)

const REACTIVE     = 'Reactive'
const PREVENTIVE   = 'Preventive'
const INSTALLATION = 'Installation'
const TYPES = [REACTIVE, PREVENTIVE, INSTALLATION]

// "reactive" => REACTIVE
// From a hash we pass it to an array with all the possible options
// The problem is that when we camelize props we lose the capital letters
// getTypes takes the policies that is an object like
// {reactive: true, preventive: true, installation: false}
// and returns an array like ['Reactive', 'Preventive']

const getTypes = policies => {
  const types = []
  let activePolicies = Object.keys(policies).filter(key => policies[key])
  activePolicies = activePolicies.map(policy => policy.toLowerCase())
  TYPES.forEach(type => {
    if(activePolicies.includes(type.toLowerCase())) types.push(type)
  })
  return types
}
const WorkorderType = props => {
  const { allowRecurrency, taskBlueprints, policies, materialsIds, maintenanceTasks } = props

  const materials = maintenanceTasks.map(task => task.material)
  let types       = getTypes(policies)
  if(materials.length == 0) types = types.filter(type => type != PREVENTIVE)

  const [type,  setType]  = useState(REACTIVE)

  const changeType = e => {
    const newType = e.currentTarget.value
    const passages = document.getElementById("workorder_passages")
    if(passages && newType == PREVENTIVE) passages.classList.add("d-none")
    if(passages && newType != PREVENTIVE) passages.classList.remove("d-none")
    setType(newType)
  }

  return (
    <>
      { types.length > 1 &&
        <div className="form-group">
          <label htmlFor="workorder_workorder_type">
            {I18n.t("workorders.type")}
          </label>

          <select
            name      = "workorder[workorder_type]"
            className = "form-control"
            value     = {type}
            onChange  = {changeType}
            required
          >
            { types.map(type =>
              <option value={type} key={type}>
                {I18n.t(`workorders.workorder_types.${type}`)}
              </option>
            )}
          </select>
        </div>
      }

      { (type == REACTIVE || type == INSTALLATION) &&
        <TypeReactive tasks={maintenanceTasks} materialsIds={materialsIds} />
      }

      { type == PREVENTIVE &&
        <TypePreventive
          blueprints      = {taskBlueprints}
          materials       = {materials}
          allowRecurrency = {allowRecurrency}
        />
      }
    </>

  )
}

export default WorkorderType
