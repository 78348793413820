export function updateNotificationsCountAction(newCount) {
  return dispatch => { dispatch(updateNotifications(newCount)) }
}

export const updateNotifications = (newCount) => ({
  type: NOTIFICATIONS_COUNT.UPDATE,
  payload: { newCount }
})

export const NOTIFICATIONS_COUNT = {
  UPDATE: 'UPDATE_NOTIFICATIONS_COUNT',
  updateResponse: ({ state, unread } = {}) => ({ ...state, unread })
}
