export function toggleNotificationsAction(old_status) {
  return dispatch => { dispatch(toggleNotifications(old_status)) }
}

export const toggleNotifications = old_status => ({
  type: NOTIFICATIONS_TOGGLE.CLICK,
  payload: { display: !old_status }
})

export const NOTIFICATIONS_TOGGLE = {
  CLICK: 'TOGGLE_NOTIFICATIONS',
  clickResponse: ({ state, display } = {}) => ({...state, display })
}

