import { getRequest }         from '@helpers/javascript/javascript'
import { parameterizeFilter } from '@helpers/javascript/javascript'
import { pathname }           from '@helpers/javascript/javascript'
import { PATHS }              from '@helpers/javascript/config/CONSTANTS'

// Dispatch
export const fetchPageWorkordersAction = filters => dispatch => {
  const params = parameterizeFilter(filters)
  const path   = PATHS.workorders.index
  const url    = pathname({ path, params })

  dispatch(fetchPageWorkordersBegin())
  getRequest(url)
  .then (data  => dispatch(fetchPageWorkordersSuccess(data)))
}

// Actions
const fetchPageWorkordersBegin = () => ({
  type: WORKORDER_FETCH_PAGE.BEGIN,
})

const fetchPageWorkordersSuccess = workorders => ({
  type: WORKORDER_FETCH_PAGE.SUCCESS,
  payload: { ...workorders }
})

// Reducer response
export const WORKORDER_FETCH_PAGE = {
  BEGIN:   'FETCH_PAGE_WORKORDERS_BEGIN',
  SUCCESS: 'FETCH_PAGE_WORKORDERS_SUCCESS',

  beginResponse: ({ state } = {}) => ({ ...state, loadingNext: true }),

  successResponse: ({ state, workorders, total_entries, total_pages } = {}) => ({
    ...state,
    total_entries,
    total_pages,
    loadingNext: false,
    items: [ ...state.items, ...workorders ]
  })
}
