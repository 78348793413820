import React           from "react"
import Combobox        from "@shared/combobox"
import SharedFlatpickr from "@shared/flatpickr"

// Components
import AssetSelect      from '@shared/assetSelect'

export const DemandsFilters = ({
  date,
  setDate,
  setAssets,
  range,
  setRange,
  displayClient,
  category,
  setCategory,
  categories,
  status,
  setStatus,
  statuses
})=> {
  const options = {
    required: {
      client: false,
      site:   false,
    },
    showLabels: {
      client: false,
      site:   false
    },
    placeholder: {
      client: I18n.t('dashboard.filter_client'),
      site:   I18n.t('dashboard.filter_site')
    },
    displayClient
  }

  return (
    <div className="dashboard__card dashboard__filters">
      <div className="d-flex j-c-between a-i-center f-column">
        <SharedFlatpickr
          options = {{
            mode:   "range",
            inline: true,
            locale: I18n.locale
          }}
          value    = {date}
          onChange = {setDate}
        />
        <span className="dashboard__date">
          {I18n.t('dashboard.date', { from: date[0].toLocaleDateString(I18n.locale), to: (date[1] ? date[1].toLocaleDateString(I18n.locale) : '…')})}
        </span>
      </div>

      <div className="flex-item-30">
        <AssetSelect
          type     = 'site'
          onChange = {setAssets}
          options  = {options}
        />

        <div className="form-group">
          <Combobox
            options         = {categories}
            onSelectElement = {setCategory}
            placeholder     = {I18n.t("dashboard.category")}
            prompt          = {I18n.t("dashboard.all_categories")}
            value           = {category?.id}
          />
        </div>

        <div className="form-group">
          <Combobox
            options         = {statuses}
            onSelectElement = {setStatus}
            placeholder     = {I18n.t("dashboard.status")}
            prompt          = {I18n.t("dashboard.all_statuses")}
            value           = {status?.id}
          />
        </div>

        <div className="form-group">
          <span className="dashboard__date">
            {I18n.t("dashboard.threshold_display")} {range}
          </span>

          <input
            type      = "range"
            min       = "0"
            max       = "20"
            className = "slider"
            value     = {range}
            onChange  = { e => setRange(parseInt(e.currentTarget.value) )}
          />
        </div>
      </div>
    </div>
  )
}
