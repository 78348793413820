import React from "react"
import Base from "./base"

// Components
import QuillInput from '@shared/quillInput'

export default class Observations extends Base {
  render() {
    const { quote, replaceQuote } = this.props

    return(
      <React.Fragment>
        <br/>
        {
          this.state.active
          ? <QuillInput text={quote.observations}
              onChange={content => replaceQuote({...quote, observations: content})}
              onBlur={this.deactivate}
              name="quote[observation]"
            />
          : <React.Fragment>
              <div onClick={this.activate}
                dangerouslySetInnerHTML={{__html: quote.observations || "-"}}
                className="no-margins quotes--form-pointer quotes--form-details"
              >
              </div>

              <input
                type="hidden"
                name="quote[observation]"
                defaultValue={quote.observations || ""}
              />
            </React.Fragment>
        }
        <div className="quotes--form-footer">
          <div className="company-name">
            {quote.account.name}
          </div>

          <div>
            {quote.account.address}
            &nbsp;-&nbsp;
            {quote.account.vat}
          </div>

          <div>
            {quote.account.phone}
            &nbsp;-&nbsp;
            {quote.account.mail}
            {quote.account.website ? ' - ' : ''}
            {quote.account.website}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
