import { bodyRequest } from '@helpers/javascript/javascript'

export const markAllAsReadAction = () => dispatch =>
  bodyRequest({
    method:     'PATCH',
    url:        `/notifications/mark_all_as_read`,
  }).then(() => dispatch({ type: NOTIFICATIONS_MARK_ALL.SUCCESS }))

export const NOTIFICATIONS_MARK_ALL = {
  SUCCESS: 'MARK_ALL_AS_READ_SUCCESS',

  successResponse: ({ state }) => {
    const items = state.items.map(notif => notif.read ? notif : { ...notif, read: true })
    return {...state, items }
  }
}


