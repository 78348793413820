export const addError = notice => ({
  type: NOTICE.ADD,
  payload: { notice }
})

export const dismissError = index => ({
  type: NOTICE.DISMISS,
  payload: { index }
})

// Dispatching through Redux
export const addNoticeAction     = notice => dispatch => dispatch(addError(notice))
export const dismissNoticeAction = index  => dispatch => dispatch(dismissError(index))

// Instantiate actions names
export const NOTICE = {
  ADD:     'ADD_NOTICE',
  DISMISS: 'DISMISS_NOTICE',

  addResponse: ({ state, notice } = {}) => {
    const content = state.content
    const newContent = content.slice(-9)
    newContent.push(notice)
    return { content: newContent }
  },

  dismissResponse: ({ state, index } = {}) => {
    const content = state.content
    content.splice(index, 1)
    return { content: content }
  }
}
