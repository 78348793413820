import { rand } from '@helpers/javascript/date_helpers'

// Dispatch
export const addReadingAction = (workorder, meter, value) => dispatch =>
  dispatch({
    type:    ADD_READING.ACTION,
    payload: { workorder, meter, value }
  })

// Reducer response
export const ADD_READING = {
  ACTION: 'ADD_READING',

  call: ({ state, workorder, meter, value }) => (
    {
      ...state,
      items: state.items.map(item => item.id != workorder.id ? item : {
        ...workorder,
        synced: false,
        readings: [...item.readings, {
          meter_id: meter.id,
          version:  rand(1e7),
          taken_at: new Date(),
          value
        }]
      })
    }
  )
}
