import React from "react"

export default class SubmitButtons extends React.Component {
  render() {
    const quote = this.props.quote
    return(
      <div className="quotes--form-submit-buttons">
        <a className="btn btn-outline-default btn-cancel-lg"
           href={this.props.backRoute}
        >
          {I18n.t("quotes.new.cancel")}
        </a>

        { quote.can_preview_pdf &&
          <input
            type="submit"
            className="btn btn-outline-default btn-cancel-lg"
            name="preview"
            formTarget="_blank"
            value={I18n.t("quotes.new.preview")}
          />
        }

        <input
          type="submit"
          className="btn btn-primary"
          value={I18n.t("quotes.new.generate")}
        />

        <input
          type="submit"
          name="exit"
          className="btn btn-primary"
          value={I18n.t("quotes.new.generate_exit")}
        />
      </div>
    )
  }
}
