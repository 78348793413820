import React, { useState, useRef } from "react"

// Components
import { BoxProduct }  from "./box_product"

// Helpers
import { postRequest } from '@helpers/javascript/javascript'
import { pathname }    from '@helpers/javascript/javascript'

export const Box = ({ currentBox, filter, technicianResupply }) => {
  const validateRef           = useRef(null)
  const [loading, setLoading] = useState(false)
  const [box, setBox]         = useState(currentBox)

  const updateProduct = product => {
    setBox({
      ...box,
      products: box.products.map(p => product.id != p.id ? p : product)
    })
  }

  const reportValidation = () => {
    const message = I18n.t("products.stock_relocation_error")
    let valid = true
    validateRef.current.querySelectorAll("input").forEach(input => {
      input.setCustomValidity("")
      if(valid) {
        const displayError = message && !input.checkValidity()
        if(displayError) input.setCustomValidity(message)
        valid = input.reportValidity()
      }
    })
    return valid
  }

  const submit = () => {
    if(!reportValidation()) return

    setLoading(true)
    const products = box.products.filter(product =>
      (parseFloat(product.stocks.transmit) || 0) > 0
    ).map(product => ({
      id:       product.id,
      transmit: product.stocks.transmit
    }))

    const technician = box.technician.id
    let   body       = { technician, products }
    if (!technicianResupply) {
      const { from, to } = filter
      body = {...body, from, to }
    }

    const path   = '/product_dispatcher'
    const params = {}
    const format = "json"
    if (technicianResupply) params["technician_resupply"] = true

    const url = pathname({ path, format, params })
    postRequest({ url, body })
    .then(newBox => {
      setBox(newBox)
      setLoading(false)
    })
    .catch(e => {
      console.error(e)
      setLoading(false)
    })
  }

  let totalTransmit = 0
  let totalMissing   = 0

  box.products.forEach(product => {
    totalTransmit += product.stocks.transmit
    totalMissing  += product.stocks.missing || 0
  })

  return (
    <div className="timesheet__sheet" ref={validateRef}>
      <div className  = 'timesheet__sheet__user'>
        <div>
          <div
            className="timesheet__sheet__bullet"
            style={{ background: box.technician.color }}
          ></div>

          {box.technician.name}
        </div>

        <div className="no-print">
          { technicianResupply
            ? <a
                data-toggle = 'modal'
                data-target = '#remote-modal'
                data-route  = {box.technician.path}
                data-action = 'click->modal#show'
                href        = ''
              >
                {I18n.t("product_dispatcher.configure_stock")}
              </a>
            : <a href={box.workorders.path} target="_blank">
                {I18n.t("product_dispatcher.concerns")}
                &nbsp;
                {box.workorders.size}
                &nbsp;
                {I18n.t(box.workorders.size == 1 ? 'workorders.workorder' : 'workorders.workorders')}
                &nbsp;
              </a>
          }
        </div>
      </div>

      { box.products.length > 0 &&
        <div className='timesheet__sheet__row  timesheet__sheet__row--headers timesheet__dispatcher-grid'>
          <div>
            {I18n.t("products.ref_product_brand")}
          </div>

          <div>
            {I18n.t("products.designation")}
          </div>

          <div className="j-c-end">
            {I18n.t(technicianResupply ? "product_dispatcher.stock_base" : "product_dispatcher.stock_needed" )}
          </div>

          <div className="o-visible j-c-end">
            {I18n.t("product_dispatcher.technician_storehouse")}
          </div>

          <div className="o-visible j-c-end">
            {I18n.t("product_dispatcher.account_storehouse")}
          </div>

          <div className="j-c-end">
            {I18n.t("product_dispatcher.stock_transmit")}
          </div>
        </div>
      }

      { box.products.map((product, key) =>
          <BoxProduct {...{key, product, updateProduct, technicianResupply}}/>
      )}

      { box.products.length > 0 &&
        <div className="flex-end mt-2 no-print">
          { totalTransmit == 0 && totalMissing == 0 &&
            <button className="btn btn-success btn-success-transparent btn-no-hover">
              {I18n.t("product_dispatcher.box_ready")}
            </button>
          }

          { totalTransmit == 0 && totalMissing > 0 &&
            <button className="btn btn-danger btn-no-hover">
              {I18n.t("product_dispatcher.box_missing")}
            </button>
          }

          { totalTransmit > 0 &&
            <button
              className="btn btn-primary btn-primary-transparent"
              onClick={submit}
              disabled={loading}
            >
              { I18n.t("product_dispatcher.activate") }
            </button>
          }
        </div>
      }
    </div>
  )
}
