import React from 'react'
import { minToString, showMoney } from '@helpers/javascript/date_helpers'

export const CostsStatistics = ({ totals, showRevenue }) => {
  return(
    <div className={`timesheet__resume g-t-col-${showRevenue ? 7 : 5}`}>
      <div>
        <h3>{I18n.t('operational_costs.number')}</h3>
        <p className="timesheet__box">{totals.count}</p>
      </div>

      <div>
        <h3>{I18n.t('operational_costs.time')}</h3>
        <p className="timesheet__box">{minToString(totals.time)}</p>
      </div>

      <div>
        <h3>{I18n.t('operational_costs.labor')}</h3>
        <p className="timesheet__box">{showMoney(totals.labor)}</p>
      </div>

      <div>
        <h3>{I18n.t('operational_costs.products')}</h3>
        <p className="timesheet__box">{showMoney(totals.products)}</p>
      </div>

      <div>
        <h3>{I18n.t('operational_costs.total')}</h3>
        <p className="timesheet__box">{showMoney(totals.total)}</p>
      </div>

      { showRevenue &&
        <div>
          <h3>{I18n.t('operational_costs.revenue')}</h3>
          <p className="timesheet__box">{showMoney(totals.revenue)}</p>
        </div>
      }

      { showRevenue &&
        <div>
          <h3>{I18n.t('operational_costs.margin')}</h3>
          <p className="timesheet__box">{showMoney(totals.margin)}</p>
        </div>
      }
    </div>
  )
}
