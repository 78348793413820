import { bodyRequest } from '@helpers/javascript/javascript'
import { PATHS }       from '@helpers/javascript/config/CONSTANTS'

export const changeLocaleBegin   = () => ({ type: CHANGE_LOCALE.BEGIN })
export const changeLocaleFailure = () => ({ type: CHANGE_LOCALE.FAILURE })

export const changeLocaleSuccess = result => ({
  type: CHANGE_LOCALE.SUCCESS,
  payload: { user: result.user }
})

// Action
export const changeLocaleAction = locale => {
  return dispatch => {
    dispatch(changeLocaleBegin())
    return (
      bodyRequest({
        method: 'POST',
        url:    PATHS.users.change_locale,
        body:   { locale }
      })
        .then(data => dispatch(changeLocaleSuccess(data)))
        .catch(_error => dispatch(changeLocaleFailure()))
    )
  }
}

export const CHANGE_LOCALE = {
  BEGIN:   'CHANGE_LOCALE_BEGIN',
  SUCCESS: 'CHANGE_LOCALE_SUCCESS',
  FAILURE: 'CHANGE_LOCALE_FAILURE',

  beginResponse:   state => ({ ...state }),
  failureResponse: state => ({ ...state }),
  successResponse: user  => {
    I18n.locale = user.locale ? user.locale : 'en'
    return user
  }
}
