import React, { useState, useEffect } from 'react'
import { ImportsColumn } from "./imports_column"

const ImportsEdit = props  => {
  const samples = JSON.parse(props.samples)
  const [columns, setColumns] = useState(props.columns)
  const [errors,  setErrors]  = useState([])
  const abecedary = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split("")

  const reset = e => {
    e.preventDefault()
    setColumns({})
  }

  useEffect(() => {
    const errors     = []
    const table      = props.table
    const values     = Object.values(columns).filter(column => column)
    const required   = Object.keys(table).filter(column => table[column].required)
    const duplicates = (new Set(values)).size !== values.length
    if (duplicates) { errors.push(I18n.t("import.errors.columns_repeated")) }
    if (!props.editing) {
      const missing = required.filter(r => !values.includes(r)).map(m => table[m].name).join(", ")
      if (missing.length > 0) { errors.push(I18n.t("import.errors.columns_missing", { missing })) }
    }
    setErrors(errors)
  }, [columns])
  return(
    <>
      { Object.keys(samples).map(column =>
        <ImportsColumn
          key        = {column}
          letter     = {abecedary.shift()}
          column     = {column}
          samples    = {samples[column]}
          columns    = {columns}
          setColumns = {setColumns}
          table      = {props.table}
          type       = {props.type}
          editing    = {props.editing}
        />
      )}
      {errors.length > 0 &&
        <div className="imports-errors">
          { errors.map(error => <p key={error}>{error}</p>) }
        </div>
      }

      <div className="invoice-float-buttons">
        <a className="btn btn-outline-default" href="/imports">
          { I18n.t("button.cancel")}
        </a>

        <button
          name      = "button"
          className = "btn btn-outline-default"
          onClick   = {reset}
        >
          { I18n.t("workorders.reset_filter") }
        </button>

        <button
          name      = "button"
          type      = "submit"
          className = "btn btn-primary"
          disabled  = { errors.length > 0 }
        >
          { I18n.t("import.test") }
        </button>
      </div>
    </>
  )
}

export default ImportsEdit

