import React from 'react'

export default class TimesheetStatistics extends React.Component {

  render() {
    const {
      renderDate,
      range,
      timesheets
    } = this.props

    return (
      <div className="timesheet__resume">
        {/* Date range */}
        <div>
          <h3>{I18n.t('users.timesheets.date_range')}</h3>
          <p className="timesheet__box">{renderDate(range[0])} -> {renderDate(range[1])}</p>
        </div>

        {/* Total Workorders */}
        <div>
          <h3>{I18n.t('users.timesheets.total_workorders')}</h3>
          <p className="timesheet__box">{timesheets !== null ? timesheets.total_workorders : '—'}</p>
        </div>

        {/* Total time */}
        <div>
          <h3>{I18n.t('users.timesheets.total_time')}</h3>
          <p className="timesheet__box">{timesheets !== null ? timesheets.total_time : '—'}</p>
        </div>
      </div>
    )
  }
}
