import React from "react"
{/*
    title                 = String, title that will be displayed.
    shortcuts             = Hash of this format : {name: 'Andrzej', values: [{}]}
    shownValue            = 'name' => What will be displayed from the shortcut. Here, the key 'name'
    shortcutSelectedIndex = Integer. Index of the selected shortcut
    onClickShortcut       = Callback when clicked on shortcut. You will have to change the shortcutSelectedIndex in this one
*/}

const Shortcuts = ({ title, shortcuts, shownValue, identifier, shortcutSelectedIndex, onClickShortcut }) => {
  return (
    <>
      {title && <div className="timesheet__filters__title">{title}</div>}
      {shortcuts.map((shortcut, index) => (
        <span
          key       = {index}
          className = {`autocomplete__shortcut ${shortcutSelectedIndex === index ? 'autocomplete__shortcut--selected' : ''}`}
          onClick   = {() => onClickShortcut(shortcut, index)}>
          {shortcut[shownValue]}
        </span>
      ))}
    </>
  )
}

export default Shortcuts
