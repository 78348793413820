import { getRequest } from '@helpers/javascript/javascript'
import { PATHS }      from '@helpers/javascript/config/CONSTANTS'

// Action
export const fetchAccountAction = () => dispatch => getRequest(PATHS.users.account)
  .then(response => dispatch({
    type:    ACCOUNT_FETCH.ACTION,
    payload: { account: response.account }
  }))

// Reducer response
export const ACCOUNT_FETCH = {
  ACTION: 'FETCH_ACCOUNT_ACTION',
  call:   ({ account }) => ({ ...account }),
}
