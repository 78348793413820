import React from "react"

export default class LineBase extends React.Component {
  constructor(props) {
    super(props)
    this.activate     = this.activate.bind(this)
  }

  activate(e) {
    const { index, qline } = this.props
    const { replaceLine }  = this.props.buttonProps
    replaceLine({...qline, active: true}, index)
  }
}
