// Dispatch
export const updateReportAction = (id, newReport) => dispatch =>
  dispatch({
    type:    WORKORDER_UPDATE_REPORT.ACTION,
    payload: { id, newReport }
  })

// Reducer response
export const WORKORDER_UPDATE_REPORT = {
  ACTION: 'WORKORDER_UPDATE_REPORT',

  call: ({ state, id, newReport }) => {
    const workorder       = state.items.find(wo => wo.id === id)
    const editedWorkorder = {
      ...workorder,
      report: newReport,
      synced: false
    }

    const workorders = state.items.map(wo => wo.id === workorder.id ? editedWorkorder : wo)
    return { ...state, items: workorders }
  }
}
