// Dispatch
export const pauseWorkorderAction = id => dispatch =>
  dispatch({
    type:    WORKORDER_PAUSE.ACTION,
    payload: { id }
  })

// Reducer response
export const WORKORDER_PAUSE = {
  ACTION: 'WORKORDER_PAUSE',

  call: ({ state, id }) => {
    const workorder = state.items.find(wo => wo.id === id)
    const editedWorkorder = {
      ...workorder,
      start_pause: new Date,
      paused:      true,
      synced:      false
    }

    const workorders = state.items.map(wo => wo.id === id ? editedWorkorder : wo)
    return { ...state, items: workorders }
  }
}
