import { STATUSES } from '@helpers/javascript/config/CONSTANTS'

export const travelTimeFor = item => {
  if(!item.departure_date) return

  const ms = new Date().getTime() - new Date(item.departure_date).getTime()
  const seconds = parseInt(ms / 1000)
  return seconds
}

// Dispatch
export const startWorkorderAction = (id, success) => dispatch =>
  dispatch({
    type:    WORKORDER_START.ACTION,
    payload: { id, proximity_start: success }
  })

// Reducer response
export const WORKORDER_START = {
  ACTION: 'WORKORDER_START',

  call: ({ state, id, proximity }) => ({
    ...state,
    items: state.items.map(item => item.id != id ? item : {
      ...item,
      manual_travel_time:  travelTimeFor(item),
      date_started:        new Date(),
      status:              STATUSES.IN_PROGRESS,
      loading:             false,
      synced:              false,
      proximity_start:     proximity
    })
  })
}
