import React        from "react"
import LineBase     from "./line_base"

// Components
import Buttons       from "./shared/buttons"
import Calculations  from "./shared/calculations"
import Inputs        from "./shared/inputs"
import Option        from "./shared/option"
import HideInPdf     from "./shared/hide_in_pdf"
import Inactive      from "./line_inactive"

import QuillInput  from '@shared/quillInput'

// Helpers
import {lineNameFor} from '../helpers'

export default class Regular extends LineBase {
  render() {
    const { index, quote, qline, vats, showCode, showMargin, buttonProps } = this.props
    const { replaceLine } = this.props.buttonProps

    return(
      qline.active
      ? <div className="quotes--form-qline-container">
          <div className="text-input">
            <QuillInput
              text={qline.description}
              name={lineNameFor('description', index)}
              onChange={content => replaceLine({...qline, description: content}, index)}
            />
          </div>

          <Calculations
            qline={qline}
            index={index}
            replaceLine={replaceLine}
            showMargin={showMargin}
            showPriceCoefficient={quote.allow_markup}
          />

          <Inputs
            index={index}
            quote={quote}
            qline={qline}
            replaceLine={replaceLine}
            vats={vats}
            showMargin={showMargin}
          />

          <div className="checkboxes">
            <Option
              index={index}
              qline={qline}
              replaceLine={replaceLine}
            />

            <HideInPdf
              index={index}
              qline={qline}
              replaceLine={replaceLine}
            />
          </div>

          <Buttons
            index={index}
            qline={qline}
            buttonProps={buttonProps}
          />

          <input
            type="hidden"
            name={lineNameFor("id", index)}
            defaultValue={qline.id}
          />

          <input
            type="hidden"
            name={lineNameFor("order", index)}
            defaultValue={index + 1}
          />
        </div>
      : <Inactive
          index={index}
          qline={qline}
          showCode={showCode}
          buttonProps={buttonProps}
        />
    )
  }
}

