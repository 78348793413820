import React from "react"
import MonthRow from "./month_row.js"
import AnnualTableFooter from "./annual_table_footer.js"

export default class AnnualTable extends React.Component {
  sumPercentages(array) {
    let sum = 0;
    for (let i = 0; i < array.length; i++) {
      sum += Math.round(array[i] * 10000) / 10000
    }
    return Math.abs(sum - 100) < 0.01 ? 100 : sum === 0 ? 100 : sum
  }

  render () {
    return(
      <table id="products-table" style={{ width : '100%' }}>
        <thead>
          <tr id="table-header">
            <th className="header-text"
                style={{textAlign : 'left', width: '70%'}}
            >
              {I18n.t('maintenance.monthly_billing_table')}
            </th>
            <th style={{width: '15%'}}
                className="checkbox checkbox-primary"
            >
              <input
                type="checkbox"
                defaultChecked={false}
                onChange={this.props.checkAll}
                id="checkbox-allprices"
              />

              <label htmlFor="checkbox-allprices" className="header-text">
                %
              </label>
            </th>
            <th className="header-text" style={{width: '15%'}}>{this.props.currency_symbol}</th>
          </tr>
        </thead>

        <tbody>
          {this.props.percentages.map((percentage, month) =>
            <MonthRow
              key               = {month}
              index             = {month}
              monthName         = {this.props.labels[month]}
              value             = {percentage}
              checked           = {this.props.checkboxes[month]}
              total             = {this.props.price}
              handleMonthChange = {this.props.handleMonthChange}
              editable          = {this.props.editable}
              handleCheckbox    = {this.props.handleCheckbox}
            />
          )}
        </tbody>

        {
          this.props.editable
          ?
          <tfoot>
            <AnnualTableFooter
              total = {this.sumPercentages(this.props.percentages)}
              price = {this.props.price}
            />
          </tfoot>
          :
          <tfoot></tfoot>
        }
      </table>
    )
  }
}
