import React, { useState } from 'react'
import ComboboxMulti  from "@shared/combobox_multi"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Filters = ({
  selectedMaterials,
  setSelectedMaterials,
  selectedCategories,
  setSelectedCategories,
  materials,
  categories
}) => {
  const [filterOpen, setFilterOpen] = useState(false)

  return (
    <div className = "rental-filter">
      <div 
        className = "btn btn-filter btn-sm btn-primary small"
        onClick   = { () => setFilterOpen(!filterOpen) }
      >
        { filterOpen ? 
            <span>{ I18n.t("material_rentals.filter_close") } <FontAwesomeIcon icon = "angle-up" /></span>
          : <span>{ I18n.t("material_rentals.filter_open") } <FontAwesomeIcon icon = "angle-down" /></span>
        }
      </div>
      {
        filterOpen &&
        <div className = 'filter-box'>
          <div className = "form-group">
            <label>{ I18n.t('material_rentals.filter_by_material') }</label>
            <ComboboxMulti
              options  = { materials }
              onChange = { setSelectedMaterials }
              value    = { selectedMaterials }
              prompt   = { I18n.t("workorders.select") }
            />
          </div>
          <div className = "form-group">
            <label>{ I18n.t('material_rentals.filter_by_category') }</label>
            <ComboboxMulti
              options  = { categories }
              onChange = { setSelectedCategories }
              value    = { selectedCategories }
              prompt   = { I18n.t("workorders.select") }
            />
          </div>
        </div>
      }
      
    </div>
  ) 
}