import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SharedFlatpickr     from "@shared/flatpickr"

const DAILY       = "DAILY"
const WEEKLY      = "WEEKLY"
const MONTHLY     = "MONTHLY"
const YEARLY      = "YEARLY"
const FREQUENCIES = [DAILY, WEEKLY, MONTHLY, YEARLY]

const defaultRrule = {
  frequency: MONTHLY,
  interval:  1,
  bysetpos:  1,
  byday:     new Date().getDay() - 1,
  bymonth:   new Date().getMonth() + 1,
  endDate:   ''
}

// 5 -> [1, 2, 3, 4, 5]
const incrementalArrayOf = length => new Array(length).fill(0).map((_, i) => i + 1)

export const Recurrency = ({ initialRrule, name, showPos = false }) => {
  const [rrule, setRrule] = useState(initialRrule || defaultRrule)

  const display = {
    interval: [WEEKLY, MONTHLY, YEARLY].includes(rrule.frequency),
    byday:    [WEEKLY, MONTHLY, YEARLY].includes(rrule.frequency),
    bysetpos: [MONTHLY, YEARLY].includes(rrule.frequency),
    bymonth:  [YEARLY].includes(rrule.frequency)
  }

  let maxInterval
  switch(rrule.frequency) {
    case DAILY:   maxInterval = 0;  break;
    case WEEKLY:  maxInterval = 36; break;
    case MONTHLY: maxInterval = 36; break;
    case YEARLY:  maxInterval = 36; break;
  }

  const intervals = incrementalArrayOf(maxInterval)
  const positions = incrementalArrayOf(5)
  const days      = incrementalArrayOf(5)
  const months    = incrementalArrayOf(12)

  const deleteEndDate = e => {
    e.preventDefault()
    setRrule({...rrule, endDate: defaultRrule.endDate})
  }

  return(
    <>
      <div className="checkbox checkbox-primary">
        <input
          type           = "checkbox"
          id             = "automatic_generation"
          name           = "maintenance_plan[automatic_generation]"
          value          = "1"
          defaultChecked
        />

        <label htmlFor="automatic_generation">
          {I18n.t("maintenance.plan_automatic_generation")}
        </label>
      </div>

      <div className="d-flex a-i-center">
        <div className="mr-2">
          <label>
            <strong>{I18n.t("maintenance.frequency_every")}</strong>
            {I18n.t("maintenance.repeat_every")}
          </label>
        </div>

        { display.interval &&
          <div className="mr-2">
            <select
              name      = "maintenance_plan[interval]"
              className = "form-control"
              value     = {rrule.interval}
              onChange  = {e => setRrule({...rrule, interval: e.currentTarget.value})}
            >
              {intervals.map(interval =>
                <option value={interval} key={interval}>
                  {interval}
                </option>
              )}
            </select>
          </div>
        }

        <div className="mr-2">
          <select
            name      = "maintenance_plan[frequency]"
            className = "form-control"
            value     = {rrule.frequency}
            onChange  = {e => setRrule({...rrule, frequency: e.currentTarget.value})}
          >
            {FREQUENCIES.map((frequency, index) =>
              <option value={frequency} key={index}>
                {I18n.t("maintenance.frequencies.singular")[index]}
              </option>
            )}
          </select>
        </div>

        { showPos &&
          <>
            { display.bysetpos || display.byday &&
              <div className="mr-2">
                <label>{I18n.t("maintenance.on_the")}</label>
              </div>
            }

            { display.bysetpos &&
              <div className="mr-2">
                <select
                  name      = "maintenance_plan[bysetpos]"
                  className = "form-control"
                  value     = {rrule.bysetpos}
                  onChange  = {e => setRrule({...rrule, bysetpos: e.currentTarget.value})}
                >
                  {positions.map(position =>
                    <option value={position} key={position}>
                      {I18n.t("maintenance.week_position")[position - 1]}
                    </option>
                  )}
                </select>
              </div>
            }

            { display.byday &&
              <div className="mr-2">
                <select
                  name      = "maintenance_plan[byday]"
                  className = "form-control"
                  value     = {rrule.byday}
                  onChange  = {e => setRrule({...rrule, byday: e.currentTarget.value})}
                >
                  {days.map(day =>
                    <option value={day - 1} key={day}>
                      {I18n.t("date.day_names")[day]}
                    </option>
                  )}
                </select>
              </div>
            }


            { display.bymonth &&
              <>
                <div className="mr-2">
                  <label>{I18n.t("maintenance.of")}</label>
                </div>

                <div className="mr-2">
                  <select
                    name      = "maintenance_plan[bymonth]"
                    className = "form-control"
                    value     = {rrule.bymonth}
                    onChange  = {e => setRrule({...rrule, bymonth: e.currentTarget.value})}
                  >
                    {months.map(month =>
                      <option value={month} key={month}>
                        {I18n.t("date.month_names")[month]}
                      </option>
                    )}
                  </select>
                </div>

                <div className="mr-2">
                  <label>{I18n.t("maintenance.from_end")}</label>
                </div>
              </>
            }
          </>
        }

        { !showPos &&
          <div className="mr-2">
            <label>
              {I18n.t("maintenance.recurrency_from_to")}
            </label>
          </div>
        }


        <div className="mr-2">
          <SharedFlatpickr
            options={{ locale: I18n.locale, dateFormat: 'd/m/Y' }}
            className = "form-control flatpickr-nobg"
            name      = "maintenance_plan[end_date]"
            onChange  = {dates => setRrule({...rrule, endDate: dates[0]})}
            value     = {rrule.endDate}
          />
        </div>

        { rrule.endDate &&
          <div className="client-details mr-2">
            <a href='#' className="m-0" onClick={deleteEndDate}>
              <FontAwesomeIcon icon = "times"/>
            </a>
          </div>
        }
      </div>
    </>
  )
}
