import React, { useState, useEffect } from 'react'

// Helpers
import { formatNumber } from "../quotes/form/helpers"
import { getRequest, bodyRequest, patchRequest, deleteRequest, pathname } from '@helpers/javascript/javascript'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Components
import { Spinner } from '@components/Spinner/Spinner'
import Autocomplete from '@react/shared/autocomplete'

const ProductPriceManager = ({ filter, page, dataPath }) => {

  const [productPrices,           setProductPrices]           = useState([])
  const [loading,                 setLoading]                 = useState(true)
  const [editingProductPriceId,   setEditingProductPriceId]   = useState(null)
  const [editedProductPrice,      setEditedProductPrice]      = useState({})
  const [newProductPrice,         setNewProductPrice]         = useState({
    client:     {},
    product:    {},
    sale_price: {}
  })
  const [showAddProductPriceForm, setShowAddProductPriceForm] = useState(false)

  useEffect(() => { fetchProductPrices() }, [])

  const fetchProductPrices = () => {
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('product_prices_filter_form[client]', filter.client.id);

    if (filter.product && filter.product.length) {
      filter.product.forEach(p => {
        params.append('product_prices_filter_form[product][]', p.id.toString());
      });
    }
    const url = `${dataPath}.json?${params.toString()}`;

    setLoading(true)
    getRequest(url).then(response => {
      setProductPrices(response)
      setLoading(false)
    })
  };

  const fetchNewProduct = (productIdArray) => {
    const params = {
        'price[client_id]': filter.client.id,
        'price[product_id]': productIdArray[0]
    }
    const url = pathname({ path: `${dataPath}/new`, params, format: 'json' });

    getRequest(url)
      .then(setNewProductPrice)
      .catch(console.error)
  }

  const handleInputChange = (e, objectToUpdate) => {
    const { name, value } = e.target;
    let updater;
    switch(objectToUpdate) {
      case 'newProductPrice':
        updater = setNewProductPrice; break;
      case 'editedProductPrice':
        updater = setEditedProductPrice; break;
    }

    const nameParts = name.split('.'); // Split the name to access nested properties

    if (nameParts.length === 2) {
      updater(prevState => ({
        ...prevState, [nameParts[0]]: {...prevState[nameParts[0]], [nameParts[1]]: value}
      }));
    } else {
      updater(prevState => ({ ...prevState, [name]: value }));
    }
}

  const handleEditClick = (productPriceId) => {
    setEditingProductPriceId(productPriceId);
    const productPriceToEdit = productPrices.find(pp => pp.id === productPriceId);
    setEditedProductPrice({
      ...productPriceToEdit,
      sale_price: productPriceToEdit.sale_price || { amount: '', currency: '' }
    })
  }

  const handleCancelEdit = () => {
    setEditingProductPriceId(null);
    setEditedProductPrice({});
  }

  const handleNewProductPriceCancel = () => {
    setNewProductPrice({
      client:     {},
      product:    {},
      sale_price: {}
    })
    setShowAddProductPriceForm(false)
  }

  const updateProductPrice = () => {
    const url = pathname({ path: `${dataPath}/${editedProductPrice.id}`, format: 'json' });
    const body = {
      page,
      client_id:           filter.client.id,
      sale_price_cents:    editedProductPrice.sale_price.amount * 100,
    };

    setLoading(true)
    patchRequest({ url, body })
      .then(fetchProductPrices)
      .catch(console.error)

    handleCancelEdit()
  }

  const createProductPrice = (e) => {
    const url = pathname({ path: dataPath, format: 'json' });
    const body = {
      page,
      client_id:        filter.client.id,
      product_id:       newProductPrice.product.id,
      sale_price_cents: newProductPrice.sale_price.amount * 100,
    };

    setLoading(true)
    bodyRequest({url, body})
      .then(fetchProductPrices)
      .catch(console.error)
    setNewProductPrice({
      client:     {},
      product:    {},
      sale_price: {}
    })
    setShowAddProductPriceForm(false)
  }

  const deleteProductPrice = (productPriceId) => {
    const url = pathname({ path: `${dataPath}/${productPriceId}`, format: 'json' });

    deleteRequest(url)
      .then(fetchProductPrices)
      .catch(console.error)
  }

  return(
    <>
      {
        loading
        ? <Spinner />
        : <div className="css-grid-container product-prices-grid">

            <div className="css-grid">
              <div className="header">
                <div className="text-left">{I18n.t("product_prices.reference")}</div>
                <div className="text-left">{I18n.t("product_prices.description")}</div>
                <div>{I18n.t("product_prices.unit")}</div>
                <div>{I18n.t("product_prices.price_purchase")}</div>
                <div>{I18n.t("product_prices.price_regular")}</div>
                <div>{I18n.t("product_prices.price_client")}</div>
                <div></div>
              </div>

              <div className="main">
                {
                  showAddProductPriceForm
                  ? <NewProductPriceForm
                      newProductPrice    = {newProductPrice}
                      fetchNewProduct    = {fetchNewProduct}
                      handleInputChange  = {handleInputChange}
                      createProductPrice = {createProductPrice}
                      handleCancel       = {handleNewProductPriceCancel}
                    />
                  : <AddProductPriceLink setShowAddProductPriceForm={setShowAddProductPriceForm} />
                }

                { productPrices.map(productPrice =>
                    <ProductPriceRow productPrice          = {productPrice}
                                     key                   = {productPrice.id}
                                     handleInputChange     = {handleInputChange}
                                     handleEditClick       = {handleEditClick}
                                     handleCancelEdit      = {handleCancelEdit}
                                     updateProductPrice    = {updateProductPrice}
                                     deleteProductPrice    = {deleteProductPrice}
                                     editingProductPriceId = {editingProductPriceId}
                                     editedProductPrice    = {editedProductPrice}
                    />
                  )
                }
              </div>

            </div>
          </div>
      }
    </>
  )
}

const AddProductPriceLink = ({ setShowAddProductPriceForm }) => (
  <div className="pl-2 add_button" onClick={() => setShowAddProductPriceForm(true)}>
    <FontAwesomeIcon icon="plus-circle" /> &nbsp;
    {I18n.t("product_prices.add_new")}
  </div>
)

const NewProductPriceForm = ({
  newProductPrice,
  fetchNewProduct,
  handleInputChange,
  createProductPrice,
  handleCancel
}) => {

  const { id, product, sale_price } = newProductPrice

  return (
    <>
      <div className="pl-2">
        { product && <ProductLink product={product}>{product.ref_brand}</ProductLink> }
      </div>
      <div className="pl-2">
        <Autocomplete
          placeholder      = {I18n.t("workorders.minimum_2_characters")}
          autocomplete_url = {"/products/autocomplete.json"}
          inputClassName   = "form-control"
          onSelectElement  = {fetchNewProduct}
        />
      </div>

      <div className="text-center">{ product.id && product.unit }</div>
      <div className="text-right pr-2">{ product.id && formatNumber(product.purchase_price.amount) }</div>
      <div className="text-right pr-2">{ product.id && formatNumber(product.sale_price.amount) }</div>

      <div className="text-right pr-2">
        <input className="form-control text-right"
               type="number"
               step="0.01"
               name="sale_price.amount"
               onChange={e => handleInputChange(e, 'newProductPrice')}
        />
      </div>

      <div className="text-center">
        <span className="icon-wrapper" onClick={handleCancel}>
          <FontAwesomeIcon icon="times-circle" />
        </span>
        <span className="icon-wrapper" onClick={createProductPrice}>
          <FontAwesomeIcon icon="check-circle" />
        </span>
      </div>
    </>
  )
}

const ProductPriceRow = ({
  productPrice,
  handleInputChange,
  handleEditClick,
  handleCancelEdit,
  updateProductPrice,
  deleteProductPrice,
  editingProductPriceId,
  editedProductPrice
}) => {

  const { id, product, sale_price } = productPrice
  const isEditing = editingProductPriceId === id

  return (
    <>
      <div className="pl-2">
        <ProductLink product={product}>{product.ref_brand}</ProductLink>
      </div>

      <div className="pl-2">
        <ProductLink product={product}>{product.designation}</ProductLink>
      </div>

      <div className="text-center">{product.unit}</div>
      <div className="text-right pr-2">{formatNumber(product.purchase_price.amount)}</div>
      <div className="text-right pr-2">{formatNumber(product.sale_price.amount)}</div>

      {
        isEditing
        ? (
            <>
              <div className="text-right pr-2">
                <input className = "form-control text-right"
                       type      = "number"
                       step      = "0.01"
                       value     = {editedProductPrice.sale_price.amount || ''}
                       name      = 'sale_price.amount'
                       onChange  = {e => handleInputChange(e, 'editedProductPrice')}
                />
              </div>
              <div className="text-center">
                <span className="icon-wrapper" onClick={handleCancelEdit}>
                  <FontAwesomeIcon icon="times-circle" />
                </span>

                <span className="icon-wrapper" onClick={updateProductPrice}>
                  <FontAwesomeIcon icon="check-circle" />
                </span>
              </div>
            </>
          )
        : (
            <>
              <div className="text-right pr-2 clickable" onClick={() => handleEditClick(id)}>
                  {formatNumber(sale_price.amount)}
              </div>
              <div className="text-center">
                <span className="icon-wrapper" onClick={() => handleEditClick(id)}>
                  <FontAwesomeIcon icon="edit" />
                </span>

                {/* TODO: add prompt */}
                <span className="icon-wrapper" onClick={() => deleteProductPrice(id)} data-confirm={I18n.t('buttons.yes')}>
                  <FontAwesomeIcon icon="trash" />
                </span>
              </div>
            </>
          )
      }
    </>
  );
}

const ProductLink = ({children, product}) => (
  <a data-toggle = "modal"
     data-target = "#remote-modal"
     data-action = "click->modal#show"
     data-route  = {product.path}>
    {children}
  </a>
);

export default ProductPriceManager;
