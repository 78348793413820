import React      from "react"
import LineBase   from "./line_base"

// Components
import Buttons       from "./shared/buttons"
import QuillInput  from '@shared/quillInput'

import { lineNameFor } from "../helpers"

const resetAttributes = [
  "ref_product_distrib",
   "discount",
   "quantity",
   "purchase_price",
   "unit_price",
   "vat_rate_id",
   "product_id",
   "is_page_break",
   "is_option"
]

export default class Descriptive extends LineBase {
  render() {
    const { index, qline, showCode, buttonProps } = this.props
    const { replaceLine } = this.props.buttonProps

    return(
      qline.active
      ? <div className="quotes--form-qline-container">
          <div className="quotes--form-full-row">
            <QuillInput
              text={qline.description}
              onChange={(content) => replaceLine({...qline, description: content}, index)}
              name={lineNameFor('description', index)}
            />
          </div>

          <Buttons
            index={index}
            qline={qline}
            buttonProps={buttonProps}
          />

          <input
            type="hidden"
            name={lineNameFor("order", index)}
            defaultValue={index + 1}
          />

          <input
            type="hidden"
            name={lineNameFor("id", index)}
            defaultValue={qline.id}
          />

          {
            resetAttributes.map((attribute, resetIndex) =>
              <input
                type="hidden"
                name={lineNameFor(attribute, index)}
                defaultValue=""
                key={resetIndex}
              />
            )
          }
        </div>
      : <div
          className="quotes--form-pointer quotes--form-table-row"
          onClick={this.activate}
        >
          { showCode ? <div className="code-column"></div> : "" }

          <div className="description-full"
            dangerouslySetInnerHTML={{__html: qline.description}}
          ></div>

          <Buttons
            index={index}
            qline={qline}
            buttonProps={buttonProps}
          />

          <input
            type="hidden"
            name={lineNameFor("order", index)}
            defaultValue={index + 1}
          />

          <input
            type="hidden"
            name={lineNameFor("description", index)}
            defaultValue={qline.description}
          />

          <input
            type="hidden"
            name={lineNameFor("id", index)}
            defaultValue={qline.id}
          />

          {
            resetAttributes.map((attribute, resetIndex) =>
              <input
                type="hidden"
                name={lineNameFor(attribute, index)}
                key={resetIndex}
                defaultValue=""
              />
            )
          }
        </div>
    )
  }
}
