import React from "react"

// Helpers
import {lineNameFor} from '../../helpers'

export default class HideInPdf extends React.Component {
  onChange(e) {
    const { index, replaceLine, qline } = this.props
    replaceLine({...qline, hide_in_pdf: e.target.checked}, index)
  }

  render() {
    const { qline, index } = this.props

    return(
      <div className="option checkbox checkbox-primary">
        <input
          type="hidden"
          defaultValue="0"
          name={lineNameFor("hide_in_pdf", index)}
        />

        <input
          type="checkbox"
          id={`hide-in-pdf-${index}`}
          checked={qline.hide_in_pdf}
          defaultValue="1"
          onChange={this.onChange.bind(this)}
          name={lineNameFor("hide_in_pdf", index)}
        />

        <label
          className="control-label"
          htmlFor={`hide-in-pdf-${index}`}
        >
          {I18n.t("quotes.new.hide_in_pdf")}
        </label>
      </div>
    )
  }
}
