import React from "react"

export default class AnnualTableFooter extends React.Component {
  render () {
    const percentageCovered = this.props.total === 100

    return(
      <tr style={{color : `${percentageCovered ? 'green' : 'red'}`}}>
        <td className="no-padding text-left">
          { percentageCovered ? 'Success T' : 'Fail T' }
        </td>

        <td className="no-padding">
          <input
            type="number"
            value={this.props.total}
            style={{
              border : 'none',
              width : '80%',
              textAlign: 'center',
              color : `${percentageCovered ? 'green' : 'red'}`
            }}
            onChange={(e) => { e.preventDefault()}}
            min="100"
            max="100"
          />

        </td>
        <td className="no-padding text-right">
          {Math.round(this.props.price * this.props.total) / 100}
        </td>
      </tr>
    )
  }
}
