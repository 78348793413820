import React, { useRef }           from "react"
import AssetSelect                 from "@shared/assetSelect"
import Input                       from "@shared/input"
import TextArea                    from "@shared/textarea"
import OverlaySpinner              from "@shared/overlaySpinner"
import { UploadFiles }             from "./uploadFiles"
import { ExtraFieldsAndCategory }  from "./extraFieldsAndCategory"
import { DefaultStorehouse }       from "./defaultStorehouse"
import { Products }                from "./products"
import { Checkboxes }              from "./checkboxes"
import { Type }                    from "./type"
import { Materials }               from './materials'
import { ManagerSelect  }          from "../stepTwo/managerSelect"
import { TechnicianSelect }        from "../stepTwo/technicianSelect"
import { Passage }                 from "../stepTwo/passage"

export const StepOne = ({
  categories,
  categoryFields,
  checkboxes,
  workorderState,
  setWorkorderState,
  setCurrentStep,
  storehouses,
  blueprints,
  siteMaterials,
  changeAssets,
  displayUnpaid,
  allowRecurrency,
  handleSubmit,
  managers,
  technicians,
  isSubmitting,
  changeTechnician,
  maximumDuration
}) => {
  const formRef = useRef()

  const changeValue = (value, name) => {
    setWorkorderState({ ...workorderState, [name]: value })
  }

  const goToStepTwo = () => {
    if(!formRef.current.reportValidity()) return
    setCurrentStep(2)
  }

  const title = () => {
    if (workorderState.purchaseOrderId) {
      return <h4>{ I18n.t("workorders.new_purchase_delivery") }</h4>
    } else if (workorderState.parentWorkorderId) {
      return <h4>{ I18n.t("workorders.new_passage") }</h4>
    } else  {
      return <h4>{ I18n.t("workorders.new_order") }</h4>
    }
  }

  return(
    <div className = "workorder-form workorder-form-react step-one">
      <div className = "card-body">
        { title() }
        <form ref = { formRef }>
          <AssetSelect
            type     = 'site'
            onChange = { changeAssets }
            options  = {
              {
                displayContact:     true,
                displayLink:        true,
                displayAddress:     true,
                displayHistory:     true,
                displayObservation: true,
                displayContact:     true,
                displayUnpaid:      displayUnpaid,
                required: {
                  client:   true,
                  site:     true
                }
              }
            }
            data={
              {
                client:  workorderState.clientId,
                site:    workorderState.siteId,
                contact: workorderState.contactId
              }
            }
          />
          <Type
            materials               = { siteMaterials }
            workorderState          = { workorderState }
            setWorkorderState       = { setWorkorderState }
            />
          { siteMaterials.length > 0 &&
            <Materials
              materials         = { siteMaterials }
              workorderState    = { workorderState }
              setWorkorderState = { setWorkorderState }
              blueprints        = { blueprints }
              allowRecurrency   = { allowRecurrency }
              />
            }
          <Input
            onChange     = { value => changeValue(value, 'title') }
            id           = "workorder_name"
            name         = "title"
            defaultValue = { workorderState.title || '' }
            placeholder  = { I18n.t("workorders.enter_title") }
            label        = { I18n.t("workorders.wo_title") }
            />
          <TextArea
            onChange     = { value => changeValue(value, 'description') }
            id           = "workorder_description"
            name         = "description"
            defaultValue = { workorderState.description || '' }
            placeholder  = { I18n.t("workorders.enter_description") }
            label        = { I18n.t("workorders.description") }
            required
          />
          <div className= "d-flex gap-5">
            <ManagerSelect
              managers = { managers }
              manager  = { workorderState.managerId }
              onChange = { managerId => { setWorkorderState({ ...workorderState, managerId: managerId }) } }
            />
            <TechnicianSelect
              technicians        = { technicians.filter(tech => !workorderState.secondaryTechniciansIds.includes(tech.id)) }
              technician         = { workorderState.technicianId }
              externalTechnician = { workorderState.externalTechnicianId }
              onTechChange       = { changeTechnician }
              onExternalChange   = { externalId => { setWorkorderState({ ...workorderState, externalTechnicianId: externalId }) } }
              />
          </div>
          <div className = "date-and-duration">
            <label className = "control-label" htmlFor = "workorder_date_planned">
              { I18n.t("workorders.due_date") }
            </label>
            <label className = "control-label"
                   htmlFor   = "workorder_predicted_duration"
                   >
              { I18n.t("workorders.duration_in_min") }
            </label>
          </div>
          <div className= "date-and-duration">
            <Passage
              index                   = { null }
              datePlanned             = { new Date(workorderState.datePlanned).toISOString() }
              predictedDuration       = { workorderState.predictedDuration }
              changePassageDate       = { date => { setWorkorderState({ ...workorderState, datePlanned: date.toISOString() })} }
              changePassageDuration   = { duration => { setWorkorderState({ ...workorderState, predictedDuration: duration > maximumDuration ? maximumDuration : duration })} }
              showPassageNumber       = { false }
            />
          </div>
          <Products
            workorderState    = { workorderState }
            setWorkorderState = { setWorkorderState }
          />
          {
            storehouses &&
            <DefaultStorehouse
              storehouses       = { storehouses }
              workorderState    = { workorderState }
              setWorkorderState = { setWorkorderState }
            />
          }

          <ExtraFieldsAndCategory
            workorderState     = { workorderState }
            setWorkorderState  = { setWorkorderState }
            categories         = { categories }
            workorderCategory  = { workorderState.categoryId }
            categoryFields     = { categoryFields }
            />
          <Checkboxes
            checkboxes              = { checkboxes }
            workorderState          = { workorderState }
            setWorkorderState       = { setWorkorderState }
          />
          <UploadFiles workorderState = { workorderState } setWorkorderState = { setWorkorderState }/>
          <div className="d-flex">
            <div className="ml-auto">
              <div className = "btn btn-outline-default small ml-0 h-fit-content" onClick={() => history.back()}>
                {I18n.t('button.cancel')}
              </div>
              <div
                className="btn btn-primary small ml-2"
                onClick  = { () => handleSubmit(formRef.current, false) }
                disabled = { !!isSubmitting }
              >
                { I18n.t('button.save') }
              </div>
              <div className="btn btn-primary small ml-2" onClick = { () => goToStepTwo() }>
                { I18n.t('button.plan') }
              </div>
            </div>
          </div>
        </form>
      </div>
      { !!isSubmitting &&
        <OverlaySpinner />
      }
    </div>
  )
}
