import { NOTICE } from './actions'

const initialState = {
  content: []
}

export default function noticesReducer(state = initialState, action) {
  switch (action.type) {

    case NOTICE.ADD:
      return NOTICE
        .addResponse({ state: state, notice: action.payload.notice })

    case NOTICE.DISMISS:
      return NOTICE
        .dismissResponse({ state: state, index: action.payload.index })

    default:
      return state
  }
}
