import { Synchronizer } from '@helpers/javascript/cable/synchronizer'

const shouldSync = workorder => {
  // If is set to false we should sync
  if (!workorder.synced)  return true

  // If is synced but is not syncing, is from the server, we should not resync
  if (!workorder.syncing) return false

  // If it is syncing but it took too long we retrigger the sync
  const timeout = Synchronizer.timeout * Synchronizer.maxRetries * 2
  return new Date().getTime() - workorder.version > timeout
}

export const syncWorkorderAction = workorder => {
  if (!shouldSync(workorder)) return

  const synchronizer = new Synchronizer({
    ...workorder,
    version: new Date().getTime()
  })
  synchronizer.transmit()
}

export const syncWorkorderLockAction = workorder => dispatch => {
  dispatch({
    type:    WORKORDER_SYNC.LOCK,
    payload: { workorder }
  })
}

export const syncWorkorderBeginAction = workorder => dispatch => {
  dispatch({
    type:    WORKORDER_SYNC.BEGIN,
    payload: { workorder }
  })
}

export const syncWorkorderSuccessAction = workorder => dispatch => {
  dispatch({
    type:    WORKORDER_SYNC.SUCCESS,
    payload: { workorder }
  })
}

export const syncWorkorderRetryAction = workorder => dispatch => {
  dispatch({
    type: WORKORDER_SYNC.RETRY,
    payload: { workorder }
  })
}

export const syncWorkorderFailureAction = (workorder, error) => dispatch => {
  dispatch({
    type: WORKORDER_SYNC.FAILURE,
    payload: { workorder, error }
  })
}

const replaceRecord = (collection, record) => {
  return collection.map(item => item.id === record.id ? record : item)
}

const replaceWorkorder = (state, workorder) => ({
  ...state,
  items:            replaceRecord(state.items,            workorder),
  today_workorders: replaceRecord(state.today_workorders, workorder)
})

export const WORKORDER_SYNC = {
  LOCK:    'WORKORDER_SYNC_LOCK',
  BEGIN:   'WORKORDER_SYNC_BEGIN',
  SUCCESS: 'WORKORDER_SYNC_SUCCESS',
  RETRY:   'WORKORDER_SYNC_RETRY',
  FAILURE: 'WORKORDER_SYNC_FAILURE',

  lockResponse: ({ state, workorder }) => {
    return ({
      ...state,
      items: state.items.map(item => item.id != workorder.id ? item : {
        ...item,
        locked: true
      }
    )})
  },

  beginResponse: ({ state, workorder }) => ({
    ...state,
    items: state.items.map(item => item.id != workorder.id ? item : {
      ...item,
      synced:  true,
      syncing: true,
      locked:  false,
      version: workorder.version,
      retries: 0,
      photos:  item.photos.map(photo => ({ ...photo, syncing: true }))
    })
  }),

  successResponse: ({ state, workorder }) => ({
    ...state,
    items: state.items.map(item => item.id != workorder.id ? item : workorder)
  }),

  retryResponse: ({ state, workorder }) => ({
    ...state,
    items: state.items.map(item => item.id != workorder.id ? item : {
      ...item,
      retries: workorder.retries += 1
    })
  }),

  failureResponse: ({ state, workorder, error }) => ({
    ...state,
    items: state.items.map(item => item.id != workorder.id ? item : {
      ...item,
      synced:  true,
      syncing: false,
      error
    })
  })
}


