import React from 'react'

export const StepMove = props => {
  const { steps, state, setState, minStep } = props
  const { material, parent, child, categories, currentStep } = state

  const availableSteps = steps.filter(step => {
    switch(step) {
      case 1: return minStep == 1
      case 2: return !!material.id && categories.length > 0
      case 3: return !!material.id && parent.children.length > 0
      case 4: return !!material.id
    }
  })

  const showButtonRight = () => {
    switch(currentStep) {
      case 1: return material.id
      case 2: return parent.id
      case 3: return child.id
      case 4: return false
    }
  }

  const foward = () => {
    const nextStep = availableSteps.find(step => currentStep < step)
    setState({ ...state, currentStep: nextStep })
  }

  const backwards = () => {
    const nextStep = availableSteps.reverse().find(step => step < currentStep)
    setState({ ...state, currentStep: nextStep })
  }

  const submit = () => {
    setState({...state, loading: true})
    document.getElementById("new_workorder_demand").submit()
  }
  
  return(
    <>
      { currentStep === steps.length
        ? <button type="submit" onClick={submit} className="demands--button__flow right">
            <i className="fas fa-check"></i>
          </button>
        : showButtonRight() &&
          <div className="demands--button__flow right" onClick={foward}>
            <i className="fas fa-arrow-right"></i>
          </div>
      }

      { currentStep > minStep &&
        <div className="demands--button__flow left" onClick={backwards}>
          <i className="fas fa-arrow-left"></i>
        </div>
      }
    </>
  )
}
