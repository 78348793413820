import React from 'react'

export const ExistingEventCard = ({ info }) => {  
  return (
    <div>
      <div className='flexbox-space-between line-one' data-action="application-list#show">
        <b>{info.title}</b>
        <div className="duration"><i className="fas fa-hourglass-half mr-1"></i>{info.duration}h</div> 
      </div>
      <div className="mt-1 line-two flexbox-space-between ">
        <div className="event-address">{info.second_line}</div>
      </div>
      <div className='mt-1 line-three'>
        {info.event_start} - {info.event_end}
      </div>
    </div>
  )
}