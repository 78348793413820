import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon }            from '@fortawesome/react-fontawesome'
import SharedFlatpickr                from "@shared/flatpickr"
import Checkbox                       from '@shared/checkbox/checkbox'

const DAILY       = "DAILY"
const WEEKLY      = "WEEKLY"
const MONTHLY     = "MONTHLY"
const YEARLY      = "YEARLY"
const FREQUENCIES = [DAILY, WEEKLY, MONTHLY, YEARLY]

const defaultRrule = {
  frequency: MONTHLY,
  interval:  1,
  bysetpos:  1,
  byday:     new Date().getDay() - 1,
  bymonth:   new Date().getMonth() + 1,
  endDate:   ''
}

// 5 -> [1, 2, 3, 4, 5]
const incrementalArrayOf = length => new Array(length).fill(0).map((_, i) => i + 1)

export const Recurrency = ({ workorderState, setWorkorderState }) => {
  const [rrule, setRrule] = useState(defaultRrule)

  const display = {
    interval: [WEEKLY, MONTHLY, YEARLY].includes(rrule.frequency),
    byday:    [WEEKLY, MONTHLY, YEARLY].includes(rrule.frequency),
    bysetpos: [MONTHLY, YEARLY].includes(rrule.frequency),
    bymonth:  [YEARLY].includes(rrule.frequency)
  }

  let maxInterval
  switch(rrule.frequency) {
    case DAILY:   maxInterval = 0;  break;
    case WEEKLY:  maxInterval = 12; break;
    case MONTHLY: maxInterval = 12; break;
    case YEARLY:  maxInterval = 12; break;
  }

  const intervals = incrementalArrayOf(maxInterval)

  const deleteEndDate = e => {
    e.preventDefault()
    setRrule({...rrule, endDate: defaultRrule.endDate})
  }

  useEffect(() => {
    setWorkorderState({ ...workorderState, rrule })
  }, [rrule])

  return(
    <>
      <Checkbox
        id           = { "automatic_generation" }
        label        = { I18n.t("maintenance.plan_automatic_generation") }
        checked      = { workorderState.automaticGeneration || true }
        onChange     = { value => setWorkorderState({ ... workorderState, automaticGeneration: value }) }
        className    = "checkbox-primary"
      />

      <div className="d-flex a-i-center">
        <div className="mr-2">
          <label>
            <strong>{I18n.t("maintenance.frequency_every")}</strong>
            {I18n.t("maintenance.repeat_every")}
          </label>
        </div>

        { display.interval &&
          <div className="mr-2" style = {{ width: '80px'}}>
            <select
              name      = "maintenance_plan[interval]"
              className = "form-control"
              value     = {rrule.interval}
              onChange  = {e => setRrule({...rrule, interval: e.currentTarget.value})}
            >
              {intervals.map(interval =>
                <option value={interval} key={interval}>
                  {interval}
                </option>
              )}
            </select>
          </div>
        }

        <div className="mr-2">
          <select
            name      = "maintenance_plan[frequency]"
            className = "form-control"
            value     = {rrule.frequency}
            onChange  = {e => setRrule({...rrule, frequency: e.currentTarget.value})}
          >
            {FREQUENCIES.map((frequency, index) =>
              <option value={frequency} key={index}>
                {I18n.t("maintenance.frequencies.singular")[index]}
              </option>
            )}
          </select>
        </div>

        <div className="mr-2">
          <label>
            {I18n.t("maintenance.recurrency_from_to")}
          </label>
        </div>

        <div className="mr-2">
          <SharedFlatpickr
            options={{ locale: I18n.locale, dateFormat: 'd/m/Y' }}
            className = "form-control flatpickr-nobg"
            name      = "maintenance_plan[end_date]"
            onChange  = {dates => setRrule({...rrule, endDate: dates[0].toISOString()})}
            value     = {rrule.endDate}
          />
        </div>

        { rrule.endDate &&
          <div className="client-details mr-2">
            <a href='#' className="m-0" onClick={deleteEndDate}>
              <FontAwesomeIcon icon = "times"/>
            </a>
          </div>
        }
      </div>
    </>
  )
}
