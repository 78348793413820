import React    from "react"
import Checkbox from '@shared/checkbox/checkbox'

export const Checkboxes = ({ 
  checkboxes, 
  workorderState, 
  setWorkorderState, 
}) => {
  const onChange = (value, field) => {
    setWorkorderState({...workorderState, [field]: value})
  }

  return (
    <>
      {
        workorderState.purchaseDeliveryTitle && 
          <div className = "form-group">
            <label>{ I18n.t("purchase_orders.purchase_order") }</label>
            <input className = "form-control" value = { workorderState.purchaseDeliveryTitle } disabled />
          </div>
      }

      {
        workorderState.quoteTitle && 
          <div className = "form-group">
            <label>{ I18n.t("interventions.quote_associated") }</label>
            <input className = "form-control" value = { workorderState.quoteTitle } disabled />
          </div>
      }

      {
        workorderState.materialRentalTitle && 
          <div className = "form-group">
            <label>{ I18n.t("interventions.material_rental_associated") }</label>
            <input className = "form-control" value = { workorderState.materialRentalTitle } disabled />
          </div>
      }

      {
        checkboxes.map(checkbox => {
          return (
            <Checkbox
              key          = { checkbox.name }
              id           = { checkbox.name }
              label        = { I18n.t(checkbox.label) }
              checked      = { workorderState[checkbox.name] || false }
              onChange     = { value => onChange(value, checkbox.name) }
              className    = "checkbox-primary"
            />
          )
        })
      }
    </>
  )
}