import React from "react"

// Helpers
import {lineNameFor, roundNumber} from '../../helpers'

export default class Inputs extends React.Component {
  constructor(props) {
    super(props)
    this.onChange  = this.onChange.bind(this)
    this.changeVat = this.changeVat.bind(this)
  }

  onChange(e, attribute) {
    e.preventDefault()
    const value = e.target.value
    const { index, replaceLine, qline, quote } = this.props

    if (quote.allow_markup && attribute == "purchase_price") {
      const purchase_price = value
      const unit_price     = roundNumber(purchase_price * quote.price_markup_coefficient)
      replaceLine({...qline, purchase_price, unit_price}, index)
    } else {
      replaceLine({...qline, [attribute]: value}, index)
    }
  }

  changeVat(e) {
    e.preventDefault()
    const { index, replaceLine, qline, vats } = this.props
    const vat = vats.find((vat) => vat.id == e.target.value)
    replaceLine({...qline, vat_rate: vat || {}}, index)
  }

  render() {
    const { quote, qline, vats, index, showMargin } = this.props

    const minimalQuantityTooltip = qline.can_be_deleted
        ? null
        : I18n.t("quotes.new.quantity_minimal", { min: qline.quantity_invoiced })

    return(
      <div className="row-inputs" style={{gridTemplateColumns: `repeat(${showMargin ? '7' : '6'},  1fr)`}}>
        <div>
          <label>{I18n.t("quotes.bigcard.reference")}</label>

          <input className="form-control"
            value={qline.ref_product_distrib || ""}
            onChange={e => this.onChange(e, "ref_product_distrib")}
            name={lineNameFor("ref_product_distrib", index)}
          />
        </div>

        <div {...(minimalQuantityTooltip ? { tooltip: minimalQuantityTooltip, tooltip_position: "top" } : {})}>
          <label required>{I18n.t("quotes.new.quantity")}</label>

          <input className="form-control text-right"
            type     = "number"
            min      = {qline.quantity_invoiced}
            step     = "0.01"
            value    = {qline.quantity || ""}
            onChange = {e => this.onChange(e, "quantity")}
            name     = {lineNameFor("quantity", index)}
            required
          />
        </div>

        <div>
          <label>{I18n.t("quotes.bigcard.unit")}</label>

          <input className="form-control"
            value={qline.unit || ""}
            onChange={e => this.onChange(e, "unit")}
            name={lineNameFor("unit", index)}
          />
        </div>

        { showMargin &&
          <>
            <div>
              <label>{I18n.t("quotes.new.purchase_price")}</label>

              <input className="form-control text-right"
                type="number"
                step="0.01"
                value={qline.purchase_price || ""}
                onChange={e => this.onChange(e, "purchase_price")}
                name={lineNameFor("purchase_price", index)}
              />
            </div>
          </>
        }

        <div>
          <label required>{I18n.t("quotes.new.sale_price")}</label>

          <input className="form-control text-right"
            type     = "number"
            step     = "0.01"
            value    = {qline.unit_price || ""}
            onChange = {e => this.onChange(e, "unit_price")}
            name     = {lineNameFor("unit_price", index)}
            required
          />
        </div>

        <div>
          <label required>{I18n.t("quotes.bigcard.vat")}</label>

          <select
            className="form-control height-41"
            value={qline.vat_rate.id || ""}
            onChange={this.changeVat}
            name={lineNameFor("vat_rate_id", index)}
            required
          >
            <option value="">{I18n.t("sites.select_vat")}</option>
            { vats.map((vat, vatIndex) =>
                <option key={vatIndex} value={vat.id}>{vat.label}</option>
              )
            }
          </select>
        </div>

        <div>
          <label>{I18n.t("quotes.new.discount")}</label>

          <input className="form-control text-right"
            type="number"
            step="0.01"
            max="100"
            value={qline.discount || ""}
            onChange={e => this.onChange(e, "discount")}
            name={lineNameFor("discount", index)}
          />
        </div>
      </div>
    )
  }
}
