import React               from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Product }         from "./product"

export const Products = ({ workorderState, setWorkorderState }) => {  
  const products = workorderState.invoiceLines || []

  const handleProductChange = (productId, quantity) => {
    const copiedProducts = [...products]
    const changedElement     = products.findIndex(el => el.productId === productId)
    if (changedElement >= 0) {
      copiedProducts[changedElement]['quantity'] = quantity
    } else {
      copiedProducts.push({ productId, quantity })
    }
    setWorkorderState({ ...workorderState, invoiceLines: copiedProducts })
  }

  const updateProduct = (previousId, newId, name) => {
    const copiedProducts = [...products]
    const changedElement = products.findIndex(el => el.productId === previousId)
    copiedProducts[changedElement]['productId'] = newId
    copiedProducts[changedElement]['name']      = name
    
    setWorkorderState({ ...workorderState, invoiceLines: copiedProducts })
  }

  const addProduct = () => {
    const newProducts = [...products]
    newProducts.push({ productId: null, quantity: "", name: '' })
    setWorkorderState({ ...workorderState, invoiceLines: newProducts })
  }

  const removeProduct = id => {
    const newProducts     = [...products]
    const productToRemove = newProducts.findIndex(product => product.productId === id)
    newProducts.splice(productToRemove, 1)
    setWorkorderState({ ...workorderState, invoiceLines: newProducts })
  }
   
  return (
    <>
      <div className ="cocoon-grid mb-2">
        <div className ="cocoon-grid__header">
          <label>{ I18n.t("products.product") }</label>
          <label className ="g-c-span-2">{ I18n.t("interventions.quantity") }</label>
        </div>
      </div>
      { 
        products.map((product, index) => {
          return (
            <Product 
              key           = { index } 
              product       = { product } 
              technician    = { workorderState.technicianId }
              onChange      = { handleProductChange }
              updateProduct = { updateProduct }
              removeProduct = { removeProduct }
            />
          )
        })
      }

      <a className = 'small flex-end text-pointer' onClick = { addProduct }>
        <FontAwesomeIcon icon='plus-circle' className="mr-2"/>
        { I18n.t("interventions.add_product") }
      </a>
    </>
  )
}