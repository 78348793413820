import React from "react"

// Components
import CreationDate    from "./creation_date"
import Client          from "./client"
import Site            from "./site"
import Contact         from "./contact"
import Title           from "./title"
import Qlines          from "./qlines/qlines"
import Details         from "./details"
import Observations    from "./observations"
import Manager         from "./manager"
import Status          from "./status"
import SubmitButtons   from "./submit_buttons"
import CheckboxField   from "./checkbox_field"

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {quote: this.props.data}
    this.replaceQuote = this.replaceQuote.bind(this)
  }
  replaceQuote(quote) { 
    this.setState({quote}) 
  }

  render() {
    const quote = this.state.quote

    return(
      <React.Fragment>
        {/*Logo, Quote number, Creation date & expiration_date*/}
        <div className="row">
          <div className="col-6">
            <img
              src={quote.account.logo}
              alt="company logo"
              className="quotes--form-header-logo"
              height="90"
            />
          </div>

          <div className="col-6">
            <div className="quotes--form-header-title">
              { quote.is_contract_offer
                  ? quote.number
                    ? I18n.t("quotes.pdf.quote_contract", {number: quote.number})
                    : I18n.t("quotes.new.new_quote_contract")
                  : quote.number
                    ? I18n.t("quotes.pdf.quote", {number: quote.number})
                    : I18n.t("quotes.new.new_quote")
              }
            </div>

            <CreationDate
              quote={quote}
              replaceQuote={this.replaceQuote}
            />
          </div>
        </div>

        {/*Personalized Header & Client*/}
        <div className="row mt-3">
          <div className="col-6 no-margins"
               dangerouslySetInnerHTML={{__html: quote.account.pdf_header || "-"}}
          >
          </div>

          <div className="col-6">
            <Client
              quote={quote}
              replaceQuote={this.replaceQuote}
            />
          </div>
        </div>

        {/*Site*/}
        <div className="row mt-3">
          <div className="col-12">
            <Site
              quote={quote}
              replaceQuote={this.replaceQuote}
            />
          </div>
        </div>

        {/*Contact*/}
        <div className="row">
          <div className="col-12">
            <Contact
              quote={quote}
              replaceQuote={this.replaceQuote}
            />
          </div>
        </div>

        {/*Title*/}
        <div className="row">
          <div className="col-12">
            <Title
              quote={quote}
              replaceQuote={this.replaceQuote}
            />
          </div>
        </div>

        {/*Qlines Table*/}
        <div className="row">
          <div className="col-12">
            <Qlines
              quote={quote}
              replaceQuote={this.replaceQuote}
            />
          </div>
        </div>

        {/*VAT labels & Expiration Date*/}
        <div className="row">
          <div className="col-12">
            <Details
              quote={quote}
              replaceQuote={this.replaceQuote}
            />
          </div>
        </div>

        {/*Observations & Footer*/}
        <div className="row">
          <div className="col-12">
            <Observations
              quote={quote}
              replaceQuote={this.replaceQuote}
            />
          </div>
        </div>

        {/*Manager and Status*/}
        <div className="row">
          <div className="col-6">
            <Manager
              quote={quote}
              replaceQuote={this.replaceQuote}
              managers={quote.account.managers}
            />
          </div>

          <div className="col-6">
            <Status
              quote={quote}
              replaceQuote={this.replaceQuote}
            />
          </div>

          {/* Pdf: Show site address */}
          <div className="col-6 my-auto">
            <CheckboxField
              checked={quote.show_site_address}
              onChange={() => this.replaceQuote({ ...quote, show_site_address: !quote.show_site_address })}
              name="quote[show_site_address]"
              label={I18n.t("quotes.new.show_site_address")}
              index="show_site_address"
            />
          </div>

          {/* Pdf: Show totals */}
          <div className="col-6 my-auto">
            <CheckboxField
              checked={quote.show_totals}
              onChange={() => this.replaceQuote({ ...quote, show_totals: !quote.show_totals })}
              name="quote[show_totals]"
              label={I18n.t("quotes.new.show_totals")}
              index="show_totals"
            />
          </div>

          {/* Pdf: Show articles */}
          <div className="col-6 my-auto">
            <CheckboxField
              checked={quote.show_products}
              onChange={() => this.replaceQuote({ ...quote, show_products: !quote.show_products })}
              name="quote[show_products]"
              label={I18n.t("quotes.new.show_products")}
              index="show_products"
            />
          </div>

          {/* Pdf: Show line price details */}
          <div className="col-6 my-auto">
            <CheckboxField
              checked={quote.show_line_price_details}
              onChange={() => this.replaceQuote({ ...quote, show_line_price_details: !quote.show_line_price_details })}
              name="quote[show_line_price_details]"
              label={I18n.t("quotes.new.show_line_price_details")}
              index="show_line_price_details"
            />
          </div>

          {/* Show products avatar */}
          { quote.account.allow_product_avatar_in_quotes &&
            <div className="col-6 my-auto">
              <CheckboxField
                checked={quote.show_product_avatar}
                onChange={() => this.replaceQuote({ ...quote, show_product_avatar: !quote.show_product_avatar })}
                name="quote[show_product_avatar]"
                label={I18n.t("quotes.new.show_avatar")}
                index="show_product_avatar"
              />
            </div>
          }

          {/* Show margin */}
          { quote.account.hide_margin_visibility_in_quotes &&
            <div className="col-6 my-auto">
              <CheckboxField
                checked={quote.show_margin}
                onChange={() => this.replaceQuote({ ...quote, show_margin: !quote.show_margin })}
                name="quote[show_margin]"
                label={I18n.t("quotes.new.show_margin")}
                index="show_margin"
              />
            </div>
          }

          {/* Pdf: Show table header */}
          <div className="col-6 my-auto">
            <CheckboxField
              checked={quote.show_table_header}
              onChange={() => this.replaceQuote({ ...quote, show_table_header: !quote.show_table_header })}
              name="quote[show_table_header]"
              label={I18n.t("quotes.new.show_table_header")}
              index="show_table_header"
            />
          </div>

          {/* Switch to a contract offer */}
          { quote.account.allow_contract_offers_in_quotes &&
            <div className="col-6 my-auto">
              <CheckboxField
                checked={quote.is_contract_offer}
                onChange={() => this.replaceQuote({ ...quote, is_contract_offer: !quote.is_contract_offer })}
                name="quote[is_contract_offer]"
                label={I18n.t("quotes.new.is_contract_offer")}
                index="is_contract_offer"
              />
            </div>
          }

          {/* Automatic reminders enabled */}
          { quote.account.allow_reminders &&
            <div className="col-6 my-auto">
              <CheckboxField
                checked={quote.reminders_enabled}
                onChange={() => this.replaceQuote({ ...quote, reminders_enabled: !quote.reminders_enabled })}
                name="quote[reminders_enabled]"
                label={I18n.t("reminders.title")}
                index="reminders_enabled"
              />
            </div>
          }
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>{I18n.t("quotes.new.internal_info")}</label>
              <textarea
                name="quote[internal_info]"
                className="form-control autosize"
                value={quote.internal_info || ""}
                onChange={e => this.replaceQuote({...quote, internal_info: e.target.value})}
                data-controller="autoSize"
                data-target="autoSize.value"
                data-action="input->autoSize#changeHeight"
              />
            </div>
          </div>
        </div>

        <SubmitButtons quote={quote} backRoute={this.props.back_route} />

      </React.Fragment>
    )
  }
}
