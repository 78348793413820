import { getRequest } from '@helpers/javascript/javascript'

const url = '/notifications'

export const fetchNotificationsAction = () => dispatch => getRequest(url)
  .then(notifications => dispatch({
    type:    NOTIFICATIONS_FETCH.ACTION,
    payload: { notifications }
  }))


export const NOTIFICATIONS_FETCH = {
  ACTION: 'FETCH_NOTIFICATIONS_ACTION',
  call: ({ state, items, unread }) => ({ ...state, items, unread })
}
