import React, { useEffect, useState } from "react"
import { FontAwesomeIcon }            from '@fortawesome/react-fontawesome'
import { bodyRequest }                from '@helpers/javascript/javascript'

const TodayOverviewLine = ({ icon, sentence, color, link }) => (
  <div className="dashboard__today__line" style={{ color: color ? color : 'black' }}>
    <FontAwesomeIcon icon={icon} />
    {sentence}
    {link &&
      <a href={link} target="_blank" className="dashboard__btn dashboard__btn--arrow">
        <FontAwesomeIcon icon="arrow-right" />
      </a>
    }
  </div>
)

export const TodayOverview = ({ url, team, invoices }) => {

  const [workordersCount, setWorkordersCount]           = useState(0)
  const [toInvoice, setToInvoice]                       = useState(0)
  const [pastScheduled, setPastScheduled]               = useState(0)
  const [typeCount, setTypeCount]                       = useState({ 'Reactive': 0, 'Preventive': 0, 'Installation': 0})
  const [workordersPath, setWorkordersPath]             = useState(false)
  const [unbilledPath, setUnbilledPath]                 = useState(false)
  const [pastScheduledPath, setPastScheduledPath]       = useState(false)


  const fetchData = () => {
    bodyRequest({
      method: 'POST', url,
      body: { users: team }
    }).then(data => {
      setWorkordersCount(data.total)
      setToInvoice(data.to_invoice)
      setPastScheduled(data.past_scheduled)
      setTypeCount(data.count_types)
      setWorkordersPath(data.workorders_path)
      setUnbilledPath(data.unbilled_path)
      setPastScheduledPath(data.past_scheduled_path)
    })
  }

  useEffect(() => fetchData(), [])

  return(
    <div className="dashboard__card">
      <div className="dashboard__today__header">
        <h2>{I18n.t('dashboard.today')}</h2>
      </div>
      <div className="dashboard__today__grid">
        <TodayOverviewLine
          icon     = "calendar"
          sentence={I18n.t('dashboard.today_count', { workordersCount })}
          link     = {workordersPath}
        />

        <TodayOverviewLine
          icon     = {pastScheduled === 0 ? "thumbs-up" : "exclamation-triangle"}
          sentence = {pastScheduled === 0 ? I18n.t('dashboard.past_scheduled_none') : I18n.t('dashboard.past_scheduled_count', { pastScheduled })}
          link     = {pastScheduledPath}
          color    = {pastScheduled > 0 ? 'orange' : 'black' }
        />

        <TodayOverviewLine
          icon     = "search"
          sentence = {I18n.t('dashboard.workorder_type', { reactive: typeCount['Reactive'] || 0, preventive: typeCount['Preventive'] || 0, installations: typeCount['Installation'] || 0})}
          link     = {false}
        />

        { invoices &&
          <TodayOverviewLine
            icon     = "file-invoice"
            sentence = {I18n.t('dashboard.to_invoice', { toInvoice })}
            link     = {unbilledPath}
          />
        }

      </div>
    </div>
  )
}
