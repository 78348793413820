import React, { useEffect, useState } from "react"
import { ResponsivePie } from '@nivo/pie'

export const DailyStatus = ({ data }) => {
  const STATUS_HEIGHT     = 400
  const [total, setTotal] = useState(0)

  useEffect(() => {
    const currentTotal = data.map(v => v['value']).reduce((a, b) => a + b, 0)
    setTotal(currentTotal)
  }, [data])

  return (
    <div className="dashboard__card mr-3" style={{ height: `${STATUS_HEIGHT}px`}}>
      {total === 0
        ? <div className="dashboard__card--empty">{I18n.t('dashboard.no_data')}</div>
        : <ResponsivePie
          data         = {data}
          margin       = {{ top: 0, right: 0, bottom: 80, left: 40 }}
          innerRadius  = {0.5}
          padAngle     = {0.7}
          cornerRadius = {3}
          borderWidth  = {1}
          colors       = {d => d.color}
          radialLabel  = {d => d.label}
          radialLabelsSkipAngle   = {10}
          radialLabelsTextXOffset = {6}
          radialLabelsTextColor   =  "#333333"
          radialLabelsLinkDiagonalLength   ={16}
          radialLabelsLinkHorizontalLength = {24}
          radialLabelsLinkStrokeWidth      = {1}
          radialLabelsLinkColor            = {{ from: 'color' }}
          slicesLabel           = {d => d.label}
          slicesLabelsSkipAngle = {10}
          slicesLabelsTextColor = "#333333"
          startAngle      = {270}
          animate
          motionStiffness = {90}
          motionDamping   = {15}
          legends={[
            {
              anchor:        'bottom',
              direction:     'row',
              translateY:    56,
              itemWidth:     100,
              itemHeight:    18,
              itemTextColor: '#999',
              symbolSize:    18,
              symbolShape:   'circle',
              effects: [{
                  on: 'hover',
                  style: { itemTextColor: '#000' }
              }]
            }
          ]}
        />
      }
    </div>
  )
}
