// Modules
import React, { useState, useEffect } from "react"

import { getRequest }      from '@react/helpers/fetch'
import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas }             from '@fortawesome/free-solid-svg-icons'
library.add(fas)

import { CostsFilters }    from "./costsFilters"
import { CostsStatistics } from "./costsStatistics"
import { CostsTable }      from "./costsTable"

const App = ({ feedUrl, initialFilter, showRevenue }) => {
  const [filter,  setFilter]  = useState(initialFilter)
  const [data,    setData]    = useState({})
  const [loading, setLoading] = useState(true)

  const feedData = () => {
    setLoading(true)
    const url = new URL(location.origin + feedUrl)
    url.searchParams.set('from',  filter.from)
    url.searchParams.set('to',    filter.to)

    if(filter.asset) {
      url.searchParams.set(filter.asset.type, filter.asset.id)
    }

    if (filter.type) {
      url.searchParams.set('type', filter.type.map(t => t.id))
    }

    if (filter.createdFrom) {
      url.searchParams.set('created_from', filter.createdFrom.map(c => c.id))
    }

    getRequest(url)
    .then(data => {
      history.replaceState(history.state, document.title, url.href.replace(".json", ""))
      setData(data)
      setLoading(false)
    })
  }

  useEffect(feedData, [filter])

  return(
    <div>
      <CostsFilters filter={filter} setFilter={setFilter} showRevenue={showRevenue} />
      { loading
        ? <div className="loading-spinner-container-sm">
            <div id="loading-spinner"></div>
          </div>
        : <React.Fragment>
            <CostsStatistics
              totals      = {data.totals}
              showRevenue = {showRevenue}
            />

            <CostsTable
              filter      = {filter}
              setFilter   = {setFilter}
              totals      = {data.totals}
              lines       = {data.lines}
            />
          </React.Fragment>
      }
    </div>
  )
}

export default App

// const mockFilter = {
//   from: "2021/01/01",
//   to:   "2021/01/31",
//   type: "preventive"
// }


// const mockTotals = {
//   count:    3,
//   time:     '25:00',
//   labor:    '2350,00 €',
//   products: '2111.98 €',
//   total:    '4431,98 €'
// }

// const mockLines = [
//   {
//     id:       1,
//     type:     "site",
//     name:     "site 1",
//     count:    3,
//     time:     "2:00",
//     labor:    '350,00 €',
//     products: '150,20 €',
//     total:    '500,20 €'
//   },
//   {
//     id:       2,
//     type:     "site",
//     name:     "site 2",
//     count:    2,
//     time:     "2:00",
//     labor:    '150,00 €',
//     products: '450,20 €',
//     total:    '600,20 €'
//   },
//   {
//     id:       3,
//     type:     "site",
//     name:     "site 3",
//     count:    3,
//     time:     "2:00",
//     labor:    '650,00 €',
//     products: '250,20 €',
//     total:    '500,20 €'
//   },
//   {
//     id:       4,
//     type:     "site",
//     name:     "site 5",
//     count:    3,
//     time:     "2:00",
//     labor:    '350,00 €',
//     products: '150,20 €',
//     total:    '500,20 €'
//   }
// ]
